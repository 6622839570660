import React, { useRef, useEffect, useState } from 'react';

const Scrollbox = (props) => {
    const ref = useRef();
    const innerRef = useRef();
    const [isTop, setIsTop] = useState(true);
    const [isBottom, setIsBottom] = useState(true);

    const update = () => {
        if (ref.current) {
            setIsTop(ref.current.scrollTop === 0);
            setIsBottom(
                ref.current.scrollHeight - ref.current.clientHeight - ref.current.scrollTop <= 1 ||
                    ref.current.clientHeight === ref.current.scrollHeight
            );
        }
    };

    useEffect(() => {
        const listener = () => update();
        const domElement = ref.current;
        let observer;

        if (domElement) domElement.addEventListener('scroll', listener);

        if ('ResizeObserver' in window) {
            observer = new ResizeObserver(listener);
            observer.observe(innerRef.current);
        }
        update();

        // Cleanup event listeners to avoid a memory leak
        return () => {
            if (domElement) {
                domElement.removeEventListener('scroll', listener);
            }

            observer.disconnect();
        };
    }, []);

    return (
        <div className={`scrollbox-outer ${props.className ? props.className : ''}`}>
            <div
                className={`scrollbox-gradient scrollbox-gradient-top ${
                    isTop || props.hideTopGradient ? 'hidden' : ''
                }`}
            />
            <div ref={ref} className="scrollbox">
                <div ref={innerRef}>{props.children}</div>
            </div>
            <div className={`scrollbox-gradient scrollbox-gradient-bottom ${isBottom ? 'hidden' : ''}`} />
            <div className={`scroll-prompt scroll-prompt-bottom fal fa-angles-down ${isBottom ? 'hidden' : ''}`} />
        </div>
    );
};

export default Scrollbox;
