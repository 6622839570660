import { Cache, FileLoader, ImageLoader } from 'three';
import * as helpers from '@turf/helpers';
import booleanIntersects from '@turf/boolean-intersects';
import Fetcher from '@giro3d/giro3d/utils/Fetcher';
import * as geojsonUtils from 'geojsonUtils';
import HttpConfiguration from '@giro3d/giro3d/utils/HttpConfiguration';
import { type Instance } from '@giro3d/giro3d/core';
import { type Extent } from '@giro3d/giro3d/core/geographic';
import type { Geometry } from 'geojson';

import Dataset, {
    SingleBandCogDatasetProperties,
    MultiBandCogDatasetProperties,
    EmptyDatasetProperties,
    MosaicDatasetProperties,
    SeismicDatasetProperties,
    TrackDatasetProperties,
    LasDatasetProperties,
} from 'types/Dataset';

import Layer, { HostView } from 'giro3d_extensions/layers/Layer';
import { DefaultQueue } from '@giro3d/giro3d/core/RequestQueue';
import { GlobalCache } from '@giro3d/giro3d/core/Cache';
import { getApiUrl } from 'config';
import { SourceFile } from 'types/SourceFile';
import type { GeometryWithCRS } from 'types/common';
import LayerManager from 'giro3d_extensions/LayerManager';
import { Dispatch } from 'store';
import { LAYER_TYPES, SOURCE_FILE_STATES } from '../../services/Constants';
import { BuildContext } from './LayerBuilderFactory';

const BASE_API = getApiUrl();

async function fetchGeometryOnce(url: string): Promise<GeometryWithCRS> {
    const cached = GlobalCache.get(url);
    if (cached) {
        return cached as GeometryWithCRS;
    }

    const request: () => Promise<GeometryWithCRS> = async () => Fetcher.json(url);
    const result = (await DefaultQueue.enqueue({ id: url, request })) as GeometryWithCRS;

    GlobalCache.set(url, result);

    return result;
}

// Patching ImageLoader to support credentials
// Used for texture loading of FBX objects, but might be used elsewhere at some point
// https://github.com/mrdoob/three.js/issues/10439#issuecomment-275785639
ImageLoader.prototype.load = function load(url, onLoad, onProgress, onError) {
    if (this.path !== undefined) url = this.path + url;

    url = this.manager.resolveURL(url);
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const scope = this;

    const opts = { headers: Object.assign(scope.requestHeader) };
    HttpConfiguration.applyConfiguration(url, opts);
    scope.withCredentials = true;

    const cached = Cache.get(url);

    if (cached !== undefined) {
        scope.manager.itemStart(url);
        setTimeout(() => {
            if (onLoad) onLoad(cached);
            scope.manager.itemEnd(url);
        }, 0);
        return cached;
    }

    const image = document.createElementNS('http://www.w3.org/1999/xhtml', 'img') as HTMLImageElement;

    image.onload = function onload() {
        image.onload = null;
        URL.revokeObjectURL(image.src);
        Cache.add(url, this);
        if (onLoad) onLoad(image);
        scope.manager.itemEnd(url);
    };
    image.onerror = onError;

    const loader = new FileLoader();
    loader.setResponseType('blob');
    loader.setRequestHeader(opts.headers);
    loader.setWithCredentials(this.withCredentials);
    loader.load(
        url,
        (blob) => {
            // @ts-expect-error incorrect typing
            image.src = URL.createObjectURL(blob as Blob);
        },
        onProgress,
        onError
    );

    scope.manager.itemStart(url);
    return image;
};

export default abstract class LayerBuilder<
    TProps extends
        | LasDatasetProperties
        | TrackDatasetProperties
        | SingleBandCogDatasetProperties
        | MultiBandCogDatasetProperties
        | EmptyDatasetProperties
        | MosaicDatasetProperties
        | SeismicDatasetProperties,
    TOutput = Layer,
> {
    protected readonly _dataset: Dataset;
    protected readonly _sourceFiles: SourceFile[];
    protected readonly _apiUrl: string;
    protected readonly _layerManager: LayerManager;
    protected readonly _dispatch: Dispatch;
    protected _geometry: GeometryWithCRS;
    protected _giro3dInstance: Instance;
    protected _hostView: HostView;

    constructor(dataset: Dataset, sourceFiles: SourceFile[], context: BuildContext) {
        this._dataset = dataset;
        this._sourceFiles = sourceFiles;
        this._layerManager = context.layerManager;
        this._apiUrl = BASE_API;
        this._giro3dInstance = context.instance;
        this._dispatch = context.dispatch;
        this._hostView = context.hostView;
    }

    protected getInstance() {
        if (!this._giro3dInstance) {
            throw new Error('instance is not set');
        }
        return this._giro3dInstance;
    }

    protected getInstanceCrs() {
        return this.getInstance().referenceCrs;
    }

    protected buildUrl(source: string) {
        return `${this._apiUrl}${source}`;
    }

    /** @deprecated */
    protected buildDatasetLinkUrl() {
        return this.buildUrl(this._dataset.links.download);
    }

    protected buildDatasetAttachmentUrls() {
        return this._dataset.links.attachments.map((link) => this.buildUrl(link));
    }

    protected buildDatasetGeometryUrl() {
        return this.buildUrl(this._dataset.links.geometry);
    }

    /** @deprecated */
    protected checkDownloadLink() {
        if (!this._dataset.links.download) {
            throw new Error(`Error building dataset ${this._dataset.id}: no download link defined`);
        }
    }

    protected checkDatasetAttachments() {
        if (!this._dataset.links.attachments) {
            throw new Error(`Error building dataset ${this._dataset.id}: no attachments' links defined`);
        }
    }

    protected isDatasetElevation() {
        return [LAYER_TYPES.BATHYMETRY, LAYER_TYPES.HORIZON].includes(this.getDatasetType());
    }

    getDatasetId() {
        return this._dataset.id;
    }

    getDatasetName() {
        return this._dataset.name;
    }

    getDatasetSourceRef() {
        return this._dataset.source_ref;
    }

    async getSourceFiles(): Promise<SourceFile[]> {
        return this._sourceFiles;
    }

    getDatasetProjection() {
        return this._dataset.projection;
    }

    getDatasetProjectionAsString() {
        return `EPSG:${this._dataset.projection}`;
    }

    async getDatasetGeometry() {
        if (!this._geometry) {
            if (!this._dataset.links.geometry) {
                throw new Error(`Error loading dataset ${this._dataset.id}: no geometry link defined`);
            }
            this._geometry = await fetchGeometryOnce(this.buildDatasetGeometryUrl());
        }
        return this._geometry;
    }

    protected async getDatasetFootprint(): Promise<GeometryWithCRS> {
        const geom = await this.getDatasetGeometry();
        return geom;
    }

    getDatasetType() {
        return this._dataset.type;
    }

    getDatasetDatatype() {
        return this._dataset.datatype;
    }

    getDatasetProperties() {
        return this._dataset.properties as TProps;
    }

    getDatasetCollectionId() {
        return this._dataset.collection_id;
    }

    /**
     * Returns a function that tests the intersection between
     * the given geometry and any arbitrary extent.
     */
    // eslint-disable-next-line class-methods-use-this
    getContainsFn(geometry: GeometryWithCRS) {
        // Testing the actual geometry might be very cosly. Let's use the convex hull.
        const actualGeometry = geojsonUtils.getConvexHull(geometry);

        function customIntersectionTest(extent: Extent) {
            if (!geometry) {
                return true;
            }

            const extentWithMargin = extent.withRelativeMargin(0.1);
            const left = extentWithMargin.west();
            const right = extentWithMargin.east();
            const top = extentWithMargin.north();
            const bottom = extentWithMargin.south();

            const corners = [
                [left, top],
                [right, top],
                [right, bottom],
                [left, bottom],
            ];

            const extentAsPolygon = helpers.polygon([[corners[0], corners[1], corners[2], corners[3], corners[0]]]);

            // Note: the Geometry type we use is the official definition from the geojson package,
            // but Turf.js redefines its own Geometry type. It is is almost identical, but
            // does not seem to handle geometry collections, hence the error if we don't cast.
            const intersects = booleanIntersects(extentAsPolygon, actualGeometry as helpers.Geometry);

            return intersects;
        }

        return customIntersectionTest;
    }

    async getDatasetExtent(): Promise<Extent> {
        const geometry = await this.getDatasetGeometry();

        return this.computeExtent(geometry);
    }

    computeExtent(geometry: Geometry): Extent {
        const crsIn = this.getDatasetProjectionAsString();
        const crsOut = this.getInstanceCrs();

        return geojsonUtils.computeExtent(geometry, crsIn, crsOut);
    }

    /**
     * Builds a single layer from the specified source file.
     */
    protected abstract buildLayer(sourceFile: SourceFile): Promise<TOutput>;

    /**
     * Builds the layers.
     */
    async build(): Promise<TOutput[]> {
        const sources = await this.getSourceFiles();

        const activeFiles = sources.filter((s) => s.state === SOURCE_FILE_STATES.ACTIVE);

        return Promise.all(activeFiles.map((source) => this.buildLayer(source)));
    }
}
