import { useAppSelector } from 'store';
import { DRAWN_TOOL } from 'services/Constants';
import * as drawToolSlice from '../../redux/drawTool';

const DisabledDrawTool = () => {
    const tool = useAppSelector(drawToolSlice.getTool);

    const toolName = (toolId) => {
        switch (toolId) {
            case DRAWN_TOOL.ANNOTATION:
                return 'annotation';
            case DRAWN_TOOL.ELEVATION_PROFILE:
                return 'elevation profile tool';
            case DRAWN_TOOL.MEASURE:
                return 'measurment tool';
            default:
                return '';
        }
    };

    return (
        <p>
            Tool disabled while {toolName(tool)} is active.
            <br />
            Close that tool before using this one
        </p>
    );
};

export default DisabledDrawTool;
