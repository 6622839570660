import React from 'react';
import { useSelector } from 'react-redux';

import { getVersionInfo } from '../../redux/selectors';

// eslint-disable-next-line import/no-unresolved
import versionInfo from '../../version';
import Releases from '../releases/Releases';

const BuildInfo = () => {
    const versionAPI = useSelector(getVersionInfo);
    const today = new Date();
    const threeMonthsAgo = today.setMonth(today.getMonth() - 3);
    return (
        <>
            <div className="map-pane-title">App Information</div>
            <div className="map-pane-body app-info">
                <div className="release-info">
                    <h4>New features & improvements</h4>
                    <Releases showSince={threeMonthsAgo} />
                </div>
                <div className="build-info">
                    <h4>Build information</h4>
                    <div className="build-info-parts">
                        <div className="build-info-part">
                            <b>App</b>
                            <ul>
                                <li>{versionInfo.version}</li>
                                <li>{versionInfo.branch}</li>
                                <li>{versionInfo.hash.substring(0, 8)}</li>
                            </ul>
                        </div>
                        <div className="build-info-part">
                            <b>API</b>
                            <ul>
                                <li>{versionAPI.version}</li>
                                <li>{versionAPI.branch}</li>
                                <li>{versionAPI.hash.substring(0, 8)}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default BuildInfo;
