import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Input, Label, Row, Col } from 'reactstrap';
import { useFormik } from 'formik';
import { getDistance } from 'ol/sphere';

import { getOverviewMap, getMeasurementResult, getMeasurementCoordinates } from '../../../redux/selectors';
import {
    addMeasureInteraction,
    removeMeasureInteraction,
    endMeasureInteraction,
    editDrawnPoint,
    deleteDrawnPoint,
} from './mapInteractions';
import Scrollbox from '../../Scrollbox';

const MeasurementTool = () => {
    const dispatch = useDispatch();

    const map = useSelector(getOverviewMap);

    const measurementCoords = useSelector(getMeasurementCoordinates);
    const measurementResult = useSelector(getMeasurementResult);
    const [isMeasuring, setIsMeasuring] = useState(false);
    const [hasMeasured, setHasMeasured] = useState(false);
    const [editIndex, setEditIndex] = useState(null);

    const endMeasurement = () => {
        setIsMeasuring(false);
        setHasMeasured(true);
        endMeasureInteraction();
    };

    const startMeasurement = () => {
        addMeasureInteraction(map, dispatch, endMeasurement);
        setIsMeasuring(true);
    };

    const removeMeasurement = () => {
        removeMeasureInteraction(map, dispatch);
        setHasMeasured(false);
    };

    const validate = (values) => {
        const errors = {};
        if (values.lat === '' || values.lon === '') errors.submit = 'Both inputs must be provided';
        else if (Math.abs(values.lon) > 90) errors.submit = 'Longitude is out of bounds';
        return errors;
    };

    const formik = useFormik({
        initialValues: {
            lat: 0,
            lon: 0,
        },
        onSubmit: (values) => {
            editDrawnPoint(editIndex, [values.lat, values.lon]);
            setEditIndex(null);
        },
        validate,
    });

    const editPoint = (event) => {
        const index = parseInt(event.currentTarget.id.split('-')[1], 10);
        formik.setValues({ lat: measurementCoords[index][0].toFixed(5), lon: measurementCoords[index][1].toFixed(5) });
        setEditIndex(index);
    };

    const inputForm = () => {
        if (editIndex !== null)
            return (
                <>
                    <hr />
                    <form onSubmit={formik.handleSubmit}>
                        <Row>
                            <Col sm={2}>
                                <Label htmlFor="lat">
                                    <span className="coordinate-component-x">Latitude</span>:
                                </Label>
                            </Col>
                            <Col sm={9}>
                                <Input
                                    id="lat"
                                    name="lat"
                                    type="number"
                                    onChange={formik.handleChange}
                                    value={formik.values.lat}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={2}>
                                <Label htmlFor="lon">
                                    <span className="coordinate-component-y">Longitude</span>:
                                </Label>
                            </Col>
                            <Col sm={9}>
                                <Input
                                    id="lon"
                                    name="lon"
                                    type="number"
                                    onChange={formik.handleChange}
                                    value={formik.values.lon}
                                />
                            </Col>
                        </Row>
                        {formik.errors.submit ? <div className="invalid">{formik.errors.submit}</div> : null}

                        <div className="spread-horizontal">
                            <Button type="submit">Submit</Button>
                            <Button onClick={() => setEditIndex(null)}>Cancel</Button>
                        </div>
                    </form>
                </>
            );
        return null;
    };

    const deletePoint = (event) => {
        deleteDrawnPoint(parseInt(event.currentTarget.id.split('-')[1], 10));
    };

    const calculateDistance = (c1, c2) => {
        if (c1 !== undefined && c2 !== undefined) {
            const distance = getDistance(c1, c2);
            return distance < 10000 ? `${distance.toFixed(0)}m` : `${(distance / 1000).toFixed(0)}km`;
        }
        return 'Close shape to find last length';
    };

    const renderCoords = () => {
        if (measurementCoords === undefined) return null;

        let faded = -1;
        if (
            measurementCoords.length !== 1 &&
            measurementCoords[0][0] === measurementCoords[measurementCoords.length - 1][0] &&
            measurementCoords[0][1] === measurementCoords[measurementCoords.length - 1][1]
        )
            faded = measurementCoords.length - 1;

        const canDelete = measurementCoords.length > 3 && !(measurementCoords.length === 4 && measurementResult?.area);

        return measurementCoords.map((c, index) => (
            <tbody key={`selected-points-table-row-${String.fromCharCode(index + 65)}`}>
                <tr>
                    <td>
                        <div className="coordinate-label">
                            {measurementResult?.area && index === measurementCoords.length - 1 ? 1 : index + 1}
                        </div>
                    </td>
                    <td className="coordinate-box">
                        <span className="coordinate-component-x">Latitude</span>: {c[0].toFixed(5)}°
                        <br />
                        <span className="coordinate-component-y">Longitude</span>: {c[1].toFixed(5)}°
                    </td>
                    <td className="coordinate-buttons">
                        {faded !== index && index !== measurementCoords.length - 1 ? (
                            <>
                                <Button
                                    className="borderless green"
                                    type="button"
                                    id={`edit-${index}`}
                                    onClick={editPoint}
                                    onKeyDown={editPoint}
                                >
                                    <i className="fal fa-pen" />
                                </Button>
                                <Button
                                    className="borderless red"
                                    type="button"
                                    id={`delete-${index}`}
                                    onClick={deletePoint}
                                    onKeyDown={deletePoint}
                                    disabled={!canDelete}
                                >
                                    <i className="fal fa-trash-can" />
                                </Button>
                            </>
                        ) : null}
                    </td>
                </tr>
                <tr>
                    {measurementCoords[index + 1] ? (
                        <>
                            <td className="coordinates-result-tag">┣</td>
                            <td className="coordinates-result">{calculateDistance(c, measurementCoords[index + 1])}</td>
                        </>
                    ) : null}
                </tr>
            </tbody>
        ));
    };

    return (
        <>
            <div className="map-pane-title">Measure Tool</div>

            <p>Defined Points</p>
            <Scrollbox className="annotation-content">
                <table className="coordinates-table" id="selected-points-table">
                    {renderCoords()}
                </table>
            </Scrollbox>

            {inputForm()}

            <hr />
            <table>
                <tbody>
                    <tr>
                        <td>Total Distance</td>
                        <td>Total Area</td>
                    </tr>
                    <tr>
                        {measurementResult ? (
                            <td className="coordinates-result">{measurementResult.length}</td>
                        ) : (
                            <td className="coordinates-result-alt">Begin measuring</td>
                        )}
                        {measurementResult && measurementResult.area ? (
                            <td className="coordinates-result">{measurementResult.area}</td>
                        ) : (
                            <td className="coordinates-result-alt">Close shape</td>
                        )}
                    </tr>
                </tbody>
            </table>

            <hr />

            <div className="sideform-buttons">
                {!isMeasuring && !hasMeasured ? (
                    <Button
                        onClick={() => {
                            startMeasurement();
                        }}
                    >
                        Measure
                    </Button>
                ) : null}
                {isMeasuring ? (
                    <Button
                        onClick={() => {
                            endMeasurement();
                        }}
                    >
                        End
                    </Button>
                ) : null}
                {hasMeasured ? (
                    <Button
                        onClick={() => {
                            removeMeasurement();
                        }}
                    >
                        Done
                    </Button>
                ) : null}
            </div>
        </>
    );
};

export default MeasurementTool;
