import * as THREE from 'three';
import Entity3D from '@giro3d/giro3d/entities/Entity3D';
import Instance from '@giro3d/giro3d/core/Instance';
import OperationCounter from '@giro3d/giro3d/core/OperationCounter';
import ScreenSpaceError from '@giro3d/giro3d/core/ScreenSpaceError';
import { Camera } from '@giro3d/giro3d/renderer';
import type Context from '@giro3d/giro3d/core/Context';
import { createEmptyReport, GetMemoryUsageContext, MemoryUsageReport } from '@giro3d/giro3d/core/MemoryUsage';
import Source, { ReadRequest } from './Source';
import SeismicPlaneTile from './SeismicPlaneTile';
import SeismicPlaneRegion from './SeismicPlaneRegion';

const tilesToUpdateThisFrame = [];
/**
 * The ideal tile size, in pixels. This will not be the actual tile size because the source
 * decides of the actual size (as long as it is not too far from this value).
 */
const TILE_SIZE_LIMIT = 2048;
/**
 * Unitless number that is a factor to adjust how fast the tiles subdivide. The higher the value,
 * the faster the tiles split, and the more tiles there are for a given view.
 */
const SSE_FACTOR = 0.08;

const tempVec = new THREE.Vector3();

class ImageRequest {
    controller: AbortController;

    private abortedCallback: () => void;

    tile: SeismicPlaneTile;

    constructor(tile: SeismicPlaneTile) {
        this.controller = new AbortController();
        this.tile = tile;
        this.abortedCallback = null;
    }

    abort() {
        this.controller.abort('aborted');
        if (this.abortedCallback) {
            this.abortedCallback();
        }
    }

    onAborted(callback: () => void) {
        this.abortedCallback = callback;
    }
}

/**
 * @param tile The tile.
 * @returns The ancestor.
 */
function findSuitableAncestor(tile: SeismicPlaneTile): SeismicPlaneTile {
    let parent: SeismicPlaneTile = tile;

    do {
        parent = parent.parent as SeismicPlaneTile;
        if (parent && parent.isSeismicPlaneTile && parent.isLoaded()) {
            return parent as SeismicPlaneTile;
        }
    } while (parent);

    return null;
}

/**
 * A custom Giro3D entity to stream seismic plane data.
 * The plane curtain is divided into tiles that are dynamically loaded according
 * to the correct LOD.
 *
 * Tiles farther from the camera will receive a lower resolution texture
 * in order to save memory.
 */
export default class SeismicPlaneEntity extends Entity3D {
    private _disposeTimeout: NodeJS.Timeout;

    private readonly _opCounter: OperationCounter;

    private readonly _loader: Source;

    private _ready: boolean;

    private rootTile: SeismicPlaneTile;

    private allTiles: SeismicPlaneTile[];

    private depthDiff: number;

    private offset: number;

    readonly requests: Map<number, ImageRequest>;

    private _wireframe: boolean;

    protected orthographic: boolean;

    private curve: THREE.Curve<THREE.Vector3>;

    private totalLength: number;

    private sseScale: number;

    private colorMapLut: THREE.Color[];

    private anisotropy: number;

    private colormapBounds: {
        min: number;
        max: number;
        customMin: number;
        customMax: number;
    };

    private customBoundsMode: boolean;

    private intensityFilter: number;

    private filterTransparency: boolean;

    private boundingBox: THREE.Box3;
    private _brightness = 0;

    /**
     * @param id The unique id of this entity.
     * @param params Additional parameters.
     * @param params.object3d The root object to use as the top of
     * this entity hierarchy.
     * @param params.loader The data loader.
     * @param params.depthDiff The depth difference (aka height of the curtain).
     * @param params.instance The data loader.
     * @param params.offset The vertical offset.
     * @param params.totalLength The total length of the curtain.
     * @param params.orthographic Orthographic mode flag.
     * @param params.curve The curve of the seismic plane.
     */
    constructor(
        id: string,
        params: {
            datasetId: string;
            sourceFileId: string;
            instance: Instance;
            object3d: THREE.Object3D;
            curve: THREE.Curve<THREE.Vector3>;
            loader: Source;
            depthDiff: number;
            offset: number;
            totalLength?: number;
            orthographic: boolean;
            bounds: {
                min: number;
                max: number;
                customMax: number;
                customMin: number;
            };
        }
    ) {
        super(id, params.object3d);
        this.object3d.name = 'SeismicPlaneEntity';

        this.userData.datasetId = params.datasetId;
        this.userData.sourceFileId = params.sourceFileId;

        this._opCounter = new OperationCounter();
        if (!(params.loader instanceof Source)) {
            throw new Error('missing loader');
        }
        this._loader = params.loader;

        if (!(params.instance instanceof Instance)) {
            throw new Error('missing instance');
        }
        this._instance = params.instance;

        this._ready = false;

        this.rootTile = null;

        this.allTiles = [];

        this.depthDiff = params.depthDiff;

        this.offset = params.offset;

        this.requests = new Map();

        this._disposeTimeout = null;
        this._wireframe = false;

        this.orthographic = params.orthographic;

        this.colormapBounds = params.bounds;

        this.type = 'SeismicPlaneEntity';

        this.object3d.position.setZ(this.offset);
        this.object3d.updateMatrixWorld();

        /** @type {THREE.CatmullRomCurve3} */
        this.curve = params.curve;
        this.totalLength = this.curve.getLength();

        /** @type {number} */
        this.sseScale = SSE_FACTOR;
    }

    get source() {
        return this._loader;
    }

    get wireframe() {
        return this._wireframe;
    }

    set wireframe(v) {
        this._wireframe = v;

        this.allTiles.forEach((t) => {
            t.wireframe = v;
        });
        this.notifyLayerChange();
    }

    get progress() {
        return this._opCounter.progress;
    }

    get loading() {
        return this._opCounter.loading;
    }

    setBrightness(brightness: number) {
        this._brightness = brightness;
        this.allTiles.forEach((t) => {
            t.material.uniforms.brightness.value = this._brightness;
        });
    }

    setCurve(curve: THREE.CatmullRomCurve3) {
        this.dispose();
        this.curve = curve;
        this.notifyLayerChange();
    }

    /**
     * @param region The region of the plane occupied by the tile.
     * @param subdivisions The mesh subdivisions along the slice.
     * @returns The tile.
     */
    createTile(region: SeismicPlaneRegion, subdivisions: number): SeismicPlaneTile {
        // The start/end of the slice, in linear units (aka meters)
        const x = region.u;
        const y = region.v;
        const w = region.width;
        const h = region.height;
        const xStart = x * this.totalLength;
        const xEnd = (x + w) * this.totalLength;
        const yStart = y * this.depthDiff;
        const yEnd = (y + h) * this.depthDiff;
        const widthMeters = w * this.totalLength;
        const heightMeters = h * this.depthDiff;

        const rawSize = new THREE.Vector2(w * this._loader.width, h * this._loader.height);
        const ratio = rawSize.width / rawSize.height;
        let sizePixels: THREE.Vector2;
        const baseSize = TILE_SIZE_LIMIT;
        if (ratio > 1) {
            sizePixels = new THREE.Vector2(baseSize, baseSize / ratio);
        } else {
            sizePixels = new THREE.Vector2(baseSize * ratio, baseSize);
        }

        const { positions, uvs, indices } = this._computeSliceBuffers(xStart, xEnd, yStart, yEnd, subdivisions);

        const geom = new THREE.BufferGeometry();

        geom.setAttribute('position', new THREE.BufferAttribute(positions, 3));
        geom.setAttribute('uv', new THREE.BufferAttribute(uvs, 2));
        geom.setIndex(indices);

        geom.computeBoundingSphere();
        geom.computeBoundingBox();

        const tile = new SeismicPlaneTile({
            region,
            geometry: geom,
            opacity: this.opacity,
            bounds: this.colormapBounds,
            lut: this.colorMapLut,
            widthMeters,
            heightMeters,
            sizePixels,
        });

        this.allTiles.push(tile);

        this.onTileCreated(tile);

        return tile;
    }

    onTileCreated(tile: SeismicPlaneTile) {
        tile.wireframe = this._wireframe;

        this.updateMaterial(tile);
        this.onObjectCreated(tile);

        tile.userData = this.userData;
    }

    async loadImage(tile: SeismicPlaneTile, signal: AbortSignal) {
        const outputSize = tile.sizePixels;
        const tileSizeMeters = tile.sizeMeters;
        const region = tile.region;

        const request = new ReadRequest({
            region,
            signal,
            outputSize,
            tileSizeMeters,
            shouldAbort: () => tile.disposed,
        });

        const { texture, adjustedRegion } = await this._loader.read(request);

        texture.generateMipmaps = true;
        texture.anisotropy = this.anisotropy;
        texture.minFilter = THREE.LinearMipMapLinearFilter;
        texture.magFilter = THREE.LinearFilter;
        texture.wrapS = THREE.ClampToEdgeWrapping;
        texture.wrapT = THREE.ClampToEdgeWrapping;

        if (signal.aborted) {
            texture.dispose();
            signal.throwIfAborted();
        } else {
            tile.setOwnTexture(texture, adjustedRegion);
        }
    }

    updateVisibility() {
        this.object3d.visible = this.visible;
        this.notifyLayerChange();

        if (this.visible && this._disposeTimeout) {
            // Cancel delayed disposal
            clearTimeout(this._disposeTimeout);
            this._disposeTimeout = null;
        }
        if (!this.visible && !this._disposeTimeout) {
            // Disposes resources after a small delay
            // The delay is there to ensure that if the user displays
            // the entity right after hiding it (i.e a quick succession of clicks),
            // the objects are immediately shown.
            this._disposeTimeout = setTimeout(() => this.dispose(), 3000);
        }
    }

    notifyLayerChange() {
        this._instance.notifyChange(this);
    }

    /**
     * Sets the vertical offset of the seismic plane.
     * @param {number} offset The vertical offset.
     */
    setZOffset(offset: number) {
        this.object3d.position.setZ(offset);

        this.allTiles.forEach((t) => {
            t.computeBoundingBox();
        });
        this.object3d.updateMatrixWorld(true);
    }

    /**
     * @param params The parameters.
     * @param params.intensityFilter The intensity filter.
     * @param params.opacity The opacity.
     * @param params.filterTransparency Transparency cutoff.
     * @param params.customBoundsMode Custom bounds mode.
     * @param params.depthDiff The depth difference.
     * @param params.lut The LUT.
     */
    setMaterialParameters(params: {
        opacity: number;
        filterTransparency: boolean;
        intensityFilter: number;
        lut: THREE.Color[];
        customBoundsMode: boolean;
        depthDiff: number;
        bounds: {
            min: number;
            max: number;
            customMin: number;
            customMax: number;
        };
    }) {
        this.colormapBounds = params.bounds;
        this.depthDiff = params.depthDiff;
        this.opacity = params.opacity;
        this.customBoundsMode = params.customBoundsMode;
        this.intensityFilter = params.intensityFilter;
        this.filterTransparency = params.filterTransparency;
        this.colorMapLut = params.lut;
        this.allTiles.forEach((t) => {
            this.updateMaterial(t);
        });

        this.notifyLayerChange();
    }

    updateMaterial(tile: SeismicPlaneTile) {
        const material = tile.material;
        const uniforms = material.uniforms;
        uniforms.intensityFilter.value = this.intensityFilter;
        uniforms.brightness.value = this._brightness;
        uniforms.filterTransparency.value = this.filterTransparency;
        uniforms.vLut.value = this.colorMapLut;
        uniforms.customBoundsMode.value = this.customBoundsMode;
        uniforms.dataMin.value = this.colormapBounds.min;
        uniforms.dataMax.value = this.colormapBounds.max;
        uniforms.customMin.value = this.colormapBounds.customMin;
        uniforms.customMax.value = this.colormapBounds.customMax;

        material.opacity = this.opacity;
        if (material.defines.LUT_SIZE !== this.colorMapLut.length) {
            material.defines.LUT_SIZE = this.colorMapLut.length;
            material.needsUpdate = true;
        }
        uniforms.opacity.value = this.opacity;
    }

    createRootTilesIfNecessary() {
        if (this.rootTile) {
            return;
        }

        const rootTile = this.createTile(SeismicPlaneRegion.full(), 128);
        this.object3d.add(rootTile);
        this.rootTile = rootTile;
        this.object3d.updateMatrixWorld();

        this.boundingBox = new THREE.Box3();
        this.boundingBox.expandByObject(this.object3d);
    }

    /**
     * @param {SeismicPlaneRegion} region
     * @returns {THREE.Vector2}
     */
    selectBestSubdivisions(region: { width: number; height: number }): THREE.Vector2 {
        const w = region.width * this.totalLength;
        const h = region.height * this.depthDiff;
        const ratio = w / h;

        let x = 1;
        let y = 1;

        const MAX_SUPPORTED_ASPECT_RATIO = 10;

        if (ratio > 1) {
            // Our extent is an horizontal rectangle
            x = Math.min(Math.round(ratio), MAX_SUPPORTED_ASPECT_RATIO);
        } else if (ratio < 1) {
            // Our extent is an vertical rectangle
            y = Math.min(Math.round(1 / ratio), MAX_SUPPORTED_ASPECT_RATIO);
        }

        if (x === 1 && y === 1) {
            x = 2;
            y = 2;
        }

        return new THREE.Vector2(x, y);
    }

    /**
     * @param {SeismicPlaneTile} tile The tile to subdivide.
     */
    subdivide(tile: SeismicPlaneTile) {
        // We want tiles to be as square as possible to have the best LOD computation.
        const subdivs = this.selectBestSubdivisions(tile.region);
        const subregions = tile.region.split(subdivs.x, subdivs.y);
        const subdivisions = 15;
        for (const subregion of subregions) {
            const child = this.createTile(subregion, subdivisions);

            tile.addChild(child);
            tile.updateMatrixWorld(true);

            // The new tile is going to temporarily inherit an ancestor's texture
            // to avoid having to display a blank rectangle.
            const ancestor = findSuitableAncestor(child);
            if (ancestor) {
                child.inheritTextureFromAncestor(ancestor.texture, ancestor.textureRegion);
            }
        }
    }

    getMemoryUsage(context: GetMemoryUsageContext, target?: MemoryUsageReport): MemoryUsageReport {
        const result = target ?? createEmptyReport();

        this.allTiles.forEach((tile) => {
            tile.getMemoryUsage(context, result);
        });

        return result;
    }

    /**
     * During the preprocessing stage, we create the geometries and materials of the tiles.
     */
    async preprocess() {
        await this._loader.preprocess();
        if (this._ready) {
            return;
        }
        this.anisotropy = this._instance.renderer.capabilities.getMaxAnisotropy();
        this._ready = true;
    }

    /**
     * Computes position, UV and index buffers from for a rectangular section of the seismic plane,
     * where xStart and xEnd are linear distances from the beginning of the seismic line, and
     * yStart and yEnd are linear distances from the lowest depth of the plane.
     *
     * @param xStart The x location of the slice start on the seismic line, expressed as
     * a linear distance from the start of the seismic line.
     * @param xEnd The x location of the slice end.
     * @param yStart The y location of the slice start.
     * @param yEnd The y location of the slice end.
     * @param subdivisions The number of mesh subdivisions along the slice.
     * @returns The buffers.
     */
    _computeSliceBuffers(
        xStart: number,
        xEnd: number,
        yStart: number,
        yEnd: number,
        subdivisions: number
    ): { positions: Float32Array; uvs: Float32Array; indices: number[] } {
        const indices = [];

        const sliceLength = xEnd - xStart;

        const positions = [];
        const uvs = [];

        function pushIndexPair(i: number) {
            const idx = i * 2;
            indices.push(idx + 0, idx + 1, idx + 3);
            indices.push(idx + 0, idx + 3, idx + 2);
        }

        // Each coordinate on the seismic line produces two vertices : one for the bottom of the
        // plane, and one for the top.
        function pushVertexPair(u: number, pos: { x: number; y: number; z: number }) {
            positions.push(pos.x, pos.y, pos.z - yEnd);
            uvs.push(u, 1);

            positions.push(pos.x, pos.y, pos.z - yStart);
            uvs.push(u, 0);
        }

        let k = 0;

        const segmentLength = sliceLength / subdivisions;

        let cumulativeDist = xStart;
        const curveLength = this.curve.getLength();

        for (let i = 0; i < subdivisions; i++) {
            const tCurrent = THREE.MathUtils.clamp(cumulativeDist / curveLength, 0, 1);
            const tNext = THREE.MathUtils.clamp((cumulativeDist + segmentLength) / curveLength, 0, 1);

            const posCurrent = this.curve.getPointAt(tCurrent, tempVec);

            const u = i / subdivisions;
            pushVertexPair(u, posCurrent);
            pushIndexPair(k);

            // Last vertex
            if (i === subdivisions - 1) {
                const uLast = 1;
                const postLast = this.curve.getPointAt(tNext, tempVec);
                pushVertexPair(uLast, postLast);
            }

            cumulativeDist += segmentLength;
            k++;
        }

        return {
            positions: new Float32Array(positions),
            uvs: new Float32Array(uvs),
            indices,
        };
    }

    /**
     * Updates a single tile.
     * @param _context The context.
     * @param tile The tile to update.
     */
    update(_context: Context, tile: SeismicPlaneTile) {
        if (tile.isLoaded() || this.frozen) {
            return null;
        }

        const requests = this.requests;

        // Is there a running request for this tile ?
        let currentRequest = requests.get(tile.id);

        // No request ? let's create one.
        if (!currentRequest) {
            currentRequest = new ImageRequest(tile);
            currentRequest.onAborted(() => requests.delete(tile.id));

            requests.set(tile.id, currentRequest);

            this._opCounter.increment();

            this.loadImage(tile, currentRequest.controller.signal)
                .then(() => this.notifyLayerChange())
                .catch((e) => {
                    // e.message !== 'canceled' is for aborted Axios-requests
                    if (e !== 'aborted' && e.message !== 'canceled') {
                        console.error(e);
                    }
                })
                .finally(() => {
                    this._opCounter.decrement();
                    requests.delete(tile.id);
                });
        }

        return null;
    }

    /**
     * Determine which tiles should be updated.
     * @param context The Giro3D context.
     * @returns The tiles that need to be updated.
     */
    preUpdate(context: Context): SeismicPlaneTile[] {
        if (this.frozen) {
            return [];
        }

        if (!this.rootTile) {
            this.createRootTilesIfNecessary();
            return [this.rootTile];
        }

        if (!this.rootTile.isLoaded()) {
            return [];
        }

        tilesToUpdateThisFrame.length = 0;

        const camera = context.camera;

        this.object3d.traverse((obj) => {
            const tile = obj as SeismicPlaneTile;
            if (tile.isSeismicPlaneTile) {
                const shouldBeVisible = camera.isBox3Visible(tile.localBoundingBox, tile.matrixWorld);

                if (shouldBeVisible) {
                    if (this.shouldSubdivide(camera, tile)) {
                        if (tile.isLeaf) {
                            this.subdivide(tile);
                        }

                        tile.showChildren();
                    } else {
                        tile.showSelf();
                        tilesToUpdateThisFrame.push(tile);
                    }
                } else {
                    tile.hide();
                }
            }
        });

        return tilesToUpdateThisFrame;
    }

    /**
     * @param {Camera} camera The Giro3D camera.
     * @param {SeismicPlaneTile} tile The tile to evaluate.
     * @returns {boolean} `true` if the tile must be subdivided, `false` otherwise.
     */
    shouldSubdivide(camera: Camera, tile: SeismicPlaneTile): boolean {
        const region = tile.region;
        const widthPixels = region.width * this._loader.width;
        const heightPixels = region.height * this._loader.height;

        // No need to subdivide further, 512px is a reasonable texture size for a tile
        if (widthPixels < TILE_SIZE_LIMIT && heightPixels < TILE_SIZE_LIMIT) {
            return false;
        }

        const sse = ScreenSpaceError.computeFromBox3(
            camera,
            tile.localBoundingBox,
            tile.matrixWorld,
            tile.geometricError,
            ScreenSpaceError.Mode.MODE_3D
        );

        if (sse == null) {
            return true;
        }

        const sizeOnScreen = Math.max(sse.lengths.x, sse.lengths.y);

        return sizeOnScreen * this.sseScale > tile.diagonalPixels;
    }

    postUpdate() {
        // Let's cancel all requests that are no longer relevant
        this.requests.forEach((req) => {
            if (req.tile.disposed) {
                req.abort();
            }
        });

        this.allTiles = this.allTiles.filter((t) => !t.disposed);
    }

    dispose() {
        // This entity can be disposed at any point, then whenever the entity must be visible
        // again, it will recreate the graphical resources (geometries and textures).
        this.requests.forEach((r) => r.abort());
        this.requests.clear();
        this.allTiles.forEach((t) => {
            t.dispose();
        });
        this.allTiles.length = 0;
        this.rootTile?.removeFromParent();
        this.rootTile = null;
        this._disposeTimeout = null;
    }
}
