import { Box3, Color } from 'three';

import { type Instance } from '@giro3d/giro3d/core';
import { Extent } from '@giro3d/giro3d/core/geographic';
import { AxisGrid } from '@giro3d/giro3d/entities';
import { TickOrigin } from '@giro3d/giro3d/entities/AxisGrid';
import { genericEqualityFn } from 'components/utils';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import * as giro3d from 'redux/giro3d';
import * as grid from 'redux/grid';
import { TICKS_PRESETS } from 'services/Constants';
import { useAppSelector } from 'store';
import * as datasetsSlice from 'redux/datasets';

export type Props = {
    instance: Instance;
    /**
     * The mode of the grid. 2D means seismic view.
     */
    mode: '3D' | '2D';
};

/**
 * Computes the best ticks from the given spatial parameters.
 * @param extent The extent.
 * @param floorLevel The floor elevation.
 * @param ceilingLevel The ceiling elevation.
 * @returns The computed ticks.
 */
function computeTicksFor3DView(extent: Extent, floorLevel: number, ceilingLevel: number): grid.Ticks {
    const dims = extent.dimensions();

    const length = Math.max(dims.x, dims.y);

    // We wish around 5 ticks per axis
    let hSize = length / 5;
    let vSize = Math.abs(ceilingLevel - floorLevel) / 5;

    // Let's find the closest tick preset that match our desired tick size
    // so that we have nice, readable numbers.
    hSize = TICKS_PRESETS.sort((a, b) => Math.abs(a - hSize) - Math.abs(b - hSize))[0];
    vSize = TICKS_PRESETS.sort((a, b) => Math.abs(a - vSize) - Math.abs(b - vSize))[0];

    return {
        x: hSize,
        y: hSize,
        z: vSize,
    };
}

/**
 * Computes the best ticks from the given spatial parameters.
 * @param floorLevel The floor elevation.
 * @param ceilingLevel The ceiling elevation.
 * @returns The computed ticks.
 */
function computeTicksFor2DView(extent: Extent, floorLevel: number, ceilingLevel: number): grid.Ticks {
    // We wish around 5 ticks per axis
    const hSize = 100;
    let vSize = Math.abs(ceilingLevel - floorLevel) / 5;

    // Let's find the closest tick preset that match our desired tick size
    // so that we have nice, readable numbers.
    vSize = TICKS_PRESETS.sort((a, b) => Math.abs(a - vSize) - Math.abs(b - vSize))[0];

    return {
        x: hSize,
        y: hSize,
        z: vSize,
    };
}

function Grid(props: Props) {
    const { instance, mode } = props;

    const dispatch = useDispatch();

    const [axisGrid, setAxisGrid] = useState<AxisGrid>(
        instance.getObjects().find((o) => o instanceof AxisGrid) as AxisGrid
    );

    const is3D = mode === '3D';
    const volume = useAppSelector(is3D ? giro3d.getVolume : giro3d.getSeismicVolume, genericEqualityFn<Box3>);
    const project = useAppSelector(datasetsSlice.currentProject);

    // Note: in 3D mode, if those values are null, they are computed using the volume
    // and fed back to the redux state. However, we don't do this for the 2D view.
    const ticks = is3D ? useAppSelector(grid.getTicks) : null;
    const ceiling = is3D ? useAppSelector(grid.getCeilingLevel) : null;
    const floor = is3D ? useAppSelector(grid.getFloorLevel) : null;

    const showCeiling = useAppSelector(grid.getCeilingVisibility);
    const showSides = useAppSelector(grid.getSideVisibility);
    const color = useAppSelector(grid.getColor, genericEqualityFn<Color>);
    const opacity = useAppSelector(grid.getOpacity);
    const visible = useAppSelector(grid.isVisible);
    const showLabels = useAppSelector(grid.getLabelVisibility);
    const zScale = useAppSelector(giro3d.getZScale);

    function cleanup() {
        const entity = instance.getObjects().find((o) => o instanceof AxisGrid) as AxisGrid;
        if (entity) {
            instance.remove(entity);
            setAxisGrid(null);
        }
    }

    function updateGrid(entity: AxisGrid) {
        if (!volume || volume.isEmpty()) {
            entity.visible = false;
        } else {
            let verticalMargin = 0;
            let horizontalMargin = 0;

            if (is3D) {
                verticalMargin = 10;
                // 5% margin on each side of the extent, so that the edges of
                // the grid are not hidden by the datasets.
                horizontalMargin = 0.05;
            }
            const computedFloor = (volume.min.z - verticalMargin) / zScale;
            const computedCeiling = (volume.max.z + verticalMargin) / zScale;

            const min = volume.min.clone();
            const max = volume.max.clone();

            if (!is3D) {
                // To make the grid appear in front of the seismic plane
                min.setY(-1);
                max.setY(-1);
            }

            const actualBox = new Box3(min, max);
            const gridExtent = Extent.fromBox3(instance.referenceCrs, actualBox).withRelativeMargin(horizontalMargin);

            entity.volume.extent = gridExtent;
            entity.visible = visible;
            entity.volume.ceiling = ceiling ?? computedCeiling;
            entity.volume.floor = floor ?? computedFloor;
            if (is3D && (ceiling == null || floor == null)) {
                dispatch(grid.setFloorLevel(computedFloor));
                dispatch(grid.setCeilingLevel(computedCeiling));
            }

            entity.object3d.scale.setZ(zScale);
            entity.object3d.updateMatrixWorld();
            entity.color = color;
            entity.showLabels = showLabels;

            const computeTicks = is3D ? computeTicksFor3DView : computeTicksFor2DView;
            entity.ticks = ticks ?? computeTicks(gridExtent, entity.volume.floor, entity.volume.ceiling);

            if (!ticks && is3D) {
                dispatch(grid.setTicks(entity.ticks));
            }

            entity.showCeilingGrid = showCeiling;
            entity.showSideGrids = showSides;
            entity.opacity = opacity;

            entity.refresh();
        }
    }

    function update() {
        if (!axisGrid) {
            const entity = new AxisGrid('axis-grid', {
                origin: TickOrigin.Relative,
                ticks,
                volume: {
                    ceiling: 0,
                    floor: 0,
                    extent: new Extent(instance.referenceCrs, 0, 1, 0, 1),
                },
            });

            instance.add(entity);
            setAxisGrid(entity);
            updateGrid(entity);
        } else updateGrid(axisGrid);

        instance.notifyChange(axisGrid);
    }

    useEffect(cleanup, [project]);

    useEffect(() => {
        update();
        return cleanup;
    }, [volume, opacity, color, visible, ceiling, floor, ticks, showCeiling, showSides, showLabels, zScale]);

    // This is a renderless component. We don't create any DOM element,
    // however we are still "rendering" stuff in the 3D view.
    return null;
}

const GridWrapper = (props: Props) => {
    const visible = useAppSelector(grid.isVisible);
    if (visible) return <Grid mode={props.mode} instance={props.instance} />;
    return null;
};

export default GridWrapper;
