import chroma, { type Scale } from 'chroma-js';
import { Color } from 'three';
import { CYCLE_SCALE, SEISMIC_SCALE, KLEIN_SCALE, COPPER_SCALE, GRAYSCALE_SCALE } from './ColorMapScales';
import { Curve } from './Curve';

export enum COLORMAP_NAMES {
    VIRIDIS = 'Viridis',
    YLORRD = 'YlOrRd',
    SPECTRAL = 'Spectral',
    RDBU = 'RdBu',
    ACCENT = 'Accent',
    PASTEL1 = 'Pastel1',
    GRAYSCALE = 'Grayscale',
    SEISMIC = 'Seismic',
    CYCLE = 'Aspect',
    COPPER = 'Copper',
    KLEIN = 'Klein',
}

export enum COLORMAP_BOUNDSMODE {
    DATA = 'data',
    CUSTOM = 'custom',
}

export type SerializedColorMap = {
    name: COLORMAP_NAMES;
    colors: string[];
    colorsSetps: number[];
    discrete: boolean;
    invert: boolean;
    discreteNumberOfColors: number;
    representation: string;
    dataMin: number;
    dataMax: number;
    customMin: number;
    customMax: number;
    boundsMode: COLORMAP_BOUNDSMODE;
    dataZScale: number;
    dataZOffset: number;
    pinned: boolean;
};

type ColorMap = {
    boundsMode: COLORMAP_BOUNDSMODE;
    customMin: number;
    customMax: number;
    name: COLORMAP_NAMES;
    invert: boolean;
    discrete: boolean;
    opacityCurve?: Curve;
};

export function getLUT(colorMap: ColorMap, options?: { samples?: number }): Color[] {
    let scale: Scale;

    // TODO ?
    // if (Array.isArray(value)) {
    //     const colors = value as string[];
    //     scale = chroma.scale(colors);
    //     if (options.colorsSteps) {
    //         scale = scale.domain(options.colorsSteps);
    //     }
    // } else {
    switch (colorMap.name) {
        case COLORMAP_NAMES.SPECTRAL:
        case COLORMAP_NAMES.VIRIDIS:
        case COLORMAP_NAMES.YLORRD:
        case COLORMAP_NAMES.RDBU:
        case COLORMAP_NAMES.ACCENT:
        case COLORMAP_NAMES.PASTEL1:
            scale = chroma.scale(colorMap.name);
            break;
        case COLORMAP_NAMES.SEISMIC:
            scale = SEISMIC_SCALE;
            break;
        case COLORMAP_NAMES.CYCLE:
            // cyclical scale for aspect mode
            scale = CYCLE_SCALE;
            break;
        case COLORMAP_NAMES.COPPER:
            scale = COPPER_SCALE;
            break;
        case COLORMAP_NAMES.KLEIN:
            scale = KLEIN_SCALE;
            break;
        case COLORMAP_NAMES.GRAYSCALE:
        default:
            scale = GRAYSCALE_SCALE;
            break;
    }
    const actualSamples = colorMap.discrete ? 8 : options?.samples ?? 256;

    let lut = scale
        .mode('lab')
        .colors(actualSamples)
        .map((c) => {
            const rgb = chroma(c).gl();
            return new Color().setRGB(rgb[0], rgb[1], rgb[2], 'srgb');
        });

    if (colorMap.invert) {
        lut = lut.reverse();
    }

    return lut;
}

export default ColorMap;
