// React
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { getUid } from 'ol';
import LayerGroup from 'ol/layer/Group';

// Redux
import { getOverviewMap } from '../../redux/selectors';

// Components
import ProjectsMenu from './projectList/ProjectsMenu';
import CollectionsMenu from './collectionsList/CollectionsMenu';
import OverlayGroup from './overlayList/OverlayGroup';
import AddSource from './overlayList/AddSource';
import Scrollbox from '../Scrollbox';

const getLayerGroups = (map) => map.getLayers().array_.filter((layer) => layer instanceof LayerGroup);

const OverviewMenu = () => {
    const map = useSelector(getOverviewMap);

    const [value, setValue] = useState(0);

    const renderedLayerGroups = map
        ? getLayerGroups(map).map((group) => <OverlayGroup layerGroup={group} key={`overlay-${getUid(group)}-group`} />)
        : [];

    const update = () => setValue(value + 1);

    return (
        <>
            <div className="map-pane-title-bar">
                <div className="map-pane-title">Overlay data</div>
                <AddSource cb={update} />
            </div>
            <div className="map-pane-body">
                <Scrollbox>
                    <ProjectsMenu />
                    <CollectionsMenu />
                    {renderedLayerGroups}
                </Scrollbox>
            </div>
        </>
    );
};
export default OverviewMenu;
