interface Props {
    date: string;
    version: string;
    children: unknown | unknown[];
}
const Release = (props: Props) => {
    return (
        <div className="release">
            <h5>
                {props.date} <span>{props.version}</span>
            </h5>
            {props.children}
        </div>
    );
};

export default Release;
