import { useDispatch } from 'react-redux';
import ReactSlider from 'react-slider';

import { HasDraping, LayerState } from 'types/LayerState';

import * as layers from 'redux/layers';
import * as datasetsSlice from 'redux/datasets';

import ToggleSwitch from 'components/ToggleSwitch';
import { ElevationRange } from '@giro3d/giro3d/core';
import { useRef } from 'react';
import { useAppSelector } from 'store';

export type Props = {
    layer: LayerState & HasDraping;
};

const DrapingSettings = (props: Props) => {
    const { layer } = props;
    const dispatch = useDispatch();

    const isDraped = useAppSelector(layers.isDraped(layer));
    function setDraped(value: boolean) {
        dispatch(layers.setDraping({ layer, value }));
    }

    const zOffset = useAppSelector(layers.getUndrapedZOffset(layer));
    function setZOffset(value: number) {
        dispatch(layers.setUndrapedZOffset({ layer, value }));
    }

    const enableClippingRange = useAppSelector(layers.isClippingRangeEnabled(layer));
    function setEnableClippingRange(value: boolean) {
        dispatch(layers.setEnableClippingRange({ layer, value }));
    }

    const maxRange = useAppSelector(datasetsSlice.totalElevationRange);

    const clippingRange = useAppSelector(layers.getClippingRange(layer));
    function setClippingRange(value: ElevationRange) {
        dispatch(layers.setClippingRange({ layer, value }));
    }

    const clippinMinRef = useRef<HTMLInputElement>(null);
    const clippinMaxRef = useRef<HTMLInputElement>(null);

    const min = Math.round(Math.max(clippingRange.min, maxRange.min));
    const max = Math.round(Math.min(clippingRange.max, maxRange.max));

    return (
        <>
            {/* Toggle draping on/off */}
            <div className="row">
                <label htmlFor="toggle-draping" className="col-sm-5 col-form-label">
                    <i className="fal fa-mountains fa-fw" /> Drape on bathymetry
                </label>
                <div className="col-sm-7 col-form-label">
                    <ToggleSwitch
                        id="toggle-draping"
                        checked={isDraped}
                        onChange={(e) => setDraped(e.target.checked)}
                    />
                </div>
            </div>

            {/* If the dataset is not draped, we can change its elevation */}
            {!isDraped ? (
                <div className="row">
                    <label htmlFor="undraped-elevation" className="col-sm-5 col-form-label">
                        Elevation
                    </label>
                    <div className="col-sm-7">
                        <div className="input-group">
                            <input
                                id="undraped-elevation"
                                type="number"
                                step="1"
                                min="-999999"
                                max="999999"
                                defaultValue={zOffset}
                                autoComplete="off"
                                className="form-control"
                                onChange={(e) => setZOffset(e.target.valueAsNumber)}
                            />
                            <div className="input-group-text">m</div>
                        </div>
                    </div>
                </div>
            ) : null}

            {/* If the dataset is draped, we can enable clipping range */}
            {isDraped ? (
                <div className="row">
                    <label htmlFor="toggle-clipping-range" className="col-sm-5 col-form-label">
                        <i className="fal fa-scalpel-line-dashed fa-fw" /> Clipping range
                    </label>
                    <div className="col-sm-7 col-form-label">
                        <ToggleSwitch
                            id="toggle-clipping-range"
                            checked={enableClippingRange}
                            onChange={(e) => setEnableClippingRange(e.target.checked)}
                        />

                        {enableClippingRange ? (
                            <>
                                <div className="legend-limits">
                                    <span>{`${Math.round(min)}m`}</span>
                                    <span>{`${Math.round(max)}m`}</span>
                                </div>
                                <ReactSlider
                                    className="elevation-slider"
                                    thumbClassName="elevation-slider-thumb"
                                    trackClassName="elevation-slider-track"
                                    value={[min, max]}
                                    min={Math.round(maxRange.min)}
                                    max={Math.round(maxRange.max)}
                                    ariaLabel={['Lower thumb', 'Upper thumb']}
                                    ariaValuetext={(state) => `Thumb value ${state.valueNow}`}
                                    renderTrack={(prop, state) => {
                                        switch (state.index) {
                                            case 1:
                                                return (
                                                    <div
                                                        className="elevation-slider-active"
                                                        style={{ ...prop.style }}
                                                    />
                                                );
                                            case 0:
                                            case 2:
                                                return (
                                                    <div
                                                        className="elevation-slider-inactive"
                                                        style={{ ...prop.style }}
                                                    />
                                                );
                                            default:
                                                return null;
                                        }
                                    }}
                                    step={1}
                                    minDistance={1}
                                    pearling
                                    onChange={(data: [number, number]) => {
                                        setClippingRange({ min: data[0], max: data[1] });
                                    }}
                                    onAfterChange={(data: [number, number]) => {
                                        clippinMinRef.current.valueAsNumber = Math.round(data[0]);
                                        clippinMaxRef.current.valueAsNumber = Math.round(data[1]);
                                    }}
                                />
                                <label htmlFor="min">Min:</label>
                                <div className="input-group">
                                    <input
                                        title="min"
                                        ref={clippinMinRef}
                                        type="number"
                                        className="form-control"
                                        defaultValue={min}
                                        min={Math.round(maxRange.min)}
                                        max={Math.round(maxRange.max)}
                                        onChange={(event) => {
                                            setClippingRange({
                                                min: event.target.valueAsNumber,
                                                max,
                                            });
                                        }}
                                        step={1}
                                    />
                                    <div className="input-group-text">m</div>
                                </div>
                                <label htmlFor="max">Max:</label>
                                <div className="input-group">
                                    <input
                                        title="max"
                                        ref={clippinMaxRef}
                                        type="number"
                                        className="form-control"
                                        defaultValue={max}
                                        min={Math.round(maxRange.min)}
                                        max={Math.round(maxRange.max)}
                                        onChange={(event) => {
                                            setClippingRange({
                                                min,
                                                max: event.target.valueAsNumber,
                                            });
                                        }}
                                        step={1}
                                    />
                                    <div className="input-group-text">m</div>
                                </div>
                            </>
                        ) : null}
                    </div>
                </div>
            ) : null}
        </>
    );
};

export default DrapingSettings;
