// React
import React from 'react';
import classnames from 'classnames';
import { NavLink as RRNavLink } from 'react-router-dom';

import { Nav, Navbar, NavItem, NavLink, UncontrolledTooltip, TabContent, TabPane } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';

// Redux
import { getPage, getLeftTab, getProjects, getCanUserAccessAdminPage } from './redux/selectors';
import { selectPage, selectLeftTab, selectRightTab } from './redux/actions';
import { PAGE, LEFT_TAB } from './Navigation';

import logo from './images/Argeo-logo-silver-gradient.svg';
import OverviewMenu from './components/overview/OverviewMenu';
import ProjectsMenu from './components/giro3d/ProjectsPane';
import ProjectDatasetsMenu from './components/giro3d/datasetsMenu/ProjectDatasetsMenu';
import ErrorBoundary from './components/ErrorBoundary';
import LazyTabPane from './components/LazyTabPane';

const AppMenu = () => {
    const dispatch = useDispatch();

    const currentPage = useSelector(getPage);
    const currentTab = useSelector(getLeftTab);
    const projects = useSelector(getProjects);
    const featurelessProjectsCount = projects.filter((project) => !project.geometry).length;
    const adminAccess = useSelector(getCanUserAccessAdminPage);

    const goToTab = (tab) => {
        if (currentTab !== tab) {
            dispatch(selectLeftTab(tab));
        } else if (currentTab) {
            dispatch(selectLeftTab(null));
        }
    };

    const goToPage = (page) => {
        if (currentPage !== page) {
            dispatch(selectRightTab(null));
            dispatch(selectPage(page));
        }
        goToTab(null);
    };
    const navTabSubItem = (tabId, title, iconName, link) => (
        <NavItem key={tabId}>
            <NavLink
                className="nav-link"
                onClick={() => goToTab(tabId)}
                to={link}
                id={tabId}
                active={currentTab === tabId}
            >
                <span className="fa-stack">
                    <i className="fas fa-circle fa-stack-2x icon-background" />
                    <i className={`${iconName} fa-stack-1x`} />
                </span>
                <span>{title}</span>
                <UncontrolledTooltip placement="right" target={tabId}>
                    <span className="menu-tooltip">{title}</span>
                </UncontrolledTooltip>
            </NavLink>
        </NavItem>
    );
    const navTabItem = (tabId, title, iconName, link, subItems) => (
        <NavItem
            className={classnames({
                active: currentPage === tabId,
            })}
            key={tabId}
        >
            <NavLink
                className="nav-link"
                onClick={() => goToTab(tabId)}
                to={link}
                id={tabId}
                active={currentPage === tabId}
            >
                <span className="fa-stack">
                    <i className="fas fa-circle fa-stack-2x icon-background" />
                    <i className={`${iconName} fa-stack-1x`} />
                    {currentPage === PAGE.OVERVIEW && tabId === LEFT_TAB.PROJECTS && featurelessProjectsCount !== 0 ? (
                        <div className="notification-bubble">
                            <i className="fas fa-circle" />
                            <span>{featurelessProjectsCount < 100 ? featurelessProjectsCount : '#'}</span>
                        </div>
                    ) : null}
                </span>
                <span>{title}</span>
                <UncontrolledTooltip placement="right" target={tabId}>
                    <span className="menu-tooltip">{title}</span>
                </UncontrolledTooltip>
            </NavLink>
            <ul key={`${tabId}-submenu`}>{subItems}</ul>
        </NavItem>
    );

    const navPageItem = (pageId, title, iconName, link, end, subItems) => (
        <NavItem
            className={classnames({
                active: currentPage === pageId,
            })}
            key={pageId}
            style={{ '--subItem-count': subItems ? subItems.length : 0 }}
        >
            <NavLink
                tag={RRNavLink}
                className="nav-link"
                onClick={() => goToPage(pageId)}
                to={link}
                id={pageId}
                end={end}
            >
                <span className="fa-stack">
                    <i className="fas fa-circle fa-stack-2x icon-background" />
                    <i className={`${iconName} fa-stack-1x`} />
                </span>
                <span>{title}</span>
                <UncontrolledTooltip placement="right" target={pageId}>
                    <span className="menu-tooltip">{title}</span>
                </UncontrolledTooltip>
            </NavLink>
            <ul key={`${pageId}-submenu`}>{subItems}</ul>
        </NavItem>
    );

    const tabPaneMenu = (tabId, menuComponent) => (
        <ErrorBoundary
            dispatch={dispatch}
            fallback={
                <TabPane tabId={tabId}>
                    <span className="error-fallback-message">
                        <i className="fal fa-exclamation-triangle icon-red" />
                        An error occured in the {tabId}. Reload the page to use it.
                        <i className="fal fa-exclamation-triangle icon-red" />
                    </span>
                </TabPane>
            }
        >
            <LazyTabPane tabId={tabId} currentTab={currentTab}>
                {menuComponent}
            </LazyTabPane>
        </ErrorBoundary>
    );

    return (
        <div className="app-menu map-menu">
            <div className="menu-right">
                <div className="map-menu-list">
                    <Navbar>
                        <a href="/">
                            <img src={logo} alt="Argeo logo" />
                        </a>
                    </Navbar>
                    <Nav tabs vertical>
                        {navPageItem(PAGE.OVERVIEW, 'Overview', 'fas fa-earth-americas', '/', true, [
                            navTabSubItem(
                                LEFT_TAB.OVERVIEW_DATASETS,
                                'Overlay data',
                                'fal fa-layer-group',
                                '/',
                                PAGE.OVERVIEW
                            ),
                        ])}
                        {navTabItem(LEFT_TAB.PROJECTS, 'Projects', 'fas fa-folder-heart', '/project', [
                            navTabSubItem(
                                LEFT_TAB.PROJECT_DATASETS,
                                'Project data',
                                'fal fa-layer-group',
                                '/project',
                                PAGE.PROJECT
                            ),
                        ])}
                        {navPageItem(PAGE.LIBRARY, 'Library', 'fas fa-rectangle-history', '/library', false)}
                        <li style={{ flex: '1 1' }} />
                        {adminAccess ? navPageItem(PAGE.ADMIN, 'Admin', 'fas fa-cogs', '/admin', false) : null}
                    </Nav>
                </div>
            </div>
            <div className="menu-right sub-menu">
                <TabContent activeTab={currentTab} className={!currentTab ? 'closed' : undefined}>
                    <Navbar>
                        <div title="Close" className="menu-close">
                            <NavLink onClick={() => goToTab(null)} id="app-menu-close">
                                <span className="fa-stack">
                                    <i className="fas fa-circle fa-stack-2x icon-background" />
                                    <i className="fal fa-arrow-left-long fa-stack-1x" />
                                </span>
                                <UncontrolledTooltip placement="right" target="app-menu-close">
                                    <span className="menu-tooltip">Close</span>
                                </UncontrolledTooltip>
                            </NavLink>
                        </div>
                    </Navbar>
                    {tabPaneMenu(LEFT_TAB.PROJECT_DATASETS, <ProjectDatasetsMenu />)}
                    {tabPaneMenu(LEFT_TAB.OVERVIEW_DATASETS, <OverviewMenu />)}
                    {tabPaneMenu(LEFT_TAB.PROJECTS, <ProjectsMenu />)}
                </TabContent>
            </div>
        </div>
    );
};
export default AppMenu;
