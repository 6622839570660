// React
import { Feature } from 'ol';
import React, { Fragment } from 'react';
import { Card, CardHeader, CardBody } from 'reactstrap';

// Required for preventing Giro3D from using any click event on the tooltip
const noop = (e) => {
    e.stopPropagation();
};

const MAX_DISPLAYED_PROPERTIES = 5;

export type Props = {
    feature: Feature;
    closeCardCallback: () => void;
};

const FeatureCard = (props: Props) => {
    const { feature, closeCardCallback } = props;

    const properties = Object.entries(feature.getProperties())
        .filter(([key]) => key !== 'geometry' && key !== 'geometryProperty')
        .slice(0, MAX_DISPLAYED_PROPERTIES);

    return (
        <Card
            className="popup-body"
            style={{ pointerEvents: 'auto', transform: 'translate(50%, 50%) translate(var(--spacer), -16px)' }}
            onClick={noop}
        >
            <CardHeader>
                <h5>Feature Info</h5>
                <button
                    type="button"
                    className="btn-close btn-close-white"
                    aria-label="Close"
                    onClick={closeCardCallback}
                />
            </CardHeader>
            <CardBody>
                <dl className="feature-info-dl">
                    {properties.map(([key, value]) => (
                        <Fragment key={key}>
                            <dt className="feature-info-dt">{key}:</dt>
                            <dd className="feature-info-dd">{value}</dd>
                        </Fragment>
                    ))}
                </dl>
            </CardBody>
        </Card>
    );
};

export default FeatureCard;
