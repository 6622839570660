// React
import { useDispatch, useSelector } from 'react-redux';
import * as graphicsSettings from 'redux/graphicsSettings';
import * as settings from 'redux/settings';

// Components
import SettingToggle from './SettingToggle';

function PointCloudSettings() {
    const dispatch = useDispatch();

    const open = useSelector(settings.getDropDown);

    const enableEDL = useSelector(graphicsSettings.isEyeDomeLightingEnabled);
    const enableInpainting = useSelector(graphicsSettings.isInpaintingEnabled);

    const changeOpen = (value: boolean) => {
        dispatch(settings.setDropDown(value ? 'pointcloud' : null));
    };

    return (
        <div className="settings-group">
            <div className="settings-main-toggle">
                <div>
                    <i className="fal fa-cloud active" />
                </div>
                <label htmlFor="pointcloud-dropdown" className="settings-label dropdown-arrow">
                    <span>Point cloud settings</span>
                    <input
                        type="checkbox"
                        id="pointcloud-dropdown"
                        checked={open === 'pointcloud'}
                        onChange={(e) => changeOpen(e.target.checked)}
                        hidden
                    />
                    <div className="arrow" />
                </label>
            </div>
            {open === 'pointcloud' ? (
                <div className="settings-dropdown">
                    <SettingToggle
                        title="Eye dome lighting"
                        checked={enableEDL}
                        onChange={(v) => dispatch(graphicsSettings.enableEyeDomeLighting(v))}
                    />
                    <SettingToggle
                        title="Inpainting"
                        checked={enableInpainting}
                        onChange={(v) => dispatch(graphicsSettings.enableInpainting(v))}
                    />
                </div>
            ) : null}
        </div>
    );
}

export default PointCloudSettings;
