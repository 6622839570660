// React
import React, { useState, useEffect } from 'react';
import * as ReactDOM from 'react-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Card, CardHeader, CardBody } from 'reactstrap';
import { OVERVIEW_MAP_FEATURE_INFO } from '../../../redux/actionTypes';
import addFeatureInfoInteraction from './mapInteractions';
// Redux
import { getOverviewMap, getOverviewMapFeatureInfo } from '../../../redux/selectors';

const FeatureInfo = () => {
    const dispatch = useDispatch();
    const map = useSelector(getOverviewMap);

    const featureInfo = useSelector(getOverviewMapFeatureInfo);
    const [domNode, setDomNode] = useState(undefined);

    const toggle = () => {
        const olOverlay = map.getOverlayById('featureInfoPopover');
        olOverlay.setPosition(undefined);
        dispatch({ type: OVERVIEW_MAP_FEATURE_INFO, payload: undefined });
    };

    useEffect(() => {
        if (map !== undefined) {
            addFeatureInfoInteraction(map, dispatch);

            const olOverlay = map.getOverlayById('featureInfoPopover');
            setDomNode(olOverlay.getElement());
        }
    }, [map]);

    useEffect(() => {
        if (map !== undefined && featureInfo !== undefined) {
            const olOverlay = map.getOverlayById('featureInfoPopover');
            olOverlay.setPosition(featureInfo.coordinate);
            olOverlay.setOffset([15, -25]);
        }
    }, [featureInfo]);

    const featureProperties = () =>
        Object.keys(featureInfo.data.features[0].properties).map((key) => (
            <>
                <dt className="feature-info-dt">{key}:</dt>
                <dd className="feature-info-dd">{featureInfo.data.features[0].properties[key]}</dd>
            </>
        ));

    const popover = () =>
        featureInfo === undefined || featureInfo.data.features[0] === undefined ? null : (
            <Card className="popup-body">
                <CardHeader>
                    <h5>Feature Info</h5>
                    <button type="button" className="btn-close btn-close-white" aria-label="Close" onClick={toggle} />
                </CardHeader>

                <CardBody>
                    <dl className="feature-info-dl">{featureProperties()}</dl>
                </CardBody>
            </Card>
        );

    const nullOrRender = () => (domNode === undefined ? null : ReactDOM.createPortal(popover(), domNode));

    return nullOrRender();
};

export default FeatureInfo;
