// React
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as settings from 'redux/settings';

import InspectorSetting from './InspectorSetting';
import ResolutionSetting from './ResolutionSetting';
import TransparencySetting from './TransparencySetting';
import ClickableVectorsSetting from './ClickableVectorsSetting';

function AdvancedSetting() {
    const dispatch = useDispatch();

    const open = useSelector(settings.getDropDown);

    const changeOpen = (value) => {
        dispatch(settings.setDropDown(value ? 'advanced' : null));
    };

    return (
        <div className="settings-group">
            <div className="settings-main-toggle">
                <div>
                    <i className="fal fa-flask active" />
                </div>
                <label htmlFor="advanced-dropdown" className="settings-label dropdown-arrow">
                    <span>Advanced Settings</span>
                    <input
                        type="checkbox"
                        id="advanced-dropdown"
                        checked={open === 'advanced'}
                        onChange={(e) => changeOpen(e.target.checked)}
                        hidden
                    />
                    <div className="arrow" />
                </label>
            </div>
            {open === 'advanced' ? (
                <div className="settings-dropdown">
                    <ResolutionSetting />
                    <TransparencySetting />
                    <ClickableVectorsSetting />
                    <InspectorSetting />
                </div>
            ) : null}
        </div>
    );
}

export default AdvancedSetting;
