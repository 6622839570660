import TypeaheadField from 'components/forms/TypeaheadField';
import * as datasetsSlice from 'redux/datasets';
import * as giro3d from 'redux/giro3d';
import * as layers from 'redux/layers';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import Dataset from 'types/Dataset';
import { useAppSelector } from 'store';
import { shallowEqual, useDispatch } from 'react-redux';
import { Formik, Form } from 'formik';
import ToggleSwitch from 'components/ToggleSwitch';
import BaseField from 'components/forms/BaseField';
import { CONTROLS_MODE } from 'services/Controls';
import { PlayState } from 'types/common';
import HelpPanel from 'components/forms/HelpPanel';
import { useEffect } from 'react';

const LineFollowerModal = () => {
    const dispatch = useDispatch();

    const datasets: Dataset[] = useAppSelector(datasetsSlice.getRenderableDatasets, shallowEqual);
    const cameraSettings: giro3d.FollowCamera = useAppSelector(giro3d.getFollowCamera);
    const pathLayers = useAppSelector(layers.getPathedLayers);
    const loadedPathLayers = pathLayers.filter((l) => l.pathLoaded);
    const loadedPathDatasets = datasets.filter((d) => loadedPathLayers.map((l) => l.datasetId).includes(d.id));

    const closeModal = () => dispatch(giro3d.setFollowCameraModal(false));

    const validate = (values) => {
        if (!values.dataset) return { dataset: 'Dataset is required' };
        return {};
    };

    const onSubmit = async (values) => {
        const id = values.dataset[0]?.id || cameraSettings.datasetId;
        const newCameraSettings: giro3d.FollowCamera = {
            state: PlayState.Pause,
            modal: false,
            datasetId: id,
            rotateCamera: values.rotateCamera,
            fixedDepth: values.fixedDepth,
            depth: values.depth,
            altitude: values.altitude,
            speed: values.speed,
            progress: values.progress,
        };
        dispatch(giro3d.setControlsMode(CONTROLS_MODE.FOLLOW));
        dispatch(giro3d.setFollowCamera(newCameraSettings));
    };

    useEffect(() => {
        dispatch(giro3d.setFollowCameraState(PlayState.Pause));
    }, [cameraSettings.modal]);

    return (
        <Modal isOpen={cameraSettings.modal}>
            <ModalHeader toggle={closeModal}>Line Follower Settings</ModalHeader>
            <Formik
                enableReinitialize
                initialValues={{ ...cameraSettings, dataset: datasets.find((d) => d.id === cameraSettings.datasetId) }}
                validate={validate}
                onSubmit={onSubmit}
            >
                {({ values, setFieldValue }) => (
                    <Form>
                        <ModalBody>
                            <TypeaheadField
                                name="dataset"
                                label="Dataset"
                                required="required"
                                defaultInputValue={
                                    values.datasetId
                                        ? loadedPathDatasets.find((d) => d.id === values.datasetId).name
                                        : ''
                                }
                                options={loadedPathDatasets}
                                labelKey="name"
                                setFieldValue={setFieldValue}
                                highlightOnlyResult
                                emptyLabel="No datasets available"
                                disabled={datasets?.length === 1}
                            />
                            {pathLayers.length - loadedPathLayers.length !== 0 ? (
                                <HelpPanel>
                                    There are {pathLayers.length - loadedPathLayers.length} other datasets that need to
                                    be loaded to be used here.
                                </HelpPanel>
                            ) : null}
                            <div className="form-group">
                                Rotate with Line Direction
                                <ToggleSwitch
                                    id="rotateCamera"
                                    checked={values.rotateCamera}
                                    onChange={(e) => setFieldValue('rotateCamera', e.target.checked)}
                                />
                            </div>
                            <div className="form-group">
                                Fixed Depth
                                <ToggleSwitch
                                    id="fixedDepth"
                                    checked={values.fixedDepth}
                                    onChange={(e) => setFieldValue('fixedDepth', e.target.checked)}
                                />
                            </div>
                            {values.fixedDepth ? (
                                <BaseField name="depth" label="Depth" type="number" disabled={!values.fixedDepth} />
                            ) : (
                                <BaseField
                                    name="altitude"
                                    label="Altitude"
                                    type="number"
                                    disabled={values.fixedDepth}
                                />
                            )}
                            <BaseField name="speed" label="Auto-scroll speed" type="number" />
                        </ModalBody>
                        <ModalFooter>
                            <Button type="submit" color="primary">
                                Apply
                            </Button>
                        </ModalFooter>
                    </Form>
                )}
            </Formik>
        </Modal>
    );
};

export default LineFollowerModal;
