import { Vector3 } from 'three';

export type Props = {
    coordinate: Vector3;
    title: string;
    onChange: (vec: Vector3) => void;
};

function CoordinateInput(props: Props) {
    const { coordinate } = props;

    const x = coordinate ? coordinate.x.toFixed(2) : 0;
    const y = coordinate ? coordinate.y.toFixed(2) : 0;

    return (
        <div className="settings-row">
            <span>{props.title}</span>
            <div className="d-flex">
                <span className="mx-1">X</span>
                <input
                    className="dropdown-settings-slider-button"
                    type="number"
                    value={x}
                    onChange={(e) =>
                        props.onChange(new Vector3(e.target.valueAsNumber, props.coordinate.y, props.coordinate.z))
                    }
                />
            </div>
            <div className="d-flex">
                <span className="mx-1">Y</span>
                <input
                    className="dropdown-settings-slider-button"
                    type="number"
                    value={y}
                    onChange={(e) =>
                        props.onChange(new Vector3(coordinate.x, e.target.valueAsNumber, props.coordinate.z))
                    }
                />
            </div>
        </div>
    );
}
export default CoordinateInput;
