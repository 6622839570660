import GeoJSON from 'ol/format/GeoJSON';

import ColorLayer from '@giro3d/giro3d/core/layer/ColorLayer';
import VectorSource from '@giro3d/giro3d/sources/VectorSource';
import { SourceFile, SourceFileGeometry } from 'types/SourceFile';
import * as geojsonUtils from 'geojsonUtils';
import { ScopeColorLayer } from 'types/common';
import { EmptyDatasetProperties } from 'types/Dataset';
import LayerBuilder from './LayerBuilder';
import VectorLayer from '../layers/raster/VectorLayer';

export default class OLGeojsonBuilder extends LayerBuilder<EmptyDatasetProperties> {
    private async loadGiro3dLayer(sourceFile: SourceFile, vectorSource: VectorSource) {
        const extent = this.computeExtent(sourceFile.geometry);

        const colorLayer: ScopeColorLayer = new ColorLayer({ name: this.getDatasetId(), source: vectorSource, extent });
        colorLayer.userData.datasetId = this.getDatasetId();
        colorLayer.userData.sourceFileId = sourceFile.id;
        return colorLayer;
    }

    protected override buildLayer(sourceFile: SourceFile): Promise<VectorLayer> {
        let footprintGeometry: SourceFileGeometry;

        if (this.getDatasetProjectionAsString() !== this.getInstanceCrs()) {
            footprintGeometry = geojsonUtils.transform(
                sourceFile.geometry,
                this.getDatasetProjectionAsString(),
                this.getInstanceCrs()
            );
        } else {
            footprintGeometry = sourceFile.geometry;
        }

        const vectorSource = new VectorSource({
            data: this.buildUrl(sourceFile.links.download),
            format: new GeoJSON(),
            dataProjection: this.getDatasetProjectionAsString(),
            containsFn: this.getContainsFn(footprintGeometry),
            style: null,
        });

        const layer = new VectorLayer({
            layerManager: this._layerManager,
            readableName: this.getDatasetName(),
            datasetType: this.getDatasetType(),
            instance: this.getInstance(),
            datasetId: this.getDatasetId(),
            sourceFileId: sourceFile.id,
            dispatch: this._dispatch,
            getFootprint: () => sourceFile.geometry,
            createLayer: async () => this.loadGiro3dLayer(sourceFile, vectorSource),
            source: vectorSource,
            hostView: this._hostView,
        });

        return Promise.resolve(layer);
    }
}
