// React
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

// Redux
import { useNavigate, useParams } from 'react-router-dom';
import { selectPage, selectLeftTab } from '../../redux/actions';

// Components
import { PAGE } from '../../Navigation';
import Library from './Library';

const LibraryPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();

    useEffect(() => {
        dispatch(selectPage(PAGE.LIBRARY));
        dispatch(selectLeftTab(null));
    }, []);

    const onOpenCollection = (collection) => {
        const slug = collection.name
            .toString()
            .normalize('NFD') // break accented characters into components
            .replace(/[\u0300-\u036f]/g, '') // remove diacritics
            .toLowerCase()
            .replace(/\s+/g, '-') // spaces to dashes
            .replace(/&/g, '-and-') // ampersand to and
            .replace(/[^\w-]+/g, '') // remove non-words
            .replace(/--+/g, '-') // collapse multiple dashes
            .replace(/^-+/, '') // trim starting dash
            .replace(/-+$/, ''); // trim ending dash;
        navigate(`/library/collection/${collection.id}/${slug}`);
        document.title = `${collection.name} | SCOPE`;
    };

    const onCloseCollection = () => {
        navigate(`/library`);
        document.title = 'Library | SCOPE';
    };

    return (
        <div className="full-page">
            <Library
                project={undefined}
                collectionId={id}
                onCollectionClose={onCloseCollection}
                onCollectionOpen={onOpenCollection}
            />
        </div>
    );
};

export default LibraryPage;
