import ImageWMS from 'ol/source/ImageWMS';
import { WMSCapabilities } from 'ol/format';

import { Image } from 'ol/layer';

// React
import React, { useState } from 'react';

// Formik
import { Formik, Form } from 'formik';

// Boostrap
import { Button, Modal, ModalBody } from 'reactstrap';

import Axios from 'axios';

// Components
import BaseField from '../../forms/BaseField';
import BaseSubmit from '../../forms/BaseSubmit';
import CheckboxGroup from '../../forms/CheckboxGroup';
import overviewMapService from '../../../services/OverviewMapService';

const AddSource = (props) => {
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
    const initialFormState = {
        name: '',
        url: '',
        layers: [],
    };
    const [checkboxOptions, setList] = useState(initialFormState.layers);
    const [currentFormState, setCurrentFormState] = useState(initialFormState);
    const [lastUrl, setLastUrl] = useState('');

    const parser = new WMSCapabilities();

    const resetForm = () => {
        setCurrentFormState(initialFormState);
        setList(initialFormState.layers);
    };

    const findLayers = (source, matchList) => {
        const tempArr = [];
        if (source.Layer)
            source.Layer.forEach((subLayer) => {
                const sub = findLayers(subLayer, matchList);
                sub.forEach((item) => tempArr.push(item));
            });
        else if (matchList.includes(source.Name)) tempArr.push({ Title: source.Title, Name: source.Name });
        return tempArr;
    };

    const addSource = (values) => {
        Axios.get(lastUrl).then((response) => {
            const data = parser.read(response.data);
            if (data.Service) {
                findLayers(data.Capability.Layer, values.layers).forEach((layer) => {
                    overviewMapService.addLayer(
                        new Image({
                            title: layer.Title,
                            source: new ImageWMS({
                                url: lastUrl,
                                params: { 'layers': layer.Name },
                                crossOrigin: 'anonymous',
                            }),
                            opacity: 1.0,
                            visible: true,
                            layerId: layer.Name,
                        }),
                        values.name
                    );
                });
                props.cb();
            }
        });
        setModal(false);
        setCurrentFormState(initialFormState);
    };

    const validate = (values) => {
        const errors = {};
        if (!values.name) errors.name = 'required field';
        return errors;
    };

    const getLayers = (layer, array) => {
        if (!layer.Layer) array.push({ key: layer.Title, value: layer.Name });
        else layer.Layer.forEach((subLayer) => getLayers(subLayer, array));
    };

    const getCapabilities = (url) => {
        const base = url.split('?')[0];
        Axios.get(`${base}?service=WMS&request=GetCapabilities`)
            .then((response) => {
                const data = parser.read(response.data);
                const tempArr = [];
                if (data.Service) {
                    setCurrentFormState({
                        name: data.Service.Title,
                        url,
                        layers: [],
                    });
                    getLayers(data.Capability.Layer, tempArr);
                    setList(tempArr);
                    setLastUrl(`${base}?service=WMS&request=GetCapabilities`);
                } else resetForm();
            })
            .catch(() => resetForm());
    };

    return (
        <>
            <Button className="circle green" onClick={toggle} title="Add new source">
                <i className="fal fa-plus" />
            </Button>
            <Modal isOpen={modal} toggle={toggle} onClosed={resetForm} centered>
                <ModalBody>
                    <Formik
                        enableReinitialize
                        initialValues={currentFormState}
                        onSubmit={addSource}
                        validate={validate}
                    >
                        {({ values, isSubmitting }) => (
                            <Form>
                                <BaseField
                                    name="url"
                                    label="WMS URL"
                                    helperLabel="Example: https://ows.emodnet-humanactivities.eu/geoserver/emodnet/ows"
                                />
                                <Button
                                    className="form-control"
                                    onClick={() => {
                                        getCapabilities(values.url);
                                    }}
                                >
                                    Search capabilities
                                </Button>
                                <CheckboxGroup
                                    label="Layers"
                                    helperLabel="Some layers may not be shown on the overview map"
                                    name="layers"
                                    options={checkboxOptions}
                                />
                                <BaseField name="name" label="Name" />
                                <BaseSubmit name="submit" disabled={isSubmitting} value="Add source" />
                            </Form>
                        )}
                    </Formik>
                </ModalBody>
            </Modal>
        </>
    );
};

export default AddSource;
