import { FILTER_DISPLAY_MODE } from 'services/Constants';
import { HasVectorStyle, LayerState, VectorStyle } from 'types/LayerState';
import { useDispatch } from 'react-redux';
import * as layers from 'redux/layers';
import * as datasetsSlice from 'redux/datasets';
import { Color } from 'three';
import Dataset from 'types/Dataset';
import React from 'react';
import { toHex, toColor } from 'types/common';
import { useAppSelector } from 'store';
import SettingColorSelector from './SettingColorSelector';

export type Props = {
    layer: LayerState & HasVectorStyle;
};

type Getter<T = number> = () => T;
type Setter<T = number> = (v: T) => void;

type SimpleProps = {
    getter: Getter;
    setter: Setter;
};

const PointSizeSetting = (props: SimpleProps) => {
    return (
        <div className="row">
            <label htmlFor="pointSize" className="col-sm-5 col-form-label">
                <i className="fa fa-circle-o fa-fw" /> Point size
            </label>
            <div className="col-sm-7">
                <div className="input-group">
                    <input
                        id="pointSize"
                        type="number"
                        min="1"
                        max="100"
                        step="1"
                        value={props.getter()}
                        autoComplete="off"
                        className="form-control"
                        onChange={(e) => props.setter(e.target.valueAsNumber)}
                    />
                    <div className="input-group-text">m</div>
                </div>
            </div>
        </div>
    );
};

const LineWidthSettings = (props: SimpleProps) => {
    return (
        <div className="row">
            <label htmlFor="lineWidth" className="col-sm-5 col-form-label">
                <i className="fa fa-horizontal-rule fa-fw" /> Line width
            </label>
            <div className="col-sm-7">
                <div className="input-group">
                    <input
                        id="lineWidth"
                        type="number"
                        min="1"
                        max="100"
                        step="1"
                        value={props.getter()}
                        autoComplete="off"
                        className="form-control"
                        onChange={(e) => props.setter(e.target.valueAsNumber)}
                    />
                    <div className="input-group-text">m</div>
                </div>
            </div>
        </div>
    );
};

const FillOpacitySettings = (props: SimpleProps) => {
    return (
        <div className="row">
            <label htmlFor="fillOpacity" className="col-sm-5 col-form-label">
                <i className="fal fa-low-vision fa-fw" /> Opacity
            </label>
            <div className="col-sm-7">
                <div className="input-group">
                    <input
                        id="fillOpacity"
                        type="number"
                        min="0"
                        max="100"
                        step="1"
                        value={Math.round(props.getter() * 100)}
                        autoComplete="off"
                        className="form-control"
                        onChange={(e) => props.setter(e.target.valueAsNumber / 100)}
                    />
                    <div className="input-group-text">%</div>
                </div>
            </div>
        </div>
    );
};

const BorderOpacitySettings = (props: SimpleProps) => {
    return (
        <div className="row">
            <label htmlFor="borderOpacity" className="col-sm-5 col-form-label">
                <i className="fal fa-low-vision fa-fw" /> Opacity
            </label>
            <div className="col-sm-7">
                <div className="input-group">
                    <input
                        id="borderOpacity"
                        type="number"
                        min="0"
                        max="100"
                        step="1"
                        value={Math.round(props.getter() * 100)}
                        autoComplete="off"
                        className="form-control"
                        onChange={(e) => props.setter(e.target.valueAsNumber / 100)}
                    />
                    <div className="input-group-text">%</div>
                </div>
            </div>
        </div>
    );
};

const BorderWidthSettings = (props: SimpleProps) => {
    return (
        <div className="row">
            <label htmlFor="borderWidth" className="col-sm-5 col-form-label">
                <i className="fa fa-horizontal-rule fa-fw" /> Width
            </label>
            <div className="col-sm-7">
                <div className="input-group">
                    <input
                        id="borderWidth"
                        type="number"
                        min="0"
                        max="5"
                        step="0.1"
                        value={props.getter()}
                        autoComplete="off"
                        className="form-control"
                        onChange={(e) => props.setter(e.target.valueAsNumber)}
                    />
                    <div className="input-group-text">m</div>
                </div>
            </div>
        </div>
    );
};

function StyleEditor(props: {
    style: VectorStyle;
    setLineWidth(v: number): void;
    setPointSize(v: number): void;
    setFillOpacity(v: number): void;
    setFillColor(v: Color): void;
    setBorderOpacity(v: number): void;
    setBorderWidth(v: number): void;
    setBorderColor(v: Color): void;
}) {
    const { style } = props;
    return (
        <>
            <h5>Layer style</h5>
            <LineWidthSettings getter={() => style.lineWidth} setter={(v) => props.setLineWidth(v)} />
            <PointSizeSetting getter={() => style.pointSize} setter={(v) => props.setPointSize(v)} />
            <h6>Fill</h6>
            <FillOpacitySettings getter={() => style.fillOpacity} setter={(v) => props.setFillOpacity(v)} />
            <SettingColorSelector
                title="Color"
                color={toColor(style.fillColor)}
                onChange={(v) => props.setFillColor(v)}
            />
            <h6>Border</h6>
            <BorderOpacitySettings getter={() => style.borderOpacity} setter={(v) => props.setBorderOpacity(v)} />
            <BorderWidthSettings getter={() => style.borderWidth} setter={(v) => props.setBorderWidth(v)} />
            <SettingColorSelector
                title="Color"
                color={toColor(style.borderColor)}
                onChange={(v) => props.setBorderColor(v)}
            />
        </>
    );
}

export const FilterMode = (props: { dataset: Dataset } & React.StyleHTMLAttributes<HTMLDivElement>) => {
    const dispatch = useDispatch();

    const layer = useAppSelector(layers.get<HasVectorStyle>(props.dataset.id));
    const filter = useAppSelector(layers.getFeatureFilter(layer));

    const style = filter.style;
    const filterMode = filter.mode;

    const changeFilterMode = (value: FILTER_DISPLAY_MODE) => {
        dispatch(layers.setFeatureFilterMode({ layer, value }));
    };

    return (
        <>
            <div className="row" {...props} key={`vector-filtermode-${props.dataset.id}`}>
                <label htmlFor="vector-filtermode" className="col-sm-6 col-form-label">
                    <i className="fal fa-filter fa-fw" /> Filter mode
                </label>
                <div className="col-sm-6">
                    <div className="select-group">
                        <select
                            id="vector_filtermode"
                            className="form-select"
                            value={filter.mode}
                            onChange={(e) => changeFilterMode(e.target.value as FILTER_DISPLAY_MODE)}
                        >
                            <option key="vector-filtermode-hide" value={FILTER_DISPLAY_MODE.HIDE}>
                                {FILTER_DISPLAY_MODE.HIDE}
                            </option>
                            <option key="vector-filtermode-style" value={FILTER_DISPLAY_MODE.STYLE}>
                                {FILTER_DISPLAY_MODE.STYLE}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
            {filterMode === FILTER_DISPLAY_MODE.STYLE ? (
                <StyleEditor
                    style={style}
                    setBorderColor={(value) => dispatch(layers.setFilterBorderColor({ layer, value: toHex(value) }))}
                    setBorderOpacity={(value) => dispatch(layers.setFilterBorderOpacity({ layer, value }))}
                    setLineWidth={(value) => dispatch(layers.setFilterLineWidth({ layer, value }))}
                    setPointSize={(value) => dispatch(layers.setFilterPointSize({ layer, value }))}
                    setBorderWidth={(value) => dispatch(layers.setFilterBorderWidth({ layer, value }))}
                    setFillColor={(value) => dispatch(layers.setFilterFillColor({ layer, value: toHex(value) }))}
                    setFillOpacity={(value) => dispatch(layers.setFilterFillOpacity({ layer, value }))}
                />
            ) : null}
        </>
    );
};

const VectorSettings = (props: Props) => {
    const { layer } = props;

    const dispatch = useDispatch();

    const style = useAppSelector(layers.getVectorStyle(layer));
    const hasFilter = useAppSelector(layers.hasFeatureFilter(layer));
    const filter = useAppSelector(layers.getFeatureFilter(layer));
    const dataset = useAppSelector(datasetsSlice.get(layer.datasetId));

    return (
        <>
            <StyleEditor
                style={style}
                setBorderColor={(value) => dispatch(layers.setBorderColor({ layer, value: toHex(value) }))}
                setBorderOpacity={(value) => dispatch(layers.setBorderOpacity({ layer, value }))}
                setLineWidth={(value) => dispatch(layers.setLineWidth({ layer, value }))}
                setPointSize={(value) => dispatch(layers.setPointSize({ layer, value }))}
                setBorderWidth={(value) => dispatch(layers.setBorderWidth({ layer, value }))}
                setFillColor={(value) => dispatch(layers.setFillColor({ layer, value: toHex(value) }))}
                setFillOpacity={(value) => dispatch(layers.setFillOpacity({ layer, value }))}
            />

            {hasFilter ? (
                <>
                    <h5>Style options for filter: {filter.name} </h5>
                    <FilterMode dataset={dataset} />
                </>
            ) : (
                <div>
                    This layer does not have a filter. You can add one using the attribute table and style it here later
                </div>
            )}
        </>
    );
};

export default VectorSettings;
