import Dataset from 'types/Dataset';
import { LAYER_STATES } from '../../../services/Constants';

export type Props = {
    dataset: Dataset;
};

const DatasetListItemStatus = (props: Props) => {
    const { dataset } = props;
    switch (dataset.state) {
        case LAYER_STATES.EMPTY:
            return <p>Dataset has no data.</p>;
        case LAYER_STATES.UPLOADING:
            return <p>Dataset is currently being uploaded.</p>;
        case LAYER_STATES.VALIDATION_FAILED:
            return <p>{`Data validation has failed: ${dataset.state_msg}`}</p>;
        case LAYER_STATES.CONVERTING:
            return (
                <p>
                    Dataset is currently being processed. It will be automatically loaded once the processing is done.
                </p>
            );
        case LAYER_STATES.CONVERSION_FAILED:
            return <p>{`Data processing has failed: ${dataset.state_msg}`}</p>;
        case LAYER_STATES.DELETING:
            return <p>Dataset is being deleted</p>;
        case LAYER_STATES.DELETION_FAILED:
            return <p>{`Data deletion has failed: ${dataset.state_msg}`}</p>;
        case LAYER_STATES.LOADING:
            return <p>Dataset is currently being loaded into the view.</p>;
        case LAYER_STATES.LOADING_FAILED:
            return <p>{`Loading data has failed: ${dataset.loadFailureReason}`}</p>;
        default:
            return <p>Unknown state</p>;
    }
};

export default DatasetListItemStatus;
