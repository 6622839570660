import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import { DATASETS_STATES, LAYER_STATES } from 'services/Constants';
import { RootState } from 'store';
import Dataset from 'types/Dataset';
import Project from 'types/Project';
import { DatasetId, SourceFileId } from 'types/common';
import * as geojsonUtils from 'geojsonUtils';
import { SourceFile, getBaseName } from 'types/SourceFile';
import { getMinMax } from './buildLayerState';

export type State = {
    datasets: Dataset[];
    sourceFiles: Record<DatasetId, SourceFile[]>;
    currentProject: Project;
    clickedDataset: DatasetId;
    clickedSourceFile: SourceFileId;
    inspectedDataset: DatasetId;
    state: DATASETS_STATES;
};

const initialState: State = {
    datasets: [],
    sourceFiles: {},
    currentProject: undefined,
    clickedDataset: undefined,
    clickedSourceFile: undefined,
    inspectedDataset: undefined,
    state: undefined,
};

function getDataset(state: State, id: DatasetId): Dataset | null {
    const idx = state.datasets.findIndex((ds) => ds.id === id);
    if (idx >= 0) {
        return state.datasets[idx];
    }

    return null;
}

function computeElevationRange(datasets: Dataset[]) {
    let min = +Infinity;
    let max = -Infinity;

    for (const dataset of datasets) {
        const range = getMinMax(dataset);
        if (range) {
            min = Math.min(min, range.min);
            max = Math.max(max, range.max);
        }
    }

    return { min, max };
}

function findSourceFile(s: State, datasetId: DatasetId, sourceFileId: SourceFileId): SourceFile {
    const sourceFiles = s.sourceFiles[datasetId];
    if (sourceFiles) {
        return sourceFiles.find((sf) => sf.id === sourceFileId);
    }
    return undefined;
}

// Selectors
const self = (store: RootState) => store.datasets;

export const getProjectDatasets = createSelector(self, (s) => s.datasets);
export const getProjectSourceFiles = createSelector(self, (s) => s.sourceFiles);
export const get = (id: DatasetId) => createSelector(self, (s) => getDataset(s, id));
export const getRenderableDatasets = createSelector(
    (s) => self(s).datasets,
    (datasets) => datasets?.filter((ds) => ds.doRender)
);
export const getDatasetState = (id: DatasetId) => createSelector(self, (s) => getDataset(s, id).state);
export const getState = createSelector(self, (s) => s.state);
export const getClickedDataset = createSelector(self, (s) => s.clickedDataset);
export const getClickedSourceFile = createSelector(self, (s) => s.clickedSourceFile);
export const getInspectedDataset = createSelector(self, (s) => s.inspectedDataset);
export const currentProject = createSelector(self, (s) => s.currentProject);
export const getSourceFile = (datasetId: DatasetId, sourceFileId: SourceFileId) =>
    createSelector(self, (s) => findSourceFile(s, datasetId, sourceFileId));
export const getSourceFiles = (id: DatasetId) => createSelector(self, (s) => s.sourceFiles[id]);
export const getSourceFilename = (datasetId: DatasetId, sourceFileId: SourceFileId) =>
    createSelector(self, (s) => {
        const sourceFile = findSourceFile(s, datasetId, sourceFileId);
        return getBaseName(sourceFile);
    });
export const totalElevationRange = createSelector(
    (s) => self(s).datasets,
    (datasets) => computeElevationRange(datasets)
);
export const getDatasetFromSourceFileId = (id: SourceFileId) =>
    createSelector(self, (s) => {
        for (const [datasetId, files] of Object.entries(s.sourceFiles)) {
            if (files.some((file) => file.id === id)) {
                return getDataset(s, datasetId);
            }
        }
        return null;
    });

export const getProjectVolume = createSelector(self, (s) => {
    const geometry = s.currentProject?.geometry;
    if (geometry) {
        const crs = `EPSG:${s.currentProject.projection}`;
        const extent = geojsonUtils.computeExtent(geometry, 'EPSG:4326', crs);
        const range = computeElevationRange(s.datasets);
        return extent.toBox3(range.min, range.max);
    }
    return null;
});

export const getProjectExtent = createSelector(self, (s) => {
    const geometry = s.currentProject?.geometry;
    if (geometry) {
        const crs = `EPSG:${s.currentProject.projection}`;
        const extent = geojsonUtils.computeExtent(geometry, 'EPSG:4326', crs);
        return extent;
    }
    return null;
});

const slice = createSlice({
    name: 'datasets',
    initialState,
    reducers: {
        reset: () => initialState,
        setCurrentProject: (state, action: PayloadAction<Project>) => {
            state.currentProject = action.payload;
        },
        addDataset: (state, action: PayloadAction<Dataset>) => {
            state.datasets.push(action.payload);
        },
        removeDataset: (state, action: PayloadAction<Dataset | DatasetId>) => {
            const id = typeof action.payload === 'string' ? action.payload : action.payload.id;

            const idx = state.datasets.findIndex((ds) => ds.id === id);
            state.datasets.splice(idx, 1);
        },
        setState: (state, action: PayloadAction<DATASETS_STATES>) => {
            state.state = action.payload;
        },
        updateDataset: (state, action: PayloadAction<Dataset>) => {
            const id = action.payload.id;
            const idx = state.datasets.findIndex((ds) => ds.id === id);
            state.datasets[idx] = action.payload;
        },
        reorderDatasets: (state, action: PayloadAction<DatasetId[]>) => {
            const payload = action.payload;
            state.datasets.sort((a, b) => {
                const indexA = payload.indexOf(a.id);
                const indexB = payload.indexOf(b.id);
                return (indexA !== -1 ? indexA : 0) - (indexB !== -1 ? indexB : 0);
            });
        },
        setClickedDataset: (state, action: PayloadAction<{ dataset: DatasetId; sourceFile: SourceFileId }>) => {
            state.clickedDataset = action.payload.dataset;
            state.clickedSourceFile = action.payload.sourceFile;
        },
        setInspectedDataset: (state, action: PayloadAction<DatasetId>) => {
            state.inspectedDataset = action.payload;
        },
        setDatasetState: (
            state,
            action: PayloadAction<{ dataset: Dataset; state: LAYER_STATES; message?: string }>
        ) => {
            const dataset = getDataset(state, action.payload.dataset.id);
            if (dataset) {
                dataset.state = action.payload.state;
                dataset.state_msg = action.payload.message;
            }
        },
        setDatasetSourceFiles: (state, action: PayloadAction<{ datasetId: DatasetId; sourceFiles: SourceFile[] }>) => {
            const datasetId = action.payload.datasetId;
            const dataset = getDataset(state, datasetId);
            if (dataset) {
                state.sourceFiles[datasetId] = action.payload.sourceFiles;
            }
        },
        updateDatasetSourceFile: (state, action: PayloadAction<{ datasetId: DatasetId; sourceFile: SourceFile }>) => {
            const datasetId = action.payload.datasetId;
            const dataset = getDataset(state, datasetId);
            if (dataset) {
                const sourceFiles = state.sourceFiles[datasetId];
                const idx = sourceFiles.findIndex((sf) => sf.id === action.payload.sourceFile.id);
                if (idx) {
                    sourceFiles[idx] = action.payload.sourceFile;
                }
            }
        },
        removeDatasetSourceFile: (
            state,
            action: PayloadAction<{ datasetId: DatasetId; sourceFileId: SourceFileId }>
        ) => {
            const datasetId = action.payload.datasetId;
            const dataset = getDataset(state, datasetId);
            if (dataset && state.sourceFiles[datasetId]) {
                const idx = state.sourceFiles[datasetId].findIndex((sf) => sf.id === action.payload.sourceFileId);
                state.sourceFiles[datasetId].splice(idx, 1);
            }
        },
    },
});

export const reducer = slice.reducer;

export const reset = slice.actions.reset;
export const addDataset = slice.actions.addDataset;
export const updateDataset = slice.actions.updateDataset;
export const removeDataset = slice.actions.removeDataset;
export const reorderDatasets = slice.actions.reorderDatasets;
export const setCurrentProject = slice.actions.setCurrentProject;
export const setClickedDataset = slice.actions.setClickedDataset;
export const setInspectedDataset = slice.actions.setInspectedDataset;
export const setDatasetState = slice.actions.setDatasetState;
export const setDatasetSourceFiles = slice.actions.setDatasetSourceFiles;
export const updateDatasetSourceFile = slice.actions.updateDatasetSourceFile;
export const removeDatasetSourceFile = slice.actions.removeDatasetSourceFile;
export const setState = slice.actions.setState;
