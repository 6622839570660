// React
import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// selector
import { Button } from 'reactstrap';

import * as giro3d from 'redux/giro3d';

import { QueryParameters } from 'types/common';
import { useAppSelector } from 'store';
import UppyService from 'services/UppyService';
import { useEventBus } from 'EventBus';
import { getService } from 'ServiceContainer';
import { getSeismicViewEnabled, getUppyShown } from 'redux/selectors';
import * as datasetsSlice from 'redux/datasets';

// Redux
import { useMountEffect } from 'components/utils';
import BaseGiro3dService from 'services/BaseGiro3dService';
import { HostView } from 'giro3d_extensions/layers/Layer';
import {
    loadGiro3d,
    unloadGiro3d,
    selectPage,
    selectLeftTab,
    fetchProject,
    closeSeismicView,
    selectRightTab,
} from '../../redux/actions';
import { LEFT_TAB, PAGE } from '../../Navigation';

// Components
import Giro3dControls from './Giro3DControls';
import ProjectsPage from './ProjectsPane';
import Help from '../Help';
import ItemsPopup from './itemsPopover/ItemsPopup';
import TitleBar from './TitleBar/TitleBar';
import { DATASETS_STATES } from '../../services/Constants';
import StatusBar from './StatusBar/StatusBar';
import PinnedLegendList from './pinnedLegend/PinnedLegendList';
import Minimap from './minimap/Minimap';
import ContextMenu from './contextMenu/ContextMenu';
import DragAndDropArea from './DragAndDropArea';
import CrossSections from './crossSections/CrossSections';

import TooltipLabel from './TooltipLabel';
import Grid from './Grid';
import LineFollowerMenu from './lineFollower/LineFollowerMenu';
import LineFollowerModal from './lineFollower/LineFollowerModal';
import LineFollower from './lineFollower/LineFollower';

const Giro3D = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const eventBus = useEventBus();
    const { id: projectId } = useParams();
    const mainView = getService('MainViewManager');
    const seismicView = getService('SeismicViewManager');

    const project = useAppSelector(datasetsSlice.currentProject);
    const allDatasets = useAppSelector(
        datasetsSlice.getProjectDatasets,
        (a, b) => (a.length === 0) === (b.length === 0) // We only care if there are datasets
    );
    const loadingState = useAppSelector(datasetsSlice.getState);
    const giro3dReady = useAppSelector(giro3d.isInitialized);
    const seismicViewReady = useAppSelector(giro3d.isSeismicViewInitialized);
    const seismicViewEnabled = useAppSelector<boolean>(getSeismicViewEnabled);

    const uppyShown = useAppSelector(getUppyShown);

    const giro3dRef = useRef<HTMLDivElement>(null);
    const giro3dMinimapRef = useRef(null);
    const giro3dSeismicRef = useRef(null);

    const inspectorRef = useRef(null);

    const giro3dControlsRef = useRef(null);

    function getQueryStringParams(query: string): QueryParameters {
        return query
            ? (/^[?#]/.test(query) ? query.slice(1) : query).split('&').reduce((params, param) => {
                  const [key, value] = param.split('=');
                  params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
                  return params;
              }, {})
            : {};
    }

    const queryParams = getQueryStringParams(useLocation().search);

    const [contextLost, setContextLost] = useState<boolean>(false);

    const onContextLost = (options: { service: BaseGiro3dService }) => {
        if (options.service.view === HostView.MainView) {
            setContextLost(true);
        }
    };

    const onContextRestored = (options: { service: BaseGiro3dService }) => {
        if (options.service.view === HostView.MainView) {
            setContextLost(false);
        }
    };

    const subscribe = () => {
        eventBus.subscribe('rendering-context-lost', onContextLost);
        eventBus.subscribe('rendering-context-restored', onContextRestored);
    };

    const unsubscribe = () => {
        eventBus.unsubscribe('rendering-context-lost', onContextLost);
        eventBus.unsubscribe('rendering-context-restored', onContextRestored);
    };

    useMountEffect(subscribe, unsubscribe);

    // Load data from API
    useEffect(() => {
        if (projectId) {
            dispatch(fetchProject(projectId, false, navigate));
            dispatch(selectPage(PAGE.PROJECT));
            dispatch(selectLeftTab(null));
            dispatch(selectRightTab(null));
        }
    }, [projectId]);

    // Trigger init of giro3d
    useEffect(() => {
        document.title = `${project ? project.name : 'Loading Project...'} | SCOPE`;

        if (!giro3dRef.current) return undefined;

        if (project && project.geometry && project.id === projectId) {
            dispatch(
                loadGiro3d(
                    giro3dRef.current,
                    giro3dMinimapRef.current,
                    giro3dSeismicRef.current,
                    inspectorRef.current,
                    giro3dControlsRef.current,
                    project,
                    queryParams
                )
            );
        }
        return () => {
            dispatch(unloadGiro3d());
        };
    }, [project]);

    if (!projectId) {
        return <ProjectsPage />;
    }

    const onFilesDrop = (files) => {
        if (!uppyShown) {
            dispatch(selectLeftTab(LEFT_TAB.PROJECT_DATASETS));
        }
        UppyService.getInstance().addFiles(files);
    };

    const loading = loadingState === DATASETS_STATES.LOADING || undefined;

    return (
        <div className="project">
            {loading && (
                <div className="map-container-loader">
                    <div className="dot-flashing" />
                </div>
            )}
            {contextLost && (
                <div className="map-container-loader">
                    <div style={{ width: '19rem' }}>
                        <h5 className="row mb-3">
                            <span>
                                <i className="fal fa-exclamation-triangle icon-yellow" /> Failed to display 3D view.
                            </span>
                        </h5>

                        <div className="row mb-3 w-100">
                            <Button
                                color="success"
                                className="w-100"
                                title="Reload project"
                                onClick={() => window.location.reload()}
                            >
                                <i className="fal fa-arrow-rotate-left" /> Reload project
                            </Button>
                        </div>
                    </div>
                </div>
            )}
            {(!project?.geometry || allDatasets?.length === 0) && loadingState === DATASETS_STATES.LOADED && (
                <div className="map-container-loader">No datasets to show</div>
            )}
            <ContextMenu />
            <ItemsPopup />
            <TooltipLabel />
            <div className="giro3d">
                <div className="giro3d-container" ref={giro3dRef} hidden={seismicViewEnabled} />
                <div className="giro3d-seismic-container" ref={giro3dSeismicRef} hidden={!seismicViewEnabled} />

                <Minimap minimapRef={giro3dMinimapRef} />
                <TitleBar />

                <div className="giro3d-controls">
                    <PinnedLegendList />
                    <LineFollowerMenu />
                    <Giro3dControls
                        viewport={giro3dRef.current}
                        compassRef={giro3dControlsRef}
                        hidden={seismicViewEnabled || loading}
                    />
                </div>

                <LineFollowerModal />
                <LineFollower />

                <div className="giro3d-inspector" ref={inspectorRef} />

                <Button
                    className="borderless close-view"
                    title="Close seismic view"
                    onClick={() => dispatch(closeSeismicView())}
                    hidden={!seismicViewEnabled}
                >
                    <i className="fal fa-arrow-left" />
                </Button>

                <StatusBar />

                {giro3dReady ? (
                    <CrossSections
                        notify={(source) => eventBus.dispatch('notify-change', { source })}
                        parent={mainView.getInstance().threeObjects}
                    />
                ) : null}

                {giro3dReady ? <Grid mode="3D" instance={mainView.getInstance()} /> : null}
                {seismicViewReady ? <Grid mode="2D" instance={seismicView.getInstance()} /> : null}

                <Help />

                <DragAndDropArea showFeedback={!uppyShown} onDrop={onFilesDrop} />
            </div>
        </div>
    );
};

export default Giro3D;
