import { combineReducers } from 'redux';
import buildReducer from '../buildReducer';

import { ORGANIZATIONS_TYPES, REGISTER_USER_TYPES, SSDM_TYPES, USER_TYPES } from '../actionTypes';

import { reducer as datasets } from '../datasets';
import collections from './collections';
import projects from './projects';
import projections from './projections';
import proj4 from './proj4';
import authentication from './authentication';
import apiHealthCheck from './apiHealthCheck';
import error from './error';
import release from './release';
import help from './help';
import { reducer as giro3d } from '../giro3d';
import overviewMap from './overviewMap';
import version from './version';
import vector from './vector';
import notifications from './notifications';
import users from './users';
import navigation from './navigation';
import { reducer as settings } from '../settings';
import userEligible from './user_eligible';
import allDatasets from './allDatasets';
import seismicView from './seismicView';
import apiKeys from './apiKeys';
import { reducer as crossSections } from '../crossSections';
import { reducer as grid } from '../grid';
import { reducer as graphicsSettings } from '../graphicsSettings';
import { reducer as layers } from '../layers';
import { reducer as annotations } from '../annotations';
import uppy from './uppy';
import { reducer as drawTool } from '../drawTool';
import { reducer as elevationProfile } from '../elevationProfile';

export default combineReducers({
    authentication,
    registration: buildReducer(REGISTER_USER_TYPES, false),
    fetchingUser: buildReducer(USER_TYPES, false),
    organizations: buildReducer(ORGANIZATIONS_TYPES, true),
    ssdm: buildReducer(SSDM_TYPES, true),
    allDatasets,
    annotations,
    vector,
    projections,
    proj4,
    datasets,
    collections,
    projects,
    apiHealthCheck,
    error,
    release,
    help,
    giro3d,
    overviewMap,
    version,
    notifications,
    users,
    navigation,
    settings,
    userEligible,
    seismicView,
    apiKeys,
    crossSections,
    grid,
    graphicsSettings,
    layers,
    uppy,
    drawTool,
    elevationProfile,
});
