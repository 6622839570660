// React
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'reactstrap';

import * as giro3d from 'redux/giro3d';
import { DEFAULT_GIRO3D_SETTINGS } from 'services/Constants';

// Components
import { BareSettingSlider } from './SettingSlider';

function ZScaleSetting() {
    const dispatch = useDispatch();

    const scale = useSelector(giro3d.getZScale);

    return (
        <div className="settings-group">
            <div className="settings-main-toggle">
                <div>
                    <i className="fal fa-arrows-alt-v active" />
                </div>
                <label htmlFor="zscale" className="settings-label">
                    <span>Z-Scale</span>
                </label>
                <Button className="borderless" title="Reset Z-Scale" onClick={() => dispatch(giro3d.resetZScale())}>
                    <i className="fal fa-arrow-rotate-left" />
                </Button>
                <BareSettingSlider
                    format="percent"
                    min={0.5}
                    max={20}
                    step={0.01}
                    value={scale}
                    onChange={(v) => dispatch(giro3d.setZScale(v || DEFAULT_GIRO3D_SETTINGS.Z_SCALE))}
                    replaceNaN={0.5}
                />
            </div>
        </div>
    );
}

export default ZScaleSetting;
