import 'ol/ol.css';
import { Draw, Modify } from 'ol/interaction';
import { Vector as VectorSource } from 'ol/source';
import { Vector as VectorLayer } from 'ol/layer';
import { getArea, getLength } from 'ol/sphere';
import { unByKey } from 'ol/Observable';
import { never } from 'ol/events/condition';
import { fromLonLat } from 'ol/proj';

import { MEASUREMENT_COORDINATES, MEASUREMENT_RESULT } from '../../../redux/actionTypes';

import { drawStyleFunction, finishedStyleFunction, modifyStyleFunction } from './drawStyles';

const formatLength = (line) => {
    const length = getLength(line);
    let output;
    if (length > 10000) output = `${Math.round((length / 1000) * 100) / 100} km`;
    else output = `${Math.round(length)} m`;
    return output;
};

const formatArea = (polygon) => {
    const area = getArea(polygon);
    let output;
    if (area > 1000000) output = `${Math.round((area / 1000000) * 100) / 100} km2`;
    else output = `${Math.round(area)} m2`;
    return output;
};

let drawLayer;
let source;
let sketch;
let draw;
let drawing = true;

let completeDraw;
let modify;

const guessType = (map, coordinates) => {
    const pixelCoord0 = map.getPixelFromCoordinate(coordinates[0]);
    const pixelCoord1 = map.getPixelFromCoordinate(coordinates[coordinates.length - 1]);
    const dX = pixelCoord0[0] - pixelCoord1[0];
    const dY = pixelCoord0[1] - pixelCoord1[1];
    return Math.sqrt(dX * dX + dY * dY) < 10 && coordinates.length > 3 ? 'Polygon' : 'LineString';
};

export const removeMeasureInteraction = (map, dispatch) => {
    if (draw) draw.dispatchEvent('drawend');
    map.removeLayer(drawLayer);
    map.removeInteraction(modify);

    dispatch({ type: MEASUREMENT_COORDINATES, payload: undefined });
    dispatch({ type: MEASUREMENT_RESULT, payload: undefined });
};

export const endMeasureInteraction = () => {
    if (draw) draw.finishDrawing();
};

export const editDrawnPoint = (index, coord) => {
    const feature = source.getFeatures()[0] || draw.sketchFeature_;
    const geom = feature.getGeometry();
    const coords = geom.getCoordinates();
    switch (geom.getType()) {
        case 'Polygon':
            coords[0][index] = fromLonLat(coord);
            if (index === 0) coords[0][coords[0].length - 1] = fromLonLat(coord);
            break;
        case 'LineString':
            coords[index] = fromLonLat(coord);
            break;
        default:
    }
    geom.setCoordinates(coords);
    feature.setGeometry(geom);
    if (drawing) draw.sketchCoords_ = coords;
};

export const deleteDrawnPoint = (index) => {
    const feature = source.getFeatures()[0] || draw.sketchFeature_;
    const geom = feature.getGeometry();
    let coords = geom.getCoordinates();
    switch (geom.getType()) {
        case 'Polygon':
            if (coords[0].length > 4) {
                coords[0] = coords[0].slice(0, index).concat(coords[0].slice(index + 1));
                if (index === 0) coords[0][coords[0].length - 1] = coords[0][0];
            }
            break;
        case 'LineString':
            if (coords.length > 2) coords = coords.slice(0, index).concat(coords.slice(index + 1));
            break;
        default:
    }
    geom.setCoordinates(coords);
    feature.setGeometry(geom);
    if (drawing) draw.sketchCoords_ = coords;
};

const updateResults = (type, geom, dispatch) => {
    let coordinates;

    switch (type) {
        case 'LineString':
            coordinates = geom.clone().transform('EPSG:3857', 'EPSG:4326').getCoordinates();
            break;
        case 'Polygon':
            coordinates = geom.clone().transform('EPSG:3857', 'EPSG:4326').getCoordinates()[0];
            break;
        default:
    }

    const output = {
        length: formatLength(geom),
        area: type === 'Polygon' ? formatArea(geom) : undefined,
    };

    dispatch({ type: MEASUREMENT_COORDINATES, payload: coordinates });
    dispatch({ type: MEASUREMENT_RESULT, payload: output });
};

export const addMeasureInteraction = (map, dispatch, endMeasure) => {
    drawing = true;

    source = new VectorSource();
    drawLayer = new VectorLayer({
        source,
        style: finishedStyleFunction,
    });
    map.addLayer(drawLayer);

    draw = new Draw({
        source,
        type: 'LineString',
        style: drawStyleFunction,
        stopClick: true,
        freehandCondition: never,
    });
    map.addInteraction(draw);

    let listener;
    let count = 1;
    draw.on('drawstart', (evt) => {
        sketch = evt.feature;
        listener = sketch.getGeometry().on('change', (event) => {
            const geom = event.target;

            const g = geom.clone();
            g.transform('EPSG:3857', 'EPSG:4326');

            const output = { length: formatLength(geom), area: undefined };
            const coordinates = g.getCoordinates();

            // Runs after every new vertex is added
            if (coordinates.length !== count) {
                count = coordinates.length;
                const type = guessType(map, geom.getCoordinates().slice(0, count - 1));
                if (type === 'Polygon') draw.finishDrawing();
            }

            if (drawing) {
                dispatch({ type: MEASUREMENT_COORDINATES, payload: coordinates });
                dispatch({ type: MEASUREMENT_RESULT, payload: output });
            }
        });
        updateResults(evt.feature.getGeometry().getType(), evt.feature.getGeometry(), dispatch);
    });

    source.on('addfeature', (event) => {
        if (drawing) {
            drawing = false;
            endMeasure();

            source.clear(true);

            const geom = event.feature.getGeometry();
            const type = guessType(map, geom.getCoordinates());

            completeDraw = new Draw({
                source,
                type,
                style: finishedStyleFunction,
                stopClick: true,
                freehandCondition: never,
            });
            map.addInteraction(completeDraw);

            switch (type) {
                case 'LineString':
                    completeDraw.appendCoordinates(geom.getCoordinates());
                    break;
                case 'Polygon':
                    completeDraw.appendCoordinates(geom.getCoordinates().slice(0, -1));
                    break;
                default:
            }
            completeDraw.finishDrawing();
            map.removeInteraction(completeDraw);

            modify = new Modify({
                source,
                style: modifyStyleFunction,
            });

            map.addInteraction(modify);

            source
                .getFeatures()[0]
                .getGeometry()
                .on('change', (evt) => {
                    const newGeom = evt.target;
                    updateResults(type, newGeom, dispatch);
                });

            const newGeom = source.getFeatures()[0].getGeometry();
            updateResults(type, newGeom, dispatch);
        }
    });

    draw.on('drawend', () => {
        map.removeInteraction(draw);

        sketch = null;
        draw = null;

        unByKey(listener);
    });
};
