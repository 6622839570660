// React
import React, { useState } from 'react';
import { getUid } from 'ol/util';

import LayerItem from './LayerItem';
import ToggleSwitch from '../../ToggleSwitch';

const OverlayGroup = (props) => {
    const { layerGroup } = props;
    const key = getUid(layerGroup);

    const [open, setOpen] = useState(false);
    const [visible, setVisible] = useState(layerGroup.getVisible());

    function onToggle() {
        setVisible(!visible);
        layerGroup.setVisible(!layerGroup.getVisible());
    }

    const renderedLayerItems = layerGroup
        .getLayers()
        .array_.map((layer) => <LayerItem layer={layer} key={`overlay-${getUid(layer)}-item`} groupOpen={open} />);

    return (
        <div className="data-group">
            <div className="data-group-header">
                <label htmlFor={`${key}-dropdown`} className="data-group-label dropdown-arrow">
                    <span>{layerGroup.get('title')}</span>
                    <input
                        type="checkbox"
                        id={`${key}-dropdown`}
                        checked={open}
                        onChange={(e) => setOpen(e.target.checked)}
                    />
                    <div className="arrow" />
                </label>
                <ToggleSwitch id={`${key}-visible`} checked={visible} onChange={() => onToggle()} />
            </div>
            {renderedLayerItems}
        </div>
    );
};

export default OverlayGroup;
