import { ArrowHelper, Color, Curve, Group, MathUtils, Vector3 } from 'three';
import { type Instance } from '@giro3d/giro3d/core';
import Entity3D from '@giro3d/giro3d/entities/Entity3D';
import type Source from './Source';

const WHITE = new Color('white');

/**
 * A collection of arrow heads to display the direction of flow in a line.
 */
export default class ArrowHeadCollection extends Entity3D {
    private readonly _arrowHelpers: ArrowHelper[] = [];

    private readonly _source: Source;

    private _color: Color;

    get color() {
        return this._color;
    }

    set color(c: Color) {
        if (this._color == null || !this._color.equals(c)) {
            this._arrowHelpers.forEach((helper) => {
                helper.userData.color = c;
                helper.setColor(c);
            });
            this._instance.notifyChange();
        }
    }

    constructor(source: Source, instance: Instance) {
        super(MathUtils.generateUUID(), new Group());
        this._source = source;
        this._instance = instance;
        this.object3d.name = 'ArrowHeadCollection';
    }

    private createArrowHelper(t: number, line: Curve<Vector3>, color: Color, scale: number) {
        const pos = line.getPoint(t);
        const tangent = line.getTangent(t);

        // make arrowlength equal to headlength so the head starts at the center of the line.
        const arrowLength = 12 * scale;
        const headLength = 12 * scale;
        const headWidth = 5 * scale;

        const arrowHelper = new ArrowHelper(tangent, pos, arrowLength, color, headLength, headWidth);

        arrowHelper.userData.color = color;

        arrowHelper.name = 'ArrowHelper';

        this._arrowHelpers.push(arrowHelper);
        this.object3d.add(arrowHelper);

        this.onObjectCreated(arrowHelper);
    }

    setBrightness(brightness: number) {
        this._arrowHelpers.forEach((helper) => {
            const solidColor: Color = helper.userData.color;
            if (brightness <= 0) {
                helper.setColor(solidColor);
            } else {
                helper.setColor(new Color().lerpColors(solidColor, WHITE, brightness));
            }
        });
    }

    async addArrowHelpers(params: { scale: number; spacing: number; color: Color }) {
        const curves = await this._source.getCurves();

        const spacing = params.spacing;

        for (const curve of curves) {
            const length = curve.getLength();
            let param = 0;
            let t = 0;

            while (param < length) {
                t = param / length;
                this.createArrowHelper(t, curve, params.color, params.scale);
                param += spacing;
            }
        }

        this.object3d.updateMatrixWorld(true);

        this._instance.notifyChange();
    }

    removeArrowHelpers() {
        this._arrowHelpers.forEach((helper) => {
            helper.dispose();
            helper.removeFromParent();
        });
        this._arrowHelpers.length = 0;
        this._instance.notifyChange();
    }

    dispose() {
        this.removeArrowHelpers();
    }
}
