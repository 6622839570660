// React
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'reactstrap';

import * as graphicsSettings from 'redux/graphicsSettings';

// Components
import { DEFAULT_GIRO3D_SETTINGS } from '../../../services/Constants';

function ResolutionSetting() {
    const dispatch = useDispatch();

    const mapSegments = useSelector(graphicsSettings.getMapSegments);

    const [open, setOpen] = useState(false);

    return (
        <div className="settings-row">
            <span>Geometric Resolution</span>
            <Button
                className="borderless"
                title="Reset Geometric Resolution"
                onClick={() => dispatch(graphicsSettings.setMapSegments(DEFAULT_GIRO3D_SETTINGS.MAP_SEGMENTS))}
            >
                <i className="fal fa-arrow-rotate-left" />
            </Button>
            {/* Geometric resolution is stepped logarithmically so uses unique slider implementation */}
            <button
                type="button"
                className="dropdown-settings-slider-button"
                onClick={(e) => {
                    if (open) {
                        setOpen(false);
                        e.currentTarget.blur();
                    } else setOpen(true);
                }}
            >
                {mapSegments}
                <i className="fal fa-angle-down" />
                <div>
                    <div>
                        <input
                            id="resolution"
                            type="range"
                            min="1"
                            max="7"
                            step="1"
                            value={Math.log2(mapSegments)}
                            autoComplete="off"
                            className="settings-slider"
                            onChange={(e) => dispatch(graphicsSettings.setMapSegments(2 ** e.target.valueAsNumber))}
                        />
                    </div>
                </div>
            </button>
        </div>
    );
}

export default ResolutionSetting;
