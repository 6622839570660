// Giro3d
import Giro3dMap from '@giro3d/giro3d/entities/Map';
import Extent from '@giro3d/giro3d/core/geographic/Extent';
import { GlobalCache } from '@giro3d/giro3d/core/Cache';
import Instance from '@giro3d/giro3d/core/Instance';
import Giro3dVectorSource from '@giro3d/giro3d/sources/VectorSource';
import Giro3dLayer, { LayerEvents } from '@giro3d/giro3d/core/layer/Layer';
import MaskLayer from '@giro3d/giro3d/core/layer/MaskLayer';
import Inspector from '@giro3d/giro3d/gui/Inspector';
import EntityPanel from '@giro3d/giro3d/gui/EntityPanel';
import { Entity3D } from '@giro3d/giro3d/entities';

// proj4
import proj4 from 'proj4';

// THREE
import * as THREE from 'three';

// three-mesh-bvh
import { computeBoundsTree, disposeBoundsTree, acceleratedRaycast } from 'three-mesh-bvh';

import { LayerState } from 'types/LayerState';

// Giro3d Extensions
import Layer, { HostView } from 'giro3d_extensions/layers/Layer';
import RasterLayer from 'giro3d_extensions/layers/raster/RasterLayer';
import createLayerBuilder from 'giro3d_extensions/layerBuilders/LayerBuilderFactory';
import ControlsInspector from 'giro3d_extensions/ControlsInspector';
import SeismicPlaneEntityInspector from 'giro3d_extensions/layers/seismic/SeismicPlaneEntityInspector';

import * as giro3d from 'redux/giro3d';
import * as datasetsSlice from 'redux/datasets';
import * as graphicsSettings from 'redux/graphicsSettings';
import * as layersSlice from 'redux/layers';
import LayerManager from 'giro3d_extensions/LayerManager';
import Dataset from 'types/Dataset';
import { DatasetId, ScopeEntity, ScopeLayerUserData, SourceFileId, ZoomFactor } from 'types/common';
import { EventBus, useEventBus } from 'EventBus';
import StateObserver from 'giro3d_extensions/layers/StateObserver';
import store, { Dispatch, RootState } from 'store';
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import type { Geometry, MultiPolygon, Polygon } from 'geojson';
import IsClickable from 'giro3d_extensions/layers/IsClickable';
import { MapPickResult, PickResult } from '@giro3d/giro3d/core/picking';
import { ColorLayer } from '@giro3d/giro3d/core/layer';
import { ElevationRange } from '@giro3d/giro3d/core';
import { SourceFile } from 'types/SourceFile';
import { setClickedDataset } from 'redux/actions';
import AnnotationLayer from 'giro3d_extensions/layers/AnnotationLayer';
import IsHoverable, { isHoverable } from 'giro3d_extensions/layers/IsHoverable';
import { DEFAULT_GIRO3D_SETTINGS } from './Constants';
import Controls, { CONTROLS_MODE } from './Controls';
import { DrawingManagerImpl } from './DrawingManager';
import { AnnotationManagerImpl } from './AnnotationManager';

export type DatasetOrId = Dataset | DatasetId;
type EntityOrObject = Entity3D | THREE.Object3D;

export type PickedPoint = { picked: boolean; point: { x: number; y: number; z: number } };

const eventBus = useEventBus();

function getId(datasetOrId: DatasetOrId): DatasetId {
    let id: string;
    if (typeof datasetOrId === 'object') {
        id = datasetOrId.id; // assume id
    } else {
        id = datasetOrId;
    }
    return id;
}

export function isGiro3dMap(o: unknown): o is Giro3dMap {
    return (o as Giro3dMap)?.type === 'Map';
}

export function isGiro3dMaskLayer(o: unknown): o is MaskLayer {
    return (o as MaskLayer)?.isMaskLayer;
}

export function isGiro3dColorLayer(o: unknown): o is ColorLayer {
    return (o as ColorLayer)?.type === 'ColorLayer';
}

export function isGiro3dVectorSource(o: unknown): o is Giro3dVectorSource {
    return (o as Giro3dVectorSource)?.isVectorSource;
}

function isInElevationRange(range: ElevationRange, point: THREE.Vector3): boolean {
    if (!range) {
        return true;
    }

    return point.z <= range.max && point.z >= range.min;
}

function ensurePolygonFootprint(footprint: Geometry): Polygon | MultiPolygon {
    if (footprint.type === 'Polygon') return footprint as Polygon;
    if (footprint.type === 'MultiPolygon') return footprint as MultiPolygon;
    throw new Error(`Footprint not useable. Type: ${footprint.type}`);
}

// Add the extension functions
THREE.BufferGeometry.prototype.computeBoundsTree = computeBoundsTree;
THREE.BufferGeometry.prototype.disposeBoundsTree = disposeBoundsTree;
THREE.Mesh.prototype.raycast = acceleratedRaycast;

export function initproj4(additionalDefs = []) {
    Instance.registerCRS('EPSG:4326', '+proj=longlat +datum=WGS84 +no_defs');
    Instance.registerCRS(
        'EPSG:3857',
        '+proj=merc +a=6378137 +b=6378137 +lat_ts=0.0 +lon_0=0.0 +x_0=0.0 +y_0=0 +k=1.0 +units=m +nadgrids=@null +wktext  +no_defs'
    );
    for (const def of additionalDefs) {
        Instance.registerCRS(def[0], def[1]);
    }
}

abstract class BaseGiro3dService {
    protected _dispatch: Dispatch;
    protected _instance: Instance;
    protected _layerManager: LayerManager;
    protected _drawingManager: DrawingManagerImpl;
    protected _annotationManager: AnnotationManagerImpl;
    protected _layers: Map<string, Layer[]>;
    protected readonly _loadedLayers: Set<string>;
    protected _inspector: Inspector;
    protected _inspectorVisible: boolean;
    protected _segments: number;
    protected _controls: Controls;
    protected _stateObserver: StateObserver<RootState>;
    protected _tmpGetClosestPointOnBox: {
        planes: THREE.Plane[];
        normals: THREE.Vector3[];
        vec3: THREE.Vector3;
        ray: THREE.Ray;
        intersection: {
            point: THREE.Vector3;
            distance: number;
            planeIdx: number;
            plane: THREE.Plane;
        };
    };
    protected _controlsInspector: ControlsInspector;
    protected _inspectorTitle: string;
    protected _raycaster: THREE.Raycaster;
    protected _interactionTimer: NodeJS.Timeout;
    protected _interactionTransparency: boolean;
    protected _isUserInteracting: boolean;
    protected _enableEDL: boolean;
    protected _enableInpainting: boolean;
    protected _enableHillshading: boolean;
    protected _hillshadeIntensity: number;
    protected _lightDirection: { azimuth: number; zenith: number };
    protected _tmpCoords: THREE.Vector2;
    protected _tmpVec3: THREE.Vector3;
    protected _inspectorDomElem: HTMLDivElement;
    protected _currentCameraPosition: THREE.Vector3;
    protected readonly _previousOpacities: Map<DatasetId, number>;
    protected readonly _hostView: HostView;
    protected _previouslyHovered: Layer & IsHoverable = null;
    protected _currentlyHovered: Layer & IsHoverable = null;
    protected readonly _canHover: boolean;
    private _oldEventToCanvasCoords: (event: Event, target: THREE.Vector2, touchIdx?: number) => THREE.Vector2;
    private _onContextLost: () => void;
    private _onContextRestored: () => void;
    private _onSourceChanged: (e: { source?: unknown }) => void;

    constructor(params: { hostView: HostView; canHover: boolean }) {
        this._dispatch = undefined;
        this._instance = undefined;
        this._hostView = params.hostView;
        this._canHover = params.canHover;

        this._layerManager = undefined;
        this._layers = new Map();
        this._loadedLayers = new Set();

        this._controls = undefined;

        this._inspector = undefined;
        this._controlsInspector = undefined;
        this._inspectorVisible = DEFAULT_GIRO3D_SETTINGS.INSPECTOR;
        this._inspectorTitle = 'Primary View Inspector';

        this._raycaster = undefined;

        this._interactionTimer = null;
        this._interactionTransparency = DEFAULT_GIRO3D_SETTINGS.INTERACTION_TRANSPARENCY;
        this._isUserInteracting = false;

        this._enableEDL = DEFAULT_GIRO3D_SETTINGS.POINTCLOUD_EDL;
        this._enableInpainting = DEFAULT_GIRO3D_SETTINGS.POINTCLOUD_INPAINTING;

        this._enableHillshading = DEFAULT_GIRO3D_SETTINGS.HILLSHADE;
        this._hillshadeIntensity = DEFAULT_GIRO3D_SETTINGS.HILLSHADE_INTENSITY;
        this._lightDirection = {
            azimuth: DEFAULT_GIRO3D_SETTINGS.HILLSHADE_AZIMUTH,
            zenith: DEFAULT_GIRO3D_SETTINGS.HILLSHADE_ZENITH,
        };

        this._segments = DEFAULT_GIRO3D_SETTINGS.MAP_SEGMENTS;

        this._tmpGetClosestPointOnBox = {
            planes: [
                new THREE.Plane(),
                new THREE.Plane(),
                new THREE.Plane(),
                new THREE.Plane(),
                new THREE.Plane(),
                new THREE.Plane(),
            ],
            normals: [
                new THREE.Vector3(1, 0, 0), // xmin
                new THREE.Vector3(0, 1, 0), // ymin
                new THREE.Vector3(0, 0, 1), // zmin
                new THREE.Vector3(1, 0, 0), // xmax
                new THREE.Vector3(0, 1, 0), // ymax
                new THREE.Vector3(0, 0, 1), // zmax
            ],
            vec3: new THREE.Vector3(),
            ray: new THREE.Ray(),
            intersection: undefined,
        };

        this._tmpCoords = new THREE.Vector2();
        this._tmpVec3 = new THREE.Vector3();
    }

    get view() {
        return this._hostView;
    }

    /**
     * Initializes global rendering options for the whole instance.
     */
    initRenderingOptions() {
        this.setPointCloudEDL(this._enableEDL);
        this.setPointCloudInpainting(this._enableInpainting);
    }

    init(
        domElem: HTMLDivElement,
        inspectorDomElem: HTMLDivElement,
        extent: Extent,
        dispatch: Dispatch,
        instanceOptions = {}
    ) {
        if (proj4.defs(extent.crs()) === undefined) console.warn('Unknown projection being used', extent.crs());

        this._dispatch = dispatch;
        this._stateObserver = new StateObserver(store.getState, store.subscribe);

        this.onStateObserverCreated(this._stateObserver);

        this.subscribeToEventBus(eventBus);

        this._instance = new Instance(domElem, {
            crs: extent.crs(),
            renderer: { clearColor: false, checkShaderErrors: false },
            ...instanceOptions,
        });

        this._onContextLost = this.onRenderingContextLost.bind(this);
        this._onContextRestored = this.onRenderingContextRestored.bind(this);

        this._instance.domElement.addEventListener('webglcontextlost', this._onContextLost);
        this._instance.domElement.addEventListener('webglcontextrestored', this._onContextRestored);

        this.initRenderingOptions();

        this._layerManager = this.initLayerManager();

        this._oldEventToCanvasCoords = this._instance.eventToCanvasCoords.bind(this._instance);
        Object.defineProperty(this._instance, 'labelRenderer', {
            value: this._instance.mainLoop.gfxEngine.labelRenderer,
            writable: false,
        });
        this._instance.eventToCanvasCoords = (event: MouseEvent | TouchEvent, target, touchIdx = 0) => {
            const touchEvent = event as TouchEvent;
            const mouseEvent = event as MouseEvent;
            if (touchEvent.touches === undefined || !touchEvent.touches.length) {
                if (event.target === this._instance.domElement)
                    return this._oldEventToCanvasCoords(event, target, touchIdx);

                const canvasRect = this._instance.domElement.getBoundingClientRect();
                return target.set(mouseEvent.clientX - canvasRect.left, mouseEvent.clientY - canvasRect.top);
            }
            return this._oldEventToCanvasCoords(event, target, touchIdx);
        };

        const light = new THREE.AmbientLight(0xffffff, 2);
        this._instance.scene.add(light);

        const dirLight = new THREE.DirectionalLight(0xffffff, 2);
        dirLight.position.set(0, 1, 1);
        dirLight.updateMatrixWorld();
        this._instance.scene.add(dirLight);

        this._controls = this.initControls();

        this._inspectorDomElem = inspectorDomElem;
        this.setInspectorVisibility(this._inspectorVisible);

        this._raycaster = new THREE.Raycaster();

        this._instance.viewport.addEventListener('click', this.clickHandler.bind(this));
        this._instance.viewport.addEventListener('dblclick', this.dblClickHandler.bind(this));
        this._instance.viewport.addEventListener('mousemove', this.mouseMoveHandler.bind(this));
        this._instance.viewport.addEventListener('mouseleave', this.mouseLeaveHandler.bind(this));
        this._instance.renderer.localClippingEnabled = true;

        // We use 'control' instead of 'controlstart' to detect beginning of interaction
        // because 'controlstart' is triggered on a click for dragging even before the user actually
        // starts dragging (therefore would swallow click for drawing)
        this._controls.cameraControls.addEventListener('control', this.onInteraction.bind(this));
        this._controls.cameraControls.addEventListener('controlend', this.onInteractionEnd.bind(this));
    }

    protected onRenderingContextLost() {
        eventBus.dispatch('rendering-context-lost', { service: this });
    }

    protected onRenderingContextRestored() {
        eventBus.dispatch('rendering-context-restored', { service: this });
    }

    protected subscribeToEventBus(bus: EventBus) {
        this._onSourceChanged = this.onSourceChanged.bind(this);

        bus.subscribe('notify-change', this._onSourceChanged);
    }

    protected unsubscribeFromEventBus(bus: EventBus) {
        bus.unsubscribe('notify-change', this._onSourceChanged);
    }

    private onSourceChanged(e: { source?: unknown }) {
        this._instance.notifyChange(e.source);
    }

    protected abstract initLayerManager(): LayerManager;

    protected abstract initControls(): Controls;

    protected onStateObserverCreated(observer: StateObserver<RootState>): void {
        // Hillshading
        observer.subscribe(graphicsSettings.isHillshadingEnabled, (v) => this.setHillshade(v));
        observer.subscribe(graphicsSettings.getHillshadingIntensity, (v) => this.setHillshadeIntensity(v));
        observer.subscribe(graphicsSettings.getHillshadingAzimuth, (v) => this.setAzimuth(v));
        observer.subscribe(graphicsSettings.getHillshadingZenith, (v) => this.setZenith(v));

        // Point cloud effects
        observer.subscribe(graphicsSettings.isEyeDomeLightingEnabled, (v) => this.setPointCloudEDL(v));
        observer.subscribe(graphicsSettings.isInpaintingEnabled, (v) => this.setPointCloudInpainting(v));

        observer.subscribe(graphicsSettings.isTransparencyOnInteractionEnabled, (v) =>
            this.setInteractionTransparency(v)
        );

        observer.subscribe(graphicsSettings.getMapSegments, (v) => this.setMapResolution(v));

        observer.subscribe(giro3d.showInspector, (v) => this.setInspectorVisibility(v));

        observer.subscribe(datasetsSlice.getProjectDatasets, (v: Dataset[]) => {
            const ordered = v.map((ds) => ds.id);
            this.reorderDatasets(ordered).catch(console.error);
        });
    }

    protected onInteraction() {
        this._isUserInteracting = true;
        this.resetPreviouslyHovered();
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-function, class-methods-use-this
    protected onInteractionEnd() {}

    protected doOnInteractionEnd() {
        this._isUserInteracting = false;
        this._interactionTimer = null;

        if (this._interactionTransparency)
            this.eachInteractableLayer((l) => {
                const previous = this._previousOpacities.get(l.datasetId);
                if (previous) {
                    l.setOpacity(previous);
                    this._previousOpacities.delete(l.datasetId);
                }
            });
    }

    /**
     * Returns all datasets IDs that contain with the picked point for the specified map.
     */
    private getIntersectingDatasetIdFileIdForMap(pickResult: MapPickResult<unknown>): {
        dataset: DatasetId;
        sourceFile: SourceFileId;
    } {
        const { entity, point, coord, object } = pickResult;

        if (!entity.visible) {
            return undefined;
        }

        const footprints = this._layerManager.pickFootprints(coord);
        if (footprints && footprints.length > 0) {
            return footprints[0];
        }

        const validVectors = this._layerManager
            .getVectorAtCoordinate(entity, coord, 10, object)
            .filter((f) => f.layer.visible)
            .map((f) => f.layer.userData.datasetId);

        function predicate(layer: Giro3dLayer<LayerEvents, ScopeLayerUserData>): boolean {
            if (!layer.visible) {
                return false;
            }

            // Masks are purely helper layers and don't contain business value
            if (isGiro3dMaskLayer(layer)) {
                return false;
            }

            if (isGiro3dColorLayer(layer)) {
                // Exclude layers that do not contain the point in their elevation range
                if (!isInElevationRange(layer.elevationRange, point)) {
                    return false;
                }
            }

            if (isGiro3dVectorSource(layer.source)) {
                if (!validVectors.includes(layer.userData.datasetId)) {
                    return false;
                }
            }

            return true;
        }

        const layers = entity.getLayers(predicate) as Giro3dLayer<LayerEvents, ScopeLayerUserData>[];

        const results = layers.map((layer) => {
            const datasetId: DatasetId = layer.userData.datasetId;
            const datasetLayers = this.getLayersForDataset(datasetId);

            const datasetResults = datasetLayers.map((datasetLayer) => {
                const footprint = datasetLayer.getFootprint();
                return booleanPointInPolygon([point.x, point.y], ensurePolygonFootprint(footprint))
                    ? { dataset: datasetId, sourceFile: layer.name }
                    : null;
            });

            return datasetResults.find((v) => v !== null);
        });

        return results[0];
    }

    protected getIntersectingDatasetIdFileId(pickResult: PickResult): { dataset: DatasetId; sourceFile: SourceFileId } {
        if (!pickResult) {
            return undefined;
        }

        if (!pickResult.object && !pickResult.entity) {
            return undefined;
        }

        if (pickResult.object?.userData) {
            const { datasetId, sourceFileId } = pickResult.object.userData;
            if (datasetId && sourceFileId) {
                return {
                    dataset: datasetId,
                    sourceFile: sourceFileId,
                };
            }
        }

        if (!pickResult.entity) {
            return {
                dataset: pickResult.object?.parent.userData.datasetId,
                sourceFile: pickResult.object?.parent.userData.sourceFileId,
            };
        }

        if (isGiro3dMap(pickResult.entity) && pickResult.entity.layerCount > 0) {
            const pick = pickResult as MapPickResult;
            return this.getIntersectingDatasetIdFileIdForMap(pick);
        }

        if (pickResult.entity) {
            const entity = pickResult.entity as ScopeEntity;
            if (entity.userData.datasetId) {
                return {
                    dataset: entity.userData.datasetId,
                    sourceFile: entity.userData.sourceFileId,
                };
            }
        }

        return {
            dataset: pickResult.object?.parent.userData.datasetId,
            sourceFile: pickResult.object?.parent.userData.sourceFileId,
        };
    }

    protected getIntersectingDataset(point: PickResult): Layer | null {
        if (!point) {
            return null;
        }
        const result = this.getIntersectingDatasetIdFileId(point);
        if (result) {
            const { dataset, sourceFile } = result;
            const layers = this.getLayersForDataset(dataset);

            return layers.find((l) => l.sourceFileId === sourceFile);
        }

        return null;
    }

    protected async clickHandler(event: MouseEvent) {
        if (this._isUserInteracting) return null;

        const point = this.getPointAt(event);

        if (point?.picked) {
            const result = this.getIntersectingDatasetIdFileId(point.pickResult);
            if (result) {
                const { dataset, sourceFile } = result;
                this._dispatch(setClickedDataset(dataset, sourceFile));
            }
        }
        return point;
    }

    getInstance() {
        return this._instance;
    }

    protected dblClickHandler(event: MouseEvent) {
        if (this._controls.mode === CONTROLS_MODE.FOLLOW) return;
        const point = this.getPointAt(event);
        if (point) {
            this._controls.moveTo(point.point, true);
        }
    }

    protected isCameraMoving() {
        const cam = this._instance.camera.camera3D;
        if (!this._currentCameraPosition) {
            this._currentCameraPosition = new THREE.Vector3();
            this._currentCameraPosition.copy(cam.position);
            return false;
        }

        const dist = this._currentCameraPosition.distanceToSquared(cam.position);
        this._currentCameraPosition.copy(cam.position);

        return dist > 0;
    }

    protected async mouseMoveHandler(event: MouseEvent) {
        if (this.isCameraMoving()) {
            return;
        }

        const point = this.getPointAt(event);

        this.updateCoordinates(point);

        if (this._canHover) {
            if (!this._isUserInteracting) {
                this.processHover(event, point?.pickResult);
            } else {
                this.resetPreviouslyHovered();
            }
        }
    }

    protected resetPreviouslyHovered() {
        if (this._previouslyHovered) {
            this.hoverLayer(null, this._previouslyHovered, false);
        }
        this._previouslyHovered = this._currentlyHovered;
    }

    private processHover(mouseEvent: MouseEvent, pick?: PickResult) {
        const object = pick?.object;

        if (!object || !object.userData) {
            this.resetPreviouslyHovered();
            return;
        }

        let hoverable: Layer & IsHoverable = null;

        const { annotationId } = object.userData;

        if (annotationId) {
            const annotation = this._annotationManager.getAnnotation(annotationId);
            hoverable = annotation.layer;
        } else {
            const layer = this.getIntersectingDataset(pick);

            if (isHoverable(layer)) {
                hoverable = layer;
            }
        }

        if (hoverable) {
            if (this._previouslyHovered === hoverable) {
                this._previouslyHovered = null;
            }
            this._currentlyHovered = hoverable;
            this.hoverLayer(mouseEvent, hoverable, true);
        } else {
            this._currentlyHovered = null;
        }

        this.resetPreviouslyHovered();
    }

    // eslint-disable-next-line class-methods-use-this
    protected hoverLayer(mouseEvent: MouseEvent, layer: Layer & IsHoverable, hover: boolean) {
        layer.hover(hover);
    }

    private mouseLeaveHandler() {
        this.updateCoordinates(null);
    }

    protected eachInteractableLayer(action: (layer: Layer) => void) {
        for (const layerCollection of this._layers.values()) {
            layerCollection.forEach((l) => action(l));
        }
    }

    protected getLayerState<T = LayerState>(id: DatasetId): T {
        return this._stateObserver.select((s) => s.layers.layersById[id]) as T;
    }

    async loadDataset(dataset: Dataset, sourceFiles: SourceFile[]): Promise<Layer[]> {
        if (!this._instance) return Promise.reject(new Error('Giro3d not initialized yet'));

        // TODO ?
        // if (dataset.state !== LAYER_STATES.ACTIVE) {
        //     return Promise.reject(new Error(`State should be ${LAYER_STATES.ACTIVE}, is ${dataset.state}`));
        // }

        this._loadedLayers.add(dataset.id);

        const builder = createLayerBuilder(dataset, sourceFiles, {
            dispatch: this._dispatch,
            instance: this._instance,
            layerManager: this._layerManager,
            hostView: this._hostView,
        });

        try {
            const layers = await builder.build();

            const initPromises: Promise<void>[] = [];

            const list: Layer[] = [];

            this._layers.set(dataset.id, list);

            for (const layer of layers) {
                list.push(layer);

                if (layer instanceof RasterLayer) {
                    layer.setGetLayersFunction(this.getLayersForDataset.bind(this));
                }

                if (layer.shouldInitialize()) {
                    initPromises.push(layer.init());
                }

                layer.setZScale(this.getZScale());
            }

            await Promise.all(initPromises);

            eventBus.dispatch('dataset-loaded', { id: dataset.id, view: this._hostView });

            return layers;
        } catch (error) {
            console.error(error);
            return Promise.reject(new Error(`Something went wrong during loading of dataset ${dataset.name}`));
        }
    }

    async reorderDatasets(datasetIds: DatasetId[]) {
        if (this._layerManager) {
            await this._layerManager.reorderLayers(datasetIds);
        }
    }

    deinit() {
        if (!this._instance) return; // nothing to do

        this._instance.viewport.removeEventListener('click', this.clickHandler);
        this._instance.viewport.removeEventListener('dblclick', this.dblClickHandler);
        this._instance.viewport.removeEventListener('mousemove', this.mouseMoveHandler);
        this._instance.viewport.removeEventListener('mouseleave', this.mouseLeaveHandler);

        // Clear all events & dispose of objects we created (reverse order of creation)
        this._controls.cameraControls.removeEventListener('control', this.onInteraction);
        this._controls.cameraControls.removeEventListener('controlend', this.onInteractionEnd);

        this._instance.domElement.removeEventListener('webglcontextlost', this._onContextLost);
        this._instance.domElement.removeEventListener('webglcontextrestored', this._onContextRestored);

        this._controlsInspector?.dispose();
        this._controls.dispose();

        this._inspector?.detach();
        this._instance.dispose();
        this._stateObserver?.dispose();

        this.unsubscribeFromEventBus(eventBus);

        GlobalCache.clear();

        // reset initial values and let the garbage collector to its holy job
        this._instance = null;
        this._layerManager = null;
        this._controls = null;
        this._layers.clear();
        this._raycaster = null;
        this._interactionTimer = null;
        this._inspector = null;
        this._controlsInspector = null;
        this._stateObserver = null;
    }

    firstClickableLayerAtPosition(pos: MouseEvent): Layer & IsClickable {
        const mouse = this._instance.eventToCanvasCoords(pos, this._tmpCoords).clone();
        const pointer = this._instance.canvasToNormalizedCoords(mouse, this._tmpCoords).clone();
        this._raycaster.setFromCamera(pointer, this._instance.camera.camera3D);
        const picked = this._raycaster.intersectObjects(this.clickableObjects());
        for (let i = 0; i < picked.length; i++) {
            const obj = picked[i].object;
            const parentId = obj.userData.datasetId;
            if (parentId) {
                const layer = this.getClickableLayer(parentId, obj.userData.sourceFileId);
                const layerState = this._stateObserver.select(layersSlice.get(layer.datasetId));

                if (layer instanceof AnnotationLayer) {
                    return layer;
                }

                if (layer && layerState && layerState.visible) {
                    return layer;
                }
            }
        }

        return null;
    }

    // eslint-disable-next-line class-methods-use-this, @typescript-eslint/no-unused-vars
    protected getClickableLayer(id: string, fileId?: string): Layer & IsClickable {
        return null;
    }

    // eslint-disable-next-line class-methods-use-this
    clickableObjects(): THREE.Object3D[] {
        throw new Error('Method not implemented.');
    }

    getClosestPointOnBox(mouse, bbox) {
        // Create our plans based on the bounding box
        // (basically extend each side so it covers the whole world)
        this._tmpGetClosestPointOnBox.normals[2].setZ(Math.sign(bbox.min.z));
        this._tmpGetClosestPointOnBox.normals[5].setZ(Math.sign(bbox.max.z));

        for (let i = 0; i < 6; i += 1)
            this._tmpGetClosestPointOnBox.planes[i].set(this._tmpGetClosestPointOnBox.normals[i], 0);
        for (let i = 0; i < 3; i += 1) {
            this._tmpGetClosestPointOnBox.planes[i].translate(bbox.min);
            this._tmpGetClosestPointOnBox.planes[i + 3].translate(bbox.max);
        }

        // Now found out where we'd sit if we project our cursor
        // onto each plane, and take the closest point to the bbox
        const ndc = this._instance.canvasToNormalizedCoords(mouse, this._tmpCoords);
        this._tmpGetClosestPointOnBox.vec3
            .set(ndc.x, ndc.y, 0.5)
            .unproject(this._instance.camera.camera3D)
            .sub(this._instance.camera.camera3D.position)
            .normalize();
        this._tmpGetClosestPointOnBox.ray.set(
            this._instance.camera.camera3D.position,
            this._tmpGetClosestPointOnBox.vec3
        );

        this._tmpGetClosestPointOnBox.intersection = undefined;
        for (let i = 0; i < 6; i += 1) {
            const projected = this._tmpGetClosestPointOnBox.ray.intersectPlane(
                this._tmpGetClosestPointOnBox.planes[i],
                this._tmpGetClosestPointOnBox.vec3
            );
            if (projected) {
                // Projection intersects the plane, compute the
                // distance between the bbox and the point
                // Note: not having an intersection is not a sign of misbehavior:
                // this happens for instance if we're viewing from top or a side
                const dist = bbox.distanceToPoint(projected);
                if (
                    !this._tmpGetClosestPointOnBox.intersection ||
                    this._tmpGetClosestPointOnBox.intersection.distance > dist
                )
                    this._tmpGetClosestPointOnBox.intersection = {
                        point: projected.clone(),
                        distance: dist,
                        planeIdx: i,
                        plane: this._tmpGetClosestPointOnBox.planes[i],
                    };
            }
        }

        if (this._tmpGetClosestPointOnBox.intersection)
            this._tmpGetClosestPointOnBox.intersection.point.divide(this._instance.threeObjects.scale);

        // Return the closest point
        return this._tmpGetClosestPointOnBox.intersection;
    }

    getPointAt(
        mouseOrEvt: MouseEvent | THREE.Vector2,
        options: {
            radius?: number;
            objects?: EntityOrObject[];
        } = {}
    ): {
        pickResult?: PickResult<unknown>;
        picked: boolean;
        point: THREE.Vector3;
    } {
        const touchIdx = 0;
        const mouse =
            mouseOrEvt instanceof Event
                ? this._instance.eventToCanvasCoords(mouseOrEvt, this._tmpCoords, touchIdx).clone()
                : mouseOrEvt;

        const { objects, radius } = options;

        let where: EntityOrObject[];

        if (!objects) {
            where = this._instance
                .getObjects()
                .concat(this._instance.threeObjects.children)
                .filter(
                    (l) => (l as EntityOrObject).visible && l !== this._drawingManager?.drawObject
                ) as EntityOrObject[];
        } else {
            where = objects;
        }

        const picked = this._instance.pickObjectsAt(mouse, {
            radius: radius || 0,
            where,
            filter: (p) =>
                !Number.isNaN(p.point.x) && !Number.isNaN(p.point.y) && !Number.isNaN(p.point.z) && p.object.visible, // Discard any incoherent value and non-visible data
        });

        if (picked.length > 0) {
            picked.sort((p0, p1) => (p0.distance > p1.distance ? 1 : -1));

            // We found an object on click, return its position
            const point = picked[0].point.clone();

            return { pickResult: picked[0], point, picked: true };
        }

        const bbox = this.getBoundingBox();
        if (bbox) {
            const closest = this.getClosestPointOnBox(mouse, bbox);
            if (closest) {
                return { point: closest.point, picked: false };
            }
        }

        // Even getClosestPointOnBox failed, we're lost
        // Don't try other fallback methods as it will be even worse
        console.trace('Could not find point, we might be lost');
        return undefined;
    }

    protected getZScale() {
        return this._instance ? this._instance.threeObjects.scale.z : 1;
    }

    protected setZScale(scale: number) {
        if (!this._instance) {
            return;
        }

        if (!this._controls.cameraControls.enabled) {
            return;
        }

        const oldScale = this._instance.threeObjects.scale.z;

        if (oldScale === scale) {
            return;
        }

        // TODO if this happens right after we rstore the camera position, the camera's target is ignored
        this._controls.resetPivot();
        const target = new THREE.Vector3();
        this._controls.getTarget(target);
        this._controls.setInteractionPoint(target);
        this.onInteraction();

        // Scale pipelines, cables, seg-y, document and vessel so they show up at the correct elevation
        this._instance.threeObjects.scale.set(1, 1, scale);
        this._instance.threeObjects.updateMatrix();
        this._instance.threeObjects.updateMatrixWorld(true);
        this._instance.notifyChange(this._instance.threeObjects);

        // Updates the scale of the maps managed by the LayerManager.
        this._layerManager.setZScale(scale);

        this._drawingManager.setZScale(scale);

        this.eachInteractableLayer((l) => {
            try {
                l.setZScale(scale);
            } catch (error) {
                if (error.name !== 'NotImplementedError') throw error;
            }
        });

        // scale controls so they stay in place
        // Found out how much target got displaced by new scale
        const newTargetZ = (target.z * scale) / oldScale;
        const diff = target.z - newTargetZ;
        // Move the camera to make it look like it didn't move
        this._instance.camera.camera3D.position.z -= diff;
        // Apply offset back
        target.z = newTargetZ;
        this._controls.setPivot(target);

        const bbox = this.getBoundingBox();
        if (bbox) {
            this._controlsInspector?.setBoundingBox(bbox);
        }
        this.onInteractionEnd();
    }

    private setInteractionTransparency(value: boolean) {
        this._interactionTransparency = value;
    }

    private setInspectorVisibility(value: boolean) {
        if (!this._inspector && value) {
            EntityPanel.registerInspector('SeismicPlaneEntity', SeismicPlaneEntityInspector);
            this._inspector = Inspector.attach(this._inspectorDomElem, this._instance, { title: this._inspectorTitle });
            this._controlsInspector = new ControlsInspector(this._inspector.gui, this._instance, this._controls);
            this._inspector.addPanel(this._controlsInspector);
        }
        this._inspectorVisible = value;
        if (this._inspector) {
            this._inspector.gui.domElement.parentElement.style.visibility = value ? 'visible' : 'hidden';
        }
    }

    private setHillshade(hillshade: boolean) {
        if (!this._instance) return;

        this._enableHillshading = hillshade;
        if (this._layerManager) this._layerManager.hillshading = hillshade;
    }

    private setHillshadeIntensity(value: number) {
        if (!this._instance) return;

        this._hillshadeIntensity = value;
        if (this._layerManager) this._layerManager.hillshadingIntensity = value;
    }

    private setPointCloudEDL(edl: boolean) {
        if (!this._instance) {
            return;
        }
        this._enableEDL = edl;
        this._instance.renderingOptions.enableEDL = edl;
        this._instance.notifyChange();
    }

    private setPointCloudInpainting(inpainting: boolean) {
        if (!this._instance) {
            return;
        }
        this._enableInpainting = inpainting;
        // Both parameters should be toggled together as they are meant to work together.
        this._instance.renderingOptions.enableInpainting = inpainting;
        this._instance.renderingOptions.enablePointCloudOcclusion = inpainting;
        this._instance.notifyChange();
    }

    private setAzimuth(azimuth: number) {
        if (!this._instance) return;

        this._lightDirection.azimuth = azimuth;
        if (this._layerManager) this._layerManager.azimuth = azimuth;
    }

    private setZenith(zenith: number) {
        if (!this._instance) return;

        this._lightDirection.zenith = zenith;
        if (this._layerManager) this._layerManager.zenith = zenith;
    }

    private *iterateLayers(): Iterable<Layer> {
        for (const entry of this._layers.values()) {
            for (const layer of entry) {
                yield layer;
            }
        }
    }

    getAllLayers(): Layer[] {
        return [...this.iterateLayers()];
    }

    forEachLayer(action: (layer: Layer) => void): void {
        for (const layer of this.iterateLayers()) {
            action(layer);
        }
    }

    /**
     * Returns the bounding box of the specified layers, if possible, otherwise return null.
     * @param layers The layers to compute. If undefined, all layers are used instead.
     * @param ignoreVisibility Also return the bbox of invisible layers.
     * @returns The bounding box of visible and initialized layers, otherwise null.
     */
    protected getBoundingBox(layers?: Layer[], ignoreVisibility = false): THREE.Box3 | null {
        const result = new THREE.Box3();
        let isEmpty = true;

        const allLayers = layers ?? this.getAllLayers();

        for (const layer of allLayers) {
            if (ignoreVisibility || layer.getVisibility()) {
                const layerBbox = layer.getBoundingBox(this._instance.threeObjects.scale.z);

                if (layerBbox && !Number.isNaN(layerBbox.min.x)) {
                    isEmpty = false;
                    result.union(layerBbox);
                }
            }
        }

        return isEmpty ? null : result;
    }

    private goToLayers(datasets: DatasetId[], enableTransition = true) {
        if (!this._instance) return;

        const layers = datasets.flatMap((id) => this.getLayersForDataset(id));

        const bbox = this.getBoundingBox(layers, true);

        if (bbox) {
            this._controls.lookAtBbox(bbox, enableTransition);
        }
    }

    protected goToLayersTopdown() {
        if (!this._instance) return;

        const bbox = this._stateObserver.select(datasetsSlice.getProjectVolume);

        if (bbox) {
            const extent = Extent.fromBox3(this._instance.referenceCrs, bbox);
            this._controls.lookTopDownAt(extent, Number.isFinite(bbox.max.z) ? bbox.max.z : 0, false);
        }
    }

    protected goToBBox(bbox: THREE.Box3, enableTransition = true) {
        if (!this._instance) return;

        this._controls.lookAtBbox(bbox, enableTransition);
    }

    getLayers() {
        return this._layers;
    }

    getLayerManager() {
        return this._layerManager;
    }

    /**
     * Zooms in/out to center of the screen
     * @param 1 for zooming in, -1 for zooming out
     */
    protected zoom(factor: ZoomFactor) {
        if (!this._instance) return;

        this._controls.zoom(factor);
    }

    protected setControlsMode(mode: CONTROLS_MODE) {
        if (!this._instance) return;

        this._controls.setMode(mode);
    }

    protected setControlsTarget(target: THREE.Vector3) {
        if (!this._instance) return;

        target.multiply(this._instance.threeObjects.scale);
        this._controls._do(() => this._controls.cameraControls.setTarget(target.x, target.y, target.z, true));
        this._controls.setInteractionPoint(target);
    }

    saveControlsState() {
        this._controls.save();
    }

    restoreControlsState() {
        this._controls.restore();
    }

    hasLayer(id: DatasetId) {
        if (!this._loadedLayers) {
            return false;
        }
        return this._loadedLayers.has(id);
    }

    getLayer<T extends Layer = Layer>(datasetOrId: DatasetOrId, sourceFileId: SourceFileId): T {
        const layers = this.getLayersForDataset(datasetOrId);

        for (const layer of layers) {
            if (layer.sourceFileId === sourceFileId) {
                return layer as T;
            }
        }

        return null;
    }

    /**
     * Gets all layers associated with the specified dataset.
     *
     * @param datasetOrId - dataset as taken from the api (or actually any object with an id field), or directly the id
     * @param layers - layers to search, otherwise uses this.layers
     * @return A list of layers associated with this dataset, or an empty list if none could be found.
     */
    getLayersForDataset<T extends Layer = Layer>(datasetOrId: DatasetOrId, layers?: Map<string, Layer[]>): T[] {
        const id = getId(datasetOrId);

        const input = layers ?? this._layers;

        if (input?.has(id)) {
            return input.get(id) as T[];
        }

        return [];
    }

    goToLayer(id: DatasetId, enableTransition = true) {
        if (!this._instance) return;
        if (this._controls.mode === CONTROLS_MODE.FOLLOW) return;

        this.goToLayers([id], enableTransition);
    }

    goToFile(datasetId: DatasetId, fileId: SourceFileId, enableTransition = true) {
        if (!this._instance) return;

        const layer = this.getLayersForDataset(datasetId).find((l) => l.sourceFileId === fileId);

        const bbox = this.getBoundingBox([layer], true);

        if (bbox) {
            this._controls.lookAtBbox(bbox, enableTransition);
            layer.flashHighlight();
        }
    }

    removeLayer(id: DatasetId) {
        if (!this._instance) return; // nothing to do

        const allLayers = this._layers.get(id);

        if (allLayers) {
            for (const layer of allLayers) {
                layer.dispose();
            }

            this._layers.delete(id);
            this._loadedLayers.delete(id);
        }
    }

    private setMapResolution(value: number) {
        if (!this._instance) return;

        this._segments = value;
        if (this._layerManager) this._layerManager.segments = value;
    }

    // eslint-disable-next-line class-methods-use-this
    updateCoordinates(coord: PickedPoint = null) {
        const coordinates = {
            x: undefined,
            y: undefined,
            z: undefined,
            picked: false,
        };

        if (
            coord &&
            Number.isFinite(coord.point.x) &&
            Number.isFinite(coord.point.y) &&
            Number.isFinite(coord.point.z)
        ) {
            coordinates.x = coord.point.x;
            coordinates.y = coord.point.y;
            coordinates.z = coord.point.z / this.getZScale();
            coordinates.picked = coord.picked;
        }

        eventBus.dispatch('mouse-coordinates', { coordinates });
        return coordinates;
    }

    notifyLayerChange() {
        this._dispatch(giro3d.layerSettingsChanged());
    }

    notifyChange(changeSource?: unknown) {
        if (this._instance) this._instance.notifyChange(changeSource);
    }

    addThreeObject(object: THREE.Object3D) {
        if (this._instance) this._instance.threeObjects.add(object);
    }
}

export default BaseGiro3dService;
