// React
import React from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';

// Formik
import { useField } from 'formik';

// Boostrap
import { Label, FormFeedback, FormText } from 'reactstrap';

const TypeaheadField = ({
    label = undefined,
    helperLabel = undefined,
    className = undefined,
    id = undefined,
    options,
    setFieldValue,
    ...props
}) => {
    const [field, meta] = useField(props);
    if (!className) {
        className = 'form-control';
    }
    if (!id) {
        id = props.name;
    }

    if (meta.error && meta.touched) {
        className += ' is-invalid';
    }

    const ref = React.createRef();

    return (
        <div className="form-group">
            {label ? <Label for={id}>{label}</Label> : null}
            <Typeahead
                {...field}
                {...props}
                ref={ref}
                id={id}
                className={className}
                options={options}
                onChange={(selected) => {
                    setFieldValue(id, selected);
                    if (props.onChange) props.onChange(selected);
                }}
                onBlur={undefined}
            />
            {meta.touched && meta.error ? <FormFeedback>{meta.error}</FormFeedback> : null}
            {helperLabel ? <FormText>{helperLabel}</FormText> : null}
        </div>
    );
};

export default TypeaheadField;
