import { Disposable, Instance } from '@giro3d/giro3d/core';
import { isPerspectiveCamera } from '@giro3d/giro3d/renderer/Camera';
import { DRAWTOOL_STATE } from 'giro3d_extensions/DrawTool';
import GeometryObject, { GEOMETRY_TYPE } from 'giro3d_extensions/GeometryObject';
import AnnotationLayer from 'giro3d_extensions/layers/AnnotationLayer';
import { Box3, EventDispatcher, Frustum, MathUtils, Matrix4, Object3D } from 'three';
import { CSS2DObject } from 'three/examples/jsm/renderers/CSS2DRenderer';
import Annotation, { AnnotationFilter, AnnotationGeometry, AnnotationId, isFiltered } from 'types/Annotation';
import type { Point } from 'geojson';
import { DatasetId } from 'types/common';
import { Dispatch, RootState } from 'store';
import { EventBus, EventMap } from 'EventBus';
import * as annotationsSlice from 'redux/annotations';
import * as settingsSlice from 'redux/settings';
import { HostView } from 'giro3d_extensions/layers/Layer';
import LayerManager from 'giro3d_extensions/LayerManager';
import StateObserver from 'giro3d_extensions/layers/StateObserver';
import { DRAWTOOL_MODE } from './Constants';
import DrawingManager from './DrawingManager';

export type AnnotationObject = {
    annotation: Annotation;
    layer: AnnotationLayer;
    label: CSS2DObject;
    hidden: boolean;
    filtered: boolean;
    inRange: boolean;
    inView: boolean;
};

interface AnnotationManagerEventMap {
    'hovered': EventMap['hovered-item'];
}

export default interface AnnotationManager extends EventDispatcher<AnnotationManagerEventMap> {
    removeAnnotation(id: AnnotationId): void;
    getAnnotation(id: AnnotationId): AnnotationObject | null;
    setAnnotations(annotations: Annotation[]): Promise<void>;
    editAnnotation(id: AnnotationId): Promise<AnnotationGeometry>;
    startViewAnnotation(id: AnnotationId): Promise<AnnotationGeometry>;
    setSelectedAnnotation(id: AnnotationId): void;
    stopViewAnnotation(): void;
    isFiltered(annotation: Annotation): boolean;
    isInView(annotation: Annotation): boolean;
    getClickableAnnotations(target: Object3D[]): void;
}

export class AnnotationManagerImpl
    extends EventDispatcher<AnnotationManagerEventMap>
    implements AnnotationManager, Disposable
{
    private readonly _annotationById: Map<AnnotationId, AnnotationObject> = new Map();
    private readonly _tmpBox3 = new Box3();
    private readonly _tmpFrustum = new Frustum();
    private readonly _tmpMatrix4 = new Matrix4();
    private readonly _hostView: HostView;

    private _instance: Instance;
    private _dispatch: Dispatch;

    private _annotationDisplayRatio = 0.15;
    private _annotationsClickable = true;
    private _onStartDrawing: () => void;
    private _onEndDrawing: () => void;
    private _eventBus: EventBus;
    private _drawingManager: DrawingManager;
    private _layerManager: LayerManager;
    private _showAnnotations: boolean;
    private _filter: AnnotationFilter;
    private _filterByVisibility: boolean;

    constructor(hostView: HostView) {
        super();
        this._hostView = hostView;
    }

    init(
        instance: Instance,
        dispatch: Dispatch,
        drawingManager: DrawingManager,
        eventBus: EventBus,
        layerManager: LayerManager,
        stateObserver: StateObserver<RootState>
    ) {
        this._instance = instance;
        this._dispatch = dispatch;
        this._drawingManager = drawingManager;
        this._layerManager = layerManager;

        this._eventBus = eventBus;

        this._onStartDrawing = this.onStartDrawing.bind(this);
        this._onEndDrawing = this.onEndDrawing.bind(this);

        this._eventBus.subscribe('start-drawing', this._onStartDrawing);
        this._eventBus.subscribe('end-drawing', this._onEndDrawing);

        instance.addEventListener('update-end', this.update.bind(this));

        this._showAnnotations = stateObserver.select(settingsSlice.getShowAnnotationsInViewport);
        this._filterByVisibility = stateObserver.select(settingsSlice.getFilterAnnotationByVisibility);
        this._filter = stateObserver.select(annotationsSlice.filter);

        stateObserver.subscribe(settingsSlice.getShowAnnotationsInViewport, (v) => {
            this._showAnnotations = v;
            this.updateVisibility();
        });

        stateObserver.subscribe(settingsSlice.getFilterAnnotationByVisibility, (v) => {
            this._filterByVisibility = v;
            if (v) {
                this.updateAnnotations();
            }
        });

        stateObserver.subscribe(annotationsSlice.filter, (v) => {
            this._filter = v;
            this.update();
        });

        stateObserver.subscribe(annotationsSlice.list, () => {
            this.update();
        });
    }

    private update() {
        this.updateAnnotationFilter();
        this.updateVisibility();
        this.updateAnnotations();
    }

    dispose() {
        this._eventBus.unsubscribe('start-drawing', this._onStartDrawing);
        this._eventBus.unsubscribe('end-drawing', this._onEndDrawing);
    }

    private onEndDrawing() {
        this._annotationsClickable = true;
        this._annotationById.forEach((a) => {
            a.label.element.style.pointerEvents = 'auto';
        });
    }

    private onStartDrawing() {
        this._annotationsClickable = false;
        this._annotationById.forEach((a) => {
            a.label.element.style.pointerEvents = 'none';
        });
    }

    getAnnotation(id: AnnotationId): AnnotationObject | null {
        return this._annotationById.get(id) ?? null;
    }

    private updateVisibility() {
        if (!this._instance) {
            return;
        }

        let mustNotify = false;
        this._annotationById.forEach((annotation) => {
            if (annotation.hidden !== !this._showAnnotations) {
                annotation.hidden = !this._showAnnotations;
                this.setAnnotationDisplayType(annotation, this._annotationDisplayRatio, true);
                mustNotify = true;
            }
        });

        if (mustNotify) {
            this._instance.notifyChange(this._instance.threeObjects);
        }
    }

    private updateAnnotationFilter() {
        if (!this._instance) {
            return;
        }

        let mustNotify = false;
        this._annotationById.forEach((tuple) => {
            const filtered = isFiltered(this._filter, tuple.annotation);
            if (filtered !== tuple.filtered) {
                tuple.filtered = filtered;
                this.setAnnotationDisplayType(tuple, this._annotationDisplayRatio, true);
                mustNotify = true;
            }
        });

        if (mustNotify) {
            this._instance.notifyChange(this._instance.threeObjects);
        }
    }

    updateGeometry(layer: AnnotationLayer, newGeometry: AnnotationGeometry) {
        this._dispatch(annotationsSlice.updateAnnotationGeometry({ id: layer.annotation.id, geometry: newGeometry }));
        layer.setGeojson(newGeometry);
    }

    editAnnotation(id: AnnotationId) {
        if (this._drawingManager.state !== DRAWTOOL_STATE.READY) {
            this._drawingManager.reset();
        }

        // Prevent other annotations to be selected while drawing
        this._annotationsClickable = false;
        this._annotationById.forEach((a) => {
            a.label.element.style.pointerEvents = 'none';
        });

        const { annotation, layer } = this._annotationById.get(id);

        layer.removeFromParent();

        return this._drawingManager
            .edit(annotation.geometry)
            .then((polygon) => {
                polygon.crs = {
                    'type': 'name',
                    'properties': {
                        'name': this._instance.referenceCrs,
                    },
                };
                this.updateGeometry(layer, polygon);
                return polygon;
            })
            .catch((e) => {
                this.updateGeometry(layer, annotation.geometry);
                throw e;
            })
            .finally(() => {
                this._drawingManager.end();
                this._instance.threeObjects.add(layer.get3dElement());
                this._annotationsClickable = true;
                this._annotationById.forEach((a) => {
                    a.label.element.style.pointerEvents = 'auto';
                });
            });
    }

    startViewAnnotation(id: DatasetId) {
        if (this._drawingManager.state !== DRAWTOOL_STATE.READY) {
            this._drawingManager.reset();
        }

        this._annotationsClickable = false;
        this._annotationById.forEach((a) => {
            a.label.element.style.pointerEvents = 'none';
        });

        const { annotation, layer } = this._annotationById.get(id);

        layer.removeFromParent();

        return this._drawingManager
            .view(annotation.geometry)
            .catch((e) => {
                throw e;
            })
            .finally(() => {
                this._instance.threeObjects.add(layer.get3dElement());
                this._annotationsClickable = true;
                this._annotationById.forEach((a) => {
                    a.label.element.style.pointerEvents = 'auto';
                });
            });
    }

    isFiltered(annotation: Annotation): boolean {
        return this._annotationById.get(annotation.id).filtered;
    }

    isInView(annotation: Annotation): boolean {
        return this._annotationById.get(annotation.id).inView;
    }

    async setAnnotations(list: Annotation[]) {
        if (!this._instance) {
            return;
        }

        const promises = [];

        for (const annotation of list) {
            if (this._annotationById.has(annotation.id)) {
                this.removeAnnotation(annotation.id);
            }

            // Style label
            const point = document.createElement('div');
            point.classList.add('point-annotation');

            point.id = `label-${annotation.id}`;

            const layer = new AnnotationLayer(
                annotation.id,
                this._instance,
                this._dispatch,
                annotation,
                this._layerManager,
                this._hostView
            );

            const label = new CSS2DObject(point);

            promises.push(
                layer.init().then(() => {
                    // As CSS2DObject is not rendered by THREE.js, but as a DOM element
                    // we need to use the DOM features for tooltips & event handlers
                    point.ariaLabel = annotation.name;
                    point.addEventListener('click', () => layer.clickHandler());

                    point.addEventListener('mouseover', (ev) => {
                        point.classList.add('point-annotation-hovered');
                        this.dispatchEvent({
                            type: 'hovered',
                            item: {
                                name: annotation.name,
                                itemType: 'Annotation',
                            },
                            location: { x: ev.clientX, y: ev.clientY },
                        });
                    });

                    point.addEventListener('mouseout', () => {
                        point.classList.remove('point-annotation-hovered');
                        this.dispatchEvent({ type: 'hovered', item: null });
                    });

                    if (annotation.geometry.type === GEOMETRY_TYPE.POINT) {
                        const p = annotation.geometry as Point;
                        label.position.set(p.coordinates[0], p.coordinates[1], p.coordinates[2]);
                    } else {
                        // Center label on annotation
                        const boundingBox = layer.getBoundingBox();
                        label.position.set(
                            (boundingBox.max.x + boundingBox.min.x) / 2,
                            (boundingBox.max.y + boundingBox.min.y) / 2,
                            (boundingBox.max.z + boundingBox.min.z) / 2
                        );
                    }

                    this._instance.threeObjects.add(label);

                    if (annotation.geometry.type !== GEOMETRY_TYPE.POINT) {
                        this._instance.threeObjects.add(layer.get3dElement());
                    }

                    const result = {
                        annotation,
                        layer,
                        label,
                        hidden: false,
                        filtered: false,
                        inRange: false,
                        inView: false,
                    };

                    this.setAnnotationDisplayType(result, this._annotationDisplayRatio);

                    this._annotationById.set(annotation.id, result);
                })
            );
        }

        // Wait on all annotations to be added before updating view
        await Promise.allSettled(promises);

        // ZScale is not properly applied when adding new objects
        // We have to force the update
        this._instance.threeObjects.updateMatrix();
        this._instance.threeObjects.updateMatrixWorld(true);
        this._instance.notifyChange(this._instance.threeObjects);
    }

    getClickableAnnotations(target: Object3D[]) {
        if (this._annotationsClickable) {
            this._annotationById.forEach((a) => {
                if (a.filtered || a.hidden) return;

                if (a.inRange) {
                    target.push(a.layer.get3dElement());
                } else {
                    target.push(a.label);
                }
            });
        }
    }

    private updateAnnotations(forced = false) {
        this._tmpMatrix4.multiplyMatrices(
            this._instance.camera.camera3D.projectionMatrix,
            this._instance.camera.camera3D.matrixWorldInverse
        );
        this._tmpFrustum.setFromProjectionMatrix(this._tmpMatrix4);

        this._annotationById.forEach((annotation) => {
            this.setAnnotationDisplayType(annotation, this._annotationDisplayRatio, forced);
            if (this._filterByVisibility) {
                this.updateInView(annotation, this._tmpFrustum);
            }
        });
    }

    removeAnnotation(id: AnnotationId) {
        const annotation = this._annotationById.get(id);
        this._instance.threeObjects.remove(annotation.label);
        // remove event listener?
        annotation.layer.removeFromParent();
        this._instance.notifyChange();
    }

    setSelectedAnnotation(id: AnnotationId) {
        for (const i of this._annotationById.keys()) {
            const { label, layer } = this._annotationById.get(i);
            layer.setSelected(false);
            if (label) {
                label.element.classList.remove('point-annotation-selected');
            }
        }

        if (id) {
            const annotation = this._annotationById.get(id);
            annotation.layer.setSelected();
            if (annotation.label) {
                annotation.label.element.classList.add('point-annotation-selected');
            }
        }
    }

    stopViewAnnotation() {
        if (this._drawingManager.mode === DRAWTOOL_MODE.VIEW) {
            this._drawingManager.end();
        } else {
            throw Error('DrawTool not in VIEW mode');
        }
    }

    updateInView(obj: AnnotationObject, frustum: Frustum) {
        let dispatch = false;
        if (obj.inRange) {
            // Detects based on the bbox so will have some false positives
            this._tmpBox3.setFromObject(obj.layer.drawObject);
            this._tmpBox3.translate(obj.layer.drawObject.position);
            if (obj.inView !== frustum.intersectsBox(this._tmpBox3)) {
                obj.inView = !obj.inView;
                dispatch = true;
            }
        } else if (obj.inView !== frustum.containsPoint(obj.label.position)) {
            obj.inView = !obj.inView;
            dispatch = true;
        }

        if (dispatch) {
            this._dispatch(annotationsSlice.updateAnnotationVisibility({ id: obj.annotation.id, visible: obj.inView }));
        }
    }

    private setAnnotationDisplayType(obj: AnnotationObject, ratio: number, forced = false) {
        const { annotation, label, layer } = obj;
        if (annotation.geometry.type === GEOMETRY_TYPE.POINT) {
            label.visible = !(obj.filtered || obj.hidden);
            return;
        }

        if (obj.filtered === true || obj.hidden === true) {
            layer.object3d.visible = false;
            label.visible = false;
            return;
        }

        const inRange = this.annotationInRange(obj, ratio);
        if (inRange !== obj.inRange || forced) {
            obj.inRange = inRange;
            if (obj.inRange) {
                layer.object3d.visible = true;
                label.visible = false;
            } else {
                layer.object3d.visible = false;
                label.visible = true;
            }
        }
    }

    editVector(geometry: AnnotationGeometry) {
        this._annotationById.forEach((a) => {
            this._annotationsClickable = false;
            a.label.element.style.pointerEvents = 'none';
        });

        return this._drawingManager
            .edit(geometry)
            .then((feature) => {
                feature.crs = {
                    'type': 'name',
                    'properties': {
                        'name': this._instance.referenceCrs,
                    },
                };
                return feature;
            })
            .catch((e) => {
                throw e;
            })
            .finally(() => {
                this._annotationsClickable = true;
                this._annotationById.forEach((a) => {
                    a.label.element.style.pointerEvents = 'auto';
                });
            });
    }

    annotationInRange(annotation: AnnotationObject, ratio: number) {
        // Calculates the smallest side of the viewport at the distance of the closest edge of the bounding box of the annotation
        // Uses the diameter bounding sphere of the annotations main goeometry as an estimate of the size of the annotation

        const geometryObject: GeometryObject = annotation.layer.object3d.children[0] as GeometryObject;
        if (geometryObject.sideGeometry.boundingSphere === null) {
            geometryObject.sideGeometry.computeBoundingSphere();
        }

        const distanceToCamera = annotation.layer
            .getBoundingBox()
            .distanceToPoint(this._instance.camera.camera3D.position);

        const camera3D = this._instance.camera.camera3D;

        if (!isPerspectiveCamera(camera3D)) {
            throw new Error('invalid camera type');
        }

        const vFOV = MathUtils.degToRad(camera3D.fov);
        const height = 2 * Math.tan(vFOV / 2) * distanceToCamera;
        const width = height * camera3D.aspect;
        const minDimension = height > width ? height : width;

        const diameter = 2 * geometryObject.sideGeometry.boundingSphere.radius;

        return diameter / minDimension > ratio;
    }
}
