// eslint-disable-next-line import/no-cycle
import { UPPY_CHANGE, UPPY_SHOWN } from '../actionTypes';

const initialState = {
    rev: 0,
    shown: false,
};

export default function reducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case UPPY_CHANGE:
            return {
                ...state,
                rev: state.rev + 1,
            };
        case UPPY_SHOWN:
            return {
                ...state,
                shown: payload,
            };
        default:
            return state;
    }
}
