export type Props = {
    id?: string;
    min?: number;
    max?: number;
    step?: number;
    value?: number;
    onChange?: (value: number) => void;
};

/**
 * A simple horizontal slider.
 */
export default function Slider(props: Props) {
    const min = props.min ?? 0;
    const max = props.max ?? 1;
    const step = props.step ?? 0.01;
    const value = props.value ?? max;

    const id = props.id ?? Math.random();

    function emit(v: number) {
        if (props.onChange) {
            props.onChange(v);
        }
    }

    return (
        <input
            id={`${id}`}
            type="range"
            min={min}
            max={max}
            step={step}
            value={value}
            autoComplete="off"
            className="settings-slider"
            onChange={(e) => emit(e.target.valueAsNumber)}
        />
    );
}
