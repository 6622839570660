import { useState, useEffect } from 'react';
import { shallowEqual } from 'react-redux';
import { getVectorEditState } from 'redux/selectors';
import { EDITSTATE } from 'redux/reducers/vector';
import Dataset from 'types/Dataset';
import HelpPanel from 'components/forms/HelpPanel';
import * as layers from 'redux/layers';
import * as datasetsSlice from 'redux/datasets';
import { HasVectorStyle, LayerState } from 'types/LayerState';
import { LAYER_TYPES } from 'services/Constants';
import { useAppSelector } from 'store';
import AttributeTable from './AttributeTable';
import EditVector from './EditVectorGeometry';

const AttributesMenuContent = (props: { layerState: LayerState & HasVectorStyle }) => {
    const { layerState } = props;
    const dataset = useAppSelector<Dataset>(datasetsSlice.get(layerState.datasetId));

    const initialized = useAppSelector(layers.getVectorFeaturesReady(layerState));

    if (!initialized) {
        return (
            <HelpPanel>
                {dataset.name} has not been initialized.
                <br />
                Enable it in the datasets menu to finish loading it.
            </HelpPanel>
        );
    }

    return <AttributeTable dataset={dataset} />;
};

const AttributesMenu = () => {
    const datasets: Dataset[] = useAppSelector(datasetsSlice.getRenderableDatasets, shallowEqual);
    const editState = useAppSelector(getVectorEditState);

    const [currentDataset, setCurrentDataset] = useState<Dataset>(undefined);
    const vectorDatasets = datasets.filter((d) => d.type === LAYER_TYPES.VECTOR);
    const currentLayerState = useAppSelector(layers.get<LayerState & HasVectorStyle>(currentDataset?.id));

    const changeDataset = (datasetName?: string) => {
        if (!datasetName) {
            setCurrentDataset(undefined);
        } else {
            vectorDatasets.forEach((ds) => {
                if (ds.name === datasetName) {
                    setCurrentDataset(ds);
                }
            });
        }
    };

    useEffect(() => {
        if (!datasets.find((d) => d.id === currentDataset?.id)) {
            changeDataset();
        }
    }, [datasets]);

    if (vectorDatasets.length === 0) {
        return <div>No vector datasets in this project</div>;
    }

    if (editState !== EDITSTATE.NONE) {
        return (
            <>
                <div className="map-pane-title">Attribute Table</div>
                <EditVector />
            </>
        );
    }

    return (
        <>
            <div className="map-pane-title">Attribute Table</div>
            <div className="row" key="attributesmenu-select-dataset">
                <label htmlFor="select-dataset" className="col-sm-5 col-form-label">
                    Select dataset
                </label>
                <div className="col-sm-7">
                    <div className="select-group">
                        <select
                            id="select-dataset"
                            className="form-select"
                            value={currentDataset ? currentDataset.name : undefined}
                            onChange={(e) => changeDataset(e.target.value)}
                        >
                            <option key="current-attribute-dataset-none" value="">
                                None
                            </option>
                            {vectorDatasets.map((ds) => (
                                <option key={`current-attribute-dataset-${ds.name}`} value={ds.name}>
                                    {ds.name}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
            </div>
            {currentLayerState ? <AttributesMenuContent layerState={currentLayerState} /> : null}
        </>
    );
};

export default AttributesMenu;
