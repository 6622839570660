import {
    BackSide,
    Color,
    FrontSide,
    Group,
    Mesh,
    MeshBasicMaterial,
    Plane,
    PlaneGeometry,
    Vector2,
    Vector3,
} from 'three';
import { Line2 } from 'three/examples/jsm/lines/Line2';
import { LineGeometry } from 'three/examples/jsm/lines/LineGeometry';
import { LineMaterial } from 'three/examples/jsm/lines/LineMaterial';

type SolidPlane = Mesh<PlaneGeometry, MeshBasicMaterial>;
type PlaneOutline = Line2;

let PLANE_GEOMETRY: PlaneGeometry;
let LINE_GEOMETRY: LineGeometry;
let LINE_MATERIAL: LineMaterial;

/**
 * A single cross-section plane.
 */
export default class CrossSection extends Group {
    readonly isCrossSection = true;

    private readonly _surface: SolidPlane;

    private readonly _outline: PlaneOutline;

    private readonly _plane: Plane;

    private _invert: boolean;

    private _disposed: boolean;

    constructor(params: { color: Color; opacity: number; lineWidth: number }) {
        super();
        this.name = 'CrossSection';
        // @ts-expect-error type is readonly from the outside world
        this.type = 'CrossSection';

        this.renderOrder = 2;

        if (!PLANE_GEOMETRY) {
            PLANE_GEOMETRY = new PlaneGeometry(1, 1);
            PLANE_GEOMETRY.computeBoundingBox();
            PLANE_GEOMETRY.computeBoundingSphere();
        }

        const planeMaterial = new MeshBasicMaterial({
            transparent: params.opacity < 1,
            opacity: params.opacity,
            color: params.color,
            side: FrontSide,
            clipIntersection: false,
        });

        this._surface = new Mesh(PLANE_GEOMETRY, planeMaterial);
        this._surface.name = 'Plane';

        if (!LINE_GEOMETRY) {
            LINE_GEOMETRY = new LineGeometry();
            const SIZE = 0.5;
            // eslint-disable-next-line prettier/prettier
            const positions = [-SIZE, +SIZE, 0, +SIZE, +SIZE, 0, +SIZE, -SIZE, 0, -SIZE, -SIZE, 0, -SIZE, +SIZE, 0];
            LINE_GEOMETRY.setPositions(positions);
            LINE_GEOMETRY.computeBoundingSphere();
            LINE_GEOMETRY.computeBoundingBox();
        }

        if (!LINE_MATERIAL) {
            LINE_MATERIAL = new LineMaterial({
                color: params.color,
                transparent: false,
                opacity: 1,
                worldUnits: false,
                linewidth: params.lineWidth,
                clipIntersection: false,
            });
        }

        this._outline = new Line2(LINE_GEOMETRY, LINE_MATERIAL);
        this._outline.name = 'Outline';

        this.add(this._outline);
        this.add(this._surface);

        this.visible = false;

        // Make it vertical
        this.rotateX(Math.PI / 2);

        this._plane = new Plane();
    }

    get invert() {
        return this._invert;
    }

    set invert(v: boolean) {
        this._invert = v;
        this._surface.material.side = v ? BackSide : FrontSide;
    }

    set size(s: Vector2) {
        this.scale.set(s.x, s.y, 1);
        this.updateMatrixWorld(true);
    }

    get size() {
        return new Vector2(this.scale.x, this.scale.y);
    }

    get opacity() {
        return this._surface.material.opacity;
    }

    set opacity(v: number) {
        this._surface.material.opacity = v;
    }

    get color() {
        return this._surface.material.color;
    }

    set color(v: Color) {
        this._surface.material.color = v;
        this._outline.material.color = v;
    }

    get plane(): Plane {
        this._plane.setFromNormalAndCoplanarPoint(
            this.getWorldDirection(new Vector3()),
            this.getWorldPosition(new Vector3())
        );

        if (this._invert) {
            this._plane.negate();
        }

        return this._plane;
    }

    dispose() {
        if (this._disposed) {
            return;
        }

        this._outline.material.dispose();
        this._outline.geometry.dispose();
        this._surface.material.dispose();
        this._surface.geometry.dispose();
        this.removeFromParent();
        this._disposed = true;
    }
}
