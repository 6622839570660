import { useDispatch } from 'react-redux';
import { HasOpacity, LayerState } from 'types/LayerState';
import * as layers from 'redux/layers';
import { useAppSelector } from 'store';

export type Props = { layer: LayerState & HasOpacity };

function OpacitySetting(props: Props) {
    const { layer } = props;

    const dispatch = useDispatch();

    const opacity = useAppSelector(layers.getOpacity(layer));

    function changeLayerOpacity(percentage: number) {
        const value = Number.isFinite(percentage) ? percentage / 100 : 0;
        dispatch(layers.setOpacity({ layer, value }));
    }

    return (
        <div className="row">
            <label htmlFor="opacity" className="col-sm-5 col-form-label">
                <i className="fal fa-low-vision fa-fw" /> Opacity
            </label>
            <div className="col-sm-7">
                <div className="input-group">
                    <input
                        id="opacity"
                        type="number"
                        min="0"
                        max="100"
                        step="1"
                        value={Math.floor(opacity * 100).toFixed(0)}
                        autoComplete="off"
                        className="form-control"
                        onChange={(e) => changeLayerOpacity(e.target.valueAsNumber)}
                    />
                    <div className="input-group-text">%</div>
                </div>
            </div>
        </div>
    );
}
export default OpacitySetting;
