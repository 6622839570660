// React
import { useDispatch, useSelector } from 'react-redux';
import { Button, UncontrolledTooltip } from 'reactstrap';

import * as giro3d from 'redux/giro3d';

const Minimap = ({ minimapRef }) => {
    const dispatch = useDispatch();
    const open = useSelector(giro3d.isMinimapVisible);

    function setOpen(show: boolean) {
        dispatch(giro3d.showMinimap(show));
    }

    return (
        <div className={`giro3d-minimap ${open ? 'open' : ''}`}>
            <div className="giro3d-minimap-container" ref={minimapRef} hidden={!open} />
            <Button className="borderless" title="Minimise" onClick={() => setOpen(false)} hidden={!open}>
                <i className="fal fa-minus" />
            </Button>
            <Button className="map-control" id="giro3d-open-minimap" onClick={() => setOpen(true)} hidden={open}>
                <i className="fal fa-map" />
                <UncontrolledTooltip placement="bottom" target="giro3d-open-minimap">
                    Open minimap
                </UncontrolledTooltip>
            </Button>
        </div>
    );
};

export default Minimap;
