import { ElevationRange } from '@giro3d/giro3d/core';
import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import { GEOJSON_AMPLITUDE_MODE, DEFAULT_GEOJSON_SETTINGS, FILTER_DISPLAY_MODE, LAYER_TYPES } from 'services/Constants';
import { Color } from 'three';
import type Dataset from 'types/Dataset';
import {
    type LayerState,
    type HasOpacity,
    type HasDraping,
    type CanShowInMinimap,
    type ColoringMode,
    type HasMasks,
    hasMasks,
    type IsPointCloud,
    type HasRadius,
    type HasAttributes,
    type HasSeismicPlane,
    type HasArrows,
    type HasAmplitude,
    type HasVectorStyle,
    type HasBorehole,
    Attribute,
    HasColoringMode,
    HasOverlayColor,
    AmplitudeSetting,
    VectorStyle,
    FeatureFilter,
    hasOpacity,
    hasArrows,
    hasAttributes,
    hasColoringMode,
    hasBorehole,
    hasAmplitude,
    hasVectorStyle,
    hasDraping,
    canShowInMinimap,
    hasOverlayColor,
    isPointCloud,
    hasRadius,
    hasSeismicPlane,
    isOrderable,
    HasFootprint,
    DataPointMode,
    HasDataPointMode,
    hasDataPointMode,
    HasPath,
    hasPath,
    canToggleSourceFiles,
} from 'types/LayerState';
import type LayerGroup from 'types/LayerGroup';
import ColorMap, { COLORMAP_BOUNDSMODE, COLORMAP_NAMES } from 'types/ColorMap';
import {
    AttributeName,
    DatasetGroupId,
    DatasetId,
    OLFeatureId,
    SerializableColor,
    SourceFileId,
    toColor,
} from 'types/common';
import { SerializedLayerState, SerializedSourceFileState, View } from 'types/serialization/View';
import { RootState } from 'store';
import type { SourceFileState } from 'types/SourceFileState';
import type { SourceFile } from 'types/SourceFile';
import { CurveKnot } from 'types/Curve';
import buildLayerState, { buildSourceFileState, getCanToggleSourceFileProperties } from './buildLayerState';

export type State = {
    layersById: Record<DatasetId, LayerState>;
    sourceFilesById: Record<SourceFileId, SourceFileState>;
    groupsById: Record<DatasetGroupId, LayerGroup>;
};

const initialState: State = {
    layersById: {},
    groupsById: {},
    sourceFilesById: {},
};

// Selectors
const self = (store: RootState) => store.layers;

export type IdOrLayer<L extends LayerState = LayerState> = DatasetId | L;
export type IdOrGroup = DatasetGroupId | LayerGroup;

function getLayerByIdOrValue(state: State, layer: IdOrLayer): LayerState {
    if (typeof layer === 'string') {
        return getLayerById(state, layer);
    }

    return getLayer(state, layer);
}

function getLayer<T = LayerState>(state: State, layer: LayerState): T & LayerState {
    return getLayerById<T>(state, layer.datasetId);
}

function getSourceFileState(state: State, id: SourceFileId): SourceFileState {
    return state.sourceFilesById[id];
}

function getLayerById<T = LayerState>(state: State, id: DatasetId): T & LayerState {
    return state.layersById[id] as T & LayerState;
}

function getColorMapForAttribute(state: State, layer: LayerState & HasAttributes, attribute: AttributeName): ColorMap {
    const colorMaps = getLayer<HasAttributes>(state, layer).colorMaps;
    return colorMaps[attribute];
}

function getAttribute(state: State, layer: LayerState & HasAttributes, name: string): Attribute {
    const attributes = getLayer<HasAttributes>(state, layer).attributes;
    return attributes.find((att) => att.name === name);
}

function removeMask(layer: LayerState & HasMasks, id: DatasetId) {
    layer.masks.splice(layer.masks.indexOf(id), 1);
}

/**
 * Executes all triggers related to the removal of a layer.
 */
function onLayerDeleted(state: State, deletedId: DatasetId) {
    // Remove masks related to the removed dataset
    for (const layer of Object.values(state.layersById)) {
        if (hasMasks(layer) && layer.masks.includes(deletedId)) {
            removeMask(layer, deletedId);
        }
    }
}

// Global selectors
export const count = createSelector(self, (s) => Object.keys(s.layersById).length);
export const all = createSelector(
    (s) => self(s).layersById,
    (layersById: Record<DatasetId, LayerState>) => Object.values(layersById)
);
export const orderedIds = createSelector(
    [(s) => s.datasets.datasets, (s) => self(s).layersById],
    (datasets, layersById) => {
        const result: DatasetId[] = [];

        for (const dataset of datasets) {
            const layer = layersById[dataset.id];
            if (layer && isOrderable(layer)) {
                result.push(layer.datasetId);
            }
        }

        return result;
    }
);
export function filter<T extends LayerState>(predicate: (l: LayerState) => boolean): (state: RootState) => T[] {
    return createSelector(self, (s) => Object.values(s.layersById).filter((l) => predicate(l)) as T[]);
}
// export const visibleLayers = filter((l) => l.visible);
export const getMaskableLayers = filter<LayerState & HasMasks>((l) => hasMasks(l));
export const getPathedLayers = filter<LayerState & HasPath>((l) => hasPath(l));

export const getGroups = createSelector(self, (s) => s.groupsById);
export const getLayersByGroup = (id: DatasetGroupId): ((state: RootState) => LayerState[]) =>
    createSelector([(s) => self(s).groupsById[id]?.layers, (s) => self(s).layersById], (layerIds, layersById) => {
        return Object.values(layersById).filter((l) => layerIds?.includes(l.datasetId));
    });

// Per-layer selectors

/**
 * Returns the {@link LayerState} associated with this dataset id.
 * Warning: this method is not type safe, as we cannot guarantee that the return type matches
 * with the actual layer state.
 */
export function get<T = LayerState>(id: DatasetId) {
    return createSelector(self, (s) => getLayerById<T>(s, id));
}

function createSourceFileSelector<T>(selector: (sourceFile: SourceFileState) => T) {
    return (id: SourceFileId) =>
        createSelector(self, (s) => {
            if (id) {
                const sourceFile = getSourceFileState(s, id);
                return sourceFile ? selector(sourceFile) : undefined;
            }
            return undefined;
        });
}

function createLayerSelector<T, L = LayerState>(selector: (layer: L & LayerState) => T) {
    return (layer: L & LayerState) =>
        createSelector(self, (s) => {
            if (layer) {
                const actualLayer = getLayer<L & LayerState>(s, layer);
                return actualLayer ? selector(actualLayer) : undefined;
            }
            return undefined;
        });
}

/**
 * Checks if there is any {@link LayerState} matching the dataset ID.
 */
export const exists = (id: DatasetId) => createSelector(self, (s) => getLayerById(s, id) != null);

// Base properties
export const isVisibleSelf = createLayerSelector<boolean>((l) => l.visible);
export const isVisibleInMinimap = createLayerSelector<boolean, CanShowInMinimap>((l) => l.visible && l.showInMinimap);

// HasOpacity
export const getOpacity = createLayerSelector<number, HasOpacity>((l) => l.opacity);

// HasDraping
export const supportsDraping = createLayerSelector<boolean, HasDraping>((l) => l.hasDraping);
export const isDraped = createLayerSelector<boolean, HasDraping>((l) => l.isDraped);
export const getUndrapedZOffset = createLayerSelector<number, HasDraping>((l) => l.zOffset);
export const getClippingRange = createLayerSelector<ElevationRange, HasDraping>((l) => l.clippingRange);
export const isClippingRangeEnabled = createLayerSelector<boolean, HasDraping>((l) => l.enableClippingRange);

// HasOverlayColor
export const getOverlayColor = createLayerSelector<Color, HasOverlayColor>((l) => toColor(l.overlayColor));

// HasColoringMode
export const getCurrentColoringMode = createLayerSelector<ColoringMode, HasColoringMode>((l) => l.currentColoringMode);
export const getColoringModes = createLayerSelector<ColoringMode[], HasColoringMode>((l) => l.coloringModes);

// HasVectorStyle
export const getVectorFeaturesReady = createLayerSelector<boolean, HasVectorStyle>((l) => l.vectorFeaturesReady);
export const getVectorStyle = createLayerSelector<VectorStyle, HasVectorStyle>((l) => l.vectorStyle);
export const hasFeatureFilter = createLayerSelector<boolean, HasVectorStyle>((l) => l.featureFilter?.name != null);
export const getFeatureFilter = createLayerSelector<FeatureFilter, HasVectorStyle>((l) => l.featureFilter);

// HasMasks
export const getActiveMasks = (layer: LayerState & HasMasks) =>
    createSelector(self, (s) => {
        const actualLayer = getLayer<HasMasks>(s, layer);
        const maskIds: DatasetId[] = actualLayer.masks;

        return maskIds.map((id) => getLayerById<HasMasks & LayerState>(s, id));
    });

export const getActiveMaskIds = (layer: LayerState & HasMasks) =>
    createSelector(self, (s) => {
        const actualLayer = getLayer<HasMasks>(s, layer);
        const maskIds: DatasetId[] = actualLayer.masks;

        return maskIds;
    });

// CanShowInMinimap
export const isShownInMinimap = createLayerSelector<boolean, CanShowInMinimap>((l) => l.showInMinimap);

// HasFootprint
export const getFootprintVisibility = createLayerSelector<boolean, HasFootprint>((l) => l.showFootprint);
export const getFootprintLabelVisibility = createLayerSelector<boolean, HasFootprint>((l) => l.showFootprintLabels);
export const getFootprintColor = createLayerSelector<SerializableColor, HasFootprint>((l) => l.footprintColor);

// IsPointCloud
export const getPointCloudSize = createLayerSelector<number, IsPointCloud>((l) => l.pointCloudSize);
export const getSseThreshold = createLayerSelector<number, IsPointCloud>((l) => l.sseThreshold);

// HasBorehole
export const getHasVariableRadii = createLayerSelector<boolean, HasBorehole>((l) => l.variableRadii);

// HasRadius
export const getRadius = createLayerSelector<number, HasRadius>((l) => l.radius);

// HasDataPointMode
export const getDataPointModes = createLayerSelector<DataPointMode[], HasDataPointMode>(
    (l) => l.availableDataPointModes
);
export const getCurrentDataPointMode = createLayerSelector<DataPointMode, HasDataPointMode>(
    (l) => l.currentDataPointMode
);

// HasAttributes
export const getAttributes = createLayerSelector<Attribute[], HasAttributes>((l) => l.attributes);
export const getColorMap = (layer: LayerState & HasAttributes, attribute: string) =>
    createSelector(self, (s) => {
        const actualLayer = getLayer<LayerState & HasAttributes>(s, layer);
        return actualLayer.colorMaps[attribute];
    });
export const getActiveAttributeAndColorMap = createLayerSelector<
    { attribute: Attribute; colorMap: ColorMap },
    HasAttributes
>((l) => {
    const activeAttribute = l.attributes.find((a) => a.id === l.activeAttribute);
    if (activeAttribute) {
        return { attribute: activeAttribute, colorMap: l.colorMaps[activeAttribute.id] };
    }
    return undefined;
});
export const isPinnedLegend = createLayerSelector<boolean, HasAttributes>((l) => l.pinLegend);
export const getActiveAttribute = createLayerSelector<Attribute, HasAttributes>((l) => {
    const attributeById = l.attributes.find((a) => a.id === l.activeAttribute);
    if (attributeById) return attributeById;
    const attributeByName = l.attributes.find((a) => a.name === l.activeAttribute);
    if (attributeByName) l.activeAttribute = attributeByName.id;
    return attributeByName;
});

// IsSeismic
export const getSpeedModuleMs = createLayerSelector<number, HasSeismicPlane>((l) => l.speedModuleMs);
export const getSeismicOffset = createLayerSelector<number, HasSeismicPlane>((l) => l.seismicOffset);
export const getStaticCorrection = createLayerSelector<boolean, HasSeismicPlane>((l) => l.staticCorrection);
export const getFilterTransparency = createLayerSelector<boolean, HasSeismicPlane>((l) => l.filterTransparency);
export const getIntensityFilter = createLayerSelector<number, HasSeismicPlane>((l) => l.intensityFilter);
export const getActiveSourceFile = createLayerSelector<SourceFileId, HasSeismicPlane>((l) => l.activeFile);

// HasArrows
export const getArrowScale = createLayerSelector<number, HasArrows>((l) => l.arrowScale);
export const getArrowSpacing = createLayerSelector<number, HasArrows>((l) => l.arrowSpacing);
export const getShowArrows = createLayerSelector<boolean, HasArrows>((l) => l.showArrows);

// HasAmplitude
type LayerWithAmplitude = LayerState & HasAmplitude & HasAttributes;

function getAmplitudeSettingsOrDefault(layer: LayerWithAmplitude, attribute: string): AmplitudeSetting {
    if (!layer.perAttributeAmplitudeSettings[attribute]) {
        return {
            mode: DEFAULT_GEOJSON_SETTINGS.AMPLITUDE_MODE,
            range: DEFAULT_GEOJSON_SETTINGS.AMPLITUDE_SPACTIAL_RANGE,
            showAmplitude: DEFAULT_GEOJSON_SETTINGS.AMPLITUDE_SHOWN,
        };
    }
    return layer.perAttributeAmplitudeSettings[attribute];
}

export const getShowAmplitude = createLayerSelector<boolean, LayerWithAmplitude>(
    (l) => getAmplitudeSettingsOrDefault(l, l.activeAttribute).showAmplitude
);
export const getAmplitudeMode = createLayerSelector<GEOJSON_AMPLITUDE_MODE, LayerWithAmplitude>(
    (l) => getAmplitudeSettingsOrDefault(l, l.activeAttribute).mode
);
export const getAmplitudeRange = createLayerSelector<number, LayerWithAmplitude>(
    (l) => getAmplitudeSettingsOrDefault(l, l.activeAttribute).range
);

// SourceFile
export const getSourceFileVisibility = createSourceFileSelector((sf) => sf.visible);

/** An action that updates a specific layer */
export type LayerAction<T, L = LayerState> = PayloadAction<{ layer: L & LayerState; value: T }>;
export type LayerOrIdAction<T> = PayloadAction<{ layer: IdOrLayer; value: T }>;
export type SourceFileAction<T> = PayloadAction<{ sourceFileId: SourceFileId; value: T }>;
/** An action that updates a specific attribute of a specific layer */
export type LayerColorMapAction<T, L = LayerState & HasAttributes> = PayloadAction<{
    layer: L;
    /** The attribute id */
    attributeId: string;
    value: T;
}>;
export type AmplitudeSettingAction<T> = LayerColorMapAction<T, LayerWithAmplitude>;

function getStyle(state: State, layer: LayerState & HasVectorStyle): VectorStyle {
    return getLayer<LayerState & HasVectorStyle>(state, layer).vectorStyle;
}

function getFilterStyle(state: State, layer: LayerState & HasVectorStyle): VectorStyle {
    const typed = getLayer<LayerState & HasVectorStyle>(state, layer);
    return typed.featureFilter?.style;
}

function deserializeColorMaps(source: Record<AttributeName, ColorMap>): Record<AttributeName, ColorMap> {
    const result: Record<AttributeName, ColorMap> = {};

    for (const [attributeName, colorMap] of Object.entries(source)) {
        result[attributeName] = colorMap;
    }

    return result;
}

function deserializeLayerState(src: SerializedLayerState, dst: LayerState) {
    dst.visible = src.visible;

    if (hasOpacity(dst)) {
        dst.opacity = src.opacity ?? dst.opacity;
    }
    if (hasMasks(dst)) {
        dst.masks = src.masks ?? dst.masks;
    }
    if (hasArrows(dst)) {
        dst.arrowScale = src.arrowScale ?? dst.arrowScale;
        dst.arrowSpacing = src.arrowSpacing ?? dst.arrowSpacing;
        dst.showArrows = src.showArrows ?? dst.showArrows;
    }
    if (hasAttributes(dst)) {
        dst.activeAttribute = src.activeAttribute ?? dst.activeAttribute;
        dst.pinLegend = src.pinLegend ?? dst.pinLegend;
        dst.colorMaps = src.colorMaps ? deserializeColorMaps(src.colorMaps) : dst.colorMaps;
    }
    if (hasColoringMode(dst)) {
        dst.currentColoringMode = src.currentColoringMode ?? dst.currentColoringMode;
    }
    if (hasOverlayColor(dst)) {
        dst.overlayColor = src.overlayColor ?? dst.overlayColor;
    }
    if (isPointCloud(dst)) {
        dst.pointCloudSize = src.pointCloudSize ?? dst.pointCloudSize;
    }
    if (hasRadius(dst)) {
        dst.radius = src.radius ?? dst.radius;
    }
    if (hasBorehole(dst)) {
        dst.variableRadii = src.variableRadii ?? dst.variableRadii;
    }
    if (hasSeismicPlane(dst)) {
        dst.seismicOffset = src.seismicPlaneZOffset ?? dst.seismicOffset;
        dst.speedModuleMs = src.seismicSpeedModule ?? dst.speedModuleMs;
        dst.staticCorrection = src.seismicStaticCorrection ?? dst.staticCorrection;
        dst.filterTransparency = src.seismicFilterTransparency ?? dst.filterTransparency;
        dst.intensityFilter = src.seismicIntensityFilter ?? dst.intensityFilter;
        dst.activeFile = src.seismicActiveFile ?? dst.activeFile;
    }
    if (hasAmplitude(dst)) {
        dst.perAttributeAmplitudeSettings = src.amplitudeSettings ?? dst.perAttributeAmplitudeSettings;
    }
    if (hasVectorStyle(dst)) {
        dst.vectorStyle = src.vectorStyle ?? dst.vectorStyle;
        dst.featureFilter = src.featureFilter ?? dst.featureFilter;
    }
    if (hasDraping(dst)) {
        dst.isDraped = src.isDraped ?? dst.isDraped;
        dst.zOffset = src.undrapedZOffset ?? dst.zOffset;
        dst.clippingRange = src.clippingRange ?? dst.clippingRange;
        dst.enableClippingRange = src.enableClippingRange ?? dst.enableClippingRange;
    }
    if (canShowInMinimap(dst)) {
        dst.showInMinimap = src.showInMinimap ?? dst.showInMinimap;
    }
    if (hasDataPointMode(dst)) {
        dst.currentDataPointMode = src.currentDataPointMode ?? dst.currentDataPointMode;
    }
}

function deserializeSourceFileState(
    src: SerializedSourceFileState,
    options: { datasetType?: LAYER_TYPES }
): SourceFileState {
    const canToggle = getCanToggleSourceFileProperties(options.datasetType);
    const dst: SourceFileState = { ...src };

    // If source file cannot be toggled, they must be necessary visible.
    // https://dev.azure.com/argeorobotics/Digital-Ocean-Space-App/_workitems/edit/3335
    if (!canToggle) {
        dst.visible = true;
    }
    return dst;
}

const slice = createSlice({
    name: 'layers',
    initialState,
    reducers: {
        reset: () => initialState,

        loadView: (state, action: PayloadAction<View>) => {
            const view = action.payload;
            if (view.layers) {
                for (const id of Object.keys(state.layersById).filter(
                    (item) => !Object.keys(view.layers).includes(item)
                ))
                    getLayerById(state, id).visible = false;

                for (const [id, source] of Object.entries(view.layers)) {
                    const target = getLayerById(state, id);
                    if (target) {
                        deserializeLayerState(source, target);
                    }
                }
            }
            if (view.groups) {
                state.groupsById = view.groups;
            }
            if (view.sourceFiles) {
                for (const [id, source] of Object.entries(view.sourceFiles)) {
                    const layer = getLayerById(state, source.datasetId);
                    state.sourceFilesById[id] = deserializeSourceFileState(source, { datasetType: layer?.datasetType });
                }
            }
        },

        createLayer: (state, action: PayloadAction<Dataset>) => {
            const layer = buildLayerState(action.payload);

            if (layer) {
                state.layersById[action.payload.id] = layer;
            }
        },

        rebuildLayer: (state, action: PayloadAction<Dataset>) => {
            const layer = buildLayerState(action.payload);

            if (layer) {
                if (state.layersById[action.payload.id]) layer.visible = state.layersById[action.payload.id].visible;
                state.layersById[action.payload.id] = layer;
            }
        },

        createSourcefiles: (state, action: PayloadAction<{ dataset: Dataset; sourceFiles: SourceFile[] }>) => {
            const { dataset, sourceFiles } = action.payload;

            sourceFiles.forEach((sf) => {
                if (!state.sourceFilesById[sf.id]) {
                    const sourceFileState = buildSourceFileState(dataset, sf);
                    state.sourceFilesById[sourceFileState.id] = sourceFileState;
                }
            });
        },

        deleteSourcefiles: (state, action: PayloadAction<{ dataset: Dataset; sourceFiles: SourceFile[] }>) => {
            const { dataset, sourceFiles } = action.payload;

            sourceFiles.forEach((sf) => {
                if (state.sourceFilesById[sf.id]?.datasetId === dataset.id) {
                    delete state.sourceFilesById[sf.id];
                }
            });
        },

        deleteLayer: (state, action: PayloadAction<DatasetId>) => {
            delete state.layersById[action.payload];
            Object.values(state.sourceFilesById)
                .filter((sf) => sf.datasetId === action.payload)
                .forEach((sf) => delete state.sourceFilesById[sf.id]);

            // Execute triggers
            onLayerDeleted(state, action.payload);
        },

        setVisibility: (state, action: LayerOrIdAction<boolean>) => {
            getLayerByIdOrValue(state, action.payload.layer).visible = action.payload.value;
        },

        setSourceFileVisibility: (state, action: SourceFileAction<boolean>) => {
            getSourceFileState(state, action.payload.sourceFileId).visible = action.payload.value;
        },

        setFootprintColor: (state, action: LayerAction<SerializableColor>) => {
            getLayer<HasFootprint>(state, action.payload.layer).footprintColor = action.payload.value;
        },

        createGroup: (state, action: PayloadAction<LayerGroup>) => {
            Object.values(state.groupsById).forEach((group) => {
                group.layers = group.layers.filter((id) => !action.payload.layers.includes(id));
            });
            state.groupsById[action.payload.id] = action.payload;
        },

        updateGroup: (state, action: PayloadAction<LayerGroup>) => {
            Object.values(state.groupsById).forEach((group) => {
                group.layers = group.layers.filter((id) => !action.payload.layers.includes(id));
            });
            state.groupsById[action.payload.id] = action.payload;
        },

        deleteGroup: (state, action: PayloadAction<DatasetGroupId>) => {
            delete state.groupsById[action.payload];
        },

        removeLayerFromGroup: (state, action: PayloadAction<DatasetId>) => {
            Object.values(state.groupsById).forEach((group) => {
                group.layers = group.layers.filter((id) => id !== action.payload);
            });
        },

        addLayerToGroup: (state, action: PayloadAction<{ dataset: DatasetId; group: DatasetGroupId }>) => {
            Object.values(state.groupsById).forEach((group) => {
                group.layers = group.layers.filter((id) => id !== action.payload.dataset);
            });
            state.groupsById[action.payload.group].layers.push(action.payload.dataset);
        },

        setOverlayColor: (state, action: LayerAction<SerializableColor, LayerState & HasOverlayColor>) => {
            getLayer<HasOverlayColor>(state, action.payload.layer).overlayColor = action.payload.value;
        },

        setColoringMode: (state, action: LayerAction<ColoringMode, LayerState & HasColoringMode>) => {
            getLayer<HasColoringMode>(state, action.payload.layer).currentColoringMode = action.payload.value;
        },

        setCurrentDataPointMode: (state, action: LayerAction<DataPointMode, LayerState & HasDataPointMode>) => {
            getLayer<HasDataPointMode>(state, action.payload.layer).currentDataPointMode = action.payload.value;
        },

        setPinnedLegend: (state, action: LayerAction<boolean, LayerState & HasAttributes>) => {
            getLayer<HasAttributes>(state, action.payload.layer).pinLegend = action.payload.value;
        },

        setOpacity: (state, action: LayerAction<number, LayerState & HasOpacity>) => {
            getLayer<HasOpacity>(state, action.payload.layer).opacity = action.payload.value;
        },

        showInMinimap: (state, action: LayerAction<boolean, LayerState & CanShowInMinimap>) => {
            getLayer<CanShowInMinimap>(state, action.payload.layer).showInMinimap = action.payload.value;
        },

        showFootprint: (state, action: LayerAction<boolean, LayerState & HasFootprint>) => {
            getLayer<HasFootprint>(state, action.payload.layer).showFootprint = action.payload.value;
        },

        showFootprintLabels: (state, action: LayerAction<boolean, LayerState & HasFootprint>) => {
            getLayer<HasFootprint>(state, action.payload.layer).showFootprintLabels = action.payload.value;
        },

        setDraping: (state, action: LayerAction<boolean, LayerState & HasDraping>) => {
            getLayer<HasDraping>(state, action.payload.layer).isDraped = action.payload.value;
        },

        setUndrapedZOffset: (state, action: LayerAction<number, LayerState & HasDraping>) => {
            getLayer<HasDraping>(state, action.payload.layer).zOffset = action.payload.value;
        },

        setEnableClippingRange: (state, action: LayerAction<boolean, LayerState & HasDraping>) => {
            getLayer<HasDraping>(state, action.payload.layer).enableClippingRange = action.payload.value;
        },

        setClippingRange: (state, action: LayerAction<ElevationRange, LayerState & HasDraping>) => {
            getLayer<HasDraping>(state, action.payload.layer).clippingRange = action.payload.value;
        },

        setActiveMasks: (state, action: LayerAction<string[], LayerState & HasMasks>) => {
            const layer = getLayer<HasMasks>(state, action.payload.layer);
            layer.masks = action.payload.value;
        },

        getColoringMode: (state, action: LayerAction<string[], LayerState & HasColoringMode>) => {
            const layer = getLayer<HasMasks>(state, action.payload.layer);
            layer.masks = action.payload.value;
        },

        setPointCloudSize: (state, action: LayerAction<number, LayerState & IsPointCloud>) => {
            getLayer<IsPointCloud>(state, action.payload.layer).pointCloudSize = action.payload.value;
        },

        setPointCloudSseThreshold: (state, action: LayerAction<number, LayerState & IsPointCloud>) => {
            getLayer<IsPointCloud>(state, action.payload.layer).sseThreshold = action.payload.value;
        },

        setRadius: (state, action: LayerAction<number, LayerState & HasRadius>) => {
            getLayer<HasRadius>(state, action.payload.layer).radius = action.payload.value;
        },

        setActiveAttribute: (state, action: LayerAction<string, LayerState & HasAttributes>) => {
            getLayer<HasAttributes>(state, action.payload.layer).activeAttribute = action.payload.value;
        },

        setAttributes: (state, action: LayerAction<Attribute[], LayerState & HasAttributes>) => {
            const layer = getLayer<HasAttributes>(state, action.payload.layer);
            const attributes = action.payload.value;
            layer.attributes = attributes;
            if (!layer.activeAttribute && attributes.length > 0) {
                layer.activeAttribute = attributes[0].id;
            }
        },

        setSpeedModuleMs: (state, action: LayerAction<number, LayerState & HasSeismicPlane>) => {
            getLayer<HasSeismicPlane>(state, action.payload.layer).speedModuleMs = action.payload.value;
        },

        setStaticCorrection: (state, action: LayerAction<boolean, LayerState & HasSeismicPlane>) => {
            getLayer<HasSeismicPlane>(state, action.payload.layer).staticCorrection = action.payload.value;
        },

        setFilterTransparency: (state, action: LayerAction<boolean, LayerState & HasSeismicPlane>) => {
            getLayer<HasSeismicPlane>(state, action.payload.layer).filterTransparency = action.payload.value;
        },

        setIntensityFilter: (state, action: LayerAction<number, LayerState & HasSeismicPlane>) => {
            getLayer<HasSeismicPlane>(state, action.payload.layer).intensityFilter = action.payload.value;
        },

        setSeismicOffset: (state, action: LayerAction<number, LayerState & HasSeismicPlane>) => {
            getLayer<HasSeismicPlane>(state, action.payload.layer).seismicOffset = action.payload.value;
        },

        setActiveFile: (state, action: LayerAction<SourceFileId, LayerState & HasSeismicPlane>) => {
            getLayer<HasSeismicPlane>(state, action.payload.layer).activeFile = action.payload.value;
        },

        setShowArrows: (state, action: LayerAction<boolean, LayerState & HasArrows>) => {
            getLayer<HasArrows>(state, action.payload.layer).showArrows = action.payload.value;
        },

        setArrowSpacing: (state, action: LayerAction<number, LayerState & HasArrows>) => {
            getLayer<HasArrows>(state, action.payload.layer).arrowSpacing = action.payload.value;
        },

        setArrowScale: (state, action: LayerAction<number, LayerState & HasArrows>) => {
            getLayer<HasArrows>(state, action.payload.layer).arrowScale = action.payload.value;
        },

        setVectorFeaturesReady: (state, action: LayerAction<boolean, HasVectorStyle>) => {
            getLayer<HasVectorStyle>(state, action.payload.layer).vectorFeaturesReady = action.payload.value;
        },

        setFillOpacity: (state, action: LayerAction<number, LayerState & HasVectorStyle>) => {
            getStyle(state, action.payload.layer).fillOpacity = action.payload.value;
        },

        setBorderColor: (state, action: LayerAction<SerializableColor, LayerState & HasVectorStyle>) => {
            getStyle(state, action.payload.layer).borderColor = action.payload.value;
        },

        setFillColor: (state, action: LayerAction<SerializableColor, LayerState & HasVectorStyle>) => {
            getStyle(state, action.payload.layer).fillColor = action.payload.value;
        },

        setLineWidth: (state, action: LayerAction<number, LayerState & HasVectorStyle>) => {
            getStyle(state, action.payload.layer).lineWidth = action.payload.value;
        },

        setBorderWidth: (state, action: LayerAction<number, LayerState & HasVectorStyle>) => {
            getStyle(state, action.payload.layer).borderWidth = action.payload.value;
        },

        setBorderOpacity: (state, action: LayerAction<number, LayerState & HasVectorStyle>) => {
            getStyle(state, action.payload.layer).borderOpacity = action.payload.value;
        },

        setPointSize: (state, action: LayerAction<number, LayerState & HasVectorStyle>) => {
            getStyle(state, action.payload.layer).pointSize = action.payload.value;
        },

        setFilterFillOpacity: (state, action: LayerAction<number, LayerState & HasVectorStyle>) => {
            getFilterStyle(state, action.payload.layer).fillOpacity = action.payload.value;
        },

        setFilterBorderColor: (state, action: LayerAction<SerializableColor, LayerState & HasVectorStyle>) => {
            getFilterStyle(state, action.payload.layer).borderColor = action.payload.value;
        },

        setFilterFillColor: (state, action: LayerAction<SerializableColor, LayerState & HasVectorStyle>) => {
            getFilterStyle(state, action.payload.layer).fillColor = action.payload.value;
        },

        setFilterLineWidth: (state, action: LayerAction<number, LayerState & HasVectorStyle>) => {
            getFilterStyle(state, action.payload.layer).lineWidth = action.payload.value;
        },

        setFilterBorderWidth: (state, action: LayerAction<number, LayerState & HasVectorStyle>) => {
            getFilterStyle(state, action.payload.layer).borderWidth = action.payload.value;
        },

        setFilterBorderOpacity: (state, action: LayerAction<number, LayerState & HasVectorStyle>) => {
            getFilterStyle(state, action.payload.layer).borderOpacity = action.payload.value;
        },

        setFilterPointSize: (state, action: LayerAction<number, LayerState & HasVectorStyle>) => {
            getFilterStyle(state, action.payload.layer).pointSize = action.payload.value;
        },

        setFeatureFilter: (
            state,
            action: LayerAction<{ name: string; features: OLFeatureId[] }, LayerState & HasVectorStyle>
        ) => {
            const { name, features } = action.payload.value;
            const currentFilter = getLayer<HasVectorStyle>(state, action.payload.layer).featureFilter;
            currentFilter.name = name;
            currentFilter.features = features;
        },

        setFeatureFilterMode: (state, action: LayerAction<FILTER_DISPLAY_MODE, LayerState & HasVectorStyle>) => {
            const layer = getLayer<HasVectorStyle>(state, action.payload.layer);
            layer.featureFilter.mode = action.payload.value;
        },

        setVariableRadii: (state, action: LayerAction<boolean, LayerState & HasBorehole>) => {
            getLayer<HasBorehole>(state, action.payload.layer).variableRadii = action.payload.value;
        },

        setAttributeColorMap: (state, action: LayerColorMapAction<ColorMap>) => {
            const { layer, attributeId, value } = action.payload;
            const actualLayer = getLayer<HasAttributes>(state, layer);
            actualLayer.colorMaps[attributeId] = value;
        },

        setAttributeColorMapName: (state, action: LayerColorMapAction<COLORMAP_NAMES>) => {
            const { layer, attributeId, value } = action.payload;
            const colorMap = getColorMapForAttribute(state, layer, attributeId);
            colorMap.name = value;
        },

        setAttributeColorMapDiscrete: (state, action: LayerColorMapAction<boolean>) => {
            const { layer, attributeId, value } = action.payload;
            const colorMap = getColorMapForAttribute(state, layer, attributeId);
            colorMap.discrete = value;
        },

        setAttributeColorMapInvert: (state, action: LayerColorMapAction<boolean>) => {
            const { layer, attributeId, value } = action.payload;
            const colorMap = getColorMapForAttribute(state, layer, attributeId);
            colorMap.invert = value;
        },

        setAttributeColorMapOpacityCurveKnots: (state, action: LayerColorMapAction<CurveKnot[]>) => {
            const { layer, attributeId, value } = action.payload;
            const colorMap = getColorMapForAttribute(state, layer, attributeId);
            if (!colorMap.opacityCurve) {
                colorMap.opacityCurve = {
                    knots: value,
                };
            } else {
                colorMap.opacityCurve.knots = value;
            }
        },

        setAttributeColorMapCustomMaxBound: (state, action: LayerColorMapAction<number>) => {
            const { layer, attributeId, value } = action.payload;
            const colorMap = getColorMapForAttribute(state, layer, attributeId);
            if (colorMap) {
                colorMap.customMax = value;
            }
        },

        setAttributeColorMapCustomMinBound: (state, action: LayerColorMapAction<number>) => {
            const { layer, attributeId, value } = action.payload;
            const colorMap = getColorMapForAttribute(state, layer, attributeId);
            if (colorMap) {
                colorMap.customMin = value;
            }
        },

        setAttributeColorMapBoundsMode: (state, action: LayerColorMapAction<COLORMAP_BOUNDSMODE>) => {
            const { layer, attributeId, value } = action.payload;
            const colorMap = getColorMapForAttribute(state, layer, attributeId);
            colorMap.boundsMode = value;
        },

        setShowAmplitude: (state, action: AmplitudeSettingAction<boolean>) => {
            const { attributeId: id, value } = action.payload;

            const layer = getLayer<LayerWithAmplitude>(state, action.payload.layer);

            const setting = getAmplitudeSettingsOrDefault(layer, id);
            setting.showAmplitude = value;
            layer.perAttributeAmplitudeSettings[id] = setting;
        },

        setAmplitudeMode: (state, action: AmplitudeSettingAction<GEOJSON_AMPLITUDE_MODE>) => {
            const { attributeId: id, value } = action.payload;

            const layer = getLayer<LayerWithAmplitude>(state, action.payload.layer);

            const setting = getAmplitudeSettingsOrDefault(layer, id);
            setting.mode = value;
            layer.perAttributeAmplitudeSettings[id] = setting;
        },

        setAmplitudeRange: (state, action: AmplitudeSettingAction<number>) => {
            const { attributeId: id, value } = action.payload;

            const layer = getLayer<LayerWithAmplitude>(state, action.payload.layer);

            const setting = getAmplitudeSettingsOrDefault(layer, id);
            setting.range = value;
            layer.perAttributeAmplitudeSettings[id] = setting;
        },

        updateAttribute: (state, action: LayerColorMapAction<{ min?: number; max?: number }>) => {
            const { layer, attributeId: id, value } = action.payload;
            const attribute = getAttribute(state, layer, id);

            if (attribute) {
                attribute.min = value.min ?? attribute.min;
                attribute.max = value.max ?? attribute.max;
            }
        },

        setPathLoaded: (state, action: LayerAction<boolean>) => {
            const layer = getLayer<HasPath>(state, action.payload.layer);
            layer.pathLoaded = action.payload.value;
        },
    },
});

export const reducer = slice.reducer;

export const reset = slice.actions.reset;
export const createLayer = slice.actions.createLayer;
export const rebuildLayer = slice.actions.rebuildLayer;
export const createSourcefiles = slice.actions.createSourcefiles;
export const deleteSourcefiles = slice.actions.deleteSourcefiles;
export const loadView = slice.actions.loadView;
export const deleteLayer = slice.actions.deleteLayer;

// Common properties
export const setVectorFeaturesReady = slice.actions.setVectorFeaturesReady;
export const setVisibility = slice.actions.setVisibility;
export const setPinnedLegend = slice.actions.setPinnedLegend;

// Group properties
export const createGroup = slice.actions.createGroup;
export const updateGroup = slice.actions.updateGroup;
export const deleteGroup = slice.actions.deleteGroup;
export const removeLayerFromGroup = slice.actions.removeLayerFromGroup;
export const addLayerToGroup = slice.actions.addLayerToGroup;

// HasOpacity
export const setOpacity = slice.actions.setOpacity;

// CanShowInMinimap
export const showInMinimap = slice.actions.showInMinimap;

// HasFootprint
export const showFootprint = slice.actions.showFootprint;
export const setFootprintColor = slice.actions.setFootprintColor;
export const setFootprintLabelVisibility = slice.actions.showFootprintLabels;

// HasColoringMode
export const setColoringMode = slice.actions.setColoringMode;

// HasDataPointMode
export const setCurrentDataPointMode = slice.actions.setCurrentDataPointMode;

// HasOverlayColor
export const setOverlayColor = slice.actions.setOverlayColor;

// HasDraping
export const setDraping = slice.actions.setDraping;
export const setUndrapedZOffset = slice.actions.setUndrapedZOffset;
export const setEnableClippingRange = slice.actions.setEnableClippingRange;
export const setClippingRange = slice.actions.setClippingRange;

// HasMasks
export const setActiveMasks = slice.actions.setActiveMasks;

// IsPointCloud
export const setPointCloudSseThreshold = slice.actions.setPointCloudSseThreshold;
export const setPointCloudSize = slice.actions.setPointCloudSize;

// HasRadius
export const setRadius = slice.actions.setRadius;

// HasAttributes
export const setActiveAttribute = slice.actions.setActiveAttribute;
export const setAttributes = slice.actions.setAttributes;
export const setAttributeColorMap = slice.actions.setAttributeColorMap;
export const setAttributeColorMapName = slice.actions.setAttributeColorMapName;
export const setAttributeColorMapDiscrete = slice.actions.setAttributeColorMapDiscrete;
export const setAttributeColorMapInvert = slice.actions.setAttributeColorMapInvert;
export const setAttributeColorMapOpacityCurveKnots = slice.actions.setAttributeColorMapOpacityCurveKnots;
export const setAttributeColorMapCustomMaxBound = slice.actions.setAttributeColorMapCustomMaxBound;
export const setAttributeColorMapCustomMinBound = slice.actions.setAttributeColorMapCustomMinBound;
export const setAttributeColorMapBoundsMode = slice.actions.setAttributeColorMapBoundsMode;
export const updateAttribute = slice.actions.updateAttribute;

// IsSeismic
export const setIntensityFilter = slice.actions.setIntensityFilter;
export const setStaticCorrection = slice.actions.setStaticCorrection;
export const setFilterTransparency = slice.actions.setFilterTransparency;
export const setSpeedModuleMs = slice.actions.setSpeedModuleMs;
export const setSeismicOffset = slice.actions.setSeismicOffset;
export const setActiveFile = slice.actions.setActiveFile;

// HasArrows
export const setShowArrows = slice.actions.setShowArrows;
export const setArrowScale = slice.actions.setArrowScale;
export const setArrowSpacing = slice.actions.setArrowSpacing;

// HasAmplitude
export const setShowAmplitude = slice.actions.setShowAmplitude;
export const setAmplitudeMode = slice.actions.setAmplitudeMode;
export const setAmplitudeRange = slice.actions.setAmplitudeRange;

// HasVectorStyle
export const setFillOpacity = slice.actions.setFillOpacity;
export const setLineWidth = slice.actions.setLineWidth;
export const setBorderWidth = slice.actions.setBorderWidth;
export const setBorderOpacity = slice.actions.setBorderOpacity;
export const setBorderColor = slice.actions.setBorderColor;
export const setFillColor = slice.actions.setFillColor;
export const setPointSize = slice.actions.setPointSize;

export const setFilterFillOpacity = slice.actions.setFilterFillOpacity;
export const setFilterLineWidth = slice.actions.setFilterLineWidth;
export const setFilterBorderWidth = slice.actions.setFilterBorderWidth;
export const setFilterBorderOpacity = slice.actions.setFilterBorderOpacity;
export const setFilterBorderColor = slice.actions.setFilterBorderColor;
export const setFilterFillColor = slice.actions.setFilterFillColor;
export const setFilterPointSize = slice.actions.setFilterPointSize;

export const setFeatureFilter = slice.actions.setFeatureFilter;
export const setFeatureFilterMode = slice.actions.setFeatureFilterMode;

// HasBorehole
export const setVariableRadii = slice.actions.setVariableRadii;

// SourceFileState
export const setSourceFileVisibility = slice.actions.setSourceFileVisibility;

// HasPath
export const setPathLoaded = slice.actions.setPathLoaded;
