import { Box3, Vector3 } from 'three';

// React
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'reactstrap';

import * as settings from 'redux/settings';
import * as crossSections from 'redux/crossSections';
import * as giro3d from 'redux/giro3d';

// Components
import { toHex, fromVector3 } from 'types/common';
import { genericEqualityFn } from 'components/utils';
import ToggleSwitch from '../../ToggleSwitch';
import SettingToggle from './SettingToggle';
import { SettingSlider } from './SettingSlider';
import SettingColorSelector from './SettingColorSelector';

import CoordinateInput from './CoordinateInput';

function CrossSectionSettings() {
    const dispatch = useDispatch();

    const open = useSelector(settings.getDropDown);

    const setOpen = (value: boolean) => {
        dispatch(settings.setDropDown(value ? 'crossSections' : null));
    };

    const visible = useSelector(crossSections.getVisibility);
    const invert = useSelector(crossSections.getInvert);
    const opacity = useSelector(crossSections.getOpacity);
    const color = useSelector(crossSections.getColor);
    const azimuth = useSelector(crossSections.getAzimuth);
    const center = useSelector(crossSections.getCenter);
    const volume = useSelector(giro3d.getVolume, genericEqualityFn<Box3>);

    function showCrossSection(show: boolean) {
        // Let's put the cross section right at the center of the scene's volume.
        if (!center && volume) {
            const newCenter = volume.getCenter(new Vector3());
            dispatch(crossSections.setCenter(fromVector3(newCenter)));
        }
        dispatch(crossSections.setVisibility(show));
    }

    return (
        <div className="settings-group">
            <div className="settings-main-toggle">
                <div>
                    <i className={`fal fa-pipe-section ${visible ? 'active' : null}`} />
                </div>
                <label htmlFor="crossSections-dropdown" className="settings-label dropdown-arrow">
                    <span>Cross-section</span>
                    <Button
                        className="borderless"
                        title="Reset cross-section"
                        onClick={() => dispatch(crossSections.reset())}
                    >
                        <i className="fal fa-arrow-rotate-left" />
                    </Button>
                    <input
                        type="checkbox"
                        id="crossSections-dropdown"
                        checked={open === 'crossSections'}
                        onChange={(e) => setOpen(e.target.checked)}
                        hidden
                    />
                    <div className="arrow" />
                </label>
                <ToggleSwitch
                    id="show-cross-sections"
                    checked={visible}
                    onChange={(e) => showCrossSection(e.target.checked)}
                />
            </div>
            {open === 'crossSections' ? (
                <div className="settings-dropdown">
                    <CoordinateInput
                        coordinate={center}
                        title="Center"
                        onChange={(v) => dispatch(crossSections.setCenter(fromVector3(v)))}
                    />
                    <SettingToggle
                        checked={invert}
                        title="Invert"
                        onChange={(checked) => dispatch(crossSections.setInvert(checked))}
                    />
                    <SettingSlider
                        value={opacity}
                        title="Opacity"
                        format="percent"
                        min={0}
                        max={1}
                        step={0.01}
                        onChange={(v) => dispatch(crossSections.setOpacity(v))}
                    />
                    <SettingSlider
                        value={azimuth}
                        title="Azimuth"
                        format="degrees"
                        min={0}
                        max={360}
                        step={1}
                        onChange={(v) => dispatch(crossSections.setAzimuth(v))}
                    />
                    <SettingColorSelector color={color} onChange={(c) => dispatch(crossSections.setColor(toHex(c)))} />
                </div>
            ) : null}
        </div>
    );
}

export default CrossSectionSettings;
