import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Modal } from 'reactstrap';
import * as datasetsSlice from 'redux/datasets';
import EditProject from '../EditProject';

const TitleBar = () => {
    const active = useSelector(datasetsSlice.currentProject);
    const [editProject, setEditProject] = useState(false);

    return (
        <div className="title-bar">
            {/* <Button className="borderless yellow" id="title-info" title="Information">
                <i className="fal fa-info-circle" />
            </Button> */}
            <div className="title">{active ? active.name : null}</div>
            <Button className="borderless green" id="edit" title="Edit" onClick={() => setEditProject(true)}>
                <i className="fal fa-pen" />
            </Button>
            <Modal isOpen={editProject}>
                <EditProject project={active} endEdit={() => setEditProject(false)} />
            </Modal>
        </div>
    );
};

export default TitleBar;
