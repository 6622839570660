// eslint-disable-next-line import/no-cycle
import {
    ITEM_UNSELECTED,
    MEASUREMENT_COORDINATES,
    MEASUREMENT_RESULT,
    MULTIPLE_ITEMS_SELECTED,
    OVERVIEW_MAP_LOADED,
    COLLECTION_SELECTED,
    PROJECT_SELECTED,
    DATASET_SELECTED,
    OVERVIEW_MAP_RESOLUTION,
    OVERVIEW_MAP_FEATURE_INFO,
} from '../actionTypes';

const initialState = {
    map: undefined,
    selectedItems: undefined,
    selectedCollection: undefined,
    selectedProject: undefined,
    selectedDataset: undefined,
    measurementCoords: undefined,
    measurementResult: undefined,
    resolution: undefined,
    featureInfo: undefined,
};

export default function reducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case OVERVIEW_MAP_LOADED:
            return {
                ...state,
                map: payload,
            };
        case ITEM_UNSELECTED:
            return {
                ...state,
                selectedItems: undefined,
                selectedCollection: undefined,
                selectedProject: undefined,
                selectedDataset: undefined,
            };
        case MULTIPLE_ITEMS_SELECTED:
            // payload: { at: [float, float], items: Array<{ type: str, item: collection|project|dataset }> }>
            return {
                ...state,
                selectedItems: payload,
                selectedCollection: undefined,
                selectedProject: undefined,
                selectedDataset: undefined,
            };
        case COLLECTION_SELECTED:
            // payload: { at: [float, float], collection }
            return {
                ...state,
                selectedCollection: payload,
                selectedItems: undefined,
                selectedProject: undefined,
                selectedDataset: undefined,
            };
        case PROJECT_SELECTED:
            // payload: { at: [float, float], project }
            return {
                ...state,
                selectedProject: payload,
                selectedItems: undefined,
                selectedCollection: undefined,
                selectedDataset: undefined,
            };
        case DATASET_SELECTED:
            // payload: { at: [float, float], dataset }
            return {
                ...state,
                selectedDataset: payload,
                selectedItems: undefined,
                selectedCollection: undefined,
                selectedProject: undefined,
            };
        case MEASUREMENT_COORDINATES:
            return {
                ...state,
                measurementCoords: payload,
            };
        case MEASUREMENT_RESULT:
            return {
                ...state,
                measurementResult: payload,
            };
        case OVERVIEW_MAP_RESOLUTION:
            return {
                ...state,
                resolution: payload,
            };
        case OVERVIEW_MAP_FEATURE_INFO:
            return {
                ...state,
                featureInfo: payload,
            };
        default:
            return state;
    }
}
