import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Input, Label, Row, Col } from 'reactstrap';
import { useFormik } from 'formik';
import { Vector3 } from 'three';
import { DRAWTOOL_MODE } from 'services/Constants';
import { Integer } from 'types/common';
import { getService } from 'ServiceContainer';
import { getLength, getElevationDiff, getArea, getMinMaxAltitudes } from '../../../giro3d_extensions/Measure';
import Scrollbox from '../../Scrollbox';
import { GEOMETRY_TYPE } from '../../../giro3d_extensions/DrawTool';
import * as drawToolSlice from '../../../redux/drawTool';

const drawingManager = getService('DrawingManager');

type ValidateResult = {
    submit?: string;
};

type FormValues = {
    x: string;
    y: string;
    z: string;
    submit?: string;
};

const GeometryInfo = ({ hideEmpty = true }) => {
    const geometry = useSelector(drawToolSlice.getPendingGeometry, () => false); // Why does this need to be forced?
    const mode = useSelector(drawToolSlice.getMode);

    if (!geometry && hideEmpty) return null;
    const geometryType = geometry?.geometryType;

    const [editIndex, setEditIndex] = useState<Integer>(null);

    const validate = (values: FormValues): ValidateResult => {
        const errors: ValidateResult = {};
        if (values.x === '' || values.y === '' || values.z === '') errors.submit = 'All inputs must be provided';
        return errors;
    };

    const formik = useFormik<FormValues>({
        initialValues: {
            x: '0',
            y: '0',
            z: '0',
        },
        onSubmit: (values) => {
            drawingManager.updateDrawnPointAt(
                editIndex,
                new Vector3(parseFloat(values.x), parseFloat(values.y), parseFloat(values.z))
            );
            setEditIndex(null);
        },
        validate,
    });

    useEffect(() => {
        if (!geometry || geometry.coordinates.length === 0) return;
        formik.setValues({
            x: geometry.coordinates[editIndex * 3 + 0].toFixed(5),
            y: geometry.coordinates[editIndex * 3 + 1].toFixed(5),
            z: geometry.coordinates[editIndex * 3 + 2].toFixed(5),
        });
    }, [
        geometry?.coordinates[editIndex * 3 + 0],
        geometry?.coordinates[editIndex * 3 + 1],
        geometry?.coordinates[editIndex * 3 + 2],
    ]);

    const editPoint = (event) => {
        setEditIndex(parseInt(event.currentTarget.id.split('-')[1], 10));
    };

    const inputForm = () => {
        if (editIndex !== null)
            return (
                <>
                    <hr />
                    <form onSubmit={formik.handleSubmit}>
                        <Row>
                            <Col sm={1}>
                                <Label htmlFor="x">
                                    <span className="coordinate-component-x">x</span>:
                                </Label>
                            </Col>
                            <Col sm={11}>
                                <Input
                                    id="x"
                                    name="x"
                                    type="number"
                                    onChange={formik.handleChange}
                                    value={formik.values.x}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={1}>
                                <Label htmlFor="y">
                                    <span className="coordinate-component-y">y</span>:
                                </Label>
                            </Col>
                            <Col sm={11}>
                                <Input
                                    id="y"
                                    name="y"
                                    type="number"
                                    onChange={formik.handleChange}
                                    value={formik.values.y}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={1}>
                                <Label htmlFor="z">
                                    <span className="coordinate-component-z">z</span>:
                                </Label>
                            </Col>
                            <Col sm={11}>
                                <Input
                                    id="z"
                                    name="z"
                                    type="number"
                                    onChange={formik.handleChange}
                                    value={formik.values.z}
                                />
                            </Col>
                        </Row>
                        {formik.errors.submit ? <div className="invalid">{formik.errors.submit}</div> : null}

                        <div className="spread-horizontal">
                            <Button type="submit">Submit</Button>
                            <Button onClick={() => setEditIndex(null)}>Cancel</Button>
                        </div>
                    </form>
                </>
            );
        return null;
    };

    const deletePoint = (event) => {
        drawingManager.deleteDrawnPoint(parseInt(event.currentTarget.id.split('-')[1], 10));
    };

    const canDelete = geometry?.coordinates.length > 6;

    const distances = getLength(geometry);
    const elevationDiffs = getElevationDiff(geometry);
    const totalDistance = distances?.reduce((acc, value) => acc + value, 0);
    const minMax = getMinMaxAltitudes(geometry);
    const elevationRange = minMax[1] - minMax[0];
    const totalArea = geometryType === GEOMETRY_TYPE.POLYGON ? getArea(geometry) : null;

    const showDistance = (distance: number) =>
        distance < 10000
            ? `${distance < 100 ? distance.toPrecision(3) : distance.toFixed(0)}m`
            : `${(distance / 1000).toFixed(0)}km`;

    const showElevationDiff = (elevation: number) =>
        `${elevation < 10 ? elevation.toPrecision(3) : elevation.toFixed(0)}m`;

    const showArea = (area: number) =>
        area < 1000000 ? `${area < 10 ? area.toPrecision(3) : area.toFixed(0)}m²` : `${(area / 1000000).toFixed(0)}km²`;

    const coord = (i: number, last: boolean) => (
        <tbody key={`selected-points-table-row-${String.fromCharCode(i + 65)}`}>
            <tr>
                <td>
                    <div className="coordinate-label">{last && geometryType !== GEOMETRY_TYPE.LINE ? '1' : i + 1}</div>
                </td>
                <td className="coordinate-box coordinate-box-3d">
                    <span className="coordinate-component-x">x</span>: {geometry.coordinates[i * 3 + 0].toFixed(3)}
                    <br />
                    <span className="coordinate-component-y">y</span>: {geometry.coordinates[i * 3 + 1].toFixed(3)}
                    <br />
                    <span className="coordinate-component-z">z</span>: {geometry.coordinates[i * 3 + 2].toFixed(3)}
                </td>
                <td className="coordinate-buttons">
                    {mode !== DRAWTOOL_MODE.VIEW ? (
                        <>
                            <Button
                                className="borderless green"
                                id={`edit-${i}`}
                                onClick={editPoint}
                                onKeyDown={editPoint}
                            >
                                <i className="fal fa-pen" />
                            </Button>
                            <Button
                                className="borderless red"
                                id={`delete-${i}`}
                                onClick={deletePoint}
                                onKeyDown={deletePoint}
                                disabled={!canDelete}
                            >
                                <i className="fal fa-trash-can" />
                            </Button>
                        </>
                    ) : null}
                </td>
            </tr>
            <tr>
                {geometry.coordinates[(i + 1) * 3] ? (
                    <>
                        <td className="coordinates-result-tag">┣</td>
                        <td className="coordinates-result">
                            <span className="coordinates-result">{showDistance(distances[i])}</span>
                            <span className="coordinates-result-neutral"> / </span>
                            <span className="coordinates-result-secondary">{showElevationDiff(elevationDiffs[i])}</span>
                        </td>
                    </>
                ) : null}
            </tr>
        </tbody>
    );

    const coordinates = () => {
        if (!geometry) return [];
        const children = [];
        for (let i = 0; i < geometry.coordinates.length / 3; i += 1) {
            children.push(coord(i, geometry.coordinates.length / 3 === i + 1));
        }
        return children;
    };

    return (
        <>
            <p>Defined Points</p>
            <Scrollbox className="annotation-content">
                <table className="coordinates-table" id="selected-points-table">
                    {coordinates()}
                </table>
            </Scrollbox>

            {inputForm()}

            <hr />
            <table>
                <tbody>
                    <tr>
                        <td>Total Distance</td>
                        <td>Area Size</td>
                        <td>Elevation Range</td>
                    </tr>
                    <tr>
                        <td className="coordinates-result">{distances ? showDistance(totalDistance) : 'N/A'}</td>
                        <td className="coordinates-result">{totalArea ? showArea(totalArea) : 'N/A'}</td>
                        <td className="coordinates-result-secondary">
                            {elevationRange !== -Infinity ? showElevationDiff(elevationRange) : 'N/A'}
                        </td>
                    </tr>
                </tbody>
            </table>
        </>
    );
};

export default GeometryInfo;
