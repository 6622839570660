import { useDispatch } from 'react-redux';
import { HasRadius, LayerState } from 'types/LayerState';
import * as layers from 'redux/layers';
import { useAppSelector } from 'store';

export type Props = { layer: LayerState & HasRadius };

function RadiusSetting(props: Props) {
    const { layer } = props;

    const dispatch = useDispatch();

    const radius = useAppSelector(layers.getRadius(layer));
    function changeRadius(value: number) {
        dispatch(layers.setRadius({ layer, value }));
    }

    const id = `${layer.datasetId}-radius`;

    return (
        <div className="row">
            <label htmlFor={id} className="col-sm-5 col-form-label">
                <i className="fal fa-waveform-lines fa-fw" /> Radius
            </label>
            <div className="col-sm-7">
                <div className="input-group">
                    <input
                        id={id}
                        type="number"
                        min="0"
                        max="100"
                        step="0.5"
                        value={radius}
                        autoComplete="off"
                        className="form-control"
                        onChange={(e) => changeRadius(e.target.valueAsNumber)}
                    />
                </div>
            </div>
        </div>
    );
}
export default RadiusSetting;
