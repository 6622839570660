export enum PAGE {
    OVERVIEW = 'overview',
    PROJECT = 'project',
    LIBRARY = 'library',
    ADMIN = 'admin',
}

export enum LEFT_TAB {
    PROJECTS = 'project',
    PROJECT_DATASETS = 'project_datasets',
    OVERVIEW_DATASETS = 'overview_datasets',
}

export enum RIGHT_TAB {
    USER = 'user',
    NOTIFICATIONS = 'notifications',
    BUILD = 'build',
    OVERVIEW_MEASURE = 'overview_measure',
    OVERVIEW_VIEW = 'overview_view',
    PROJECT_SETTINGS = 'project_settings',
    PROJECT_ANNOTATIONS = 'project_annotations',
    PROJECT_ATTRIBUTES = 'project_attributes',
    PROJECT_MEASURE = 'project_measure',
    PROJECT_ELEVATION = 'project_elevation',
    UPLOADS = 'uploads',
}
