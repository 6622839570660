// React
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import * as graphicsSettings from 'redux/graphicsSettings';
import * as settings from 'redux/settings';

// Components
import ToggleSwitch from '../../ToggleSwitch';
import { SettingSlider } from './SettingSlider';

function HillshadeSetting() {
    const dispatch = useDispatch();

    const open = useSelector(settings.getDropDown);

    const enableHillshading = useSelector(graphicsSettings.isHillshadingEnabled);
    const azimuth = useSelector(graphicsSettings.getHillshadingAzimuth);
    const zenith = useSelector(graphicsSettings.getHillshadingZenith);
    const intensity = useSelector(graphicsSettings.getHillshadingIntensity);

    const changeOpen = (value: boolean) => {
        dispatch(settings.setDropDown(value ? 'hillshade' : null));
    };

    return (
        <div className="settings-group">
            <div className="settings-main-toggle">
                <div>
                    <i className={`fal fa-mountains ${enableHillshading ? 'active' : null}`} />
                </div>
                <label htmlFor="hillshade-dropdown" className="settings-label dropdown-arrow">
                    <span>Hillshade</span>
                    <Button
                        className="borderless"
                        title="Reset Hillshade"
                        onClick={() => {
                            dispatch(graphicsSettings.resetHillshading());
                        }}
                    >
                        <i className="fal fa-arrow-rotate-left" />
                    </Button>
                    <input
                        type="checkbox"
                        id="hillshade-dropdown"
                        checked={open === 'hillshade'}
                        onChange={(e) => changeOpen(e.target.checked)}
                        hidden
                    />
                    <div className="arrow" />
                </label>
                <ToggleSwitch
                    id="hillshading"
                    checked={enableHillshading}
                    onChange={(e) => dispatch(graphicsSettings.enableHillshading(e.target.checked))}
                />
            </div>
            {open === 'hillshade' ? (
                <div className="settings-dropdown">
                    <SettingSlider
                        title="Azimuth"
                        format="degrees"
                        min={0}
                        max={360}
                        step={1}
                        value={azimuth}
                        onChange={(v) => dispatch(graphicsSettings.setHillshadeAzimuth(v))}
                    />
                    <SettingSlider
                        title="Zenith"
                        format="degrees"
                        min={0}
                        max={90}
                        step={1}
                        value={zenith}
                        onChange={(v) => dispatch(graphicsSettings.setHillshadeZenith(v))}
                    />
                    <SettingSlider
                        title="Intensity"
                        format="percent"
                        min={0}
                        max={1}
                        step={0.01}
                        value={intensity}
                        onChange={(v) => dispatch(graphicsSettings.setHillshadingIntensity(v))}
                    />
                </div>
            ) : null}
        </div>
    );
}

export default HillshadeSetting;
