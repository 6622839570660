// React
import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { shallowEqual, useDispatch } from 'react-redux';
import {
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    UncontrolledTooltip,
    Nav,
    NavItem,
    NavLink,
    TabContent,
} from 'reactstrap';
import { DndContext, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';

// Redux
import { Formik } from 'formik';
import Dataset from 'types/Dataset';
import { useAppSelector } from 'store';
import { supportedTypesLabels } from 'services/Labels';
import LayerGroup from 'types/LayerGroup';
import { generateUUID } from 'three/src/math/MathUtils';
import { getUppyRev } from '../../../redux/selectors';
import * as layers from '../../../redux/layers';
import * as datasetsSlice from '../../../redux/datasets';

import { pollForDatasets, reorderDatasets } from '../../../redux/actions';

// Components
import LazyTabPane from '../../LazyTabPane';
import DatasetListGroup from './DatasetListGroup';
import DatasetListItem from './DatasetListItem';
import { PROJECT_ACTION } from '../ProjectListItem';
import Scrollbox from '../../Scrollbox';
import ErrorBoundary from '../../ErrorBoundary';
import BaseField from '../../forms/BaseField';
import Multiselect from '../../forms/Multiselect';
import HelpPanel from '../../forms/HelpPanel';
import AddDataset from './AddDataset';
import useInterval from '../../../services/useInterval';
import { LAYER_STATES, LAYER_TYPES } from '../../../services/Constants';
import UppyService from '../../../services/UppyService';
import ProjectSourceFilesMenu from './sourceFilesMenu/InspectDatasetMenu';
import Library from '../../library/Library';

const GROUP_ACTION = {
    CREATE: 'group_create',
    UPDATE: 'group_update',
    DELETE: 'group_delete',
};

const DATASET_ACTION = {
    CREATE: 'dataset_create',
    // EDIT: 'dataset_edit',?
};

const TABS = { GROUPING: 'grouping', ORDERING: 'ordering' };

const groupDatasets = (datasets: Dataset[], groups: Record<string, LayerGroup>) => {
    const groupedSets: Record<string, Dataset[]> = {};

    Object.values(groups).forEach((group) => {
        groupedSets[group.id] = datasets.filter((d) => group.layers.includes(d.id));
    });

    const groupedDatasetIds = Object.values(groups).flatMap((group) => group.layers);
    const ungroupedDatasets = datasets.filter((d) => !groupedDatasetIds.includes(d.id));

    ungroupedDatasets.forEach((dataset) => {
        if (groupedSets[dataset.type]) groupedSets[dataset.type].push(dataset);
        else groupedSets[dataset.type] = [dataset];
    });

    Object.keys(groupedSets).forEach((key) => {
        groupedSets[key] = groupedSets[key].sort((a, b) => {
            if (a.name.toUpperCase() < b.name.toUpperCase()) return -1;
            if (a.name.toUpperCase() > b.name.toUpperCase()) return 1;
            return 0;
        });
    });

    const order = [
        LAYER_TYPES.BATHYMETRY,
        LAYER_TYPES.HORIZON,
        LAYER_TYPES.WATER_COLUMN,

        LAYER_TYPES.BACKSCATTER,
        LAYER_TYPES.SAS,

        LAYER_TYPES.TRACK,
        LAYER_TYPES.EM,
        LAYER_TYPES.ATTRIBUTE,
        LAYER_TYPES.CABLE,
        LAYER_TYPES.PIPELINE,
        LAYER_TYPES.VECTOR,
        LAYER_TYPES.SEISMIC,

        LAYER_TYPES.VESSEL,
        LAYER_TYPES.DOCUMENT,
    ];

    const ordering = {};
    order.forEach((key) => {
        if (Object.prototype.hasOwnProperty.call(groupedSets, key)) ordering[key] = null;
    });

    return Object.assign(ordering, groupedSets);
};

function isDisplayable(dataset: Dataset, index: number, ordering: Dataset[]) {
    if (dataset.type === LAYER_TYPES.BATHYMETRY) {
        return true;
    }

    // Check if the dataset is "beneath" all bathymetry layers
    for (let i = 0; i < index; i++) {
        const element = ordering[i];
        if (element.type === LAYER_TYPES.BATHYMETRY) {
            return true;
        }
    }

    return false;
}

const ProjectDatasetsMenu = () => {
    const dispatch = useDispatch();
    const datasets = useAppSelector(datasetsSlice.getRenderableDatasets, shallowEqual);
    const groups: Record<string, LayerGroup> = useAppSelector(layers.getGroups);

    const currentProject = useAppSelector(datasetsSlice.currentProject);
    const inspectedDatasetId = useAppSelector(datasetsSlice.getInspectedDataset);
    const uppyRev = useAppSelector(getUppyRev);

    const [currentTab, setCurrentTab] = useState(TABS.GROUPING);

    const [action, setAction] = useState(null);

    const [activeGroup, setActiveGroup] = useState(null);

    const groupedDatasets = groupDatasets(datasets, groups);

    const orderedLayers = useAppSelector(layers.orderedIds, shallowEqual);
    const [datasetsOrdered, setDatasetsOrdered] = useState<Dataset[]>([]);

    const [draggedType, setDraggedType] = useState(null);

    useEffect(() => {
        if (
            UppyService.getInstance().getFiles().length !== 0 &&
            action !== PROJECT_ACTION.DATASETS &&
            !inspectedDatasetId
        )
            setAction(DATASET_ACTION.CREATE);
    }, [uppyRev]);

    const updateOrdering = () => {
        // We want to display datasets from front to back, reverse the order given by giro3dServer
        const orderableDatasets = datasets.filter((dataset) => orderedLayers.indexOf(dataset.id) !== -1);
        orderableDatasets.sort((a, b) => orderedLayers.indexOf(a.id) - orderedLayers.indexOf(b.id));
        setDatasetsOrdered(orderableDatasets);
    };

    useEffect(updateOrdering, [orderedLayers]);

    useInterval(() => {
        const datasetsInProcessing = datasets.filter((d) =>
            [LAYER_STATES.CONVERTING, LAYER_STATES.DELETING, LAYER_STATES.UPLOADING].includes(d.state)
        );
        if (datasetsInProcessing.length !== 0) dispatch(pollForDatasets(datasetsInProcessing));
    }, 5000);

    const goToTab = (tab) => {
        if (currentTab !== tab) {
            setCurrentTab(tab);
        }
    };

    const handleDragEndSort = (event) => {
        const { active, over } = event;

        if (active.id !== over.id) {
            const oldIndex = orderedLayers.indexOf(active.id);
            const newIndex = orderedLayers.indexOf(over.id);
            const newArray = arrayMove(orderedLayers, oldIndex, newIndex);

            dispatch(reorderDatasets(newArray));
            updateOrdering();
        }
    };

    const handleDragEndGroup = (event) => {
        const { active, over } = event;

        if (Object.values(LAYER_TYPES).includes(over.id)) dispatch(layers.removeLayerFromGroup(active.id));
        else
            dispatch(
                layers.addLayerToGroup({
                    group: over.id,
                    dataset: active.id,
                })
            );

        setDraggedType(null);
    };

    const handleDragOverGroup = (event) => {
        const { active, over } = event;

        if (Object.values(LAYER_TYPES).includes(over?.id))
            setDraggedType(datasets.find((d) => d.id === active.id).type);
        else setDraggedType(null);
    };

    const handleDatasetCreateAbort = () => {
        setAction(null);
        UppyService.clearFiles();
    };

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    const modalContent = () => {
        switch (action) {
            case PROJECT_ACTION.DATASETS:
                return (
                    <>
                        <ModalHeader toggle={() => setAction(null)}>
                            Select Datasets for {currentProject.name}
                        </ModalHeader>
                        <ModalBody>
                            <Library
                                key={`project-library-${currentProject.id}`}
                                project={currentProject}
                                collectionId={undefined}
                                onCollectionOpen={undefined}
                                onCollectionClose={undefined}
                            />
                        </ModalBody>
                    </>
                );
            case GROUP_ACTION.CREATE:
            case GROUP_ACTION.UPDATE: {
                return (
                    <Formik
                        initialValues={{
                            name: activeGroup?.name || '',
                            datasets:
                                groupedDatasets[activeGroup?.id]?.map((dataset) => ({
                                    'label': dataset.name,
                                    'value': dataset.id,
                                })) || [],
                        }}
                        onSubmit={(values) => {
                            if (activeGroup)
                                dispatch(
                                    layers.updateGroup({
                                        id: activeGroup.id,
                                        name: values.name,
                                        layers: values.datasets.map((option) => option.value),
                                    })
                                );
                            else
                                dispatch(
                                    layers.createGroup({
                                        id: generateUUID(),
                                        name: values.name,
                                        layers: values.datasets.map((option) => option.value),
                                    })
                                );
                            setAction(null);
                            setActiveGroup(null);
                        }}
                        validate={(values) => (values.name ? {} : { name: 'Required' })}
                        enableReinitialize
                    >
                        {({ isSubmitting, values, handleBlur, setFieldValue, submitForm }) => (
                            <>
                                <ModalHeader
                                    toggle={() => {
                                        setAction(null);
                                        setActiveGroup(null);
                                    }}
                                >
                                    {activeGroup ? `Settings for ${activeGroup.name}` : 'Create a new group'}
                                </ModalHeader>
                                <ModalBody>
                                    <HelpPanel>
                                        Custom groups are saved in the project view.
                                        <br />
                                        To retain these settings when you or others open this project, create a project
                                        view link or save a default view.
                                    </HelpPanel>
                                    <BaseField name="name" label="Group name" type="text" required="required" />
                                    <Multiselect
                                        name="datasets"
                                        options={datasets
                                            .sort((a, b) => {
                                                if (a.name < b.name) return -1;
                                                if (a.name > b.name) return 1;
                                                return 0;
                                            })
                                            .map((dataset) => ({
                                                'label': dataset.name,
                                                'value': dataset.id,
                                            }))}
                                        onBlur={handleBlur}
                                        onChange={(choice) => setFieldValue('datasets', choice)}
                                        placeholder="Select dataset"
                                        multiValueCounterMessage="Selected dataset"
                                        isClearable={false}
                                        value={values.datasets}
                                    />
                                </ModalBody>
                                <ModalFooter>
                                    <Button type="submit" color="primary" onClick={submitForm} disabled={isSubmitting}>
                                        {activeGroup ? 'Update' : 'Create'}
                                    </Button>
                                </ModalFooter>
                            </>
                        )}
                    </Formik>
                );
            }
            case GROUP_ACTION.DELETE:
                return (
                    <>
                        <ModalBody>
                            <i className="modal-icon modal-icon-bad fal fa-circle-xmark no-hover" />
                            <span className="big-modal-text">Are you sure?</span>
                            <Button
                                color="warning"
                                onClick={() => {
                                    dispatch(layers.deleteGroup(activeGroup.id));
                                    setAction(null);
                                    setActiveGroup(null);
                                }}
                            >
                                Yes, delete this group
                            </Button>
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                className="borderless green underline"
                                onClick={() => {
                                    setAction(null);
                                    setActiveGroup(null);
                                }}
                            >
                                No, I regret this action
                            </Button>
                        </ModalFooter>
                    </>
                );
            case DATASET_ACTION.CREATE:
                return (
                    <>
                        <ModalHeader toggle={handleDatasetCreateAbort}>
                            Add Dataset to {currentProject.name}
                        </ModalHeader>
                        <AddDataset
                            key="drag-drop-add-dataset"
                            project={currentProject}
                            collectionOpen={undefined}
                            onClose={handleDatasetCreateAbort}
                        />
                    </>
                );
            default:
                return null;
        }
    };
    if (inspectedDatasetId) {
        return <ProjectSourceFilesMenu />;
    }
    return (
        <>
            <div className="map-pane-title-bar">
                <div className="map-pane-title">Project data</div>
                <div id="add-datasets">
                    <Button
                        className="circle green"
                        onClick={() => {
                            setAction(PROJECT_ACTION.DATASETS);
                        }}
                        disabled={!currentProject?.user_permissions.update_project}
                        title="Add datasets"
                    >
                        <i className="fal fa-plus" />
                    </Button>
                    {!currentProject?.user_permissions.update_project ? (
                        <UncontrolledTooltip target="add-datasets">
                            You lack the permissions to add datasets to this project.
                        </UncontrolledTooltip>
                    ) : null}
                </div>
            </div>
            <div className="map-pane-body">
                <Nav tabs fill className="horizontal">
                    <NavItem
                        className={classnames({
                            active: currentTab === TABS.GROUPING,
                        })}
                        key={TABS.GROUPING}
                    >
                        <NavLink className="nav-link" onClick={() => goToTab(TABS.GROUPING)} id={TABS.GROUPING}>
                            Grouping
                        </NavLink>
                    </NavItem>
                    <NavItem
                        className={classnames({
                            active: currentTab === TABS.ORDERING,
                        })}
                        key={TABS.ORDERING}
                    >
                        <NavLink className="nav-link" onClick={() => goToTab(TABS.ORDERING)} id={TABS.ORDERING}>
                            Layer order
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={currentTab} className="horizontal">
                    <LazyTabPane currentTab={currentTab} tabId={TABS.GROUPING}>
                        <ErrorBoundary
                            dispatch={dispatch}
                            fallback={
                                <span className="error-fallback-message">
                                    <i className="fal fa-exclamation-triangle icon-red" />
                                    An error occured in the dataset list.
                                    <i className="fal fa-exclamation-triangle icon-red" />
                                </span>
                            }
                        >
                            <Scrollbox>
                                <DndContext
                                    sensors={sensors}
                                    onDragEnd={handleDragEndGroup}
                                    onDragOver={handleDragOverGroup}
                                    autoScroll={false}
                                >
                                    {Object.keys(groupedDatasets).map((id) => (
                                        <DatasetListGroup
                                            key={`group-${id}`}
                                            datasetIds={groupedDatasets[id].map((d) => d.id)}
                                            groupId={id}
                                            groupName={supportedTypesLabels[id] || groups[id].name}
                                            customGroup={!supportedTypesLabels[id]}
                                            setSettingsModal={() => {
                                                setActiveGroup(groups[id]);
                                                setAction(GROUP_ACTION.UPDATE);
                                            }}
                                            setDeleteModal={() => {
                                                setActiveGroup(groups[id]);
                                                setAction(GROUP_ACTION.DELETE);
                                            }}
                                            highlight={draggedType === id}
                                        />
                                    ))}
                                </DndContext>
                                <Button
                                    type="button"
                                    className="borderless"
                                    onClick={() => setAction(GROUP_ACTION.CREATE)}
                                >
                                    <i className="fal fa-plus" />
                                    Add custom group
                                </Button>
                            </Scrollbox>
                        </ErrorBoundary>
                    </LazyTabPane>
                    <LazyTabPane currentTab={currentTab} tabId={TABS.ORDERING}>
                        <ErrorBoundary
                            dispatch={dispatch}
                            fallback={
                                <span className="error-fallback-message">
                                    <i className="fal fa-exclamation-triangle icon-red" />
                                    An error occured in the sorting components.
                                    <i className="fal fa-exclamation-triangle icon-red" />
                                </span>
                            }
                        >
                            <Scrollbox>
                                <DndContext sensors={sensors} onDragEnd={handleDragEndSort} autoScroll={false}>
                                    <SortableContext
                                        id="datasets-sortable"
                                        items={orderedLayers}
                                        strategy={verticalListSortingStrategy}
                                    >
                                        {datasetsOrdered
                                            .map((item, index) => (
                                                <DatasetListItem
                                                    key={`orderable-${item.id}`}
                                                    id={`orderable-${item.id}`}
                                                    datasetId={item.id}
                                                    groupOpen
                                                    warnNotDisplayable={!isDisplayable(item, index, datasetsOrdered)}
                                                    handleMode="sort"
                                                />
                                            ))
                                            .reverse()}
                                    </SortableContext>
                                </DndContext>
                            </Scrollbox>
                        </ErrorBoundary>
                    </LazyTabPane>
                </TabContent>
            </div>
            <Modal
                id="project-data"
                isOpen={action !== null}
                className={action === GROUP_ACTION.DELETE ? 'modal-confirm' : ''}
            >
                {modalContent()}
            </Modal>
        </>
    );
};

export default ProjectDatasetsMenu;
