// React
import { useState, useEffect } from 'react';

// Redux
import { useAppDispatch, useAppSelector } from 'store';
import { loadSeismicView, reorderDatasets, selectLeftTab } from 'redux/actions';
import * as layers from 'redux/layers';
import { canToggleSourceFiles, hasSeismicPlane } from 'types/LayerState';
import { useEventBus } from 'EventBus';
import { shallowEqual } from 'react-redux';
import * as giro3d from '../../../redux/giro3d';
import * as datasetsSlice from '../../../redux/datasets';

import Dial, { type DialSegment } from './Dial';
import DatasetSettingsPopover from '../datasetsMenu/DatasetSettingsPopover';
import { LEFT_TAB } from '../../../Navigation';

// Required to prevent the normal context menu opening
const noop = (e) => {
    if (e.button === 2) e.preventDefault();
};

const ContextMenu = () => {
    const dispatch = useAppDispatch();
    const eventBus = useEventBus();
    const toolState = useAppSelector(giro3d.getContextMenu);

    const order = useAppSelector(layers.orderedIds, shallowEqual);
    const datasetId = toolState.dataset;
    const sourceFileId = toolState.sourceFile;
    const topDataset = useAppSelector(datasetsSlice.get(datasetId));
    const topSortable = order.includes(topDataset?.id);
    const topLayer = useAppSelector(layers.get(datasetId));

    let isVisible: boolean;
    let name: string;

    if (canToggleSourceFiles(topLayer)) {
        isVisible = useAppSelector(layers.getSourceFileVisibility(sourceFileId));
        name = useAppSelector(datasetsSlice.getSourceFilename(datasetId, sourceFileId)) ?? 'file';
    } else {
        isVisible = useAppSelector(layers.isVisibleSelf(topLayer));
        name = topDataset.name ?? 'dataset';
    }

    const [settingsOpen, setSettingsOpen] = useState(false);

    useEffect(() => {
        setSettingsOpen(undefined);
    }, [toolState]);

    const close = () => {
        eventBus.dispatch('close-context-menu');
    };

    const openDatasetsPopover = () => {
        setSettingsOpen(true);
    };

    const toggleVisibility = () => {
        if (canToggleSourceFiles(topLayer)) {
            dispatch(layers.setSourceFileVisibility({ sourceFileId, value: !isVisible }));
        } else {
            dispatch(layers.setVisibility({ layer: topLayer, value: !isVisible }));
        }
    };

    const moveUp = () => {
        const index = order.indexOf(topDataset.id);
        [order[index], order[index + 1]] = [order[index + 1], order[index]];
        dispatch(datasetsSlice.reorderDatasets(order));
    };

    const moveDown = () => {
        const index = order.indexOf(topDataset.id);
        [order[index - 1], order[index]] = [order[index], order[index - 1]];
        dispatch(reorderDatasets(order));
    };

    const openSeismicView = () => {
        dispatch(loadSeismicView(topDataset, sourceFileId));
        close();
    };

    const copyCoordinates = () => {
        navigator.clipboard.writeText(`${toolState.point[0]}, ${toolState.point[1]}, ${toolState.point[2]}`);
        close();
    };

    const startInspectDataset = () => {
        dispatch(datasetsSlice.setInspectedDataset(topDataset.id));
        dispatch(selectLeftTab(LEFT_TAB.PROJECT_DATASETS));
        close();
    };

    const buttons: DialSegment[] = [
        {
            icon: 'fa-copy',
            title: 'Copy coordinates',
            action: copyCoordinates,
            id: undefined,
        },
        {
            icon: 'fa-arrow-up',
            title: 'Move up',
            action: topSortable && order[order.length - 1] !== datasetId ? moveUp : undefined,
            id: undefined,
        },
        {
            icon: 'fa-bars',
            title: `${topDataset?.name || 'Dataset'} settings`,
            action: topLayer ? openDatasetsPopover : undefined,
            id: 'dataset-options',
        },
        {
            icon: 'fa-arrow-down',
            title: 'Move down',
            action: topSortable && order[0] !== datasetId ? moveDown : undefined,
            id: undefined,
        },
        {
            icon: isVisible ? 'fa-toggle-on' : 'fa-toggle-off',
            title: isVisible ? `Hide ${name}` : `Show ${name}`,
            action: topLayer ? toggleVisibility : undefined,
            id: 'sourceFile-options',
        },
        undefined,
        {
            icon: 'fa-info-circle',
            title: `Inspect ${topDataset?.name || 'dataset'}`,
            action: topLayer ? startInspectDataset : undefined,
            id: undefined,
        },
        {
            icon: 'fa-eye',
            title: 'View in 2D',
            action: hasSeismicPlane(topLayer) ? openSeismicView : undefined,
            id: undefined,
        },
    ];

    const popover = () => (
        // Preventing clicking on the menu closing it
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
        <div
            className="select-tool"
            style={{ 'top': `${toolState.offset.y}px`, 'left': `${toolState.offset.x}px` }}
            onClick={noop}
            onDoubleClick={noop}
            onContextMenu={noop}
        >
            <Dial buttons={buttons} />
            {topDataset && (
                <DatasetSettingsPopover
                    dataset={topDataset}
                    target="dataset-options"
                    isOpen={settingsOpen}
                    onToggle={() => setSettingsOpen(false)}
                />
            )}
        </div>
    );

    return toolState.open ? popover() : null;
};

const ContextMenuWrapper = () => {
    const { open } = useAppSelector(giro3d.getContextMenu, (a, b) => a.open === b.open);
    if (!open) return null;
    return <ContextMenu />;
};

export default ContextMenuWrapper;
