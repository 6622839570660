import Coordinates from './Coordinates';
import HoveredItemIndicator from './HoveredItemIndicator';
import InstanceLoadingIndicator from './InstanceLoadingIndicator';

const StatusBar = () => (
    <div className="map-statusbar">
        <div className="map-statusbar-side">
            <div className="map-statusbar-text">
                <i className="fa fa-copyright" /> Argeo
            </div>
            <InstanceLoadingIndicator />
        </div>
        <div className="map-statusbar-side">
            <HoveredItemIndicator />
            <Coordinates />
        </div>
    </div>
);

export default StatusBar;
