import { useState } from 'react';
import { Button } from 'reactstrap';

// eslint-disable-next-line import/no-unresolved
import versionInfo from '../../version.json';
import Release from './Release';

interface Props {
    showSince: Date;
}

export const latestRelease = '2024-08-14';

const Releases = (props: Props) => {
    const showNext = versionInfo.version.toLowerCase().endsWith('x');
    const [showAll, setShowAll] = useState(false);

    const showMoreClicked = () => {
        setShowAll(true);
    };

    const releases = [
        <Release date="TBD" version="1.18" key="1.18">
            <ul>
                <li>Optimize UI progress reporting</li>
                <li>Fix black flickering tiles</li>
                <li>Avoids opening context menu when dragging the mouse (i.e. rotating the camera)</li>
                <li>Auto show file for single file datasets</li>
            </ul>
        </Release>,
        <Release date="2024-08-14" version="1.17" key="1.17">
            <ul>
                <li>Objects are now highlighted on hover</li>
            </ul>
        </Release>,
        <Release date="2024-07-30" version="1.16" key="1.16">
            <ul>
                <li>Adding support for borehole data from LAS files</li>
            </ul>
        </Release>,
        <Release date="2024-07-17" version="1.15" key="1.15">
            <ul>
                <li>Adding support for GeoJSON without CRS</li>
            </ul>
        </Release>,
        <Release date="2024-07-02" version="1.14" key="1.14">
            <ul>
                <li>Adding support for water column data</li>
                <li>Adding a measure tool</li>
            </ul>
        </Release>,
        <Release date="2024-06-13" version="1.13" key="1.13">
            <ul>
                <li>Now using Keyclock as login service</li>
                <li>Reenabling the elevation profile support</li>
            </ul>
        </Release>,
        <Release date="2024-05-16" version="1.12" key="1.12">
            <ul>
                <li>Now using the same way of adding dataset in both project and admin views</li>
            </ul>
        </Release>,
        <Release date="2024-04-18" version="1.11" key="1.11">
            <ul>
                <li>UI speed improvements and memory usage</li>
                <li>Separate handling of COG single vs multi band</li>
                <li>Better handling of login and when it is required to login again</li>
                <li>SBP static correction support</li>
                <li>Anode visualization on pipelines</li>
                <li>Support for Geotiff YCbCr encoding</li>
            </ul>
        </Release>,
    ];

    const releasesToRender = releases.filter((release) => {
        const timestamp = Date.parse(release.props.date);
        return (showNext && Number.isNaN(timestamp)) || props.showSince <= new Date(timestamp) || showAll;
    });
    return (
        <div className="releases">
            {releasesToRender}
            {!showAll && (
                <div>
                    <Button color="primary" onClick={showMoreClicked}>
                        Show more
                    </Button>
                </div>
            )}
        </div>
    );
};

export default Releases;
