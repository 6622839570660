import { PropsWithChildren } from 'react';

/**
 * Wraps react components with an orange border and the mention "This feature is experimental".
 */
export default function ExperimentalMention({ children }: PropsWithChildren<unknown>) {
    return (
        <div className="border border-warning p-2 rounded">
            <span className="text-warning">
                <i className="fal fa-flask" /> This feature is experimental
            </span>
            {children}
        </div>
    );
}
