import { Button } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { startMeasureDrawing, clearMeasureDrawing } from 'redux/actions';
import { useAppSelector } from 'store';
import { DRAWN_TOOL } from 'services/Constants';
import GeometryInfo from '../annotationMenu/GeometryInfo';
import * as drawToolSlice from '../../../redux/drawTool';
import DisabledDrawTool from '../DisabledDrawTool';

const MeasureMenu = () => {
    const dispatch = useDispatch();
    const currentTool = useAppSelector(drawToolSlice.getTool);
    return (
        <>
            <div className="map-pane-title-bar">
                <div className="map-pane-title">Measure</div>
            </div>
            {!currentTool || currentTool === DRAWN_TOOL.MEASURE ? (
                <>
                    <GeometryInfo hideEmpty={false} />
                    <hr />
                    <div className="sideform-buttons">
                        <Button color="primary" id="start-drawing" onClick={() => dispatch(startMeasureDrawing())}>
                            MEASURE
                        </Button>
                        <Button color="warning" id="cancel-drawing" onClick={() => dispatch(clearMeasureDrawing())}>
                            ABORT
                        </Button>
                    </div>
                </>
            ) : (
                <DisabledDrawTool />
            )}
        </>
    );
};

export default MeasureMenu;
