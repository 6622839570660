import { createSelector, PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ANNOTATION_CREATESTATE, DEFAULT_ANNOTATION_FILTER, EDITSTATE } from 'services/Constants';
import { RootState } from 'store';
import Annotation, { AnnotationComment, AnnotationFilter, AnnotationGeometry, AnnotationId } from 'types/Annotation';
import { DatasetId } from 'types/common';

type AnnotationWithVisibility = Annotation & { inView?: boolean };

export type State = {
    list: AnnotationWithVisibility[];
    active: Annotation | null;
    createState: ANNOTATION_CREATESTATE;
    editState: EDITSTATE;
    comments: Readonly<AnnotationComment[]>;
    filter: AnnotationFilter;
};

const initialState: State = {
    list: [],
    active: null,
    createState: ANNOTATION_CREATESTATE.NONE,
    editState: EDITSTATE.NONE,
    comments: [],
    filter: DEFAULT_ANNOTATION_FILTER,
};

const self = (store: RootState) => store.annotations;

// Selectors
export const get = (id: AnnotationId) => createSelector(self, (s) => s.list.find((a) => a.id === id));
export const list = createSelector(self, (s) => s.list);
export const filter = createSelector(self, (s) => s.filter);
export const active = createSelector(self, (s) => s.active);
export const comments = createSelector(self, (s) => s.comments);
export const createState = createSelector(self, (s) => s.createState);
export const editState = createSelector(self, (s) => s.editState);

const slice = createSlice({
    name: 'annotations',
    initialState,
    reducers: {
        reset: () => initialState,
        setAnnotations: (state, action: PayloadAction<Annotation[]>) => {
            state.list = action.payload;
        },
        setActiveAnnotation: (state, action: PayloadAction<Annotation | null>) => {
            state.active = action.payload;
        },
        createAnnotationState: (state, action: PayloadAction<ANNOTATION_CREATESTATE>) => {
            state.createState = action.payload;
        },
        appendAnnotation: (state, action: PayloadAction<Annotation>) => {
            state.list.push(action.payload);
        },
        setAnnotationDatasets: (state, action: PayloadAction<{ id: AnnotationId; datasets: DatasetId[] }>) => {
            for (const annotation of state.list) {
                if (annotation.id === action.payload.id) {
                    annotation.datasets = action.payload.datasets;
                }
            }
        },
        editAnnotationState: (state, action: PayloadAction<EDITSTATE>) => {
            state.editState = action.payload;
        },
        updateAnnotation: (state, action: PayloadAction<Annotation>) => {
            const index = state.list.findIndex((ann) => ann.id === action.payload.id);
            if (index !== -1) {
                state.list[index] = action.payload;
            }
        },
        updateAnnotationGeometry: (
            state,
            action: PayloadAction<{ id: AnnotationId; geometry: AnnotationGeometry }>
        ) => {
            const index = state.list.findIndex((ann) => ann.id === action.payload.id);
            if (index !== -1) {
                state.list[index].geometry = action.payload.geometry;
            }
        },
        updateAnnotationVisibility: (state, action: PayloadAction<{ id: AnnotationId; visible: boolean }>) => {
            const index = state.list.findIndex((ann) => ann.id === action.payload.id);
            if (index !== -1) {
                state.list[index].inView = action.payload.visible;
            }
        },
        resetVisibilities: (state) => {
            state.list.forEach((annotation) => {
                annotation.inView = true;
            });
        },
        setCommentList: (state, action: PayloadAction<AnnotationComment[]>) => {
            state.comments = action.payload;
        },
        appendCommentList: (state, action: PayloadAction<AnnotationComment>) => {
            state.comments.push(action.payload);
            for (const annotation of state.list) {
                if (action.payload.annotation_id === annotation.id) {
                    annotation.comment_count += 1;
                }
            }
        },
        removeCommentFromList: (state, action: PayloadAction<AnnotationComment>) => {
            const index = state.comments.findIndex((comm) => comm.id === action.payload.id);
            if (index !== -1) {
                state.comments.splice(index);
            }
            for (const annotation of state.list) {
                if (action.payload.annotation_id === annotation.id) {
                    annotation.comment_count -= 1;
                }
            }
        },
        updateComment: (state, action: PayloadAction<AnnotationComment>) => {
            const index = state.comments.findIndex((comm) => comm.id === action.payload.id);
            if (index !== -1) {
                state.comments[index] = action.payload;
            }
        },
        setAnnotationFilter: (state, action: PayloadAction<AnnotationFilter>) => {
            state.filter = action.payload;
        },
    },
});

export const reducer = slice.reducer;

// Actions
export const reset = slice.actions.reset;
export const setAnnotations = slice.actions.setAnnotations;
export const setActiveAnnotation = slice.actions.setActiveAnnotation;
export const createAnnotationState = slice.actions.createAnnotationState;
export const appendAnnotation = slice.actions.appendAnnotation;
export const setAnnotationDatasets = slice.actions.setAnnotationDatasets;
export const editAnnotationState = slice.actions.editAnnotationState;
export const updateAnnotation = slice.actions.updateAnnotation;
export const setCommentList = slice.actions.setCommentList;
export const setAnnotationFilter = slice.actions.setAnnotationFilter;
export const updateComment = slice.actions.updateComment;
export const appendCommentList = slice.actions.appendCommentList;
export const removeCommentFromList = slice.actions.removeCommentFromList;
export const updateAnnotationVisibility = slice.actions.updateAnnotationVisibility;
export const resetVisibilities = slice.actions.resetVisibilities;
export const updateAnnotationGeometry = slice.actions.updateAnnotationGeometry;
