import * as THREE from 'three';
import type { Instance } from '@giro3d/giro3d/core';
import CameraControls from 'camera-controls';
import Controls from './Controls';

const tmpvec3 = new THREE.Vector3();
const keys = { LEFT: 'ArrowLeft', UP: 'ArrowUp', RIGHT: 'ArrowRight', BOTTOM: 'ArrowDown' };

CameraControls.install({ THREE });

/**
 * Object to handle camera in the view
 */
class MinimapControls extends Controls {
    /**
     * Constructor
     * @param instance Giro3D Instance object
     * @param getPointAt Callback to get the point coordinates from a mouse event
     * @param getBoundingBox Callback to get the bounding box to look at
     */
    constructor(
        instance: Instance,
        getPointAt: (e: THREE.Vector2 | MouseEvent) => { point: THREE.Vector3 },
        getBoundingBox: () => THREE.Box3 | null
    ) {
        super(instance, getPointAt, getBoundingBox);

        this.cameraControls.minZoom = 0; // Unlimited zoom

        // The following is a unqiue 'mode' and replaces setMode

        // Do some clean-up
        this._unregisterEventListeners();

        // Register new interactions
        // We try to use native camera-controls interactions, but we have to re-implement some
        // (keyboard, monkey-patching for mousewheel, etc.)
        this.cameraControls.smoothTime = 0.25;
        this.cameraControls.draggingSmoothTime = 0.01; // Setting this to 0 sometimes traps the camera

        this.cameraControls.mouseButtons.left = CameraControls.ACTION.TRUCK;
        this.cameraControls.mouseButtons.right = CameraControls.ACTION.NONE;
        this.cameraControls.mouseButtons.wheel = CameraControls.ACTION.ZOOM;
        this.cameraControls.mouseButtons.middle = CameraControls.ACTION.ZOOM;

        this._viewportEventsHandlers.wheel = this.onWheel.bind(this);
        this._viewportEventsHandlers.keydown = this.onKeyDownPan.bind(this);

        this._registerEventListeners();
    }

    /**
     * Sets mode for the controls
     * Disabled as minimap cannot change controls
     */
    // eslint-disable-next-line class-methods-use-this, @typescript-eslint/no-empty-function
    setMode() {}

    /**
     * Event handler to handle Panning on key stroke
     * @param e Event
     */
    onKeyDownPan(e: KeyboardEvent) {
        const factor = this.cameraControls.truckSpeed * (this.cameraControls.distance / 300);

        let forwardDirection = 0;
        let truckDirectionX = 0;
        switch (e.code) {
            case keys.UP:
                forwardDirection = 1;
                break;

            case keys.BOTTOM:
                forwardDirection = -1;
                break;

            case keys.LEFT:
                truckDirectionX = -1;
                break;

            case keys.RIGHT:
                truckDirectionX = 1;
                break;

            default:
            // do nothing
        }
        if (forwardDirection) this._exec(() => this.cameraControls.forward(forwardDirection * factor, true));
        if (truckDirectionX) this._exec(() => this.cameraControls.truck(truckDirectionX * factor, 0, true));
    }

    /**
     * Moves camera to look at a specific object.
     * If Orbit mode, that position becomes the new pivot point
     * @param {THREE.Vector3} lookAt Position to look at
     * @param {boolean} enableTransition Enables transition
     */
    moveTo(lookAt: THREE.Vector3, enableTransition = false) {
        const bbox = this.getBoundingBox();

        if (!bbox) {
            return;
        }

        this._exec(() => {
            this.setInteractionPoint(lookAt);
            return this.cameraControls.moveTo(
                lookAt.x,
                lookAt.y,
                bbox.max.z,
                enableTransition && this._canTransition()
            );
        });
    }

    /**
     * Place camera to look at a specific object
     * @param {Vector3} position
     * @param {Vector3} lookAt
     * @param {boolean} enableTransition
     * @returns {Promise<void>}
     */
    lookAt(position: THREE.Vector3, lookAt: THREE.Vector3, enableTransition = false) {
        const bbox = this.getBoundingBox();

        if (!bbox) {
            return;
        }

        this.cameraControls.setLookAt(
            position.x,
            position.y,
            bbox.max.z,
            lookAt.x,
            lookAt.y,
            lookAt.z,
            enableTransition && this._canTransition()
        );
        this.clock.getDelta();
        this.cameraControls.dispatchEvent({ type: 'update' });
    }

    /**
     * Place camera to look at the whole dataset from a direction
     * @param {boolean} enableTransition
     */
    lookFromTop(enableTransition = false) {
        const transition = enableTransition && this._canTransition();

        const bbox = this.getBoundingBox();

        if (!bbox) {
            return;
        }
        const center = bbox.getCenter(tmpvec3);

        // Since the minimap camera is an orthographic camera, this will have no effect
        // on the field of view of the camera (which do not depend on the camera distance to the
        // subject but rather on the dimensions of the camera frustum).
        // However, we need to set it to non-zero to ensure that this camera does not clip any
        // object above sea level.
        const CAMERA_HEIGHT = 1000;

        this.cameraControls.setLookAt(
            center.x,
            center.y,
            Math.max(bbox.max.z, 0) + CAMERA_HEIGHT,
            center.x,
            center.y,
            center.z,
            transition
        );

        this.cameraControls.zoomTo(
            1 / Math.max((bbox.max.x - bbox.min.x) * 0.55, (bbox.max.y - bbox.min.y) * 0.7),
            transition
        );

        this.clock.getDelta();
        this.cameraControls.dispatchEvent({ type: 'update' });
    }
}

export default MinimapControls;
