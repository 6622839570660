import React, { useState } from 'react';
import ReactSelect, { components } from 'react-select';
import * as ReactDOM from 'react-dom';
import { Button } from 'reactstrap';

const CheckboxOption = (props) => (
    <components.Option {...props}>
        {props.isSelected ? (
            <i className="fal fa-square-check multiselect-checkbox checked" />
        ) : (
            <i className="fal fa-square multiselect-checkbox" />
        )}
        {props.data.label}
    </components.Option>
);

const MultiValueCounter = (props) => {
    if (props.index !== 0) return null;
    const { length } = props.getValue();
    return (
        <div className="option-label">{`${props.selectProps.multiValueCounterMessage}${
            length === 1 ? '' : 's'
        } (${length})`}</div>
    );
};

const DropdownIndicator = (props) => (
    <components.DropdownIndicator {...props}>
        <i className="fal fa-angle-down" />
    </components.DropdownIndicator>
);

const CustomGroup = (open, setOpen, submenuId) => (props) => {
    const selected = props.options.filter((option) => option.isSelected).length;
    const total = props.options.length;
    let checkboxClass = '';

    if (selected === 0) checkboxClass = 'fa-square';
    else if (total === selected) checkboxClass = 'fa-square-check checked';
    else checkboxClass = 'fa-square-minus checked';

    const groupToggle = () => {
        const full = total === selected;
        const currentValues = props.getValue();
        if (full) {
            props.setValue(currentValues.filter((value) => !props.options.map((v) => v.value).includes(value.value)));
        } else {
            props.setValue(
                currentValues.concat(props.options.filter((v) => !currentValues.map((x) => x.value).includes(v.value)))
            );
        }
    };

    return (
        <components.Group {...props}>
            <div className="multiselect-group-option">
                <Button type="button" className="borderless multiselect-group-option-checkbox" onClick={groupToggle}>
                    <i className={`fal multiselect-checkbox ${checkboxClass}`} />
                </Button>
                <Button
                    type="button"
                    className={`borderless multiselect-group-option-text ${open === props.data.label ? 'open' : ''} ${
                        selected !== 0 ? 'selections' : ''
                    }`}
                    onClick={() => setOpen(open === props.data.label ? null : props.data.label)}
                >
                    {props.data.label}
                    <i
                        className={`multiselect-group-option-arrow fal ${
                            open === props.data.label ? 'fa-angles-right' : 'fa-angle-right'
                        }`}
                    />
                </Button>
            </div>
            {open === props.data.label
                ? ReactDOM.createPortal(props.children, document.getElementById(submenuId))
                : null}
        </components.Group>
    );
};

const GroupedMultiSelect = ({ multiValueCounterMessage = 'Selected item', ...rest }) => {
    const [open, setOpen] = useState();

    return (
        <>
            <ReactSelect
                className="multiselect"
                isMulti
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                components={{
                    Option: CheckboxOption,
                    DropdownIndicator,
                    MultiValue: MultiValueCounter,
                    Group: CustomGroup(open, setOpen, `${rest.id}-submenu`),
                    GroupHeading: () => null,
                }}
                unstyled
                classNames={{
                    control: () => 'multiselect-control',
                    input: () => 'multiselect-input',
                    dropdownIndicator: () => 'multiselect-dropdown-indicator',
                    menu: () => 'multiselect-menu',
                    menuList: () => 'multiselect-menu-list',
                    option: (state) => `multiselect-option ${state.isSelected ? 'selected' : ''}`,
                }}
                multiValueCounterMessage={multiValueCounterMessage}
                onMenuClose={() => setOpen()}
                {...rest}
            />
            <div className="multiselect-submenu-container">
                <div id={`${rest.id}-submenu`} className={`multiselect-submenu ${open ? 'open' : ''}`} />
            </div>
        </>
    );
};

export default GroupedMultiSelect;
