import { ColorLayer, ElevationLayer, LayerUserData } from '@giro3d/giro3d/core/layer';
import { Entity3D, Entity3DEventMap } from '@giro3d/giro3d/entities';
import type { Geometry } from 'geojson';
import { SSDM_CLASSIFICATION_TYPES, SSDM_GEOMETRY_TYPE } from 'services/Constants';
import { Box3, Color, Plane, Vector2, Vector3 } from 'three';

export type UUID = string;

export type Url = string;

export type Float = number;

export type Integer = number;

export type ZoomFactor = 1 | -1;

/**
 * Spatial Reference Identifier (SRID).
 * https://en.wikipedia.org/wiki/Spatial_reference_system#Identifiers
 */
export type SRID = Integer;

/**
 * ISO UTC timestamp.
 */
export type Timestamp = string;

/**
 * A number in the [0, 1] range. Useful to express percentages, opacity, etc.
 */
export type Normalized = number;

export type Permissions = Record<string, boolean>;

export type CrsDefinition = {
    crs: {
        type: string;
        properties: {
            name: string;
        };
    };
};

export type ProjDefinition = [string, string];

export type SsdmType = {
    name: string;
    display_name: string;
    parent_type: keyof typeof SSDM_CLASSIFICATION_TYPES;
    geom_type: keyof typeof SSDM_GEOMETRY_TYPE;
};

export type SourceFileId = UUID;

export type DatasetId = UUID;

export type DatasetGroupId = UUID;

export type ProjectId = UUID;

export type ViewId = UUID;

export type OLFeatureId = string | number;

export type QueryParameters = Record<string, string>;

export type AttributeName = string;

export type AttributeId = string;

export type ScopeLayerUserData = LayerUserData & {
    datasetId: DatasetId;
    sourceFileId: SourceFileId;
};

export type ScopeEntityUserData = {
    datasetId: DatasetId;
    sourceFileId: SourceFileId;
};

export type ScopeEntity = Entity3D<Entity3DEventMap, ScopeEntityUserData>;

export type ScopeColorLayer = ColorLayer<ScopeLayerUserData>;
export type ScopeElevationLayer = ElevationLayer<ScopeLayerUserData>;

/**
 * Adds CRS properties to a GeoJSON geometry.
 * Important note: this makes the object invalid GeoJSON, as the specificiation does not
 * support any other CRS than WGS84, as per https://www.rfc-editor.org/rfc/rfc7946#section-4
 */
export type GeometryWithCRS<T extends Geometry = Geometry> = T & CrsDefinition;

export function isGeometryWithCRS(obj: Geometry | GeometryWithCRS): obj is GeometryWithCRS {
    if (!obj) {
        return false;
    }

    return (obj as GeometryWithCRS).crs !== undefined;
}

export enum Unit {
    None = '',
    Meter = 'm',
    mV = 'mV',
    dBm = 'dBm',
    degrees = '°',
}

/**
 * A TypeScript [mapped type](https://www.typescriptlang.org/docs/handbook/2/mapped-types.html) that makes all properties of T optional.
 */
export type Optional<T> = {
    [Property in keyof T]+?: T[Property];
};

export type SerializableVector2 = [number, number];

export function toVector2(src: SerializableVector2): Vector2 {
    if (src == null) {
        return null;
    }
    const result = new Vector2();

    return result.set(src[0], src[1]);
}

export function toVector2Array(src: SerializableVector2[]): Vector2[] {
    if (src == null) {
        return null;
    }
    return src.map((x) => toVector2(x));
}

export function fromVector2(src: Vector2): SerializableVector2 {
    if (src == null) {
        return null;
    }

    return [src.x, src.y];
}

export function fromVector2Array(src: Vector2[]): SerializableVector2[] {
    if (src == null) {
        return null;
    }

    return src.map((x) => fromVector2(x));
}

export type SerializableVector3 = [number, number, number];

export function toVector3(src: SerializableVector3): Vector3 {
    if (src == null) {
        return null;
    }
    const result = new Vector3();

    return result.set(src[0], src[1], src[2]);
}

export function fromVector3(src: Vector3): SerializableVector3 {
    if (src == null) {
        return null;
    }

    return [src.x, src.y, src.z];
}

export type SerializableColor = number;

export function fromRgb(r: number, g: number, b: number): SerializableColor {
    return new Color(r, g, b).getHex();
}

export function toHex(src: Color): SerializableColor {
    if (src == null) {
        return null;
    }
    return src.getHex();
}

export function toColor(src: SerializableColor): Color {
    if (src == null) {
        return null;
    }
    const result = new Color(src);

    return result;
}

export type SerializableBox3 = [SerializableVector3, SerializableVector3];

export function fromBox3(box: Box3): SerializableBox3 {
    if (box == null) {
        return null;
    }
    return [fromVector3(box.min), fromVector3(box.max)];
}

export function toBox3(box: SerializableBox3): Box3 {
    if (box == null) {
        return null;
    }
    return new Box3(toVector3(box[0]), toVector3(box[1]));
}

export type SerializablePlane = [SerializableVector3, number];

export function fromPlane(src: Plane): SerializablePlane {
    if (src == null) {
        return null;
    }
    return [fromVector3(src.normal), src.constant];
}

export function toPlane(src: SerializablePlane): Plane {
    if (src == null) {
        return null;
    }
    return new Plane(toVector3(src[0]), src[1]);
}

export type ApiKey = {
    id: UUID;
    prefix: string;
    hash: string;
    user_id: UUID;
    created_at: Timestamp;
};

export type ApiVersion = {
    branch: string;
    hash: string;
    version: string;
};

export type HealthCheckResponse = {
    backend_available: boolean;
    msg: string;
};

export type ProjectLink = {
    project_id: ProjectId;
    project_name: string;
    parameters: Record<string, string>;
};

export enum PlayState {
    Play = 'play',
    Pause = 'pause',
    FastForward = 'fastForward',
    FastFastForward = 'fastFastForward',
    Rewind = 'rewind',
    FastRewind = 'fastRewind',
    Release = 'release',
}
