import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Spinner, Table } from 'reactstrap';

import { Form, Formik } from 'formik';
import * as serializer from 'services/serializer';
import { createView, deleteView, loadView, showError, updateView } from '../../../redux/actions';
import * as datasetsSlice from '../../../redux/datasets';
import DosApi from '../../../services/DosApi';
import { getProjectUsers } from '../../../redux/selectors';
import InlineDropMenu from '../../InlineDropMenu';
import BaseField from '../../forms/BaseField';

const ViewActions = ({ view, project, doLoadView, doEditView, doDeleteView, doCopyLink }) => (
    <InlineDropMenu>
        <Button className="borderless light-blue" title="Open View" onClick={doLoadView}>
            <i className="fal fa-eye" />
        </Button>
        <Button className="borderless light-blue" title="Copy View Link" onClick={doCopyLink}>
            <i className="fal fa-link" />
        </Button>
        {project.user_permissions.update_project ? (
            <>
                <Button className="borderless green" title="Edit View" onClick={doEditView}>
                    <i className="fal fa-pen" />
                </Button>
                {project.default_view_id !== view.id ? (
                    <Button className="borderless red" title="Delete View" onClick={doDeleteView}>
                        <i className="fal fa-trash-can" />
                    </Button>
                ) : null}
            </>
        ) : null}
    </InlineDropMenu>
);

const ViewMenu = ({ closeMenu }) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const project = useSelector(datasetsSlice.currentProject);
    const users = useSelector(getProjectUsers);

    const [views, setViews] = useState(null);
    const [creating, setCreating] = useState(false);
    const [editing, setEditing] = useState(null);

    const state = useSelector((s) => s);

    const [copyConfirm, setCopyConfirm] = useState(false);

    const createUrl = (view) => {
        const slug = view.name
            .toString()
            .normalize('NFD') // break accented characters into components
            .replace(/[\u0300-\u036f]/g, '') // remove diacritics
            .toLowerCase()
            .replace(/\s+/g, '-') // spaces to dashes
            .replace(/&/g, '-and-') // ampersand to and
            .replace(/[^\w-]+/g, '') // remove non-words
            .replace(/--+/g, '-') // collapse multiple dashes
            .replace(/^-+/, '') // trim starting dash
            .replace(/-+$/, ''); // trim ending dash;

        // searchParams.toString will encode the / before the slug so we make our own searchParam string
        // We also remove all others search params as we assume this link is only for the view
        return `${window.location.origin + location.pathname}?view=${view.id}/${slug}`;
    };

    const copyUrl = (view) => {
        navigator.clipboard.writeText(createUrl(view));
        setCopyConfirm(true);
        setTimeout(() => setCopyConfirm(false), 2000);
    };

    const sortViews = (unsortedViews) =>
        unsortedViews
            .map((v) => ({ ...v, updated_at: new Date(v.updated_at) }))
            .sort((a, b) => {
                if (a.id === project.default_view_id) return -1;
                if (b.id === project.default_view_id) return 1;
                return b.updated_at - a.updated_at;
            });

    useEffect(() => {
        setViews(null);
        DosApi.fetchViews(project.id)
            .then((_views) => setViews(sortViews(_views)))
            .catch((err) => showError(dispatch, err));
    }, []);

    const getUsername = (id) => {
        if (users) {
            const match = users.filter((user) => user.id === id);
            if (match.length === 1) return `${match[0].given_name} ${match[0].family_name}`;
        }
        return 'Unknown User';
    };

    if (views === null)
        return (
            <>
                <ModalHeader toggle={closeMenu}>Project Views</ModalHeader>
                <ModalBody>
                    <Spinner animation="border" />
                </ModalBody>
            </>
        );

    if (editing)
        return (
            <>
                <ModalHeader toggle={closeMenu}>Editing {editing.name}</ModalHeader>
                <Formik
                    initialValues={{ name: editing.name, overwrite_view: false }}
                    onSubmit={(values) => {
                        values.id = editing.id;
                        values.project_id = project.id;
                        if (values.overwrite_view) values.view = serializer.serialize(state);
                        dispatch(updateView(values)).then((newView) => {
                            setViews(sortViews([...views.filter((v) => v.id !== newView.id), newView]));
                            setEditing(null);
                        });
                    }}
                    validate={(values) => {
                        const errors = {};
                        if (!values.name) errors.title = 'Required';
                        return errors;
                    }}
                    enableReinitialize
                >
                    {({ isSubmitting }) => (
                        <Form>
                            <ModalBody>
                                <BaseField name="name" label="View name" type="text" required="required" />
                                <BaseField
                                    name="overwrite_view"
                                    label="Overwrite view with current settings"
                                    type="checkbox"
                                />
                            </ModalBody>
                            <ModalFooter>
                                <Button
                                    type="button"
                                    color="warning"
                                    id="cancel-update"
                                    onClick={() => setEditing(null)}
                                >
                                    Abort
                                </Button>
                                <Button type="submit" color="primary" id="complete-update" disabled={isSubmitting}>
                                    Update
                                </Button>
                            </ModalFooter>
                        </Form>
                    )}
                </Formik>
            </>
        );

    if (creating)
        return (
            <>
                <ModalHeader toggle={closeMenu}>Creating View</ModalHeader>
                <Formik
                    initialValues={{ name: '' }}
                    onSubmit={(values) => {
                        values.project_id = project.id;
                        values.view = serializer.serialize(state);
                        dispatch(createView(values)).then((newView) => {
                            setViews(sortViews([...views, newView]));
                            copyUrl(newView);
                            setCreating(false);
                        });
                    }}
                    validate={(values) => {
                        const errors = {};
                        if (!values.name) errors.title = 'Required';
                        return errors;
                    }}
                    enableReinitialize
                >
                    {({ isSubmitting }) => (
                        <Form>
                            <ModalBody>
                                <BaseField name="name" label="View name" type="text" required="required" />
                            </ModalBody>
                            <ModalFooter>
                                <Button
                                    type="button"
                                    color="warning"
                                    id="cancel-update"
                                    onClick={() => setEditing(null)}
                                >
                                    Abort
                                </Button>
                                <Button type="submit" color="primary" id="complete-update" disabled={isSubmitting}>
                                    Create
                                </Button>
                            </ModalFooter>
                        </Form>
                    )}
                </Formik>
            </>
        );

    return (
        <>
            <ModalHeader className="with-button">
                Project Views
                <Button className="circle green" title="Create View" onClick={() => setCreating(true)}>
                    <i className="fal fa-plus" />
                </Button>
            </ModalHeader>
            <ModalBody>
                <Table size="sm" className="project-views-table">
                    <thead>
                        <tr>
                            <th>View</th>
                            <th>Updated at</th>
                            <th>Updated by</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {views.map((view) => (
                            <tr key={view.id}>
                                <td className={view.id === project.default_view_id ? 'default-view' : ''}>
                                    {view.name}
                                </td>
                                <td>
                                    {view.updated_at.getDate()}-{view.updated_at.getMonth()}-
                                    {view.updated_at.getFullYear()}
                                </td>
                                <td>{getUsername(view.updated_by_id)}</td>
                                <td>
                                    <ViewActions
                                        view={view}
                                        project={project}
                                        doLoadView={() =>
                                            dispatch(loadView(view.project_id, view.id, dispatch)).then(closeMenu)
                                        }
                                        doEditView={() => setEditing(view)}
                                        doDeleteView={() =>
                                            dispatch(deleteView(view)).then(() =>
                                                setViews(views.filter((v) => v.id !== view.id))
                                            )
                                        }
                                        doCopyLink={() => copyUrl(view)}
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </ModalBody>
            <ModalFooter>
                <Button onClick={closeMenu}>Close</Button>
            </ModalFooter>
            <Modal isOpen={copyConfirm} keyboard={false} centered className="modal-confirm">
                <ModalHeader />
                <ModalBody>
                    <i className="modal-icon modal-icon-good fal fa-circle-check no-hover" />
                    <span className="big-modal-text">Link copied</span>
                </ModalBody>
                <ModalFooter />
            </Modal>
        </>
    );
};

export default ViewMenu;
