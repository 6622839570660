import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import ReactSelect, { components } from 'react-select';
import { Formik, Form } from 'formik';
import { getOrganizationsForCreatingDataset, getProjections, getCollections } from '../../redux/selectors';
import handleApiError from '../../services/Forms';

import { fetchProjections, updateDataset } from '../../redux/actions';
import { supportedDataTypes, supportedDataTypesLabels, supportedTypesLabels } from '../../services/Labels';
import HelpPanel from '../forms/HelpPanel';

const EditDataset = ({ onClose, openDataset }) => {
    const dispatch = useDispatch();
    const organizations = useSelector(getOrganizationsForCreatingDataset);
    const collections = useSelector(getCollections);
    const projections = useSelector(getProjections);

    if (projections === null) dispatch(fetchProjections());

    const initialFormState = {
        id: openDataset.id,
        type: openDataset.type,
        datatype: openDataset.datatype,
        name: openDataset.name,
        collection_id: openDataset.collection_id,
        projection: openDataset.projection,
    };

    const [currentFormState, setCurrentFormState] = useState(initialFormState);
    useEffect(() => {
        setCurrentFormState(initialFormState);
    }, [organizations]);

    const selectStyling = {
        components: {
            DropdownIndicator: (props) => (
                <components.DropdownIndicator {...props}>
                    <i className="fal fa-angle-down" />
                </components.DropdownIndicator>
            ),
            IndicatorSeparator: () => null,
        },
        classNames: {
            control: () => 'full-form-control',
            valueContainer: () => 'full-form-value-container',
            input: () => 'full-form-input',
            dropdownIndicator: () => 'full-form-dropdown-indicator-container',
            option: () => 'full-form-option',
        },
    };

    const validation = (values) => {
        const errors = {};
        if (!values.name) errors.name = 'Required';
        if (!values.type) errors.type = 'Required';
        if (!values.datatype) errors.datatype = 'Required';
        if (!values.projection) errors.projection = 'Required';
        return errors;
    };

    const editDataset = (values, helpers) => {
        if (values.collection_id === '') values.collection_id = null;
        const formData = new FormData();
        formData.append('update', JSON.stringify(values));

        dispatch(updateDataset(values.id, formData))
            .then(onClose())
            .catch((err) => {
                handleApiError(err, helpers);
            });
    };

    return (
        <Formik
            enableReinitialize
            initialValues={currentFormState}
            onSubmit={editDataset}
            validate={validation}
            validateOnBlur={false}
            validateOnChange={false}
        >
            {({ values, errors, isSubmitting, handleBlur, handleChange, setFieldValue }) => (
                <Form>
                    <table>
                        <tbody>
                            <tr>
                                <td colSpan={2}>
                                    <HelpPanel>
                                        Changing the projection will cause the dataset to be reprocessed. This will
                                        delete the existing data processed by SCOPE.
                                    </HelpPanel>
                                </td>
                            </tr>
                            <tr className={errors.type ? 'error' : ''}>
                                <td>Type:</td>
                                <td>
                                    <ReactSelect
                                        name="type"
                                        {...selectStyling}
                                        placeholder="Select... (Required)"
                                        options={[
                                            ...Object.keys(supportedTypesLabels).map((e) => ({
                                                value: e,
                                                label: supportedTypesLabels[e],
                                            })),
                                        ]}
                                        defaultValue={{ value: values.type, label: supportedTypesLabels[values.type] }}
                                        onBlur={handleBlur}
                                        onChange={(data) => setFieldValue('type', data.value)}
                                    />
                                    <span className="error-text">{errors.type}</span>
                                </td>
                            </tr>
                            <tr className={errors.datatype ? 'error' : ''}>
                                <td>Datatype:</td>
                                <td>
                                    <ReactSelect
                                        name="datatype"
                                        {...selectStyling}
                                        placeholder={`Select... (${
                                            values.type === '' ? 'Select type first' : 'Required'
                                        })`}
                                        isDisabled={values.type === ''}
                                        options={
                                            values.type !== ''
                                                ? [
                                                      ...supportedDataTypes[values.type].map((e) => ({
                                                          value: e,
                                                          label: supportedDataTypesLabels[e],
                                                      })),
                                                  ]
                                                : []
                                        }
                                        defaultValue={{
                                            value: values.datatype,
                                            label: supportedDataTypesLabels[values.datatype],
                                        }}
                                        onBlur={handleBlur}
                                        onChange={(data) => setFieldValue('datatype', data.value)}
                                    />
                                    <span className="error-text">{errors.datatype}</span>
                                </td>
                            </tr>
                            <tr className={errors.name ? 'error' : ''}>
                                <td>Name:</td>
                                <td>
                                    <input
                                        name="name"
                                        type="text"
                                        placeholder="Enter name... (Required)"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        defaultValue={values.name}
                                    />
                                    <span className="error-text">{errors.name}</span>
                                </td>
                            </tr>
                            <tr className={errors.projection ? 'error' : ''}>
                                <td>Projection:</td>
                                <td>
                                    <ReactSelect
                                        name="projection"
                                        {...selectStyling}
                                        placeholder="Select... (Required)"
                                        options={
                                            projections
                                                ? projections.map((e) => ({
                                                      value: e.srid,
                                                      label: e.title,
                                                  }))
                                                : []
                                        }
                                        onBlur={handleBlur}
                                        onChange={(data) => setFieldValue('projection', data.value)}
                                        defaultValue={
                                            projections
                                                ? {
                                                      value: projections.find(
                                                          (e) => e.srid === String(values.projection)
                                                      ).srid,
                                                      label: projections.find(
                                                          (e) => e.srid === String(values.projection)
                                                      ).title,
                                                  }
                                                : {}
                                        }
                                    />
                                    <span className="error-text">{errors.projection}</span>
                                </td>
                            </tr>
                            <tr className={errors.collection_id ? 'error' : ''}>
                                <td>Collection:</td>
                                <td>
                                    <ReactSelect
                                        name="collection"
                                        {...selectStyling}
                                        placeholder="Select..."
                                        options={[
                                            ...collections
                                                .filter(
                                                    (collection) =>
                                                        collection.user_permissions.update_collection &&
                                                        collection.organization_id === openDataset.organization_id
                                                )
                                                .map((e) => ({
                                                    value: e.id,
                                                    label: e.name,
                                                })),
                                        ]}
                                        onBlur={handleBlur}
                                        onChange={(data) => setFieldValue('collection_id', data?.value || null)}
                                        defaultValue={
                                            openDataset.collection_id
                                                ? {
                                                      value: openDataset.collection_id,
                                                      label:
                                                          collections?.find(
                                                              (collection) =>
                                                                  collection.id === openDataset.collection_id
                                                          )?.name || 'Loading',
                                                  }
                                                : null
                                        }
                                        isClearable
                                    />
                                    <span className="error-text">{errors.collection_id}</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <span className="error-text">{errors.submit}</span>
                    <Button type="submit" color="primary" disabled={isSubmitting}>
                        Apply <i className={`fal fa-${isSubmitting ? 'spinner fa-pulse' : 'arrow-right'}`} />
                    </Button>
                </Form>
            )}
        </Formik>
    );
};

export default EditDataset;
