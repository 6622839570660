// eslint-disable-next-line import/no-cycle
import { NAV_PAGE, NAV_LEFT_TAB, NAV_RIGHT_TAB } from '../actionTypes';

const initialState = {
    page: undefined,
    leftTab: undefined,
    rightTab: undefined,
};

export default function reducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case NAV_PAGE:
            return {
                ...state,
                page: payload,
            };
        case NAV_LEFT_TAB:
            return {
                ...state,
                leftTab: payload,
            };
        case NAV_RIGHT_TAB:
            return {
                ...state,
                rightTab: payload,
            };
        default:
            return state;
    }
}
