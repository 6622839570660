// React
import React from 'react';
import { useSelector } from 'react-redux';
import { getOverviewMapResolution } from '../../../redux/selectors';

const LayerLegend = (props) => {
    const { layer } = props;
    const resolution = useSelector(getOverviewMapResolution);

    const legendImage = () => {
        const url = layer.getSource().getLegendUrl
            ? layer.getSource().getLegendUrl(resolution, { 'LAYER': layer.get('layerId') })
            : null;
        if (url) {
            return <img src={url} alt="Legend" crossOrigin="anonymous" />;
        }
        return <span>No legend available</span>;
    };

    return (
        <div className="input-group">
            <span className="col-md-5">Legend:</span>
            <span>{legendImage()}</span>
        </div>
    );
};

export default LayerLegend;
