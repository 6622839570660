interface Props {
    id: string;
    checked: boolean;
    disabled?: boolean;
    onChange: React.ChangeEventHandler<HTMLInputElement>;
}

const ToggleSwitch = (props: Props) => (
    <label className="toggle-switch" htmlFor={props.id}>
        <input type="checkbox" {...props} title={props.id} />
        <span className="toggle-slider" />
    </label>
);

export default ToggleSwitch;
