import React from 'react';
import { COLLECTION_STATES } from '../../../services/Constants';

const CollectionListItemStatus = ({ collection }) => {
    switch (collection.olstate) {
        case COLLECTION_STATES.LOADING:
            return <p>Collection is currently being loaded into the view.</p>;
        case COLLECTION_STATES.LOADING_FAILED:
            return <p>{`Loading data has failed: ${collection.olstate_msg}`}</p>;
        default:
            return <p>Unknown state</p>;
    }
};

export default CollectionListItemStatus;
