// React
import React, { useState } from 'react';
import { getUid } from 'ol/util';
import { Button, Popover, PopoverBody, PopoverHeader } from 'reactstrap';
import LayerItemControls from './LayerItemControls';
import LayerLegend from './LayerLegend';
import ToggleSwitch from '../../ToggleSwitch';

const LayerItem = (props) => {
    const { layer, groupOpen } = props;
    const key = getUid(layer);
    const itemName = layer.get('title');

    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => {
        setIsOpen(!isOpen);
    };
    const [visible, setVisible] = useState(layer.getVisible());
    const onVisibilityChange = () => {
        setVisible(!visible);
        layer.setVisible(!layer.getVisible());
    };

    return (
        <div className="data-item" key={`layer-overview-row-${key}`} hidden={!groupOpen}>
            <Button className="borderless indicator" disabled title={`${layer.name}-state`}>
                <i />
            </Button>
            <span id={`layerItem-${key}`}>{itemName}</span>
            <ToggleSwitch id={`${key}-visible`} checked={visible} onChange={onVisibilityChange} />
            <Popover placement="right" isOpen={isOpen} target={`layerItem-${key}`} toggle={toggle} trigger="legacy">
                <PopoverHeader>{itemName} settings</PopoverHeader>
                <PopoverBody>
                    <LayerItemControls layer={layer} key={`overlay-${getUid(layer)}-item`} />
                    <LayerLegend layer={layer} />
                </PopoverBody>
            </Popover>
        </div>
    );
};

export default LayerItem;
