import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { shallowEqual } from 'react-redux';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, UncontrolledTooltip } from 'reactstrap';
import { useAppDispatch, useAppSelector } from 'store';
import { DatasetId, SsdmType } from 'types/common';
import Annotation, { ObservationType } from 'types/Annotation';
import Dataset from 'types/Dataset';
import { GEOMETRY_TYPE } from 'giro3d_extensions/DrawTool';
import {
    createAnnotation,
    relateAnnotationDatasets,
    setAnnotationDatasets,
    startAnnotationDrawing,
    stopAnnotationCreation,
} from 'redux/actions';
import { getSSDMTypes } from 'redux/selectors';

import * as giro3d from 'redux/giro3d';
import * as datasetsSlice from 'redux/datasets';
import * as drawToolSlice from 'redux/drawTool';
import * as annotationsSlice from 'redux/annotations';

import { ANNOTATION_CREATESTATE, DRAWN_TOOL, SSDM_CLASSIFICATION_TYPES } from 'services/Constants';

import Multiselect from '../../forms/Multiselect';
import GeometryInfo from './GeometryInfo';
import TypeaheadField from '../../forms/TypeaheadField';
import BaseField from '../../forms/BaseField';
import { isVisible } from './EditAnnotation';
import DisabledDrawTool from '../DisabledDrawTool';

const CreateAnnotation = () => {
    const dispatch = useAppDispatch();

    const project = useAppSelector(datasetsSlice.currentProject);
    const createState = useAppSelector(annotationsSlice.createState);
    const SSDMTypes = useAppSelector(getSSDMTypes);
    const datasets = useAppSelector<Dataset[]>(datasetsSlice.getRenderableDatasets, shallowEqual);
    const rev = useAppSelector(giro3d.getRevision);
    const geometryType = useAppSelector(drawToolSlice.getType);
    const currentTool = useAppSelector(drawToolSlice.getTool);

    const [datasetOptions, setDatasetOptions] = useState([]);

    const classificationTypeOptions = [];
    const classificationOptions = [];

    Object.keys(SSDM_CLASSIFICATION_TYPES).forEach((type) => {
        if (
            (type.includes('SYMBOL_POINT') && geometryType === GEOMETRY_TYPE.POINT) ||
            (type.includes('SYMBOL_LINE') && geometryType === GEOMETRY_TYPE.LINE) ||
            (type.includes('SYMBOL_POLYGON') && geometryType === GEOMETRY_TYPE.POLYGON)
        )
            classificationTypeOptions.push({ title: SSDM_CLASSIFICATION_TYPES[type], value: type });
        classificationOptions.push([]);
    });

    SSDMTypes.forEach((SSDM: SsdmType) => {
        classificationOptions[Object.keys(SSDM_CLASSIFICATION_TYPES).indexOf(SSDM.parent_type)].push({
            title: `${SSDM.name} ${SSDM.display_name}`,
            value: SSDM.name,
        });
    });

    const cancelDraw = () => {
        dispatch(stopAnnotationCreation());
    };

    const updateOptions = (choice: { value: DatasetId }[]) => {
        setDatasetOptions(
            datasets
                .filter((dataset) => choice.some((option) => option.value === dataset.id) || isVisible(dataset.id))
                .map((dataset) => ({
                    label: dataset.name,
                    value: dataset.id,
                    faded: !isVisible(dataset.id),
                }))
        );
    };

    switch (createState) {
        case ANNOTATION_CREATESTATE.SELECT:
            return !currentTool || currentTool === DRAWN_TOOL.ANNOTATION ? (
                <>
                    <p>Select a geometry type:</p>
                    <table className="geometry-select">
                        <tbody>
                            <tr>
                                <td>
                                    <Button onClick={() => dispatch(startAnnotationDrawing(GEOMETRY_TYPE.POINT))}>
                                        <i className="fal fa-period" />
                                        POINT
                                    </Button>
                                </td>
                                <td>
                                    <Button onClick={() => dispatch(startAnnotationDrawing(GEOMETRY_TYPE.LINE))}>
                                        <i className="fal fa-dash" />
                                        LINE
                                    </Button>
                                </td>
                                <td>
                                    <Button onClick={() => dispatch(startAnnotationDrawing(GEOMETRY_TYPE.POLYGON))}>
                                        <i className="fal fa-draw-polygon" />
                                        POLYGON
                                    </Button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <hr />
                    <div className="sideform-buttons">
                        <Button color="warning" id="cancel-drawing" onClick={cancelDraw}>
                            ABORT
                        </Button>
                    </div>
                </>
            ) : (
                <DisabledDrawTool />
            );
        case ANNOTATION_CREATESTATE.DRAWING:
            return !currentTool || currentTool === DRAWN_TOOL.ANNOTATION ? (
                <>
                    <p>
                        {geometryType === GEOMETRY_TYPE.POINT ? 'Left click on the map to create a point.' : null}
                        {geometryType === GEOMETRY_TYPE.LINE
                            ? 'Left click on the map to create a line. Right click to finish the line. Hold space to enable splicing mode.'
                            : null}
                        {geometryType === GEOMETRY_TYPE.POLYGON
                            ? 'Left click on the map to create a polygon. Right click or click on the first point to close the polygon. Hold space to enable splicing mode.'
                            : null}
                    </p>
                    <GeometryInfo />
                    <hr />
                    <div className="sideform-buttons">
                        <Button color="warning" id="cancel-drawing" onClick={cancelDraw}>
                            ABORT
                        </Button>
                    </div>
                </>
            ) : (
                <DisabledDrawTool />
            );
        case ANNOTATION_CREATESTATE.DETAILS:
        case ANNOTATION_CREATESTATE.SUMBIT:
        case ANNOTATION_CREATESTATE.COMPLETE:
            return (
                <Formik
                    initialValues={{
                        name: '',
                        description: '',
                        type: '',
                        observationType: '' as ObservationType,
                        classificationCategory: [],
                        classificationType: [],
                        datasets: [],
                    }}
                    validateOnChange={false}
                    validateOnBlur={false}
                    validate={(values) => {
                        const errors: {
                            name?: string;
                            type?: string;
                            observationType?: string;
                            classificationType?: string;
                            classificationCategory?: string;
                        } = {};
                        if (!values.name) errors.name = 'Required';
                        if (!values.type) errors.type = 'Required';
                        if (values.type === 'observation' && !values.observationType)
                            errors.observationType = 'Required';
                        if (values.type === 'object' && values.classificationCategory.length === 0)
                            errors.classificationCategory = 'Required';
                        if (
                            values.type === 'object' &&
                            values.classificationCategory.length !== 0 &&
                            values.classificationType.length === 0
                        )
                            errors.classificationType = 'Required';
                        return errors;
                    }}
                    onSubmit={(values) => {
                        const annotation: Partial<Annotation> = {
                            name: values.name,
                            description: values.description,
                            project_id: project.id,
                            observation_type:
                                values.type === 'observation' ? (values.observationType as ObservationType) : null,
                            ssdm_type: values.type !== 'observation' ? values.classificationType[0].value : null,
                        };

                        const datasetIds = values.datasets.map((dataset) => dataset.value);

                        dispatch(createAnnotation(annotation))
                            .then((data) => {
                                const createdAnnotation = data.payload;
                                relateAnnotationDatasets(createdAnnotation, datasetIds);
                                dispatch(setAnnotationDatasets(createdAnnotation.id, datasetIds));
                            })
                            .then(cancelDraw);
                    }}
                >
                    {({ isSubmitting, values, setFieldValue, handleChange, handleBlur, errors }) => (
                        <Form className="sideform">
                            {useEffect(() => updateOptions(values.datasets), [rev])}
                            <BaseField name="name" placeholder="Title of annotation" type="text" required="required" />
                            <hr className="sideform-bar" />
                            <div className="custom-radio">
                                <label className="text-yellow flex-row" htmlFor="observation">
                                    <input
                                        type="radio"
                                        id="observation"
                                        value="observation"
                                        name="type"
                                        className="radio"
                                        checked={values.type === 'observation'}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    <span className="flex-row grow">Observation</span>
                                    <i className="fal fa-circle-question auto-center-v" id="obsHelp" />
                                    <UncontrolledTooltip placement="right" target="obsHelp">
                                        <span className="submenu-tooltip">
                                            An observation is used to annotate parts of the data that are unidentified
                                            or erroneous.
                                        </span>
                                    </UncontrolledTooltip>
                                </label>
                                <label className="text-green flex-row" htmlFor="object">
                                    <input
                                        type="radio"
                                        id="object"
                                        value="object"
                                        name="type"
                                        className="radio"
                                        checked={values.type === 'object'}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    <span className="grow">Object</span>
                                    <i className="fal fa-circle-question auto-center-v" id="objHelp" />
                                    <UncontrolledTooltip placement="right" target="objHelp">
                                        <span className="submenu-tooltip">
                                            An object is something that can be classified by the SSDM standard.
                                        </span>
                                    </UncontrolledTooltip>
                                </label>
                            </div>
                            {errors.type ? <span className="invalid-feedback">{errors.type}</span> : null}
                            {values.type === 'observation' ? (
                                <>
                                    <hr className="sideform-bar" />
                                    <div className="custom-radio">
                                        <label htmlFor="uso" className="text-purple flex-row">
                                            <input
                                                type="radio"
                                                id="uso"
                                                value="uso"
                                                name="observationType"
                                                className="radio"
                                                checked={values.observationType === 'uso'}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            <span className="grow">USO</span>
                                            <i className="fal fa-ufo" />
                                        </label>
                                        <label htmlFor="flaw">
                                            <input
                                                type="radio"
                                                id="flaw"
                                                value="flaw"
                                                name="observationType"
                                                className="radio"
                                                checked={values.observationType === 'flaw'}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            <span className="text-purple grow">Flaw in Dataset</span>
                                        </label>
                                    </div>
                                    {errors.observationType ? (
                                        <span className="invalid-feedback">{errors.observationType}</span>
                                    ) : null}
                                </>
                            ) : null}
                            {values.type === 'object' ? (
                                <>
                                    <hr className="sideform-bar" />
                                    <TypeaheadField
                                        id="classificationCategory"
                                        name="classificationCategory"
                                        options={classificationTypeOptions || []}
                                        placeholder="Select a classification category"
                                        labelKey="title"
                                        setFieldValue={setFieldValue}
                                        selected={values.classificationCategory}
                                        clearButton
                                        onChange={() => setFieldValue('classificationType', [])}
                                    />
                                </>
                            ) : null}
                            {values.type === 'object' && values.classificationCategory.length !== 0 ? (
                                <>
                                    <hr className="sideform-bar" />
                                    <TypeaheadField
                                        id="classificationType"
                                        name="classificationType"
                                        options={
                                            classificationOptions[
                                                Object.keys(SSDM_CLASSIFICATION_TYPES).indexOf(
                                                    values.classificationCategory[0].value
                                                )
                                            ] || []
                                        }
                                        placeholder="Select a classification"
                                        labelKey="title"
                                        setFieldValue={setFieldValue}
                                        selected={values.classificationType}
                                        clearButton
                                    />
                                </>
                            ) : null}
                            <hr className="sideform-bar" />
                            <Multiselect
                                name="datasets"
                                options={datasetOptions}
                                onBlur={handleBlur}
                                onChange={(choice) => {
                                    setFieldValue('datasets', choice);
                                    updateOptions(choice);
                                }}
                                placeholder="Connect datasets"
                                multiValueCounterMessage="Connected dataset"
                                isClearable={false}
                            />
                            <hr className="sideform-bar" />
                            <BaseField name="description" label="Description" type="text" as="textarea" />
                            <hr className="sideform-bar" />
                            <div className="sideform-buttons">
                                <Button type="button" color="warning" id="cancel-creation" onClick={cancelDraw}>
                                    ABORT
                                </Button>
                                <Button
                                    type="submit"
                                    color="primary"
                                    id="complete-creation"
                                    disabled={
                                        isSubmitting ||
                                        createState === ANNOTATION_CREATESTATE.SUMBIT ||
                                        createState === ANNOTATION_CREATESTATE.COMPLETE
                                    }
                                >
                                    SAVE
                                </Button>
                            </div>
                            <Modal
                                centered
                                className="modal-confirm"
                                isOpen={createState === ANNOTATION_CREATESTATE.COMPLETE}
                            >
                                <ModalHeader />
                                <ModalBody>
                                    <i className="modal-icon modal-icon-good fal fa-circle-check no-hover" />
                                    <span className="big-modal-text">Annotation created</span>
                                </ModalBody>
                                <ModalFooter />
                            </Modal>
                        </Form>
                    )}
                </Formik>
            );
        default:
            return <span>The state is {createState}</span>;
    }
};

export default CreateAnnotation;
