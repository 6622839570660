// React
import { MutableRefObject, useEffect, useState } from 'react';
import classnames from 'classnames';
import { Button, Tooltip, UncontrolledTooltip } from 'reactstrap';

// Redux
import { useAppDispatch, useAppSelector } from 'store';
import { CONTROLS_MODE } from 'services/Controls';
import { ZoomFactor, fromVector3 } from 'types/common';
import { useEventBus } from 'EventBus';
import { getService } from 'ServiceContainer';
import * as giro3d from '../../redux/giro3d';

// Components
import ViewButton from './viewMenu/ViewButton';

const Giro3dCameraModes = (props: { viewport: HTMLDivElement }) => {
    const { viewport } = props;
    const picker = getService('Picker');
    const dispatch = useAppDispatch();
    const mode = useAppSelector(giro3d.getControlMode);
    const [isMenuOpen, setMenuOpen] = useState(false);
    const [isHelpDisplayed, setHelpDisplayed] = useState(false);

    const pickPivot = (e: MouseEvent) => {
        const point = picker.pickPoint(e);

        if (point) {
            dispatch(giro3d.setControlsMode(CONTROLS_MODE.ORBIT));
            dispatch(giro3d.setControlsTarget(fromVector3(point)));
            setHelpDisplayed(true);
        }
    };

    const openMenu = () => {
        setMenuOpen(true);
        setHelpDisplayed(false);
    };

    const setMode = (newMode: CONTROLS_MODE) => {
        setMenuOpen(false);
        let actualMode = newMode;
        if (newMode !== CONTROLS_MODE.FOLLOW) dispatch(giro3d.resetFollowCamera());
        if (newMode === CONTROLS_MODE.ORBIT) actualMode = CONTROLS_MODE.DISABLED;
        else if (newMode === CONTROLS_MODE.FOLLOW) {
            dispatch(giro3d.setFollowCameraModal(true));
            return;
        }

        dispatch(giro3d.setControlsMode(actualMode));
    };

    const hideHelp = () => setHelpDisplayed(false);

    useEffect(() => {
        setHelpDisplayed(true);
        const timer = setTimeout(hideHelp, 5000);
        return () => clearTimeout(timer);
    }, [mode]);

    useEffect(() => setHelpDisplayed(false), []);

    useEffect(() => {
        if (mode === CONTROLS_MODE.DISABLED && viewport !== null) {
            viewport.addEventListener('mousedown', pickPivot);
            return () => {
                viewport.removeEventListener('mousedown', pickPivot);
            };
        }
        return null;
    }, [mode]);

    return (
        <div
            className={classnames({
                'map-controls-drop-menu': true,
                'open': isMenuOpen,
            })}
        >
            <Button type="button" onClick={openMenu} className="giro3d-cameras" id="giro3d-cameras">
                <i
                    className={classnames({
                        'fal': true,
                        'fa-swap': mode === CONTROLS_MODE.FOLLOW || mode === CONTROLS_MODE.RELEASED_FOLLOW,
                        'fa-solar-system': mode === CONTROLS_MODE.ORBIT || mode === CONTROLS_MODE.DISABLED,
                        'fa-arrows': mode === CONTROLS_MODE.PAN,
                        'fa-arrows-v': mode === CONTROLS_MODE.DOLLY,
                    })}
                />
            </Button>
            <div className="map-controls-drop-content">
                <Button
                    className={classnames({
                        'giro3d-follow': true,
                        active: mode === CONTROLS_MODE.FOLLOW || mode === CONTROLS_MODE.RELEASED_FOLLOW,
                    })}
                    id="giro3d-follow"
                    type="button"
                    onClick={() => setMode(CONTROLS_MODE.FOLLOW)}
                >
                    <i className="fal fa-swap" />
                    <UncontrolledTooltip placement="top" target="giro3d-follow">
                        Follow
                    </UncontrolledTooltip>
                </Button>
                <Button
                    className={classnames({
                        'giro3d-orbit': true,
                        active: mode === CONTROLS_MODE.ORBIT || mode === CONTROLS_MODE.DISABLED,
                    })}
                    id="giro3d-orbit"
                    type="button"
                    onClick={() => setMode(CONTROLS_MODE.ORBIT)}
                >
                    <i className="fal fa-solar-system" />
                    <UncontrolledTooltip placement="top" target="giro3d-orbit">
                        Orbit
                    </UncontrolledTooltip>
                </Button>
                <Button
                    className={classnames({
                        'giro3d-pan': true,
                        active: mode === CONTROLS_MODE.PAN,
                    })}
                    id="giro3d-pan"
                    type="button"
                    onClick={() => setMode(CONTROLS_MODE.PAN)}
                >
                    <i className="fal fa-arrows" />
                    <UncontrolledTooltip placement="top" target="giro3d-pan">
                        Pan
                    </UncontrolledTooltip>
                </Button>
                <Button
                    className={classnames({
                        'giro3d-dolly': true,
                        active: mode === CONTROLS_MODE.DOLLY,
                    })}
                    id="giro3d-dolly"
                    type="button"
                    onClick={() => setMode(CONTROLS_MODE.DOLLY)}
                >
                    <i className="fal fa-arrows-v" />
                    <UncontrolledTooltip placement="top" target="giro3d-dolly">
                        Dolly
                    </UncontrolledTooltip>
                </Button>
            </div>
            <Tooltip placement="top" isOpen={mode === CONTROLS_MODE.FOLLOW && isHelpDisplayed} target="giro3d-cameras">
                Use the follow menu to control the position
                <br />
                Left and right click: pan around
                <br />
                Scroll: zoom in/out
                <br />
                Space bar: play/pause
            </Tooltip>
            <Tooltip
                placement="top"
                isOpen={mode === CONTROLS_MODE.DISABLED && isHelpDisplayed}
                target="giro3d-cameras"
            >
                Click on the map to set the orbit point
            </Tooltip>
            <Tooltip
                placement="top"
                isOpen={mode === CONTROLS_MODE.ORBIT && isHelpDisplayed}
                target="giro3d-cameras"
                onClick={hideHelp}
                className="giro3d-controls-tooltip"
            >
                Left and right click: orbit around
                <br />
                Scroll: zoom in/out from center
                <br />
                Key arrows: orbit around
            </Tooltip>
            <Tooltip
                placement="top"
                isOpen={(mode === CONTROLS_MODE.PAN || mode === CONTROLS_MODE.RELEASED_FOLLOW) && isHelpDisplayed}
                target="giro3d-cameras"
                onClick={hideHelp}
                className="giro3d-controls-tooltip"
            >
                Left click: pan
                <br />
                Right click: orbit around cursor
                <br />
                Scroll: zoom in/out from cursor
                <br />
                Key arrows: pan
                <br />
                Ctrl+Up/Ctrl+Down: move up/down
            </Tooltip>
            <Tooltip
                placement="top"
                isOpen={mode === CONTROLS_MODE.DOLLY && isHelpDisplayed}
                target="giro3d-cameras"
                onClick={hideHelp}
                className="giro3d-controls-tooltip"
            >
                Left click: move forward/backwards
                <br />
                Right click: orbit around cursor
                <br />
                Scroll: zoom in/out from cursor
                <br />
                Key arrows: pan
                <br />
                Ctrl+Up/Ctrl+Down: move up/down
            </Tooltip>
        </div>
    );
};

const Giro3dZoom = () => {
    const eventBus = useEventBus();

    function doZoom(zoom: ZoomFactor) {
        eventBus.dispatch('zoom-camera', { factor: zoom });
    }

    return (
        <div className="giro3d-zoom">
            <Button className="giro3d-zoom-in" id="giro3d-zoom-in" onClick={() => doZoom(1)}>
                +
                <UncontrolledTooltip placement="top" target="giro3d-zoom-in">
                    Zoom in
                </UncontrolledTooltip>
            </Button>
            <Button className="giro3d-zoom-out" id="giro3d-zoom-out" onClick={() => doZoom(-1)}>
                
                <UncontrolledTooltip placement="top" target="giro3d-zoom-out">
                    Zoom out
                </UncontrolledTooltip>
            </Button>
        </div>
    );
};

const Giro3dControls = (props: { viewport: HTMLDivElement; compassRef: MutableRefObject<null>; hidden: boolean }) => (
    <div className="map-controls map-controls-row no-gap" hidden={props.hidden}>
        <div className="map-controls-row">
            <ViewButton />
            <Giro3dCameraModes viewport={props.viewport} />
        </div>
        <div>
            <div ref={props.compassRef} />
            <Giro3dZoom />
        </div>
    </div>
);

export default Giro3dControls;
