import { useDispatch } from 'react-redux';
import { HasMasks, LayerState } from 'types/LayerState';
import * as layers from 'redux/layers';
import * as datasetsSlice from 'redux/datasets';

import Multiselect from 'components/forms/Multiselect';
import { useAppSelector } from 'store';
import Dataset from 'types/Dataset';

type MaskLayer = LayerState & HasMasks;

export type Props = {
    layerState: MaskLayer;
};

type DisplayableMask = {
    label: string;
    value: string;
};

function toDisplayableMask(layer: MaskLayer, datasets: Dataset[]): DisplayableMask {
    const dataset = datasets.find((d) => d.id === layer.datasetId);
    return {
        label: dataset.name,
        value: layer.datasetId,
    };
}

function fromDisplayableMask(dm: DisplayableMask): string {
    return dm.value;
}

const MaskSettings = (props: Props) => {
    const { layerState } = props;

    const dispatch = useDispatch();
    const datasets = useAppSelector(datasetsSlice.getRenderableDatasets);
    const possibleMasks = useAppSelector(layers.getMaskableLayers);
    const activeMasks = useAppSelector(layers.getActiveMasks(layerState));

    const displayableMasks = () =>
        possibleMasks
            .filter((l) => l.datasetId !== layerState.datasetId) // Don't mask itself
            .map((l) => toDisplayableMask(l, datasets));

    function setMasks(masks: string[]) {
        const uniqueValues = [...new Set(masks)];
        dispatch(layers.setActiveMasks({ layer: layerState, value: uniqueValues }));
    }

    return (
        <div className="row" key={`datasetsettings-${layerState.datasetId}-masks`}>
            <label htmlFor="mask-source" className="col-sm-5 col-form-label">
                <i className="fal fa-mask fa-fw" /> Masks
            </label>
            <div className="col-sm-7">
                <Multiselect
                    name="datasets"
                    options={displayableMasks()}
                    value={activeMasks.map((l) => toDisplayableMask(l, datasets))}
                    onChange={(choice: DisplayableMask[]) => setMasks(choice.map(fromDisplayableMask))}
                    placeholder="Select masks"
                    multiValueCounterMessage="Applied mask"
                    isClearable
                />
            </div>
        </div>
    );
};

export default MaskSettings;
