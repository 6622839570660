// React
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

// Redux
import { getCollections } from '../../../redux/selectors';
import ToggleSwitch from '../../ToggleSwitch';

// Components
import CollectionListItem from './CollectionListItem';

const CollectionsMenu = () => {
    const collections = useSelector(getCollections);
    const [open, setOpen] = useState(false);
    const [visible, setVisible] = useState(true);

    const renderedListItems = collections
        ? collections.map((collection) => (
              <CollectionListItem
                  key={`collection-${collection.id}`}
                  collection={collection}
                  groupOpen={open}
                  groupVisible={visible}
              />
          ))
        : [];

    return (
        <div className="data-group">
            <div className="data-group-header">
                <label htmlFor="collections-dropdown" className="data-group-label dropdown-arrow">
                    <span>Collections</span>
                    <input
                        type="checkbox"
                        id="collections-dropdown"
                        checked={open}
                        onChange={(e) => setOpen(e.target.checked)}
                    />
                    <div className="arrow" />
                </label>
                <ToggleSwitch
                    id="collections-visible"
                    checked={visible}
                    onChange={(e) => setVisible(e.target.checked)}
                />
            </div>
            {renderedListItems}
        </div>
    );
};

export default CollectionsMenu;
