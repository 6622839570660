// React
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { Button } from 'reactstrap';
import { PAGE, RIGHT_TAB } from '../../Navigation';
import {
    selectNotification,
    selectRightTab,
    updateNotificationState,
    selectPage,
    selectLeftTab,
} from '../../redux/actions';
import { getActiveNotification, getUsernames, getPage, getLeftTab } from '../../redux/selectors';
import DosApi from '../../services/DosApi';
import formatMentions from '../../services/MentionFormatting';

const NotificationsListItem = ({ notification }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const currentPage = useSelector(getPage);
    const currentTab = useSelector(getLeftTab);
    const activeNotification = useSelector(getActiveNotification);
    const usernames = useSelector(getUsernames);
    const sender = usernames ? usernames.find((x) => x.id === notification.sender_id) : null;

    const [error, setError] = useState(null);

    const formatTimestamp = (timestamp) => {
        timestamp = new Date(timestamp);
        const months = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'];
        const result = `${timestamp.getDate()} ${months[timestamp.getMonth()]} ${timestamp.getFullYear()} –
        ${timestamp.getHours().toString().padStart(2, '0')}:${timestamp.getMinutes().toString().padStart(2, '0')}`;
        return result;
    };

    const active = activeNotification === notification.id;

    const setActive = () => {
        dispatch(selectNotification(active ? null : notification.id));
    };

    const color = () => {
        switch (notification.type) {
            case 'system_downtime':
                return 'red';
            case 'system_update':
            case 'user_registered_in_organization':
                return 'green';
            default:
                return 'yellow';
        }
    };

    const icon = () => {
        switch (notification.type) {
            case 'system_downtime':
                return 'fa-warning';
            case 'system_update':
            case 'user_registered_in_organization':
                return 'fa-circle-info';
            default:
                return 'fa-message-exclamation';
        }
    };

    const tagline = () => {
        switch (notification.type) {
            case 'system_downtime':
                return 'downtime due to maintenance';
            case 'system_update':
                return 'system update information';
            case 'annotation_comment':
                return 'commented on your annotation';
            case 'annotation_comment_reply':
                return 'replied to your comment';
            case 'annotation_comment_mention':
                return 'mentioned you in a comment';
            case 'user_registered_in_organization':
                return 'joined your organization';
            default:
                return '';
        }
    };

    const headline = () => (
        <span className="notification-title">
            <b>{sender ? `${sender.given_name} ${sender.family_name}` : 'Loading User'}</b>
            {' – '}
            {tagline()}
        </span>
    );

    const followLink = async () => {
        try {
            const pathInformation = await DosApi.fetchCommentPath(notification.source_id);
            const slug = pathInformation.project_name
                .toString()
                .normalize('NFD') // break accented characters into components
                .replace(/[\u0300-\u036f]/g, '') // remove diacritics
                .toLowerCase()
                .replace(/\s+/g, '-') // spaces to dashes
                .replace(/&/g, '-and-') // ampersand to and
                .replace(/[^\w-]+/g, '') // remove non-words
                .replace(/--+/g, '-') // collapse multiple dashes
                .replace(/^-+/, '') // trim starting dash
                .replace(/-+$/, ''); // trim ending dash;
            let path = `/project/${pathInformation.project_id}/${slug}`;
            if (pathInformation.parameters) {
                const parameters = [];
                for (const [key, value] of Object.entries(pathInformation.parameters)) {
                    parameters.push(`${key}=${value}`);
                }
                path += `?${parameters.join('&')}`;
            }
            dispatch(selectRightTab(RIGHT_TAB.PROJECT_ANNOTATIONS));
            navigate(path);
        } catch (e) {
            setError(e);
        }

        dispatch(updateNotificationState(notification, { read: true, cleared: false }));
    };

    const gotoUserAdmin = async () => {
        const goToTab = (tab) => {
            if (currentTab !== tab) {
                dispatch(selectLeftTab(tab));
            } else if (currentTab) {
                dispatch(selectLeftTab(null));
            }
        };

        const goToPage = (page) => {
            if (currentPage !== page) {
                dispatch(selectRightTab(null));
                dispatch(selectPage(page));
            }
            goToTab(null);
        };

        dispatch(updateNotificationState(notification, { read: true, cleared: false }));
        goToPage(PAGE.ADMIN);
        navigate('/admin');
    };

    return (
        <div
            className={`notification-item ${active ? 'active' : ''} ${error ? 'error' : ''} ${color()} ${
                notification.read ? 'read' : ''
            }`}
            key={notification.id}
        >
            <div className="notification-upper">
                <i className={`notification-icon fal ${icon()}`} />
                <div className="notification-text">
                    <span className="notification-timestamp">
                        {formatTimestamp(notification.timestamp)} {notification.reminded_at ? '| Reminder' : ''}
                    </span>
                    {headline()}
                    <div className="notification-content">
                        {!error
                            ? formatMentions(notification.message, dispatch, usernames)
                            : 'An error occurred. This comment has probably been deleted.'}
                    </div>
                </div>
            </div>
            {error ? (
                <Button className="borderless green underline" onClick={setActive}>
                    Close notification
                </Button>
            ) : null}
            {notification.type.match('annotation_comment') && !error ? (
                <Button className="borderless green underline" onClick={followLink}>
                    Go to comment
                    <i className="fal fa-arrow-right-long" />
                </Button>
            ) : null}
            {notification.type.match('user_registered_in_organization') ? (
                <Button className="borderless green underline" onClick={gotoUserAdmin}>
                    Go to user administration
                    <i className="fal fa-arrow-right-long" />
                </Button>
            ) : null}
            <div className="dropdown-tag-container">
                <Button className="borderless dropdown-tag" onClick={setActive}>
                    <i className={`fal fa-angle-${active ? 'up' : 'down'}`} />
                </Button>
            </div>
        </div>
    );
};

export default NotificationsListItem;
