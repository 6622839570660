import React, { useEffect, useRef } from 'react';
import { shallowEqual, useDispatch } from 'react-redux';
import { useAppSelector } from 'store';
import { Button } from 'reactstrap';
import { Form, Formik } from 'formik';
import proj4 from 'proj4';
import { clearElevationProfile, generateElevationProfile, startProfileLineDrawing } from '../../../redux/actions';
import * as datasetsSlice from '../../../redux/datasets';
import { DRAWN_TOOL, LAYER_DATA_TYPES, LAYER_STATES, LAYER_TYPES } from '../../../services/Constants';
import HelpPanel from '../../forms/HelpPanel';
import MultiSelect from '../../forms/Multiselect';
import DisabledDrawTool from '../DisabledDrawTool';
import ElevationProfile from './ElevationProfile';
import * as drawToolSlice from '../../../redux/drawTool';
import * as layersSlice from '../../../redux/layers';
import BaseField from '../../forms/BaseField';

const DEFAULT_SAMPLES = 150;

const ElevationProfileMenu = () => {
    const dispatch = useDispatch();

    const project = useAppSelector(datasetsSlice.currentProject, shallowEqual);
    const datasets = useAppSelector(datasetsSlice.getRenderableDatasets, shallowEqual);
    const layers = useAppSelector(layersSlice.all);

    const units = proj4.defs(`EPSG:${project?.projection}`)?.units || 'loading...';

    const currentTool = useAppSelector(drawToolSlice.getTool);

    const formikRef = useRef();

    useEffect(() => {
        if (formikRef.current.resetForm) formikRef.current.resetForm();
    }, [project]);

    const validDatasets = datasets.filter(
        (dataset) =>
            [LAYER_TYPES.BATHYMETRY, LAYER_TYPES.HORIZON].includes(dataset.type) &&
            dataset.datatype === LAYER_DATA_TYPES.SINGLEBANDCOG &&
            dataset.state === LAYER_STATES.LOADED &&
            layers.find((l) => l.datasetId === dataset.id)?.visible
    );

    const calculateSamples = (start, end, resolution) => {
        if (!start || !end || resolution === '') return DEFAULT_SAMPLES;

        const distance = Math.sqrt((start[0] - end[0]) ** 2 + (start[1] - end[1]) ** 2);
        return Math.round(distance / resolution);
    };

    const calculateResolution = (start, end, samples) => {
        if (!start || !end || (samples && samples <= 1)) return undefined;

        if (samples === '') samples = DEFAULT_SAMPLES;

        const distance = Math.sqrt((start[0] - end[0]) ** 2 + (start[1] - end[1]) ** 2);
        return (distance / samples).toFixed(2);
    };

    return (
        <>
            <div className="map-pane-title">Elevation Profile</div>
            {!currentTool || currentTool === DRAWN_TOOL.ELEVATION_PROFILE ? (
                <Formik
                    innerRef={formikRef}
                    initialValues={{
                        points: null,
                        samples: '',
                        resolution: '',
                        datasets: [],
                    }}
                    validateOnChange={false}
                    validateOnBlur={false}
                    validate={(values) => {
                        const errors = {};
                        if (values.samples !== '') {
                            if (values.samples < 1) errors.samples = 'Value too low';
                            else if (values.samples > 1000) errors.samples = 'Value too high';
                        }
                        return errors;
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                        dispatch(
                            generateElevationProfile(
                                values.points?.start,
                                values.points?.end,
                                values.datasets.map((d) => d.value),
                                values.samples && values.samples !== '' ? values.samples : DEFAULT_SAMPLES
                            )
                        ).then(() => setSubmitting(false));
                    }}
                >
                    {({ setFieldValue, values, errors, resetForm, handleBlur, submitForm }) => (
                        <Form className="sideform">
                            <HelpPanel>Pointclouds are not supported</HelpPanel>
                            <MultiSelect
                                name="datasets"
                                options={validDatasets.map((x) => ({
                                    'label': x.name,
                                    'value': x,
                                }))}
                                onBlur={handleBlur}
                                onChange={(choice) => {
                                    setFieldValue('datasets', choice);
                                    submitForm();
                                }}
                                placeholder="Connect datasets"
                                multiValueCounterMessage="Connected dataset"
                                isClearable
                                value={values.datasets}
                                error={errors.datasets}
                            />
                            <div className="invalid-feedback">{errors.datasets}</div>
                            <hr className="sideform-bar" />
                            <BaseField
                                name="samples"
                                label="Samples"
                                placeholder="Default: 150, Max: 1000"
                                type="number"
                                onChange={(e) => {
                                    const samples = Math.abs(e.target.value);
                                    setFieldValue(
                                        'resolution',
                                        calculateResolution(
                                            values.points?.start,
                                            values.points?.end,
                                            samples ?? DEFAULT_SAMPLES
                                        )
                                    );
                                    setFieldValue('samples', samples).then(submitForm);
                                }}
                                value={values.samples}
                                error={errors.samples}
                                max={1000}
                            />
                            <hr className="sideform-bar" />
                            <BaseField
                                name="resolution"
                                label={`Resolution (${units})`}
                                placeholder={values.points ? '' : 'Draw points to set a resolution'}
                                type="number"
                                onChange={(e) => {
                                    const resolution = Math.abs(e.target.value);
                                    setFieldValue('resolution', resolution);
                                    setFieldValue(
                                        'samples',
                                        calculateSamples(values.points?.start, values.points?.end, resolution)
                                    ).then(submitForm);
                                }}
                                disabled={!values.points?.start}
                                values={values.resolution}
                                min={0}
                            />
                            <hr className="sideform-bar" />
                            <div className="form-group">
                                <div className={`profile-line-geometry ${errors.points ? 'is-invalid' : ''}`}>
                                    <Button
                                        type="button"
                                        color="secondary"
                                        id="close-profile"
                                        className={errors.points ? 'is-invalid' : ''}
                                        onClick={() =>
                                            dispatch(startProfileLineDrawing()).then((geometry) => {
                                                if (geometry) {
                                                    setFieldValue('points', {
                                                        start: geometry.coordinates[0],
                                                        end: geometry.coordinates[1],
                                                    });
                                                    setFieldValue(
                                                        'resolution',
                                                        calculateResolution(
                                                            geometry.coordinates[0],
                                                            geometry.coordinates[1],
                                                            values.samples
                                                        )
                                                    );
                                                } else setFieldValue('points', null);

                                                submitForm();
                                            })
                                        }
                                    >
                                        DRAW
                                    </Button>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td>Start:</td>
                                                <td className="coordinate-box coordinate-box-3d">
                                                    <span className="coordinate-component-x">x</span>:{' '}
                                                    {values.points?.start[0].toFixed(3)}
                                                    <br />
                                                    <span className="coordinate-component-y">y</span>:{' '}
                                                    {values.points?.start[1].toFixed(3)}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>End:</td>
                                                <td className="coordinate-box coordinate-box-3d">
                                                    <span className="coordinate-component-x">x</span>:{' '}
                                                    {values.points?.end[0].toFixed(3)}
                                                    <br />
                                                    <span className="coordinate-component-y">y</span>:{' '}
                                                    {values.points?.end[1].toFixed(3)}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="invalid-feedback">{errors.points}</div>
                            </div>
                            <hr className="sideform-bar" />
                            <ElevationProfile />
                            <div className="sideform-buttons">
                                <Button
                                    type="button"
                                    color="warning"
                                    id="close-profile"
                                    onClick={() => {
                                        resetForm();
                                        dispatch(clearElevationProfile(true));
                                    }}
                                >
                                    CLEAR
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            ) : (
                <DisabledDrawTool />
            )}
        </>
    );
};

export default ElevationProfileMenu;
