// eslint-disable-next-line import/no-cycle
import { SEISMIC_VIEW_VISIBLE } from '../actionTypes';

function helpReducer(state = { enabled: false }, action) {
    const { type, payload } = action;

    switch (type) {
        case SEISMIC_VIEW_VISIBLE:
            return {
                ...state,
                enabled: payload,
            };
        default:
            return state;
    }
}

export default helpReducer;
