import React from 'react';
import { Alert } from 'reactstrap';

const HelpPanel = ({ children }) => (
    <Alert color="light">
        <i className="fal fa-info-circle" role="img" aria-label="Info:" />
        <div>{children}</div>
    </Alert>
);

export default HelpPanel;
