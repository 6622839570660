import Entity3D from '@giro3d/giro3d/entities/Entity3D';
import {
    BufferGeometry,
    Color,
    DoubleSide,
    Float32BufferAttribute,
    Group,
    LineBasicMaterial,
    LineSegments,
    Material,
    MathUtils,
    Mesh,
    MeshPhongMaterial,
    Vector3,
} from 'three';
import LUT from 'giro3d_extensions/LUT';
import { GEOJSON_AMPLITUDE_MODE } from 'services/Constants';
import { DatasetId, SourceFileId } from 'types/common';
import Source, { LineCoordinates } from './Source';

const tmpVec3 = new Vector3();

export type DisplayMode = 'bar' | 'solid';

const WHITE = new Color('white');

// The two ways to display amplitudes: either as a series of
// vertical segments that mimic a bar chart, or as a solid mesh.
type GraphicObject = Mesh<BufferGeometry, MeshPhongMaterial> | LineSegments<BufferGeometry, LineBasicMaterial>;

export default class AmplitudeEntity extends Entity3D {
    readonly isAmplitudeEntity = true;
    private readonly _source: Source;
    private _mode: string;
    private _propertyName: string;
    private readonly _meshes: GraphicObject[] = [];
    private _abortController: AbortController;
    private readonly _datasetId: string;
    private readonly _sourceFileId: string;

    constructor(source: Source, datasetId: DatasetId, sourceFileId: SourceFileId) {
        super(MathUtils.generateUUID(), new Group());

        this.type = 'AmplitudeEntity';
        this._datasetId = datasetId;
        this._sourceFileId = sourceFileId;
        this.object3d.name = this.type;
        this.object3d.userData.datasetId = datasetId;
        this.object3d.userData.sourceFileId = sourceFileId;

        this._source = source;
    }

    private calculateAmplitude(value: number, dataMax: number, dataMin: number) {
        if (value === null) return 0;
        const range = dataMax - dataMin;
        if (range === 0) return 0;
        switch (this._mode) {
            case GEOJSON_AMPLITUDE_MODE.FROM_MAX_DEVIATION:
                return (dataMax - value) / range;
            case GEOJSON_AMPLITUDE_MODE.FROM_MIN_DEVIATION:
                return (value - dataMin) / range;
            case GEOJSON_AMPLITUDE_MODE.FROM_MEAN_DEVIATION:
                return (value - (dataMax + dataMin) / 2) / range;
            default:
                return 0;
        }
    }

    private buildMesh(
        line: LineCoordinates,
        propValues: Float32Array,
        min: number,
        max: number,
        range: number,
        lut: LUT,
        mode: DisplayMode
    ) {
        const coordinates = line.points;
        const vertexCount = coordinates.length * 2;
        const positions = new Float32Array(vertexCount * 3);
        const colors = new Float32Array(vertexCount * 4);
        let indices: number[];
        const indexedMesh = mode === 'solid';
        if (indexedMesh) {
            indices = [];
        }

        let posIndex = 0;
        let colorIndex = 0;

        for (let i = 0; i < coordinates.length; i++) {
            const pos = coordinates.get(i, tmpVec3);

            const propValue = propValues[i];
            const color = lut.sample(propValue);
            const amplitude = range * this.calculateAmplitude(propValue, min, max);

            // low
            positions[posIndex + 0] = pos.x;
            positions[posIndex + 1] = pos.y;
            positions[posIndex + 2] = pos.z;

            colors[colorIndex + 0] = color.r;
            colors[colorIndex + 1] = color.g;
            colors[colorIndex + 2] = color.b;
            colors[colorIndex + 3] = 1;

            // high
            positions[posIndex + 3] = pos.x;
            positions[posIndex + 4] = pos.y;
            positions[posIndex + 5] = pos.z + amplitude;

            colors[colorIndex + 4] = color.r;
            colors[colorIndex + 5] = color.g;
            colors[colorIndex + 6] = color.b;
            colors[colorIndex + 7] = 1;

            posIndex += 6;
            colorIndex += 8;

            if (indexedMesh && i !== coordinates.length - 1) {
                indices.push(i * 2, i * 2 + 1, i * 2 + 2);
                indices.push(i * 2 + 1, i * 2 + 2, i * 2 + 3);
            }
        }

        let material: Material;
        if (indexedMesh) {
            material = new MeshPhongMaterial({
                side: DoubleSide,
                vertexColors: true,
                flatShading: true,
                transparent: this.opacity < 1,
                opacity: this.opacity,
            });
        } else {
            material = new LineBasicMaterial({
                vertexColors: true,
                transparent: this.opacity < 1,
                opacity: this.opacity,
                linewidth: 2,
            });
        }

        const geom = new BufferGeometry();

        geom.setAttribute('position', new Float32BufferAttribute(positions, 3));
        geom.setAttribute('color', new Float32BufferAttribute(colors, 4));

        if (indexedMesh) {
            geom.setIndex(indices);
            geom.computeVertexNormals();

            // Pre-computes data for the three-mesh-bvh package
            geom.computeBoundsTree();
        }

        geom.computeBoundingSphere();
        let object: GraphicObject;
        if (indexedMesh) {
            object = new Mesh<BufferGeometry, MeshPhongMaterial>(geom, material as MeshPhongMaterial);
            object.name = 'Mesh<BufferGeometry, MeshPhongMaterial>';
        } else {
            object = new LineSegments<BufferGeometry, LineBasicMaterial>(geom, material as LineBasicMaterial);
            object.name = 'LineSegments<BufferGeometry, LineBasicMaterial>';
        }

        object.userData.datasetId = this._datasetId;
        object.userData.sourceFileId = this._sourceFileId;
        object.position.copy(line.origin);
        this._meshes.push(object);
        this.object3d.add(object);
        this.onObjectCreated(object);
    }

    private async buildMeshes(
        min: number,
        max: number,
        range: number,
        lut: LUT,
        displayMode: DisplayMode,
        signal: AbortSignal
    ) {
        await Promise.resolve();

        if (signal.aborted) {
            return;
        }

        const lines = await this._source.getGeometries();

        if (signal.aborted) {
            return;
        }

        const properties = await this._source.getPropertyValue(this._propertyName);

        if (signal.aborted) {
            return;
        }

        this.deleteMeshes();

        for (let i = 0; i < lines.length; i++) {
            const line = lines[i];
            const props = properties[i];

            this.buildMesh(line, props, min, max, range, lut, displayMode);
        }

        this.object3d.updateMatrixWorld(true);

        this._instance.notifyChange(this);
    }

    private deleteMeshes() {
        this._meshes.forEach((obj: GraphicObject) => {
            obj.geometry.dispose();
            obj.material.dispose();

            if ((obj as Mesh).isMesh) {
                obj.geometry.disposeBoundsTree();
            }

            obj.removeFromParent();
        });
        this._meshes.length = 0;
    }

    setBrightness(brightness: number) {
        this._meshes.forEach((obj: GraphicObject) => {
            if ((obj as Mesh).isMesh) {
                const mesh = obj as Mesh<BufferGeometry, MeshPhongMaterial>;
                mesh.material.emissive = WHITE;
                mesh.material.emissiveIntensity = brightness;
            }
            // TODO bar chart mode
        });
    }

    showInFront(inFront: boolean) {
        this._meshes.forEach((obj: GraphicObject) => {
            if ((obj as Mesh).isMesh) {
                const mesh = obj as Mesh<BufferGeometry, MeshPhongMaterial>;
                const material = mesh.material;
                material.transparent = inFront ? true : material.opacity < 1;
                material.depthTest = !inFront;
                mesh.renderOrder = inFront ? 9999 : 0;
            }
        });
    }

    async setParameters(
        property: string,
        mode: string,
        min: number,
        max: number,
        range: number,
        lut: LUT,
        displayMode: DisplayMode
    ) {
        this._propertyName = property;
        this._mode = mode;

        this._abortController?.abort();

        this._abortController = new AbortController();

        // Building meshes is asynchronous due to the async nature of the data source, so we
        // need an abort signal to cancel whatever pending operation is underway.
        await this.buildMeshes(min, max, range, lut, displayMode, this._abortController.signal);
    }

    dispose() {
        this.deleteMeshes();

        this.object3d.removeFromParent();
    }
}
