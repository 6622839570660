import { Color } from 'three';
import ColorSelector from 'components/giro3d/settingsMenu/ColorSelector';

export type Props = {
    title: string;
    color: Color;
    onChange: (newColor: Color) => void;
};

function SettingColorSelector(props: Props) {
    return (
        <div className="row">
            <label htmlFor="overlaycolor" className="col-sm-5 col-form-label">
                <i className="fal fa-palette fa-fw" /> {props.title}
            </label>
            <div className="col-sm-7">
                <div className="select-group d-flex">
                    <ColorSelector color={props.color} onChange={(c) => props.onChange(c)} />
                </div>
            </div>
        </div>
    );
}

export default SettingColorSelector;
