import { useState } from 'react';

import { DatasetId } from 'types/common';
import { useAppSelector } from 'store';

import InlineDropMenu from 'components/InlineDropMenu';
import { Button } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { LayerState } from 'types/LayerState';
import { useDroppable } from '@dnd-kit/core';
import DatasetListItem from './DatasetListItem';
import * as layers from '../../../redux/layers';
import * as datasetsSlice from '../../../redux/datasets';

const groupRelevantEqual = (ids: DatasetId[]) => (left: DatasetId, right: DatasetId) =>
    left === right || !(ids.includes(left) || ids.includes(right));

export type Props = {
    datasetIds: DatasetId[];
    groupName: string;
    groupId: string;
    customGroup: boolean;
    setSettingsModal: () => void;
    setDeleteModal: () => void;
    highlight: boolean;
};

const DatasetListGroup = (props: Props) => {
    const dispatch = useDispatch();

    const { datasetIds, groupName, groupId, customGroup, setSettingsModal, setDeleteModal, highlight } = props;

    const { setNodeRef, isOver } = useDroppable({ id: groupId });

    const layerStates: LayerState[] = useAppSelector(layers.all);
    const clickedDatasetId = useAppSelector(datasetsSlice.getClickedDataset, groupRelevantEqual(datasetIds));

    const [open, setOpen] = useState(false);

    const hideOthers = () =>
        layerStates.forEach((layerState) => {
            if (!datasetIds.includes(layerState.datasetId))
                dispatch(layers.setVisibility({ layer: layerState, value: false }));
        });
    const hideGroup = () =>
        layerStates.forEach((layerState) => {
            if (datasetIds.includes(layerState.datasetId))
                dispatch(layers.setVisibility({ layer: layerState, value: false }));
        });
    const showGroup = () =>
        layerStates.forEach((layerState) => {
            if (datasetIds.includes(layerState.datasetId))
                dispatch(layers.setVisibility({ layer: layerState, value: true }));
        });

    return (
        <div
            className={`data-group ${highlight || (customGroup && isOver) ? 'hovered' : ''}`}
            id={groupId}
            ref={setNodeRef}
        >
            <div className={`data-group-header ${!open && datasetIds.includes(clickedDatasetId) ? 'highlighted' : ''}`}>
                <label
                    htmlFor={`${groupId}-dropdown`}
                    className={`data-group-label dropdown-arrow ${customGroup ? 'custom' : ''}`}
                >
                    <span>{groupName}</span>
                    <input type="checkbox" id={`${groupId}-dropdown`} onChange={(e) => setOpen(e.target.checked)} />
                    <div className="arrow" />
                </label>
                <InlineDropMenu>
                    {customGroup ? (
                        <>
                            <Button className="borderless red" title="Delete group" onClick={setDeleteModal}>
                                <i className="fal fa-trash-can" />
                            </Button>
                            <Button className="borderless yellow" title="Edit group" onClick={setSettingsModal}>
                                <i className="fal fa-gear" />
                            </Button>
                        </>
                    ) : null}
                    <Button className="borderless light-blue full-color" title="Hide all others" onClick={hideOthers}>
                        <i className="fal fa-circle" />
                    </Button>
                    <hr className="centre-drop-menu-divider" />
                    <Button className="borderless red full-color" title="Hide group" onClick={hideGroup}>
                        <i className="fal fa-eye-slash" />
                    </Button>
                    <hr className="centre-drop-menu-divider" />
                    <Button className="borderless green full-color" title="Show group" onClick={showGroup}>
                        <i className="fal fa-eye" />
                    </Button>
                </InlineDropMenu>
            </div>
            {open
                ? datasetIds.map((datasetId) => (
                      <DatasetListItem
                          key={datasetId}
                          id={`groupable-${datasetId}`}
                          datasetId={datasetId}
                          groupOpen={open}
                          handleMode="group"
                      />
                  ))
                : null}
        </div>
    );
};

export default DatasetListGroup;
