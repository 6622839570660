import {
    ColoringMode,
    HasAttributes,
    HasColoringMode,
    HasDataPointMode,
    LayerState,
    DataPointMode,
} from 'types/LayerState';

import * as layers from 'redux/layers';
import { useAppDispatch, useAppSelector } from 'store';
import ExperimentalMention from 'components/ExperimentalMention';

export type Props = {
    layer: LayerState & HasAttributes & HasDataPointMode & HasColoringMode;
};

function formatDataPointMode(mode: DataPointMode) {
    switch (mode) {
        case DataPointMode.Continuous:
            return 'Continuous';
        case DataPointMode.Anode:
            return 'Anode';
        default:
            throw new Error('invalid state');
    }
}

function DataPointModeSettings(props: Props) {
    const { layer } = props;

    const dispatch = useAppDispatch();

    const coloringMode = useAppSelector(layers.getCurrentColoringMode(layer));
    const modes = useAppSelector(layers.getDataPointModes(layer));
    const currentMode = useAppSelector(layers.getCurrentDataPointMode(layer));

    function changeActiveMode(value: DataPointMode) {
        dispatch(layers.setCurrentDataPointMode({ layer, value }));
    }

    if (modes.length === 0 || currentMode == null) {
        return null;
    }

    const id = layer.datasetId;

    if (coloringMode !== ColoringMode.Colormap) {
        return null;
    }

    return (
        <ExperimentalMention>
            <div className="row" key={`dataset-${id}-line-representation`}>
                <label htmlFor={`dataset-${id}-line-representation-prop`} className="col-sm-5 col-form-label">
                    <i className="fal fa-chart-scatter" /> Data point mode
                </label>
                <div className="col-sm-7">
                    <div className="select-group">
                        <select
                            id={`dataset-${id}-line-representation-prop`}
                            className="form-select"
                            value={currentMode}
                            onChange={(e) => changeActiveMode(e.target.value as DataPointMode)}
                        >
                            {modes.map((mode) => (
                                <option key={`dataset-prop-${mode}`} value={mode}>
                                    {formatDataPointMode(mode)}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
            </div>
        </ExperimentalMention>
    );
}

export default DataPointModeSettings;
