import Uppy from '@uppy/core';
import Tus from '@uppy/tus';
// eslint-disable-next-line import/no-cycle
import { UPPY_CHANGE } from 'redux/actionTypes';
import DosApi from './DosApi';

const maxUploadFilesize = window._env_.REACT_APP_MAX_UPLOAD_FILE_SIZE
    ? window._env_.REACT_APP_MAX_UPLOAD_FILE_SIZE
    : process.env.REACT_APP_MAX_UPLOAD_FILE_SIZE;

let dispatch;

const setDispatch = (d) => {
    dispatch = d;
};

const completeUploads = {};
const uploadingInstances = {};

const newInstance = () =>
    new Uppy({
        restrictions: {
            maxFileSize: parseInt(maxUploadFilesize, 10), // bytes
        },
    })
        .use(Tus, {
            endpoint: `${DosApi.getAPIEndpoint()}/dataset/upload/`,
            chunkSize: 256 * 1024 * 1024, // bytes
            async onBeforeRequest(req) {
                // Add auth token to outgoing request
                const token = await DosApi.apiAcquireToken();
                req.setHeader('Authorization', `Bearer ${token.access_token}`);
            },
            removeFingerprintOnSuccess: true,
        })
        .on('file-added', () => dispatch({ type: UPPY_CHANGE }))
        .on('upload', () => dispatch({ type: UPPY_CHANGE }))
        .on('progress', () => dispatch({ type: UPPY_CHANGE }))
        .on('complete', (result) => {
            const datasetId = result.successful[0].meta.datasetId;
            completeUploads[datasetId] = result;
            uploadingInstances[datasetId].close();
            delete uploadingInstances[datasetId];
            dispatch({ type: UPPY_CHANGE });
        });

let currentInstance = newInstance();

const getInstance = () => currentInstance;

const getUploading = () => uploadingInstances;

const getComplete = () => completeUploads;

const upload = (datasetId) => {
    currentInstance.setMeta({ datasetId });
    currentInstance.upload();
    uploadingInstances[datasetId] = currentInstance;
    currentInstance = newInstance();
    dispatch({ type: UPPY_CHANGE });
};

const clear = () => {
    Object.keys(completeUploads).forEach((id) => delete completeUploads[id]);
    dispatch({ type: UPPY_CHANGE });
};

const clearFiles = () => {
    currentInstance.cancelAll();
    dispatch({ type: UPPY_CHANGE });
};

export default {
    upload,
    getInstance,
    getUploading,
    getComplete,
    setDispatch,
    clear,
    clearFiles,
};
