import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Field } from 'formik';

import { showError } from '../../redux/actions';
import DosApi from '../../services/DosApi';

import Crud from '../forms/Crud';
import BaseField from '../forms/BaseField';

const Memberships = ({ organization }) => {
    const dispatch = useDispatch();
    const [users, setUsers] = useState(null);

    useEffect(() => {
        DosApi.fetchUsers()
            .then((data) => setUsers(data))
            .catch((err) => showError(dispatch, err));
    }, []);

    return (
        <Crud
            createTitle="Add new membership"
            fieldName="user.username"
            fetchService={() => DosApi.fetchMembershipsFor(organization.id)}
            createService={organization.user_permissions.create_memberships ? DosApi.createMembership : undefined}
            deleteService={organization.user_permissions.delete_memberships ? DosApi.deleteMembership : undefined}
        >
            <BaseField name="user_id" label="User" as="select" showFrom="user.username">
                <option default aria-label="default" />
                {users !== null
                    ? users.map((e) => (
                          <option value={e.id} key={`user-${e.id}`}>
                              {e.username}
                          </option>
                      ))
                    : null}
            </BaseField>
            <Field name="organization_id" type="hidden" value={organization.id} />
            <BaseField name="role" label="Role" as="select">
                <option default aria-label="default">
                    owner
                </option>
                <option>member</option>
            </BaseField>
            <div className="alert alert-light d-flex align-items-center" role="alert">
                <i className="fal fa-info-circle bi flex-srink-0 me-2" role="img" aria-label="Info:" />
                Members can access all collections and datasets from the organization, in read-only mode.
                <br />
                Owners can manage memberships, add and edit collections and datasets.
            </div>
        </Crud>
    );
};

export default Memberships;
