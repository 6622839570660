// Components
import { HasSeismicPlane, LayerState } from 'types/LayerState';
import { useDispatch } from 'react-redux';
import * as layers from 'redux/layers';
import { useAppSelector } from 'store';
import ExperimentalMention from 'components/ExperimentalMention';
import SettingToggleSwitch from './SettingToggleSwitch';

export type Props = {
    layer: LayerState & HasSeismicPlane;
};

const Offset = (props: Props) => {
    const { layer } = props;
    const dispatch = useDispatch();

    const offset = useAppSelector(layers.getSeismicOffset(layer));
    function changeOffset(value: number) {
        dispatch(layers.setSeismicOffset({ layer, value }));
    }

    const period = useAppSelector(layers.getSpeedModuleMs(layer));
    function changePeriod(value: number) {
        dispatch(layers.setSpeedModuleMs({ layer, value }));
    }

    const staticCorrection = useAppSelector(layers.getStaticCorrection(layer));
    function changeStaticCorrection(value: boolean) {
        dispatch(layers.setStaticCorrection({ layer, value }));
    }

    return (
        <div className="row">
            <label htmlFor="segyOffset" className="col-sm-6 col-form-label">
                <i className="fa fa-up-down fa-fw" /> Offset
            </label>
            <div className="col-sm-6">
                <div className="input-group">
                    <input
                        id="segyOffset"
                        type="number"
                        min="-9999"
                        max="9999"
                        step="1"
                        value={offset}
                        autoComplete="off"
                        className="form-control"
                        onChange={(e) => changeOffset(e.target.valueAsNumber)}
                    />
                </div>
            </div>
            <label htmlFor="segyPeriod" className="col-sm-6 col-form-label">
                <i className="fa fa-wave-pulse fa-fw" /> Period
            </label>
            <div className="col-sm-6">
                <div className="input-group">
                    <input
                        id="segyPeriod"
                        type="number"
                        min="0"
                        max="2000"
                        step="1"
                        value={period}
                        autoComplete="off"
                        className="form-control"
                        onChange={(e) => changePeriod(e.target.valueAsNumber)}
                        // onBlur={() => setPeriod(giro3dlayer.getSpeedModuleMs())} // TODO ?
                    />
                    <div className="input-group-text">ms</div>
                </div>
            </div>
            <ExperimentalMention>
                <SettingToggleSwitch
                    id="toggle-staticCorrection-segy"
                    checked={staticCorrection}
                    title="Static correction"
                    icon="fa-distribute-spacing-vertical"
                    onChange={(e) => changeStaticCorrection(e.target.checked)}
                />
            </ExperimentalMention>
        </div>
    );
};

// TODO: reenable when giro3d support for layer clipping
// const SegyCrossSectionSetting = ({ giro3dlayer }) => {
//     const [viewCrossSection, setViewCrossSection] = useState(giro3dlayer.getIsViewingCrossSection());
//     const [invertCrossSection, setInvertCrossSection] = useState(giro3dlayer.getIsCrossSectionInverted());
//
//     const changeViewCrossSection = (value) => {
//         setViewCrossSection(value);
//         giro3dlayer.toggleCrossSection(value);
//     };
//
//     const changeInvertCrossSection = (value) => {
//         setInvertCrossSection(value);
//         giro3dlayer.toggleCrossSectionInverted(value);
//     };
//
//     return (
//         <div className="select-group">
//             <label htmlFor="segy_clip" className="col-sm-7 col-form-label">
//                 Cross-section
//                 <input
//                     id="segy_clip"
//                     type="checkbox"
//                     className="form-check-input"
//                     checked={viewCrossSection}
//                     onChange={(e) => changeViewCrossSection(e.target.checked)}
//                 />
//             </label>
//
//             <label htmlFor="segy_clip_invert" className="col-sm-5 col-form-label">
//                 Invert
//                 <input
//                     id="segy_clip_invert"
//                     type="checkbox"
//                     className="form-check-input"
//                     checked={invertCrossSection}
//                     onChange={(e) => changeInvertCrossSection(e.target.checked)}
//                 />
//             </label>
//         </div>
//     );
// };

const IntensityFilter = (props: Props) => {
    const { layer } = props;
    const dispatch = useDispatch();
    const filter = useAppSelector(layers.getIntensityFilter(layer));

    const changeFilter = (value: number) => {
        dispatch(layers.setIntensityFilter({ layer, value }));
    };

    return (
        <div className="row">
            <label htmlFor="segyfilter" className="col-sm-6 col-form-label">
                <i className="fal fa-filter fa-fw" /> Intensity filter
            </label>
            <div className="col-sm-6">
                <div className="input-group">
                    <input
                        id="segyfilter"
                        type="number"
                        min="0"
                        max="100"
                        step="1"
                        value={Math.round(filter * 100)}
                        autoComplete="off"
                        className="form-control"
                        onChange={(e) => changeFilter(e.target.valueAsNumber / 100)}
                    />
                    <div className="input-group-text">%</div>
                </div>
            </div>
        </div>
    );
};

const FilterTransparency = (props: Props) => {
    const { layer } = props;
    const dispatch = useDispatch();
    const transparency = useAppSelector(layers.getFilterTransparency(layer));

    const changeTransparency = (value: boolean) => {
        dispatch(layers.setFilterTransparency({ layer, value }));
    };

    return (
        <div className="select-group">
            <label htmlFor="segy_filter_transparency" className="col-sm-7 col-form-label">
                Filter transparency
                <input
                    id="segy_filter_transparency"
                    type="checkbox"
                    className="form-check-input"
                    checked={transparency}
                    onChange={(e) => changeTransparency(e.target.checked)}
                />
            </label>
        </div>
    );
};

const SeismicSettings = (props: Props) => {
    const { layer } = props;
    const id = layer.datasetId;

    return (
        <>
            <IntensityFilter key={`datasetsettings-${id}-filter`} layer={layer} />
            <FilterTransparency key={`datasetsettings-${id}-filter-transparency`} layer={layer} />
            <Offset key={`datasetsettings-${id}-offset`} layer={layer} />
        </>
    );
};

export default SeismicSettings;
