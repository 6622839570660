import { HasArrows, LayerState } from 'types/LayerState';

import * as layers from 'redux/layers';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'store';
import SettingToggleSwitch from './SettingToggleSwitch';

export type Props = {
    layer: LayerState & HasArrows;
};

const Spacing = (props: Props) => {
    const { layer } = props;
    const id = layer.datasetId;
    const dispatch = useDispatch();

    const arrowSpacing = useAppSelector(layers.getArrowSpacing(layer));
    function changeArrowSpacing(value: number) {
        dispatch(layers.setArrowSpacing({ layer, value }));
    }

    return (
        <div className="row" key={`datasetsettings-${id}-arrowspacing`}>
            <label htmlFor="trackline_arrowSpacing" className="col-sm-5 col-form-label">
                <i className="fa fa-up-down fa-fw" /> Arrow spacing
            </label>
            <div className="col-sm-7">
                <div className="input-group">
                    <input
                        id="trackline_arrowSpacing"
                        type="number"
                        min="100"
                        max="10000"
                        step="100"
                        value={arrowSpacing}
                        autoComplete="off"
                        className="form-control"
                        onChange={(e) => changeArrowSpacing(e.target.valueAsNumber)}
                    />
                    <div className="input-group-text">m</div>
                </div>
            </div>
        </div>
    );
};

const Scale = (props: Props) => {
    const { layer } = props;
    const id = layer.datasetId;
    const dispatch = useDispatch();

    const arrowScale = useAppSelector(layers.getArrowScale(layer));
    function changeArrowScale(value: number) {
        dispatch(layers.setArrowScale({ layer, value }));
    }

    return (
        <div className="row" key={`datasetsettings-${id}-arrowscale`}>
            <label htmlFor="trackline_arrowScale" className="col-sm-5 col-form-label">
                <i className="fa fa-up-right-and-down-left-from-center fa-fw" /> Arrow size
            </label>
            <div className="col-sm-7">
                <div className="input-group">
                    <input
                        id="trackline_arrowScale"
                        type="number"
                        min="1"
                        max="10"
                        step="1"
                        value={arrowScale}
                        autoComplete="off"
                        className="form-control"
                        onChange={(e) => changeArrowScale(e.target.valueAsNumber)}
                    />
                </div>
            </div>
        </div>
    );
};

export const ArrowSettings = (props: Props) => {
    const { layer } = props;
    const id = layer.datasetId;
    const dispatch = useDispatch();

    const showArrows = useAppSelector(layers.getShowArrows(layer));
    function setShowArrows(value: boolean) {
        dispatch(layers.setShowArrows({ layer, value }));
    }

    return (
        <>
            <SettingToggleSwitch
                id={`toggle-arrow-helpers-${id}`}
                checked={showArrows}
                title="Show direction"
                icon="fa-arrow-right"
                onChange={(e) => setShowArrows(e.target.checked)}
            />

            {showArrows ? <Spacing layer={layer} /> : null}
            {showArrows ? <Scale layer={layer} /> : null}
        </>
    );
};

export default ArrowSettings;
