import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Button } from 'reactstrap';
import { selectAnnotation } from 'redux/annotationActions';
import { editAnnotationState, selectAnnotationForEdit, startAnnotationEdit, startAnnotationView } from 'redux/actions';
import { getSSDMTypes, getUsernames } from 'redux/selectors';
import * as datasetsSlice from 'redux/datasets';
import * as annotationsSlice from 'redux/annotations';
import Annotation, { AnnotationStatus } from 'types/Annotation';
import { QueryParameters } from 'types/common';
import InlineDropMenu from '../../InlineDropMenu';
import CommentForm from './CommentForm';
import Comments from './Comments';

type Props = {
    annotation: Annotation;
    queryParams: QueryParameters;
    deleteCallback: (annotation: Annotation) => void;
};

const AnnotationItem = (props: Props) => {
    const dispatch = useDispatch();
    const location = useLocation();

    const annotation = props.annotation;
    const deleteCallback = props.deleteCallback;
    const project = useSelector(datasetsSlice.currentProject);
    const ssdm = useSelector(getSSDMTypes);
    const activeAnnotation = useSelector(annotationsSlice.active) || { id: null };

    const users = useSelector(getUsernames);
    const author = users ? users.find((x) => x.id === annotation.created_by_id) : null;

    const [statusCol, setStatusCol] = useState('');
    const [statusIcon, setStatusIcon] = useState('');
    const [isActive, setIsActive] = useState(annotation.id === activeAnnotation.id);
    const [completedLink, setCompletedLink] = useState(
        props.queryParams.annotation === props.annotation.id && !props.queryParams.comment
    );

    useEffect(() => {
        setIsActive(annotation.id === activeAnnotation.id);
        if (annotation === activeAnnotation) {
            const elem = document.getElementById(annotation.id);
            if (elem) elem.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
        }
    }, [activeAnnotation, annotation]);

    useEffect(() => {
        setCompletedLink(props.queryParams.annotation === props.annotation.id && !props.queryParams.comment);
        if (props.queryParams.annotation === props.annotation.id && !props.queryParams.comment) {
            const elem = document.getElementById(props.queryParams.annotation);
            if (elem) elem.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
        }
    }, [location]);

    const toggleOpen = () => {
        dispatch(selectAnnotation(activeAnnotation.id === annotation.id ? null : annotation));
    };

    const triggerEdit = () => {
        dispatch(selectAnnotationForEdit(annotation));
    };
    const triggerEditGeometry = () => {
        dispatch(startAnnotationEdit(annotation));
    };
    const triggerViewGeometry = () => {
        dispatch(startAnnotationView(annotation));
    };
    const setStatus = (status: AnnotationStatus) => {
        // Add logic to change local and server state
        switch (status) {
            case 'in_progress':
                setStatusCol('yellow');
                setStatusIcon('fal fa-circle-pause');
                break;
            case 'complete':
                setStatusCol('green');
                setStatusIcon('fal fa-circle-check');
                break;
            case 'archived':
                setStatusCol('red');
                setStatusIcon('fal fa-circle-minus');
                break;
            default:
                break;
        }
    };

    useEffect(() => setStatus(annotation.status));

    const classification = () => {
        if (annotation.ssdm_type) {
            if (ssdm === null) return 'Loading...';
            const ssdmDetails = ssdm.filter((elem) => elem.name === annotation.ssdm_type)[0];
            return `${ssdmDetails.name} ${ssdmDetails.display_name}`;
        }
        if (annotation.observation_type === 'uso') return 'USO';
        if (annotation.observation_type === 'flaw') return 'Flaw in Dataset';
        return null;
    };

    const formatTimestamp = (timestamp) => {
        timestamp = new Date(timestamp);
        const months = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'];
        const result = `${timestamp.getDate()} ${months[timestamp.getMonth()]} ${timestamp.getFullYear()}`;
        return result;
    };

    return (
        <>
            <div className="annotation-item" id={annotation.id}>
                {isActive && (
                    <div className="annotation-header">
                        <div className="annotation-credit">
                            <i className="fal fa-user-circle" />
                            {author ? ` ${author.given_name} ${author.family_name} ` : ' Loading User '}
                            <span className="annotation-credit-date">{`– created ${formatTimestamp(
                                annotation.created_at_utc
                            )}`}</span>
                        </div>
                        <Button className="borderless btn-list-item-close" onClick={toggleOpen}>
                            <i className="fal fa-xmark-large" />
                        </Button>
                    </div>
                )}
                <div className="title-row">
                    <Button className="borderless title-button" onClick={toggleOpen}>
                        <b>{annotation.name}</b>
                    </Button>
                    <InlineDropMenu id={`annotation-actions-${annotation.id}`}>
                        {annotation.user_permissions.manage ? (
                            <>
                                <Button
                                    className="borderless light-blue"
                                    title="Edit Geometry"
                                    onClick={triggerEditGeometry}
                                >
                                    <i className="fal fa-draw-polygon" />
                                </Button>
                                <Button className="borderless green" title="Edit annotation" onClick={triggerEdit}>
                                    <i className="fal fa-pen" />
                                </Button>
                                <Button
                                    className="borderless red"
                                    title="Delete Annotation"
                                    onClick={() => deleteCallback(annotation)}
                                >
                                    <i className="fal fa-trash-can" />
                                </Button>
                            </>
                        ) : (
                            <Button
                                className="borderless light-blue"
                                id={`annotation-view-geom-${annotation.id}`}
                                title="View Geometry"
                                onClick={triggerViewGeometry}
                            >
                                <i className="fal fa-eye" />
                            </Button>
                        )}
                    </InlineDropMenu>
                </div>
            </div>
            <div className="annotation-info-row">
                <div className="annotation-classification">{classification()}</div>
                <div className="annotation-info-box">
                    <div className="centre-drop-menu">
                        <Button className={`borderless ${statusCol} full-color`} id="annotation-status" title="Status">
                            <i className={statusIcon} />
                        </Button>
                        {annotation.user_permissions.manage ? (
                            <div className="centre-drop-content" style={{ width: '7rem' }}>
                                <Button
                                    className={`borderless yellow full-color ${annotation.status === 'in_progress' ? 'fw-bold' : ''}`}
                                    onClick={() => dispatch(editAnnotationState(annotation, 'in_progress'))}
                                >
                                    In progress
                                </Button>
                                <hr className="centre-drop-menu-divider " />
                                <Button
                                    className={`borderless green full-color ${annotation.status === 'complete' ? 'fw-bold' : ''}`}
                                    onClick={() => dispatch(editAnnotationState(annotation, 'complete'))}
                                >
                                    Completed
                                </Button>
                                <hr className="centre-drop-menu-divider" />
                                <Button
                                    className={`borderless red full-color ${annotation.status === 'archived' ? 'fw-bold' : ''}`}
                                    onClick={() => dispatch(editAnnotationState(annotation, 'archived'))}
                                >
                                    Archived
                                </Button>
                            </div>
                        ) : null}
                    </div>
                    <Button
                        className="borderless purple annotation-comments-icon full-color"
                        id="annotation-comments"
                        title="Comments"
                        onClick={() => {
                            if (activeAnnotation.id !== annotation.id) dispatch(selectAnnotation(annotation));
                        }}
                    >
                        <i className="fal fa-message-lines" />
                        <span> {annotation.comment_count}</span>
                    </Button>
                </div>
            </div>
            {isActive ? (
                <>
                    <div className="description-text">{annotation.description}</div>
                    <Comments
                        targetComment={props.queryParams.comment}
                        completedLink={completedLink}
                        completeLink={() => setCompletedLink(true)}
                    />
                    {project.user_permissions.interact ? <CommentForm annotation={annotation} /> : null}
                </>
            ) : null}
        </>
    );
};

export default AnnotationItem;
