// React
import { useDispatch, useSelector } from 'react-redux';

import * as giro3d from 'redux/giro3d';

// Components
import SettingToggle from './SettingToggle';

function InspectorSetting() {
    const dispatch = useDispatch();

    const visible = useSelector(giro3d.showInspector);

    return (
        <SettingToggle
            title="Show inspector"
            checked={visible}
            onChange={(v) => dispatch(giro3d.setShowInspector(v))}
        />
    );
}

export default InspectorSetting;
