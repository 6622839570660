import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import { stopVectorGeometryEdit, applyVectorGeometryEdit } from '../../../redux/actions';
import { getVectorEditState } from '../../../redux/selectors';
import { EDITSTATE } from '../../../services/Constants';

const EditVector = () => {
    const dispatch = useDispatch();

    const editState = useSelector(getVectorEditState);

    const abortEdit = () => {
        dispatch(stopVectorGeometryEdit());
    };
    const applyEdit = () => {
        dispatch(applyVectorGeometryEdit());
    };

    switch (editState) {
        case EDITSTATE.DRAWING:
            return (
                <>
                    <span>
                        Drag and drop points to move them. Click on an edge to create a new point. Right click to end
                        editing.
                    </span>
                    <div className="sideform-buttons">
                        <Button color="warning" id="cancel-drawing" onClick={abortEdit}>
                            ABORT
                        </Button>
                        <Button color="primary" id="apply-drawing" onClick={applyEdit}>
                            APPLY
                        </Button>
                    </div>
                </>
            );
        case EDITSTATE.DETAILS:
        case EDITSTATE.SUMBIT:
        case EDITSTATE.COMPLETE:
            return (
                <div className="sideform-buttons">
                    <Button type="button" color="warning" id="cancel-creation" onClick={abortEdit}>
                        ABORT
                    </Button>
                    <Button
                        type="submit"
                        color="primary"
                        id="complete-creation"
                        disabled={editState === EDITSTATE.SUMBIT || editState === EDITSTATE.COMPLETE}
                    >
                        SAVE
                    </Button>
                </div>
            );
        default:
            return <span>The state is {editState}</span>;
    }
};

export default EditVector;
