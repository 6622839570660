import React from 'react';
import ReactSelect, { components } from 'react-select';

function SettingsSelect(props) {
    const DropdownIndicator = (dropdownProps) => (
        <components.DropdownIndicator {...dropdownProps}>
            <i className="fal fa-angle-down" />
        </components.DropdownIndicator>
    );

    return (
        <ReactSelect
            className="settings-select"
            unstyled
            components={{
                DropdownIndicator,
            }}
            classNames={{
                control: (state) => `settings-select-control ${state.menuIsOpen ? 'open' : ''}`,
                input: () => 'settings-select-input',
                dropdownIndicator: () => 'settings-select-dropdown-indicator',
                menu: () => 'settings-select-menu',
                menuList: () => 'settings-select-menu-list',
                option: () => 'settings-select-option',
            }}
            isSearchable={false}
            maxMenuHeight="10rem"
            {...props}
        />
    );
}

export default SettingsSelect;
