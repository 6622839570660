// React
import React, { useState } from 'react';
import { getUid } from 'ol/util';

const LayerItemControls = (props) => {
    const { layer } = props;
    const key = getUid(layer);

    const [opacity, setOpacity] = useState(100);

    function onOpacityChange(value) {
        setOpacity(value);
        layer.setOpacity(value / 100.0);
    }
    return (
        <div className="input-group">
            <label className="col-md-5" htmlFor="opacity">
                Opacity:
            </label>
            <input
                className="col-md-5"
                type="range"
                key={`overlay-item-${key}-opacity`}
                value={opacity}
                id="opacity"
                min="0"
                max="100"
                step="1"
                onChange={(event) => onOpacityChange(event.target.value)}
            />
        </div>
    );
};

export default LayerItemControls;
