import React from 'react';
import { useSelector } from 'react-redux';

import { getUser } from '../../redux/selectors';
import DosApi from '../../services/DosApi';

import Crud from '../forms/Crud';
import BaseField from '../forms/BaseField';

const MyOrganizations = () => {
    const user = useSelector(getUser);

    const fetchService = () =>
        Promise.resolve(user.memberships.filter((m) => m.role === 'owner').map((m) => m.organization));

    return (
        <Crud
            editTitle="Edit organization"
            fieldName="display_name"
            fetchService={fetchService}
            updateService={DosApi.updateOrganization}
        >
            <BaseField name="display_name" label="Display name" />
            <BaseField name="official_name" label="Official name" />
            <BaseField name="organization_number" label="Organization number" />
        </Crud>
    );
};

export default MyOrganizations;
