import React from 'react';
import { useSelector } from 'react-redux';

import { getUser } from '../../redux/selectors';

import Crud from '../forms/Crud';
import BaseField from '../forms/BaseField';

const MyMemberships = () => {
    const user = useSelector(getUser);

    const fetchService = () => Promise.resolve(user.memberships);

    return (
        <Crud fetchService={fetchService}>
            <BaseField name="organization_id" label="Organization" showFrom="organization.display_name" />
            <BaseField name="role" label="Role" />
        </Crud>
    );
};

export default MyMemberships;
