// React
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import * as settings from 'redux/settings';
import * as grid from 'redux/grid';

import { toHex } from 'types/common';
import { TICKS_PRESETS } from '../../../services/Constants';

// Components
import ToggleSwitch from '../../ToggleSwitch';
import SettingsSelect from './SettingsSelect';
import ColorSelector from './ColorSelector';
import { SettingSlider } from './SettingSlider';

function GridSetting() {
    const dispatch = useDispatch();

    const ticks = useSelector(grid.getTicks);
    const ceiling = useSelector(grid.getCeilingLevel);
    const showCeiling = useSelector(grid.getCeilingVisibility);
    const showSides = useSelector(grid.getSideVisibility);
    const floor = useSelector(grid.getFloorLevel);
    const color = useSelector(grid.getColor);
    const opacity = useSelector(grid.getOpacity);
    const visible = useSelector(grid.isVisible);
    const showLabels = useSelector(grid.getLabelVisibility);

    const open = useSelector(settings.getDropDown);

    const setOpen = (value: boolean) => {
        dispatch(settings.setDropDown(value ? 'grid' : null));
    };

    const changeFloorLevel = (value: number) => {
        if (value < ceiling) {
            dispatch(grid.setFloorLevel(value));
        }
    };

    const changeCeilingLevel = (value: number) => {
        if (value > floor) {
            dispatch(grid.setCeilingLevel(value));
        }
    };

    const changeTicks = (value: grid.Ticks) => {
        dispatch(grid.setTicks(value));
    };

    return (
        <div className="settings-group">
            <div className="settings-main-toggle">
                <div>
                    <i className={`fal fa-ruler-combined ${visible ? 'active' : null}`} />
                </div>
                <label htmlFor="grid-dropdown" className="settings-label dropdown-arrow">
                    <span>Grid</span>
                    <Button
                        className="borderless"
                        title="Reset Gridlines"
                        onClick={() => {
                            dispatch(grid.reset());
                        }}
                    >
                        <i className="fal fa-arrow-rotate-left" />
                    </Button>
                    <input
                        type="checkbox"
                        id="grid-dropdown"
                        checked={open === 'grid'}
                        onChange={(e) => setOpen(e.target.checked)}
                        hidden
                    />
                    <div className="arrow" />
                </label>
                <ToggleSwitch
                    id="show-grid"
                    checked={visible}
                    onChange={(e) => dispatch(grid.setVisibility(e.target.checked))}
                />
            </div>
            {open === 'grid' ? (
                <div className="settings-dropdown">
                    <div className="settings-row">
                        <span>Vertical Grid</span>
                        <ToggleSwitch
                            id="grid-vertical"
                            checked={showSides}
                            onChange={(e) => dispatch(grid.setSidesVisibility(e.target.checked))}
                        />
                    </div>
                    <div className="settings-row">
                        <span>Ceiling Grid</span>
                        <ToggleSwitch
                            id="grid-ceiling"
                            checked={showCeiling}
                            onChange={(e) => dispatch(grid.setCeilingVisibility(e.target.checked))}
                        />
                    </div>
                    <div className="settings-row">
                        <span>Show Labels</span>
                        <ToggleSwitch
                            id="grid-labels"
                            checked={showLabels}
                            onChange={(e) => dispatch(grid.setLabelsVisibility(e.target.checked))}
                        />
                    </div>
                    <div className="settings-row">
                        <span>Color</span>
                        <ColorSelector color={color} onChange={(c) => dispatch(grid.setColor(toHex(c)))} />
                    </div>
                    <SettingSlider
                        value={opacity}
                        title="Opacity"
                        format="percent"
                        min={0}
                        max={1}
                        step={0.01}
                        onChange={(v) => dispatch(grid.setOpacity(v))}
                    />
                    <div className="settings-row">
                        <span>Floor level</span>
                        <div className="settings-input">
                            <input
                                title="floorLevel"
                                type="number"
                                value={floor?.toFixed(2) ?? 0}
                                onChange={(e) => changeFloorLevel(e.target.valueAsNumber)}
                                onBlur={() => changeFloorLevel(floor)}
                            />
                            m
                        </div>
                    </div>
                    <div className="settings-row">
                        <span>Ceiling level</span>
                        <div className="settings-input">
                            <input
                                title="ceilingLevel"
                                type="number"
                                value={ceiling?.toFixed(2) ?? 0}
                                onChange={(e) => changeCeilingLevel(e.target.valueAsNumber)}
                                onBlur={() => changeCeilingLevel(ceiling)}
                            />
                            m
                        </div>
                    </div>
                    {ticks != null ? (
                        <>
                            <div className="settings-row">
                                <span>X ticks</span>
                                <SettingsSelect
                                    value={{ label: `${ticks.x}m`, value: ticks.x }}
                                    onChange={(e) => changeTicks({ x: e.value, y: ticks.y, z: ticks.z })}
                                    options={TICKS_PRESETS.sort((a, b) => a - b).map((x) => ({
                                        label: `${x}m`,
                                        value: x,
                                    }))}
                                />
                            </div>
                            <div className="settings-row">
                                <span>Y ticks</span>
                                <SettingsSelect
                                    value={{ label: `${ticks.y}m`, value: ticks.y }}
                                    onChange={(e) => changeTicks({ x: ticks.x, y: e.value, z: ticks.z })}
                                    options={TICKS_PRESETS.sort((a, b) => a - b).map((x) => ({
                                        label: `${x}m`,
                                        value: x,
                                    }))}
                                />
                            </div>
                            <div className="settings-row">
                                <span>Z ticks</span>
                                <SettingsSelect
                                    value={{ label: `${ticks.z}m`, value: ticks.z }}
                                    onChange={(e) => changeTicks({ x: ticks.x, y: ticks.y, z: e.value })}
                                    options={TICKS_PRESETS.sort((a, b) => a - b).map((x) => ({
                                        label: `${x}m`,
                                        value: x,
                                    }))}
                                />
                            </div>
                        </>
                    ) : null}
                </div>
            ) : null}
        </div>
    );
}

export default GridSetting;
