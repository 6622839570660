import type { IsPointCloud, LayerState } from 'types/LayerState';
import { useDispatch } from 'react-redux';

import * as layers from 'redux/layers';
import { useAppSelector } from 'store';
import { DEFAULT_POINTCLOUD_SETTINGS } from 'services/Constants';
import Slider from '../Slider';

type PointCloudLayer = LayerState & IsPointCloud;

export type Props = {
    layer: PointCloudLayer;
};

const PointCloudPointSizeSetting = ({ layer }: Props) => {
    const dispatch = useDispatch();
    const pointSize = useAppSelector(layers.getPointCloudSize(layer));

    const setPointSize = (value: number) => {
        dispatch(layers.setPointCloudSize({ layer, value }));
    };

    return (
        <div className="row">
            <label htmlFor="pointSize" className="col-sm-5 col-form-label">
                <i className="fa fa-circle-o fa-fw" /> Point Size
            </label>
            <div className="col-sm-7">
                <div className="input-group">
                    <Slider
                        id="pointSize"
                        min={DEFAULT_POINTCLOUD_SETTINGS.MIN_POINT_SIZE}
                        max={DEFAULT_POINTCLOUD_SETTINGS.MAX_POINT_SIZE}
                        step={0.1}
                        value={pointSize}
                        onChange={(v) => setPointSize(v)}
                    />
                </div>
            </div>
        </div>
    );
};

const PointCloudSseThresholdSetting = ({ layer }: Props) => {
    const dispatch = useDispatch();
    const threshold = useAppSelector(layers.getSseThreshold(layer));

    /**
     * For user-friendliness purposes, SSE threshold is reversely mapped to the point density.
     * Normally, the lower the denser, but it is more intuitive to have high values maps to higher densities,
     * hence the mirroring.
     * @param value
     */
    function reverse(v: number): number {
        return DEFAULT_POINTCLOUD_SETTINGS.MAX_SSE_THRESHOLD - v;
    }

    const setThreshold = (value: number) => {
        dispatch(layers.setPointCloudSseThreshold({ layer, value }));
    };

    return (
        <div className="row">
            <label htmlFor="sseThreshold" className="col-sm-5 col-form-label">
                <i className="fa-solid fa-arrow-up-right-dots" /> Point density
            </label>
            <div className="col-sm-7">
                <div className="input-group">
                    <Slider
                        id="sseThreshold"
                        min={DEFAULT_POINTCLOUD_SETTINGS.MIN_SSE_THRESHOLD}
                        max={DEFAULT_POINTCLOUD_SETTINGS.MAX_SSE_THRESHOLD}
                        step={1}
                        value={reverse(threshold)}
                        onChange={(v) => setThreshold(reverse(v))}
                    />
                </div>
            </div>
        </div>
    );
};

const PointCloudSettings = (props: Props) => {
    const { layer } = props;
    const id = layer.datasetId;

    return (
        <>
            <PointCloudPointSizeSetting key={`datasetsettings-${id}-pointcloudpointsize`} layer={layer} />
            <PointCloudSseThresholdSetting key={`datasetsettings-${id}-pointcloudssethreshold`} layer={layer} />
        </>
    );
};

export default PointCloudSettings;
