import React, { useState } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import { useSelector } from 'react-redux';
import { Button, Modal, UncontrolledTooltip } from 'reactstrap';
import { getProjects, getUser } from '../../../redux/selectors';
import overviewMapService from '../../../services/OverviewMapService';
import CreateProject from '../../giro3d/CreateProject';

const ProjectToolsBar = () => {
    const user = useSelector(getUser);
    const projects = useSelector(getProjects);

    const [createProject, setCreateProject] = useState(false);

    const goToFeature = (element) => {
        if (element.olFeature !== null) overviewMapService.goToItem(element);
        else overviewMapService.unselectItem(null, null);
    };

    return (
        <div className="overview-project-tools">
            <div className="search-bar">
                <Typeahead
                    id="search-bar"
                    placeholder="Search Projects"
                    options={projects.sort((a, b) => {
                        if (a.name.toUpperCase() < b.name.toUpperCase()) return -1;
                        if (a.name.toUpperCase() > b.name.toUpperCase()) return 1;
                        return 0;
                    })}
                    onChange={(selection) => {
                        if (selection?.length !== 0) goToFeature(selection[0]);
                    }}
                    labelKey="name"
                />
            </div>
            <Button
                id="create-project-toolbar"
                className="circle green"
                onClick={() => setCreateProject(true)}
                disabled={
                    user.memberships.filter((membership) => membership.organization.user_permissions.create_projects)
                        .length === 0 && !user.is_admin
                }
            >
                <i className="fal fa-plus" />
            </Button>
            <UncontrolledTooltip target="create-project-toolbar">
                {user.memberships.filter((membership) => membership.organization.user_permissions.create_projects)
                    .length === 0 && !user.is_admin
                    ? 'You do not have the permissions to create projects.'
                    : 'Create project'}
            </UncontrolledTooltip>
            <Modal id="projects-pane" isOpen={createProject}>
                <CreateProject endCreate={() => setCreateProject(false)} />
            </Modal>
        </div>
    );
};

export default ProjectToolsBar;
