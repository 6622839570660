// React
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, UncontrolledTooltip } from 'reactstrap';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { useDraggable } from '@dnd-kit/core';

import { DatasetId } from 'types/common';

import { HasDraping, hasDraping, LayerState } from 'types/LayerState';

import * as layers from 'redux/layers';
import * as datasetsSlice from 'redux/datasets';

import { LAYER_STATES, LAYER_TYPES } from 'services/Constants';
import { loadSeismicView, setClickedDataset } from 'redux/actions';
import { useEventBus } from 'EventBus';
import { useAppSelector } from 'store';
import DatasetIndicator from './DatasetIndicator';
import RemoveDataset from './RemoveDataset';
import InlineDropMenu from '../../InlineDropMenu';
import ToggleSwitch from '../../ToggleSwitch';
import DatasetSettingsPopover from './DatasetSettingsPopover';

const selfRelevantEqual = (id: DatasetId) => (left: DatasetId, right: DatasetId) =>
    left === right || !(left === id || right === id);

export type handleMode = 'sort' | 'group';

export type Props = {
    id: string;
    datasetId: DatasetId;
    groupOpen: boolean;
    warnNotDisplayable?: boolean;
    handleMode: handleMode;
};

const DatasetListItem = (props: Props) => {
    const { id, datasetId, groupOpen, handleMode } = props;
    const dispatch = useDispatch();

    const { attributes, listeners, setNodeRef, transform, transition } =
        handleMode === 'sort'
            ? useSortable({ id: datasetId })
            : { ...useDraggable({ id: datasetId }), transition: undefined };

    const clickedDataset = useAppSelector(datasetsSlice.getClickedDataset, selfRelevantEqual(datasetId));
    const [settingsOpen, setSettingsOpen] = useState(false);
    const eventBus = useEventBus();

    const dataset = useAppSelector(datasetsSlice.get(datasetId));
    const state = dataset.state;

    const layerState = useAppSelector(layers.get(datasetId));
    const supportsDraping = hasDraping(layerState) ?? false;
    const isVisible = useAppSelector(layers.isVisibleSelf(layerState));
    const isDraped = useAppSelector(layers.isDraped(layerState as LayerState & HasDraping)) ?? false;

    const setVisible = (value: boolean) => {
        dispatch(layers.setVisibility({ layer: layerState, value }));
    };

    const selectDataset = () => {
        eventBus.dispatch('highlight-layer', { layer: datasetId });
        dispatch(setClickedDataset(datasetId));
    };

    const inspectDataset = () => {
        dispatch(datasetsSlice.setInspectedDataset(dataset.id));
    };

    if (transform) transform.scaleY = 1;
    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    function goToLayer() {
        eventBus.dispatch('go-to-layer', { layer: layerState.datasetId });
    }

    const handle = () => {
        switch (handleMode) {
            case 'sort':
                if (supportsDraping && !isDraped) {
                    // Layer is not drapped (reordering has no effect),
                    // let the user reorder it but display it has no effect
                    return (
                        <Button
                            id={`dataset-handle-${id}`}
                            className="borderless handle"
                            {...listeners}
                            {...attributes}
                        >
                            <span className="fa-stack" style={{ width: '18px' }}>
                                <i className="fa-light fa-grip-vertical fa-stack-1x" />
                                <i className="fa-light fa-slash fa-stack-1x" />
                            </span>
                            <UncontrolledTooltip placement="right" target={`dataset-handle-${id}`}>
                                The ordering does not affect the rendering of this layer as it is not draped on
                                bathymetry
                            </UncontrolledTooltip>
                        </Button>
                    );
                }
            // falls through
            case 'group':
                return (
                    <Button className="borderless handle" {...listeners} {...attributes}>
                        <i className="fa-light fa-grip-vertical" />
                    </Button>
                );
            default:
                return null;
        }
    };

    const disabled = state !== LAYER_STATES.LOADED && state !== LAYER_STATES.ACTIVE;

    return (
        <div
            className={`data-item ${groupOpen && clickedDataset === datasetId ? 'highlighted' : ''}`}
            hidden={!groupOpen}
            ref={setNodeRef}
            style={style}
        >
            {handle()}
            <Button
                className="borderless list-item-title"
                id={`dataset-name-${id}`}
                onClick={() => {
                    selectDataset();
                }}
                onDoubleClick={() => goToLayer()}
            >
                {props.warnNotDisplayable ? (
                    <>
                        <i className="fa-light fa-eye-slash me-1" />
                        <UncontrolledTooltip placement="top" target={`dataset-name-${id}`}>
                            This layer is not displayable because it is beneath all bathymetry layers
                        </UncontrolledTooltip>
                    </>
                ) : null}
                {dataset.name}
            </Button>
            <DatasetIndicator id={id} dataset={dataset} />
            <InlineDropMenu id={`dataset-actions-${id}`}>
                {dataset.user_permissions.update_dataset && <RemoveDataset id={id} dataset={dataset} />}
                <Button
                    id={`datasetbtn-inspect-${id}`}
                    className="borderless green"
                    title={`Inspect ${dataset.name}`}
                    onClick={() => {
                        selectDataset();
                        inspectDataset();
                    }}
                >
                    <i className="far fa-info-circle" />
                </Button>
                {dataset.type === LAYER_TYPES.SEISMIC ? (
                    <Button
                        className="borderless light-blue"
                        id={`dataset-seismic-view-${id}`}
                        title={`View ${dataset.name} in 2D`}
                        onClick={() => dispatch(loadSeismicView(dataset))}
                        disabled={disabled}
                    >
                        2D
                    </Button>
                ) : null}
                <Button
                    className="borderless yellow"
                    id={`dataset-settings-${id}`}
                    title={`${dataset.name} settings`}
                    onClick={() => {
                        selectDataset();
                        setSettingsOpen(true);
                    }}
                    disabled={disabled}
                >
                    <i className="fal fa-gear" />
                </Button>
            </InlineDropMenu>
            <ToggleSwitch
                id={`visible-${id}`}
                checked={isVisible}
                onChange={(e) => setVisible(e.target.checked)}
                disabled={disabled}
            />
            <DatasetSettingsPopover
                dataset={dataset}
                target={`dataset-actions-${id}`}
                isOpen={settingsOpen}
                onToggle={() => setSettingsOpen(false)}
            />
        </div>
    );
};

export default DatasetListItem;
