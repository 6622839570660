import { useEffect, useRef } from 'react';

import * as FunctionCurveEditor from '@giro3d/function-curve-editor';

import { CurveKnot } from 'types/Curve';

export type Props = {
    /**
     * The height of the component, in pixels.
     */
    height?: number;
    xMin?: number;
    xMax?: number;
    yMin?: number;
    yMax?: number;
    /**
     * The initial knots of the curve.
     */
    knots?: Array<CurveKnot>;
    interpolation?: FunctionCurveEditor.InterpolationMethod;
    onChange: (knots: Array<CurveKnot>) => void;
    /**
     * Changing this number will reset the curve editor to its default values.
     */
    revision: number;
};

function getDefaultKnots() {
    return [
        { x: 0, y: 1 },
        { x: 1, y: 1 },
    ];
}

const DEFAULT_HEIGHT = 128;
const DEFAULT_INTERPOLATION: FunctionCurveEditor.InterpolationMethod = 'linear';

function normalize(knots: CurveKnot[], xMin: number, xMax: number, yMin: number, yMax: number): CurveKnot[] {
    const xScale = xMax - xMin;
    const yScale = yMax - yMin;

    return knots.map((k) => ({
        x: (k.x - xMin) / xScale,
        y: (k.y - yMin) / yScale,
    }));
}

function expand(knots: CurveKnot[], xMin: number, xMax: number, yMin: number, yMax: number): CurveKnot[] {
    const xScale = xMax - xMin;
    const yScale = yMax - yMin;

    return knots.map((k) => ({
        x: xScale * k.x + xMin,
        y: yScale * k.y + yMin,
    }));
}

/**
 * Wraps the https://github.com/chdh/function-curve-editor library in a React component.
 *
 * Note: we use a fork of this library (https://github.com/sguimmara/function-curve-editor)
 * that adds configurable colors (background, curve, points...).
 * See this PR: https://github.com/chdh/function-curve-editor/pull/3
 */
function CurveEditor(props: Props) {
    const canvasRef = useRef<HTMLCanvasElement>();

    const height = props.height ?? DEFAULT_HEIGHT;
    const xMin = props.xMin ?? 0;
    const xMax = props.xMax ?? 1;
    const yMin = props.yMin ?? 0;
    const yMax = props.yMax ?? 1;
    const knots = expand(props.knots ?? getDefaultKnots(), xMin, xMax, yMin, yMax);

    useEffect(() => {
        const canvas = canvasRef.current;
        canvas.height = height;

        const widget = new FunctionCurveEditor.Widget(canvas);

        widget.setEditorState({
            interpolationMethod: props.interpolation ?? DEFAULT_INTERPOLATION,
            knots,
            snapToGridEnabled: true,
            xMin,
            xMax,
            yMin,
            yMax,
            extendedDomain: true,
            relevantXMin: xMin,
            relevantXMax: xMax,
            secondaryBackground: 'black',
            activeKnotColor: '#ffeab3', // Matches --citrine-4 in theme.css
            defaultKnotColor: '#ffb800', // Matches --citrine-2 in theme.css
            selectedKnotColor: '#0f6eff', // Matches --saphire-2 in theme.css
            background: '#272727', // Matches --dark-gray-1 in theme.css
            curveColor: '#ffb800', // Matches --citrine-2 in theme.css
            axisColor: 'white',
            primaryLineColor: '#414042', // Matches --dark-gray-2 in theme.css
            secondaryLineColor: '#414042', // Matches --dark-gray-2 in theme.css
        });

        const changeHandler = () => props.onChange(normalize(widget.getEditorState().knots, xMin, xMax, yMin, yMax));

        widget.addEventListener('change', changeHandler);

        return () => {
            widget.removeEventListener('change', changeHandler);
            widget.setConnected(false);
        };
    }, [props.revision]);

    return <canvas ref={canvasRef} className="w-100" style={{ height }} />;
}

export default CurveEditor;
