import { Extent } from '@giro3d/giro3d/core/geographic';
import type { Feature, Geometry, Polygon, Position } from 'geojson';
import * as ol from 'ol/geom';
import bbox from '@turf/bbox';
import convex from '@turf/convex';
import proj4 from 'proj4';
import { AllGeoJSON } from '@turf/helpers';
import { GeometryWithCRS } from 'types/common';

export function computeExtent(geojson: Feature | Geometry, crs: string, targetCrs?: string): Extent {
    const [west, south, east, north] = bbox(geojson);

    return new Extent(crs, {
        west,
        east,
        north,
        south,
    }).as(targetCrs ?? crs);
}

export function getConvexHull(geometry: Geometry): Geometry {
    const convexHull = convex(geometry as AllGeoJSON);
    return convexHull.geometry;
}

export function simplify(geojson: Geometry, tolerance: number): Geometry {
    const olGeom = toOpenLayersGeometry(geojson);
    const simplified = olGeom.simplify(tolerance);
    return toGeoJSON(simplified);
}

export function cloneGeoJSON<T extends Geometry | Feature>(geojson: T): T {
    return JSON.parse(JSON.stringify(geojson)) as T;
}

export function getCoordinateCount(geojson: Feature | Geometry): number {
    let result = 0;

    const count = (geometry: Geometry) => {
        switch (geometry.type) {
            case 'Point':
                result += 1;
                break;
            case 'MultiPoint':
            case 'LineString':
                result += geometry.coordinates.length;
                break;
            case 'Polygon':
                for (const ring of geometry.coordinates) {
                    result += ring.length;
                }
                break;
            case 'MultiLineString':
                for (const lineString of geometry.coordinates) {
                    result += lineString.length;
                }
                break;
            case 'MultiPolygon':
                for (const polygon of geometry.coordinates) {
                    for (const ring of polygon) {
                        result += ring.length;
                    }
                }
                break;
            case 'GeometryCollection':
                for (const geom of geometry.geometries) {
                    count(geom);
                }
                break;
            default:
                throw new Error('unhandled');
        }
    };

    if (geojson.type === 'Feature') {
        count(geojson.geometry);
    } else {
        count(geojson);
    }

    return result;
}

function mapCoordinates(geometry: Geometry, f: (pos: Position) => Position): Geometry {
    switch (geometry.type) {
        case 'Point':
            geometry.coordinates = f(geometry.coordinates);
            break;
        case 'MultiPoint':
        case 'LineString':
            geometry.coordinates = geometry.coordinates.map(f);
            break;
        case 'Polygon':
        case 'MultiLineString':
            geometry.coordinates = geometry.coordinates.map((ps) => ps.map(f));
            break;
        case 'MultiPolygon':
            geometry.coordinates = geometry.coordinates.map((pps) => pps.map((ps) => ps.map(f)));
            break;
        case 'GeometryCollection':
            geometry.geometries = geometry.geometries.map((g) => mapCoordinates(g, f));
            break;
        default:
            throw new Error('unhandled');
    }

    return geometry;
}

export function enforceRightHandRule<T extends Geometry>(geometry: T): T {
    if (geometry.type === 'Polygon') {
        const copy = cloneGeoJSON(geometry) as Polygon;
        for (let i = 0; i < copy.coordinates.length; i++) {
            if (!isClockwise(copy.coordinates[i])) {
                copy.coordinates[i].reverse();
            }
        }
        return copy as T;
    }

    return geometry as T;
}

function isClockwise(coords: Position[]) {
    let sum = 0;
    const n = coords.length;

    for (let i = 0; i < n; i++) {
        const currCoord = coords[i];
        const nextCoord = coords[(i + 1) % n];
        sum += (nextCoord[0] - currCoord[0]) * (nextCoord[1] + currCoord[1]); // * (nextCoord[2] - currCoord[2])
    }

    return sum < 0;
}

export function toStandardGeoJSON(geojson: Feature<GeometryWithCRS> | GeometryWithCRS): Feature | Geometry {
    const clone = cloneGeoJSON(geojson);
    if (clone.type === 'Feature') {
        delete clone.geometry.crs;
    } else {
        delete clone.crs;
    }

    return clone;
}

/**
 * Transforms the input geojson from the {@link crsIn} to {@link crsOut} and returns the newly
 * transformed GeoJSON.
 */
export function transform<T extends Geometry | Feature>(geojson: T, crsIn: string, crsOut: string): T {
    const clone = cloneGeoJSON(geojson);

    if (crsIn === crsOut) {
        return clone;
    }

    const geometry: Geometry = clone.type === 'Feature' ? clone.geometry : clone;

    const converter = proj4(crsIn, crsOut);

    function reproject(p: Position): Position {
        const length = p.length;
        let z: number;
        if (length === 3) {
            z = p[2];
        }

        const result = converter.forward(p);

        if (result.length < length) {
            result.push(z);
        }

        return result;
    }

    mapCoordinates(geometry, (p) => reproject(p));

    return clone as T;
}

export function toGeoJSON(geometry: ol.Geometry): Geometry {
    const type = geometry.getType();

    switch (type) {
        case 'Point':
            return { type: 'Point', coordinates: (geometry as ol.Point).getCoordinates() };
        case 'LineString':
            return { type: 'LineString', coordinates: (geometry as ol.LineString).getCoordinates() };
        case 'Polygon':
            return { type: 'Polygon', coordinates: (geometry as ol.Polygon).getCoordinates() };
        case 'MultiPoint':
            return { type: 'MultiPoint', coordinates: (geometry as ol.MultiPoint).getCoordinates() };
        case 'MultiLineString':
            return { type: 'MultiLineString', coordinates: (geometry as ol.MultiLineString).getCoordinates() };
        case 'MultiPolygon':
            return { type: 'MultiPolygon', coordinates: (geometry as ol.MultiPolygon).getCoordinates() };
        default:
            throw new Error(`unsupported geometry type: ${type}`);
    }
}

export function toOpenLayersGeometry(input: Geometry): ol.Geometry {
    const type = input.type;
    switch (type) {
        case 'Point':
            return new ol.Point(input.coordinates);
        case 'MultiPoint':
            return new ol.MultiPoint(input.coordinates);
        case 'LineString':
            return new ol.LineString(input.coordinates);
        case 'MultiLineString':
            return new ol.MultiLineString(input.coordinates);
        case 'Polygon':
            return new ol.Polygon(input.coordinates);
        case 'MultiPolygon':
            return new ol.MultiPolygon(input.coordinates);
        case 'GeometryCollection':
            return new ol.GeometryCollection(input.geometries.map(toOpenLayersGeometry));
        default:
            throw new Error(`unsupported geometry type: ${type}`);
    }
}
