import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactDatePicker from 'react-datepicker';
import { Button } from 'reactstrap';
import ReactSelect, { components } from 'react-select';
import { Formik, Form } from 'formik';
import { getOrganizationsForCreatingDataset } from '../../redux/selectors';
import handleApiError from '../../services/Forms';

import 'react-datepicker/dist/react-datepicker.css';
import { createCollection } from '../../redux/actions';

const AddCollection = ({ onClose, onCreation }) => {
    const dispatch = useDispatch();
    const organizations = useSelector(getOrganizationsForCreatingDataset);

    const initialFormState = {
        name: '',
        organization_id: '',
        start_date: '',
        end_date: '',
    };

    const [currentFormState, setCurrentFormState] = useState(initialFormState);
    useEffect(() => {
        setCurrentFormState(initialFormState);
    }, [organizations]);

    const selectStyling = {
        components: {
            DropdownIndicator: (props) => (
                <components.DropdownIndicator {...props}>
                    <i className="fal fa-angle-down" />
                </components.DropdownIndicator>
            ),
            IndicatorSeparator: () => null,
        },
        classNames: {
            control: () => 'full-form-control',
            valueContainer: () => 'full-form-value-container',
            input: () => 'full-form-input',
            dropdownIndicator: () => 'full-form-dropdown-indicator-container',
            option: () => 'full-form-option',
        },
    };

    const validation = (values) => {
        const errors = {};
        if (!values.name) errors.name = 'Required';
        if (!values.organization_id) errors.organization_id = 'Required';
        return errors;
    };

    const addCollection = (values, helpers) => {
        const startDate = values.start_date
            ? new Date(values.start_date.getTime() - values.start_date.getTimezoneOffset() * 60000)
                  .toISOString()
                  .split('T')[0]
            : null;
        const endDate = values.end_date
            ? new Date(values.end_date.getTime() - values.end_date.getTimezoneOffset() * 60000)
                  .toISOString()
                  .split('T')[0]
            : null;

        const data = {
            name: values.name,
            organization_id: values.organization_id,
        };

        if (startDate) data.start_date = startDate;
        if (endDate) data.end_date = endDate;

        dispatch(createCollection(data))
            .then((collection) => {
                onClose();
                onCreation(collection);
            })
            .catch((err) => {
                handleApiError(err, helpers);
                helpers.setSubmitting(false);
            });
    };

    return (
        <Formik
            enableReinitialize
            initialValues={currentFormState}
            onSubmit={addCollection}
            validate={validation}
            validateOnBlur={false}
            validateOnChange={false}
        >
            {({ values, errors, isSubmitting, handleBlur, handleChange, setFieldValue }) => (
                <Form>
                    <table>
                        <tbody>
                            <tr className={errors.name ? 'error' : ''}>
                                <td>Name:</td>
                                <td>
                                    <input
                                        name="name"
                                        type="text"
                                        placeholder="Enter name... (Required)"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                    />
                                    <span className="error-text">{errors.name}</span>
                                </td>
                            </tr>
                            <tr className={errors.organization_id ? 'error' : ''}>
                                <td>Organization:</td>
                                <td>
                                    <ReactSelect
                                        name="organization"
                                        {...selectStyling}
                                        placeholder="Select... (Required)"
                                        options={[
                                            ...organizations.map((e) => ({
                                                value: e.id,
                                                label: e.display_name,
                                            })),
                                        ]}
                                        onBlur={handleBlur}
                                        onChange={(data) => setFieldValue('organization_id', data.value)}
                                    />
                                    <span className="error-text">{errors.organization_id}</span>
                                </td>
                            </tr>
                            <tr className={errors.start_date ? 'error' : ''}>
                                <td>Start date:</td>
                                <td>
                                    <ReactDatePicker
                                        name="start_date"
                                        popperPlacement="bottom"
                                        placeholderText="Select..."
                                        onBlur={handleBlur}
                                        selected={values.start_date}
                                        onChange={(data) => setFieldValue('start_date', data)}
                                        dateFormat="yyyy-MM-dd"
                                    />
                                    <span className="error-text">{errors.start_date}</span>
                                </td>
                            </tr>
                            <tr className={errors.end_date ? 'error' : ''}>
                                <td>End date:</td>
                                <td>
                                    <ReactDatePicker
                                        name="end_date"
                                        popperPlacement="bottom"
                                        placeholderText="Select..."
                                        onBlur={handleBlur}
                                        selected={values.end_date}
                                        onChange={(data) => setFieldValue('end_date', data)}
                                        dateFormat="yyyy-MM-dd"
                                    />
                                    <span className="error-text">{errors.end_date}</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <span className="error-text">{errors.submit}</span>
                    <Button type="submit" color="primary" disabled={isSubmitting}>
                        Create <i className={`fal fa-${isSubmitting ? 'spinner fa-pulse' : 'arrow-right'}`} />
                    </Button>
                </Form>
            )}
        </Formik>
    );
};

export default AddCollection;
