import chroma from 'chroma-js';

export const CYCLE_SCALE = chroma
    .scale(['#89d51b', '#2daa46', '#2b66be', '#6b03a2', '#c3199c', '#f65d6a', '#f8ae4e', '#f1fb27', '#89d51b'])
    .domain([0, 1 / 8, 2 / 8, 3 / 8, 4 / 8, 5 / 8, 6 / 8, 7 / 8, 1]);

export const GRAYSCALE_SCALE = chroma.scale(['black', 'white']);
export const COPPER_SCALE = chroma.scale(['black', '#ff9f65', '#ffc77e']).domain([0, 51 / 64, 1]);

// domain specifies the position of each color. This colormap interpolates between:
// 1. white and dark orange from 0 -> 0.1
// 2. dark orange and black from 0.1 -> 0.99
export const SEISMIC_SCALE = chroma.scale(['white', '#cc7000', 'black']).domain([0, 0.1, 0.99]);

export const KLEIN_SCALE = chroma.scale([
    chroma(0, 0, 0),
    chroma(1, 1, 0),
    chroma(3, 2, 0),
    chroma(5, 3, 0),
    chroma(7, 5, 0),
    chroma(9, 6, 0),
    chroma(11, 7, 0),
    chroma(13, 9, 0),
    chroma(15, 10, 0),
    chroma(17, 11, 0),
    chroma(19, 13, 0),
    chroma(21, 14, 0),
    chroma(23, 15, 0),
    chroma(25, 17, 0),
    chroma(27, 18, 0),
    chroma(29, 19, 0),
    chroma(31, 21, 0),
    chroma(33, 22, 0),
    chroma(35, 23, 0),
    chroma(37, 25, 0),
    chroma(39, 26, 0),
    chroma(41, 27, 0),
    chroma(43, 29, 0),
    chroma(45, 30, 0),
    chroma(47, 31, 0),
    chroma(49, 33, 0),
    chroma(51, 34, 0),
    chroma(53, 35, 0),
    chroma(55, 37, 0),
    chroma(57, 38, 0),
    chroma(59, 39, 0),
    chroma(61, 41, 0),
    chroma(63, 42, 0),
    chroma(65, 43, 0),
    chroma(67, 45, 0),
    chroma(69, 46, 0),
    chroma(71, 47, 0),
    chroma(73, 49, 0),
    chroma(75, 50, 0),
    chroma(77, 51, 0),
    chroma(79, 53, 0),
    chroma(81, 54, 0),
    chroma(83, 55, 0),
    chroma(85, 57, 0),
    chroma(87, 58, 0),
    chroma(89, 59, 0),
    chroma(91, 61, 0),
    chroma(93, 62, 0),
    chroma(95, 63, 0),
    chroma(97, 65, 0),
    chroma(99, 66, 0),
    chroma(101, 67, 0),
    chroma(103, 69, 0),
    chroma(105, 70, 0),
    chroma(107, 71, 0),
    chroma(109, 73, 0),
    chroma(111, 74, 0),
    chroma(113, 75, 0),
    chroma(115, 77, 0),
    chroma(117, 78, 0),
    chroma(119, 79, 0),
    chroma(121, 81, 0),
    chroma(123, 82, 0),
    chroma(125, 83, 0),
    chroma(127, 85, 0),
    chroma(129, 86, 0),
    chroma(131, 87, 0),
    chroma(133, 89, 0),
    chroma(135, 90, 0),
    chroma(137, 91, 0),
    chroma(139, 93, 0),
    chroma(141, 94, 0),
    chroma(143, 95, 0),
    chroma(145, 97, 0),
    chroma(147, 98, 0),
    chroma(149, 99, 0),
    chroma(151, 101, 0),
    chroma(153, 102, 0),
    chroma(155, 103, 0),
    chroma(157, 105, 0),
    chroma(159, 106, 0),
    chroma(161, 107, 0),
    chroma(163, 109, 0),
    chroma(165, 110, 0),
    chroma(167, 111, 0),
    chroma(169, 113, 0),
    chroma(171, 114, 0),
    chroma(173, 115, 0),
    chroma(175, 117, 0),
    chroma(177, 118, 0),
    chroma(179, 119, 0),
    chroma(181, 121, 0),
    chroma(183, 122, 0),
    chroma(185, 123, 0),
    chroma(187, 125, 0),
    chroma(189, 126, 0),
    chroma(191, 127, 0),
    chroma(193, 129, 0),
    chroma(195, 130, 0),
    chroma(197, 131, 0),
    chroma(199, 133, 0),
    chroma(201, 134, 0),
    chroma(203, 135, 0),
    chroma(205, 137, 0),
    chroma(207, 138, 0),
    chroma(209, 139, 0),
    chroma(211, 141, 0),
    chroma(213, 142, 0),
    chroma(215, 143, 0),
    chroma(217, 145, 0),
    chroma(219, 146, 0),
    chroma(221, 147, 0),
    chroma(223, 149, 0),
    chroma(225, 150, 0),
    chroma(227, 151, 0),
    chroma(229, 153, 0),
    chroma(231, 154, 0),
    chroma(233, 155, 0),
    chroma(235, 157, 0),
    chroma(237, 158, 0),
    chroma(239, 159, 0),
    chroma(241, 161, 0),
    chroma(243, 162, 0),
    chroma(245, 163, 0),
    chroma(247, 165, 0),
    chroma(249, 166, 0),
    chroma(251, 167, 0),
    chroma(253, 169, 0),
    chroma(255, 170, 0),
    chroma(255, 170, 2),
    chroma(255, 171, 4),
    chroma(255, 172, 6),
    chroma(255, 172, 8),
    chroma(255, 173, 10),
    chroma(255, 174, 12),
    chroma(255, 174, 14),
    chroma(255, 175, 16),
    chroma(255, 176, 18),
    chroma(255, 176, 20),
    chroma(255, 177, 22),
    chroma(255, 178, 24),
    chroma(255, 178, 26),
    chroma(255, 179, 28),
    chroma(255, 180, 30),
    chroma(255, 180, 32),
    chroma(255, 181, 34),
    chroma(255, 182, 36),
    chroma(255, 182, 38),
    chroma(255, 183, 40),
    chroma(255, 184, 42),
    chroma(255, 184, 44),
    chroma(255, 185, 46),
    chroma(255, 186, 48),
    chroma(255, 186, 50),
    chroma(255, 187, 52),
    chroma(255, 188, 54),
    chroma(255, 188, 56),
    chroma(255, 189, 58),
    chroma(255, 190, 60),
    chroma(255, 190, 62),
    chroma(255, 191, 64),
    chroma(255, 192, 66),
    chroma(255, 192, 68),
    chroma(255, 193, 70),
    chroma(255, 194, 72),
    chroma(255, 194, 74),
    chroma(255, 195, 76),
    chroma(255, 196, 78),
    chroma(255, 196, 80),
    chroma(255, 197, 82),
    chroma(255, 198, 84),
    chroma(255, 198, 86),
    chroma(255, 199, 88),
    chroma(255, 200, 90),
    chroma(255, 200, 92),
    chroma(255, 201, 94),
    chroma(255, 202, 96),
    chroma(255, 202, 98),
    chroma(255, 203, 100),
    chroma(255, 204, 102),
    chroma(255, 204, 104),
    chroma(255, 205, 106),
    chroma(255, 206, 108),
    chroma(255, 206, 110),
    chroma(255, 207, 112),
    chroma(255, 208, 114),
    chroma(255, 208, 116),
    chroma(255, 209, 118),
    chroma(255, 210, 120),
    chroma(255, 210, 122),
    chroma(255, 211, 124),
    chroma(255, 212, 126),
    chroma(255, 212, 128),
    chroma(255, 213, 130),
    chroma(255, 214, 132),
    chroma(255, 214, 134),
    chroma(255, 215, 136),
    chroma(255, 216, 138),
    chroma(255, 216, 140),
    chroma(255, 217, 142),
    chroma(255, 218, 144),
    chroma(255, 218, 146),
    chroma(255, 219, 148),
    chroma(255, 220, 150),
    chroma(255, 220, 152),
    chroma(255, 221, 154),
    chroma(255, 222, 156),
    chroma(255, 222, 158),
    chroma(255, 223, 160),
    chroma(255, 224, 162),
    chroma(255, 224, 164),
    chroma(255, 225, 166),
    chroma(255, 226, 168),
    chroma(255, 226, 170),
    chroma(255, 227, 172),
    chroma(255, 228, 174),
    chroma(255, 228, 176),
    chroma(255, 229, 178),
    chroma(255, 230, 180),
    chroma(255, 230, 182),
    chroma(255, 231, 184),
    chroma(255, 232, 186),
    chroma(255, 232, 188),
    chroma(255, 233, 190),
    chroma(255, 234, 192),
    chroma(255, 234, 194),
    chroma(255, 235, 196),
    chroma(255, 236, 198),
    chroma(255, 236, 200),
    chroma(255, 237, 202),
    chroma(255, 238, 204),
    chroma(255, 238, 206),
    chroma(255, 239, 208),
    chroma(255, 240, 210),
    chroma(255, 240, 212),
    chroma(255, 241, 214),
    chroma(255, 242, 216),
    chroma(255, 242, 218),
    chroma(255, 243, 220),
    chroma(255, 244, 222),
    chroma(255, 244, 224),
    chroma(255, 245, 226),
    chroma(255, 246, 228),
    chroma(255, 246, 230),
    chroma(255, 247, 232),
    chroma(255, 248, 234),
    chroma(255, 248, 236),
    chroma(255, 249, 238),
    chroma(255, 250, 240),
    chroma(255, 250, 242),
    chroma(255, 251, 244),
    chroma(255, 252, 246),
    chroma(255, 252, 248),
    chroma(255, 253, 250),
    chroma(255, 254, 252),
    chroma(255, 254, 254),
]);
