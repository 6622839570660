// React
import React from 'react';

// Redux
import ZScaleSetting from './ZScaleSetting';
import HillshadingSetting from './HillshadingSetting';
import GridSetting from './GridSetting';
import AdvancedSetting from './AdvancedSetting';
import PointCloudSettings from './PointCloudSettings';
import ContourLineSetting from './ContourLineSetting';
import CrossSectionSettings from './CrossSectionSettings';

const Giro3dSettingsMenu = () => (
    <>
        <div className="map-pane-title">Settings</div>
        <div className="map-pane-body spacer settings-pane">
            <GridSetting />
            <HillshadingSetting />
            <PointCloudSettings />
            <ZScaleSetting />
            <ContourLineSetting />
            <CrossSectionSettings />
            <AdvancedSetting />
        </div>
    </>
);
export default Giro3dSettingsMenu;
