// React
import React from 'react';
import classnames from 'classnames';
import { useLocation } from 'react-router-dom';

import { Nav, Navbar, NavItem, NavLink, UncontrolledTooltip, TabContent, TabPane } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';

// Redux
import { getNotifications, getPage, getRightTab } from './redux/selectors';
import { selectRightTab } from './redux/actions';
import { PAGE, RIGHT_TAB } from './Navigation';

import ProfileContent from './components/authentication/ProfileContent';
import NotificationsMenu from './components/notification/NotificationsMenu';
import BuildInfo from './components/buildInfo/BuildInfo';
import Giro3dSettingsMenu from './components/giro3d/settingsMenu/Giro3dSettingsMenu';
import AnnotationMenu from './components/giro3d/annotationMenu/AnnotationMenu';
import AttributesMenu from './components/giro3d/attributesMenu/AttributesMenu';
import MeasureMenu from './components/giro3d/measureMenu/MeasureMenu';
import MeasurementTool from './components/overview/measurementWindow/MeasurementTool';
import ViewTool from './components/overview/viewWindow/ViewTool';
import ErrorBoundary from './components/ErrorBoundary';
import ElevationProfileMenu from './components/giro3d/ElevationProfileMenu/ElevationProfileMenu';
import LazyTabPane from './components/LazyTabPane';
import UploadsMenu from './components/uploads/UploadsMenu';

const PageMenu = () => {
    const dispatch = useDispatch();

    const currentPage = useSelector(getPage);
    const currentTab = useSelector(getRightTab);
    const notifications = (useSelector(getNotifications) || []).filter((x) => x.read === false);

    const getQueryStringParams = (query) =>
        query
            ? (/^[?#]/.test(query) ? query.slice(1) : query).split('&').reduce((params, param) => {
                  const [key, value] = param.split('=');
                  params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
                  return params;
              }, {})
            : {};
    const queryParams = getQueryStringParams(useLocation().search);

    const goToTab = (tab) => {
        if (currentTab !== tab) {
            dispatch(selectRightTab(tab));
        } else if (currentTab) {
            dispatch(selectRightTab(null));
        }
    };

    const navTabItem = (tabId, pageId, title, iconName) =>
        pageId === null || pageId === currentPage ? (
            <NavItem
                className={classnames({
                    active: currentTab === tabId,
                })}
                key={tabId}
            >
                <NavLink className="nav-link" onClick={() => goToTab(tabId)} id={tabId} active={currentTab === tabId}>
                    <span className="fa-stack">
                        <i className="fas fa-circle fa-stack-2x icon-background" />
                        <i className={`${iconName} fa-stack-1x`} />
                        {tabId === RIGHT_TAB.NOTIFICATIONS && notifications.length !== 0 ? (
                            <div className="notification-bubble">
                                <i className="fas fa-circle" />
                                <span>{notifications.length < 100 ? notifications.length : '#'}</span>
                            </div>
                        ) : null}
                    </span>
                    <span>{title}</span>
                    <UncontrolledTooltip placement="left" target={tabId}>
                        <span className="menu-tooltip">{title}</span>
                    </UncontrolledTooltip>
                </NavLink>
            </NavItem>
        ) : null;

    const tabPaneMenu = (tabId, menuComponent) => (
        <ErrorBoundary
            dispatch={dispatch}
            fallback={
                <TabPane tabId={tabId}>
                    <span className="error-fallback-message">
                        <i className="fal fa-exclamation-triangle icon-red" />
                        An error occured in the {tabId}. Reload the page to use it.
                        <i className="fal fa-exclamation-triangle icon-red" />
                    </span>
                </TabPane>
            }
        >
            <LazyTabPane tabId={tabId} currentTab={currentTab}>
                {menuComponent}
            </LazyTabPane>
        </ErrorBoundary>
    );

    return (
        <div className="map-menu map-menu-right">
            <div className="menu-left">
                <div className="map-menu-list">
                    <Nav tabs vertical>
                        {navTabItem(RIGHT_TAB.USER, null, 'User', 'fal fa-user')}
                        {navTabItem(RIGHT_TAB.NOTIFICATIONS, null, 'Notifications', 'fal fa-bell disabled')}
                        {navTabItem(RIGHT_TAB.BUILD, null, 'Build Information', 'fal fa-circle-question')}
                        <li>
                            <hr />
                        </li>
                        {navTabItem(RIGHT_TAB.UPLOADS, null, 'Uploads', 'fal fa-upload')}
                        <li>
                            <hr />
                        </li>
                        {navTabItem(RIGHT_TAB.PROJECT_SETTINGS, PAGE.PROJECT, 'Settings', 'fal fa-sliders')}
                        {navTabItem(RIGHT_TAB.PROJECT_ANNOTATIONS, PAGE.PROJECT, 'Annotations', 'fal fa-note-sticky')}
                        {navTabItem(RIGHT_TAB.PROJECT_MEASURE, PAGE.PROJECT, 'Measure', 'fal fa-ruler')}
                        {navTabItem(RIGHT_TAB.PROJECT_ATTRIBUTES, PAGE.PROJECT, 'Attributes', 'fal fa-table')}
                        {navTabItem(
                            RIGHT_TAB.PROJECT_ELEVATION,
                            PAGE.PROJECT,
                            'Elevation Profile',
                            'fal fa-chart-line'
                        )}
                        {navTabItem(RIGHT_TAB.OVERVIEW_MEASURE, PAGE.OVERVIEW, 'Measure', 'fal fa-ruler')}
                        {navTabItem(RIGHT_TAB.OVERVIEW_VIEW, PAGE.OVERVIEW, 'View', 'fal fa-cube')}
                    </Nav>
                </div>
            </div>
            <div className="menu-left sub-menu">
                <TabContent activeTab={currentTab} className={!currentTab ? 'closed' : undefined}>
                    <Navbar>
                        <div title="Close" className="menu-close">
                            <NavLink onClick={() => goToTab(null)} id="page-menu-close">
                                <span className="fa-stack">
                                    <i className="fas fa-circle fa-stack-2x icon-background" />
                                    <i className="fal fa-arrow-right-long fa-stack-1x" />
                                </span>
                                <UncontrolledTooltip placement="right" target="page-menu-close">
                                    <span className="menu-tooltip">Close</span>
                                </UncontrolledTooltip>
                            </NavLink>
                        </div>
                    </Navbar>
                    {tabPaneMenu(RIGHT_TAB.USER, <ProfileContent />)}
                    {tabPaneMenu(RIGHT_TAB.NOTIFICATIONS, <NotificationsMenu close={() => goToTab(null)} />)}
                    {tabPaneMenu(RIGHT_TAB.BUILD, <BuildInfo />)}
                    {tabPaneMenu(RIGHT_TAB.UPLOADS, <UploadsMenu />)}
                    {currentPage === PAGE.PROJECT
                        ? tabPaneMenu(RIGHT_TAB.PROJECT_SETTINGS, <Giro3dSettingsMenu />)
                        : null}
                    {currentPage === PAGE.PROJECT
                        ? tabPaneMenu(RIGHT_TAB.PROJECT_ANNOTATIONS, <AnnotationMenu queryParams={queryParams} />)
                        : null}
                    {currentPage === PAGE.PROJECT
                        ? tabPaneMenu(RIGHT_TAB.PROJECT_ATTRIBUTES, <AttributesMenu />)
                        : null}
                    {currentPage === PAGE.PROJECT ? tabPaneMenu(RIGHT_TAB.PROJECT_MEASURE, <MeasureMenu />) : null}
                    {currentPage === PAGE.PROJECT
                        ? tabPaneMenu(RIGHT_TAB.PROJECT_ELEVATION, <ElevationProfileMenu />)
                        : null}
                    {currentPage === PAGE.OVERVIEW
                        ? tabPaneMenu(RIGHT_TAB.OVERVIEW_MEASURE, <MeasurementTool />)
                        : null}
                    {currentPage === PAGE.OVERVIEW ? tabPaneMenu(RIGHT_TAB.OVERVIEW_VIEW, <ViewTool />) : null}
                </TabContent>
            </div>
        </div>
    );
};
export default PageMenu;
