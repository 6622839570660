// React
import React, { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';

// Formik
import { Formik, Form } from 'formik';
import { Button, ModalBody, ModalFooter } from 'reactstrap';
import { DragDrop } from '@uppy/react';

// API
import HelpPanel from 'components/forms/HelpPanel';
import { UPPY_SHOWN } from 'redux/actionTypes';
import { uploadToDataset } from '../../../redux/actions';
import handleApiError from '../../../services/Forms';

// Components
import { LAYER_TYPES, LAYER_DATA_TYPES } from '../../../services/Constants';
import { supportedDataTypesLabels, supportedDataTypesDetails } from '../../../services/Labels';

import parseFiles from '../../../services/ParseFiles';

import UppyService from '../../../services/UppyService';

import '@uppy/core/dist/style.min.css';
import '@uppy/drag-drop/dist/style.min.css';

const UploadFilesModalForm = ({ dataset, onClose }) => {
    const dispatch = useDispatch();

    // We have to store files in a state because Formik won't handle them for us
    const [files, setFiles] = useState([]);
    const [uploading, setUploading] = useState(null);
    const [closer, setCloser] = useState(null);

    // So just throw an undefined value to the field so Formik won't complain about the missing key
    const initialFormState = { files: undefined };

    const formikRef = useRef();

    useEffect(() => {
        dispatch({ type: UPPY_SHOWN, payload: true });
        const uppy = UppyService.getInstance();
        uppy.on('files-added', () => {
            setFiles(uppy.getFiles());
            parseFiles(uppy.getFiles()).then((defaults) =>
                Object.keys(defaults).forEach((key) => {
                    formikRef.current.setFieldValue(key, defaults[key] || initialFormState[key]);
                })
            );
        });
        uppy.on('file-removed', () => setFiles(uppy.getFiles()));
        return () => {
            uppy.off('files-added');
            uppy.off('file-removed');
            dispatch({ type: UPPY_SHOWN, payload: false });
        };
    }, []);

    const validate = () => {
        const errors = {};
        if (!files || files.length === 0) {
            errors.files = 'field is required';
        }
        if (
            files.length > 1 &&
            !(
                dataset.type === LAYER_TYPES.DOCUMENT ||
                dataset.datatype === LAYER_DATA_TYPES.MOSAIC ||
                dataset.datatype === LAYER_DATA_TYPES.SINGLEBANDCOG ||
                dataset.datatype === LAYER_DATA_TYPES.MULTIBANDCOG ||
                dataset.datatype === LAYER_DATA_TYPES.SEGY ||
                dataset.datatype === LAYER_DATA_TYPES.LAS
            )
        )
            errors.files = 'Multiple files not supported for this datatype';
        return errors;
    };

    const submitUpload = (values, helpers) => {
        // If we're here, validate has passed and we know files is not undefined
        UppyService.getInstance().on('restriction-failed', (file, error) => {
            helpers.setFieldError('files', error.message);
            formikRef.current.setSubmitting(false);
        });

        return dispatch(uploadToDataset(dataset))
            .then(() => {
                setUploading(dataset);
            })
            .catch((err) => {
                if (err.response && err.response.status === 409)
                    helpers.setFieldError('files', err.response.data.detail);
                else handleApiError(err, helpers);
            });
    };

    if (uploading) {
        const uppy = UppyService.getUploading()[uploading.id];
        if (uppy)
            return (
                <>
                    <ModalBody>
                        <div>Uploading files for {uploading.name}</div>
                        <div className="upload-list">
                            {uppy.getFiles().map((file) => (
                                <div key={`file-${file.id}`}>
                                    <label htmlFor={file.id}>{file.name}</label>
                                    <progress id={file.id} value={file.progress.percentage} max={100}>
                                        {file.progress.percentage}%
                                    </progress>
                                </div>
                            ))}
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <span>Ongoing uploads will continue, check the uploads menu for their status</span>
                        <Button type="button" color="primary" onClick={onClose}>
                            Close
                        </Button>
                    </ModalFooter>
                </>
            );

        if (!closer) setCloser(setTimeout(onClose, 2000));

        return (
            <ModalBody className="centred">
                <i className="modal-icon modal-icon-good fal fa-circle-check no-hover" />
                <span className="big-modal-text">Upload completed</span>
            </ModalBody>
        );
    }

    return (
        <Formik
            enableReinitialize
            initialValues={initialFormState}
            onSubmit={submitUpload}
            validate={validate}
            validateOnBlur={false}
            validateOnChange={false}
            innerRef={formikRef}
        >
            {({ errors, isSubmitting }) => (
                <Form>
                    <ModalBody>
                        <HelpPanel>
                            Uploading new files will cause the dataset to be reprocessed. <br />
                            {dataset.type === LAYER_TYPES.DOCUMENT
                                ? 'Document'
                                : supportedDataTypesLabels[dataset.datatype]}
                            {'. '}
                            {dataset.type === LAYER_TYPES.DOCUMENT
                                ? supportedDataTypesDetails[dataset.type]
                                : supportedDataTypesDetails[dataset.datatype]}
                        </HelpPanel>
                        <DragDrop
                            className="form-group"
                            uppy={UppyService.getInstance()}
                            allowMultipleFiles
                            note="Drag a file or browse to upload"
                        />
                        <div className="form-group file-readout">
                            <span className="file-upload-list">{files.map((file) => file.name).join(', ')}</span>
                            {files.length !== 0 ? (
                                <Button className="circle red" onClick={UppyService.clearFiles}>
                                    <i className="fa fa-xmark" />
                                </Button>
                            ) : null}
                            <div className="error-text">{errors.files}</div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button type="submit" color="primary" disabled={isSubmitting}>
                            Upload <i className={`fal fa-${isSubmitting ? 'spinner fa-pulse' : 'arrow-right'}`} />
                        </Button>
                    </ModalFooter>
                </Form>
            )}
        </Formik>
    );
};

export default UploadFilesModalForm;
