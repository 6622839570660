import { useState, useEffect } from 'react';
import { useLocation } from 'react-router';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledTooltip } from 'reactstrap';
import { selectAnnotation, startAnnotationCreation } from 'redux/annotationActions';
import Annotation from 'types/Annotation';
import { getService } from 'ServiceContainer';
import { useAppDispatch, useAppSelector } from 'store';
import * as datasetsSlice from 'redux/datasets';
import * as annotationsSlice from 'redux/annotations';
import * as settings from 'redux/settings';
import { QueryParameters } from 'types/common';
import { RIGHT_TAB } from '../../../Navigation';
import {
    fetchSSDMTypes,
    checkForUsernames,
    fetchAnnotations,
    selectRightTab,
    stopAnnotationEdit,
    stopAnnotationView,
    stopAnnotationCreation,
} from '../../../redux/actions';
import { getSSDMTypes } from '../../../redux/selectors';
import ApiErrors from '../../../services/ApiErrors';
import { ANNOTATION_CREATESTATE, DEFAULT_ANNOTATION_FILTER, EDITSTATE } from '../../../services/Constants';
import DosApi from '../../../services/DosApi';
import Scrollbox from '../../Scrollbox';
import ToggleSwitch from '../../ToggleSwitch';
import AnnotationExport from './AnnotationExport';
import AnnotationFilters from './AnnotationFilters';
import AnnotationItem from './AnnotationItem';
import CreateAnnotation from './CreateAnnotation';
import EditAnnotation from './EditAnnotation';
import ViewAnnotation from './ViewAnnotation';
import ErrorBoundary from '../../ErrorBoundary';

export type Props = {
    queryParams: QueryParameters;
};

const AnnotationList = (props: Props & { annotations: Annotation[]; deleteCallback: (a: Annotation) => void }) => {
    const { annotations, deleteCallback } = props;
    const dispatch = useAppDispatch();

    if (annotations.length === 0) {
        return (
            <span className="text-center mt-5">There are currently no annotations or they are hidden by filters.</span>
        );
    }

    return (
        <Scrollbox className="annotation-content">
            {annotations.map((annotation) => (
                <ErrorBoundary
                    key={annotation.id}
                    dispatch={dispatch}
                    fallback={
                        <div className="annotation-item">
                            <span className="error-fallback-message">
                                <i className="fal fa-exclamation-triangle icon-red" />
                                An error occured in this AnnotationItem component.
                                <i className="fal fa-exclamation-triangle icon-red" />
                            </span>
                        </div>
                    }
                >
                    <AnnotationItem
                        annotation={annotation}
                        deleteCallback={deleteCallback}
                        queryParams={props.queryParams}
                    />
                </ErrorBoundary>
            ))}
        </Scrollbox>
    );
};

const AnnotationMenu = (props: Props) => {
    const dispatch = useAppDispatch();
    const annotationManager = getService('AnnotationManager');

    const project = useAppSelector(datasetsSlice.currentProject);
    const filters = useAppSelector(annotationsSlice.filter);
    const createState = useAppSelector(annotationsSlice.createState);
    const editState = useAppSelector(annotationsSlice.editState);
    const annotations = useAppSelector<Annotation[]>(annotationsSlice.list);
    const ssdmTypes = useAppSelector(getSSDMTypes);

    const location = useLocation();

    const viewFilter = useAppSelector(settings.getFilterAnnotationByVisibility);
    const showAnnotations = useAppSelector(settings.getShowAnnotationsInViewport);
    const [annotationAuthors, setAnnotationAuthors] = useState<string[]>([]);
    const datasets = useAppSelector(datasetsSlice.getProjectDatasets);

    const openFromLink = () => {
        if (annotations && props.queryParams.annotation) {
            dispatch(selectRightTab(RIGHT_TAB.PROJECT_ANNOTATIONS));
            dispatch(
                selectAnnotation(annotations.find((annotation) => annotation.id === props.queryParams.annotation))
            );
        }
    };

    useEffect(() => {
        openFromLink();
    }, [location]);

    useEffect(() => {
        if (annotations) {
            const authors = [...new Set(annotations.map((annotation) => annotation.created_by_id))];
            setAnnotationAuthors(authors);
            dispatch(checkForUsernames(annotationAuthors));
        }
    }, [annotations]);

    useEffect(() => {
        if (!ssdmTypes) {
            dispatch(fetchSSDMTypes());
        }
    }, [ssdmTypes]);

    const DELETE = {
        PROMPT: 'prompt',
        DELETING: 'deleting',
        CONFIRMATION: 'confirm',
        ERROR: 'error',
    };

    const [deleteIsOpen, setDeleteIsOpen] = useState(false);
    const [deleteState, setDeleteState] = useState(DELETE.PROMPT);
    const [errorText, setErrorText] = useState(null);
    const [annotationToDelete, setAnnotationToDelete] = useState(null);
    const [filtersOpen, setFiltersOpen] = useState(false);
    const [filtersCount, setFiltersCount] = useState(0);

    useEffect(() => {
        let newCount = 0;

        Object.keys(DEFAULT_ANNOTATION_FILTER).forEach((x) => {
            if (Array.isArray(filters[x])) {
                // Symetric difference
                newCount += DEFAULT_ANNOTATION_FILTER[x]
                    .filter((element) => !filters[x].includes(element))
                    .concat(filters[x].filter((element) => !DEFAULT_ANNOTATION_FILTER[x].includes(element))).length;
            } else if (filters[x] !== DEFAULT_ANNOTATION_FILTER[x]) newCount += 1;
        });
        setFiltersCount(newCount);
    }, [filters]);

    const openDeleteModal = (annotation: Annotation) => {
        setDeleteState(DELETE.PROMPT);
        setDeleteIsOpen(true);
        setAnnotationToDelete(annotation);
    };

    const cancelDelete = () => {
        setDeleteIsOpen(false);
    };

    const deleteAnnotation = () => {
        setDeleteState(DELETE.DELETING);
        DosApi.deleteAnnotation(annotationToDelete.project_id, annotationToDelete.id)
            .then(async () => {
                fetchAnnotations(dispatch, annotationToDelete.project_id);
                annotationManager.removeAnnotation(annotationToDelete.id);
                setDeleteState(DELETE.CONFIRMATION);
                setTimeout(cancelDelete, 2000);
            })
            .catch((err) => {
                fetchAnnotations(dispatch, annotationToDelete.project_id);
                setErrorText(ApiErrors.getErrorMessage(err));
                setDeleteState(DELETE.ERROR);
            });
    };

    const deleteModalContent = () => {
        switch (deleteState) {
            case DELETE.PROMPT:
                return (
                    <>
                        <ModalBody>
                            <i className="modal-icon modal-icon-bad fal fa-circle-xmark no-hover" />
                            <span className="big-modal-text">Are you sure?</span>
                            <Button color="warning" onClick={deleteAnnotation}>
                                Yes, delete this annotation
                            </Button>
                        </ModalBody>
                        <ModalFooter>
                            <Button className="borderless green underline" onClick={cancelDelete}>
                                No, I regret this action
                            </Button>
                        </ModalFooter>
                    </>
                );
            case DELETE.DELETING:
                return (
                    <>
                        <ModalBody>
                            <i className="modal-icon modal-icon-warn fal fa-timer no-hover" />
                            <span className="big-modal-text">Deleting annotation...</span>
                        </ModalBody>
                        <ModalFooter />
                    </>
                );
            case DELETE.CONFIRMATION:
                return (
                    <>
                        <ModalBody>
                            <i className="modal-icon modal-icon-good fal fa-circle-check no-hover" />
                            <span className="big-modal-text">Annotation deleted</span>
                        </ModalBody>
                        <ModalFooter />
                    </>
                );
            case DELETE.ERROR:
                return (
                    <>
                        <ModalBody>
                            <i className="modal-icon modal-icon-bad fal fa-circle-exclamation no-hover" />
                            <span className="big-modal-text">An error occured</span>
                            <span className="small-modal-text">{errorText}</span>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="warning" onClick={cancelDelete}>
                                OK
                            </Button>
                        </ModalFooter>
                    </>
                );
            default:
                return null;
        }
    };

    const beginCreation = () => {
        dispatch(startAnnotationCreation());
    };

    function filterAnnotations(list: Annotation[]) {
        return list.filter(
            (ann) => !annotationManager.isFiltered(ann) && (annotationManager.isInView(ann) || !viewFilter)
        );
    }

    function sortByDate(list: Annotation[]): Annotation[] {
        list.sort((a, b) => new Date(b.created_at_utc).getTime() - new Date(a.created_at_utc).getTime());

        return list;
    }

    // Should filter annotations by visibility and filters set in the filter selection
    const filter = (allAnnotations: Annotation[]) => {
        if (allAnnotations === undefined) {
            return [];
        }

        return sortByDate(filterAnnotations(allAnnotations));
    };

    const [filteredAnnotations, setFilteredAnnotations] = useState(filter(annotations));

    // Ensure that filtered annotations are updated
    // whenever the filter or camera position changes.
    useEffect(() => {
        setFilteredAnnotations(filter(annotations));
    }, [viewFilter, filters, annotations]);

    if (createState !== ANNOTATION_CREATESTATE.NONE) {
        return (
            <>
                <div className="map-pane-title-bar">
                    <div className="map-pane-title">Create Annotation</div>
                    <div className="map-pane-title-bar-spacer" />
                </div>
                <ErrorBoundary
                    dispatch={dispatch}
                    fallback={
                        <>
                            <span className="error-fallback-message">
                                <i className="fal fa-exclamation-triangle icon-red" />
                                An error occured in the CreateAnnotation component.
                                <i className="fal fa-exclamation-triangle icon-red" />
                            </span>
                            <Button color="warning" onClick={() => dispatch(stopAnnotationCreation())}>
                                Back
                            </Button>
                        </>
                    }
                >
                    <CreateAnnotation />
                </ErrorBoundary>
            </>
        );
    }
    if (editState === EDITSTATE.VIEW) {
        return (
            <>
                <div className="map-pane-title-bar">
                    <div className="map-pane-title">View annotation</div>
                    <div className="map-pane-title-bar-spacer" />
                </div>
                <ErrorBoundary
                    dispatch={dispatch}
                    fallback={
                        <>
                            <span className="error-fallback-message">
                                <i className="fal fa-exclamation-triangle icon-red" />
                                An error occured in the ViewAnnotation component.
                                <i className="fal fa-exclamation-triangle icon-red" />
                            </span>
                            <Button color="warning" onClick={() => dispatch(stopAnnotationView())}>
                                Back
                            </Button>
                        </>
                    }
                >
                    <ViewAnnotation />
                </ErrorBoundary>
            </>
        );
    }
    if (editState !== EDITSTATE.NONE) {
        return (
            <>
                <div className="map-pane-title-bar">
                    <div className="map-pane-title">Edit annotation</div>
                    <div className="map-pane-title-bar-spacer" />
                </div>
                <ErrorBoundary
                    dispatch={dispatch}
                    fallback={
                        <>
                            <span className="error-fallback-message">
                                <i className="fal fa-exclamation-triangle icon-red" />
                                An error occured in the EditAnnotation component.
                                <i className="fal fa-exclamation-triangle icon-red" />
                            </span>
                            <Button color="warning" onClick={() => dispatch(stopAnnotationEdit())}>
                                Back
                            </Button>
                        </>
                    }
                >
                    <EditAnnotation />
                </ErrorBoundary>
            </>
        );
    }
    return (
        <>
            <div className="map-pane-title-bar">
                <div className="map-pane-title">
                    Annotations<span className="faded-text">({filteredAnnotations.length})</span>
                </div>
                <div className="map-pane-title-bar-buttons">
                    <AnnotationExport project={project} annotations={annotations} />
                    <Button
                        className={`circle yellow ${filtersCount !== 0 ? 'filter-active' : ''}`}
                        onClick={() => setFiltersOpen(true)}
                    >
                        <i className="fal fa-bars-filter" />
                        {filtersCount !== 0 ? (
                            <div className="filter-count-outer">
                                <div className="filter-count">{filtersCount}</div>
                            </div>
                        ) : null}
                    </Button>
                    <div id="add-annotation">
                        <Button
                            className="circle green"
                            onClick={beginCreation}
                            disabled={!project?.user_permissions.interact}
                        >
                            <i className="fal fa-plus" />
                        </Button>
                        {!project?.user_permissions.interact ? (
                            <UncontrolledTooltip target="add-annotation">
                                You do not have the permissions to add annotations.
                            </UncontrolledTooltip>
                        ) : null}
                    </div>
                </div>
            </div>
            <div className="annotation-viewfilter">
                Show annotations in viewport
                <ToggleSwitch
                    id="showAnnotations"
                    checked={showAnnotations}
                    onChange={(e) => {
                        dispatch(settings.showAnnotationsInViewport(e.target.checked));
                    }}
                />
            </div>
            <div className="annotation-viewfilter">
                List visible annotations
                <ToggleSwitch
                    id="viewFilter"
                    checked={viewFilter}
                    onChange={(e) => {
                        const active = e.target.checked;
                        dispatch(settings.filterAnnotations(active));
                        if (!active) {
                            dispatch(annotationsSlice.resetVisibilities());
                        }
                    }}
                />
            </div>
            <AnnotationList
                annotations={filteredAnnotations}
                deleteCallback={(a) => openDeleteModal(a)}
                queryParams={props.queryParams}
            />
            <Modal id="annotation" isOpen={deleteIsOpen} centered className="modal-confirm">
                <ModalHeader toggle={cancelDelete} />
                {deleteModalContent()}
            </Modal>
            <Modal isOpen={filtersOpen} toggle={() => setFiltersOpen(false)}>
                <ErrorBoundary
                    dispatch={dispatch}
                    fallback={
                        <>
                            <span className="error-fallback-message">
                                <i className="fal fa-exclamation-triangle icon-red" />
                                An error occured in the AnotationFilters component.
                                <i className="fal fa-exclamation-triangle icon-red" />
                            </span>
                            <Button color="warning" onClick={() => setFiltersOpen(false)}>
                                Back
                            </Button>
                        </>
                    }
                >
                    <AnnotationFilters
                        authors={annotationAuthors}
                        datasets={datasets}
                        close={() => setFiltersOpen(false)}
                    />
                </ErrorBoundary>
            </Modal>
        </>
    );
};

export default AnnotationMenu;
