// React
import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// Redux
import {
    loadOverviewMap,
    unloadOverviewMap,
    selectPage,
    fetchProjects,
    selectLeftTab,
    fetchCollections,
    loadProjects,
    loadCollections,
} from '../../redux/actions';
import { getProjects, getProjectsStatus, getCollections, getCollectionsStatus } from '../../redux/selectors';
import { PROJECTS, COLLECTIONS } from '../../redux/actionTypes';

// Components
import { PAGE } from '../../Navigation';
import 'ol/ol.css';
import 'ol-ext/dist/ol-ext.min.css';
import CollectionPopup from './collectionPopover/CollectionPopup';
import ProjectPopup from './projectPopover/ProjectPopup';
import DatasetPopup from './datasetPopover/DatasetPopup';
import FeatureInfo from './featureInfo/FeatureInfo';
import ItemsPopup from './itemsPopover/ItemsPopup';
import ProjectToolsBar from './projectToolsBar/ProjectToolsBar';

import logoSilver from '../../images/Argeo-logo-silver-gradient.svg';
import logoColor from '../../images/Argeo-logo-color-gradient.svg';
import arrowCompass from '../../images/arrow-compass.svg';

const Overview = () => {
    useEffect(() => {
        document.title = 'Overview | SCOPE';
    }, []);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const projects = useSelector(getProjects);
    const projectsStatus = useSelector(getProjectsStatus);

    const collections = useSelector(getCollections);
    const collectionsStatus = useSelector(getCollectionsStatus);

    const mapRef = useRef(null);

    // Map creation
    useEffect(() => {
        if (!mapRef.current) return null;
        dispatch(loadOverviewMap(mapRef.current, navigate));
        dispatch(fetchProjects());
        dispatch(fetchCollections());
        dispatch(selectPage(PAGE.OVERVIEW));
        dispatch(selectLeftTab(null));
        return () => {
            dispatch(unloadOverviewMap());
        };
    }, [dispatch, navigate]);

    // Selector projects + collections, load in map
    useEffect(() => {
        if (projectsStatus === PROJECTS.SUCCESS) {
            dispatch(loadProjects(projects));
        }
    }, [projectsStatus]);
    useEffect(() => {
        if (collectionsStatus === COLLECTIONS.SUCCESS) {
            dispatch(loadCollections(collections));
        }
    }, [collectionsStatus]);

    return (
        <div className="overview">
            <CollectionPopup />
            <ProjectPopup />
            <DatasetPopup />
            <ItemsPopup />
            <FeatureInfo />
            <ProjectToolsBar />
            <div className="map-container-loader">
                <div className="map-container-loader-logo-box">
                    <img src={logoSilver} alt="Argeo logo" />
                    <img src={logoColor} alt="Argeo logo with colors" />
                </div>
            </div>
            <div className="map-container" id="map-container" ref={mapRef}>
                <div className="map-controls">
                    <div className="map-compass">
                        <span className="map-compass-letter">N</span>
                        <img src={arrowCompass} alt="North Arrow" />
                    </div>
                    <div id="map-controls-row" />
                </div>
                <div className="map-statusbar">
                    <div className="map-statusbar-side">
                        <div className="map-statusbar-text">
                            <i className="fa fa-copyright" /> Argeo
                        </div>
                    </div>
                    <div className="map-statusbar-side">
                        <div id="status-bar-scale" className="map-statusbar-scaleline" />
                        <i className="fal fa-location-dot map-statusbar-marker" />
                        <div id="status-bar-position" className="map-statusbar-position">
                            0.0000, 0.0000
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Overview;
