import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Form, Formik } from 'formik';

// Redux
import { PROJECT_CREATED, PROJECT_LOADING } from '../../redux/actionTypes';
import { getOrganizations, getProjections } from '../../redux/selectors';
import { fetchProjections, fetchOrganizations } from '../../redux/actions';

import DosApi from '../../services/DosApi';
import handleApiError from '../../services/Forms';
import BaseField from '../forms/BaseField';
import TypeaheadField from '../forms/TypeaheadField';
import ApiErrors from '../../services/ApiErrors';

const CreateProject = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { endCreate } = props;

    const organizations = useSelector(getOrganizations);
    const projections = useSelector(getProjections);

    const STATE = {
        PROCESSING: 'processing',
        COMPLETE: 'complete',
        ERROR: 'error',
    };
    const [modalState, setModal] = useState(null);
    const [errorText, setErrorText] = useState();

    useEffect(() => {
        dispatch(fetchProjections());
        dispatch(fetchOrganizations());
    }, []);

    const modalContent = () => {
        switch (modalState) {
            case STATE.PROCESSING:
                return (
                    <>
                        <i className="modal-icon modal-icon-warn fal fa-timer no-hover" />
                        <span className="big-modal-text">Creating project</span>
                    </>
                );
            case STATE.COMPLETE:
                return (
                    <>
                        <i className="modal-icon modal-icon-good fal fa-circle-check no-hover" />
                        <span className="big-modal-text">Project created</span>
                    </>
                );
            case STATE.ERROR:
                return (
                    <>
                        <ModalHeader />
                        <ModalBody>
                            <i className="modal-icon modal-icon-bad fal fa-circle-exclamation no-hover" />
                            <span className="big-modal-text">An error occured</span>
                            <span className="small-modal-text">{errorText}</span>
                        </ModalBody>
                        <ModalFooter>
                            <Button onClick={() => setModal(null)}>Okay</Button>
                        </ModalFooter>
                    </>
                );
            default:
                return null;
        }
    };

    const validate = (values) => {
        const errors = {};
        if (!values.title) errors.title = 'Required';
        if (!values.organization) errors.organization = 'Required';
        if (!values.projection) errors.projection = 'Required';
        return errors;
    };

    const onSubmit = (values, helpers) => {
        setModal(STATE.PROCESSING);
        DosApi.createProject({
            name: values.title,
            description: values.description,
            organization_id: values.organization[0].id,
            projection: parseInt(values.projection[0].srid, 10),
        })
            .catch((err) => {
                handleApiError(err, helpers);
                setErrorText(ApiErrors.getErrorMessage(err));
                setModal(STATE.ERROR);
                helpers.setSubmitting(false);
            })
            .then((data) => {
                if (data) {
                    setModal(STATE.COMPLETE);
                    setTimeout(endCreate, 2000);
                    dispatch({
                        type: PROJECT_LOADING,
                        payload: data,
                    });
                    dispatch({
                        type: PROJECT_CREATED,
                        payload: data,
                    });
                    const slug = data.name
                        .toString()
                        .normalize('NFD') // break accented characters into components
                        .replace(/[\u0300-\u036f]/g, '') // remove diacritics
                        .toLowerCase()
                        .replace(/\s+/g, '-') // spaces to dashes
                        .replace(/&/g, '-and-') // ampersand to and
                        .replace(/[^\w-]+/g, '') // remove non-words
                        .replace(/--+/g, '-') // collapse multiple dashes
                        .replace(/^-+/, '') // trim starting dash
                        .replace(/-+$/, ''); // trim ending dash;
                    navigate(`/project/${data.id}/${slug}`);
                }
            });
    };

    return (
        <>
            <ModalHeader>Create a new project</ModalHeader>
            <Formik
                initialValues={{
                    title: '',
                    description: '',
                    organization:
                        organizations?.length === 1
                            ? [{ title: organizations[0].display_name, id: organizations[0].id }]
                            : undefined,
                    projection: '',
                }}
                onSubmit={onSubmit}
                validate={validate}
                enableReinitialize
            >
                {({ isSubmitting, setFieldValue }) => (
                    <Form>
                        <ModalBody>
                            <BaseField name="title" label="Project name" type="text" required="required" />
                            <BaseField name="description" label="Description" type="textarea" />
                            <TypeaheadField
                                name="organization"
                                label="Organization"
                                required="required"
                                options={
                                    organizations !== null
                                        ? organizations.map((e) => ({ title: e.display_name, id: e.id }))
                                        : null
                                }
                                labelKey="title"
                                setFieldValue={setFieldValue}
                                highlightOnlyResult
                                emptyLabel="No organizations found"
                                placeholder={organizations?.length === 1 ? organizations[0].display_name : null}
                                disabled={organizations?.length === 1}
                            />
                            <TypeaheadField
                                name="projection"
                                label="Projection"
                                required="required"
                                helperLabel="Default SRID to be used for datasets"
                                options={projections}
                                labelKey="title"
                                setFieldValue={setFieldValue}
                                highlightOnlyResult
                                emptyLabel="No compatible CRSs found"
                            />
                        </ModalBody>
                        <ModalFooter>
                            <Button type="button" color="warning" id="cancel-creation" onClick={endCreate}>
                                Abort
                            </Button>
                            <Button type="submit" color="primary" id="complete-creation" disabled={isSubmitting}>
                                Create
                            </Button>
                        </ModalFooter>
                    </Form>
                )}
            </Formik>
            <Modal isOpen={modalState !== null} className="modal-confirm">
                <ModalHeader />
                <ModalBody>{modalContent()}</ModalBody>
                <ModalFooter />
            </Modal>
        </>
    );
};

export default CreateProject;
