import { useState, useEffect } from 'react';
import { Table, Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { useTable, useGlobalFilter, useBlockLayout, Column, Row } from 'react-table';
import { Geometry as OLGeometry } from 'ol/geom';
import { useDispatch, useSelector } from 'react-redux';
import { Vector3 } from 'three';
import Dataset from 'types/Dataset';
import * as layers from 'redux/layers';
import * as giro3d from 'redux/giro3d';
import type { OLFeatureId } from 'types/common';
import type { HasVectorStyle, LayerState } from 'types/LayerState';
import { getService } from 'ServiceContainer';
import { useEventBus } from 'EventBus';
import { FilterMode } from '../datasetsMenu/datasetSettings/VectorSettings';

type Data = {
    featureId: OLFeatureId;
    geometry: OLGeometry;
};

const geometryAccessor = (row: Data) => row.geometry.constructor.name;

const rowAccessor = (row, c) => {
    // If the attribute is an array or object, table formatting will look bad. Need a design for how this should behave.
    if (row[c] instanceof Array) {
        return 'array';
    }
    if (row[c] instanceof Object) {
        return 'object';
    }
    return row[c];
};

const selectHeader = (colIdx, header, headerChoices, selectCallback) => {
    const [currentHeader, setCurrentHeader] = useState(header);
    const changeHeader = (newHeader) => {
        setCurrentHeader(newHeader);
        selectCallback(colIdx, newHeader, headerChoices);
    };
    return (
        <select
            id="select-dataset"
            className="form-select"
            value={currentHeader}
            onChange={(e) => changeHeader(e.target.value)}
        >
            {headerChoices.map((name) => (
                <option key={`attribute-col-${name}-${colIdx}`} value={name}>
                    {name}
                </option>
            ))}
        </select>
    );
};

type CellProps = {
    row: Row<Data>;
    dataset: Dataset;
};

const ActionsCell = (props: CellProps) => {
    const featureManager = getService('FeatureManager');
    const eventBus = useEventBus();
    const { row, dataset } = props;

    const goToFeature = (featureId: OLFeatureId) => {
        const bbox = featureManager.getFeatureBoundingBox(dataset.id, featureId);
        eventBus.dispatch('go-to-bbox', { bbox });
        const center = new Vector3();
        bbox.getCenter(center);
        const feature = featureManager.getFeatureById(dataset.id, featureId);
        featureManager.selectFeature(dataset.id, feature, center);
    };

    const goToButton = () => (
        <Button
            color="link"
            id={`attributetable-gotobtn-${row.id}`}
            title="Go to feature"
            onClick={() => goToFeature(row.original.featureId)}
        >
            <i className="fal fa-location-arrow fa-fw" />
        </Button>
    );

    return goToButton();
};

const AttributeTable = (props: { dataset: Dataset }) => {
    const { dataset } = props;
    const dispatch = useDispatch();
    const featureManager = getService('FeatureManager');
    const [attributes, setAttributes] = useState(null);
    const [columns, setColumns] = useState([]);
    const layerState = useSelector(layers.get<LayerState & HasVectorStyle>(dataset.id));
    const hasFilter = useSelector(layers.hasFeatureFilter(layerState));
    const filter = useSelector(layers.getFeatureFilter(layerState));
    const maxColNum = 2;

    const selectedFeatures = useSelector(giro3d.getSelectedItems);
    const fids = selectedFeatures
        ? selectedFeatures.items.filter((f) => f.layer === dataset.id).map((f) => f.feature.getId().toString())
        : [];

    useEffect(() => {
        let features = featureManager.getFeatures(dataset.id);
        // NOTE: temprorary hotfix to avoid implementing pagination
        if (features.length > 20) {
            features = features.slice(0, 20);
        }

        const attrs = features.map((f) => ({ ...f.getProperties(), 'featureId': f.getId() }));
        setAttributes(attrs);

        const colHeaders = Object.keys(attrs[0]).filter((h) => h !== 'geometry');

        const numCols = colHeaders.length;
        const sliceEnd = numCols > maxColNum ? maxColNum : numCols;
        const cols: Column<Data>[] = colHeaders.slice(0, sliceEnd).map((c, index) => ({
            id: c,
            // eslint-disable-next-line no-use-before-define
            Header: () => selectHeader(index, c, colHeaders, selectHeaderCallback),
            accessor: (row) => rowAccessor(row, c),
            maxWidth: 200,
            maxHeight: 50,
        }));

        cols.push({
            id: 'geometry',
            Header: 'Geometry',
            accessor: (row) => geometryAccessor(row),
            maxWidth: 200,
        });

        cols.push({
            id: 'actions',
            maxWidth: 150,
            Cell: ({ row }) => <ActionsCell row={row} dataset={dataset} />,
        });

        const selectHeaderCallback = (colIdx, newHeader, headerChoices) => {
            const newCols = cols.slice();
            newCols[colIdx].Header = () => selectHeader(colIdx, newHeader, headerChoices, selectHeaderCallback);
            newCols[colIdx].accessor = (row) => rowAccessor(row, newHeader);
            setColumns(newCols);
        };
        setColumns(cols);
    }, [dataset]);

    // @ts-expect-error setGlobalFilter is not present
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, state, setGlobalFilter } = useTable<Data>(
        {
            columns,
            data: attributes,
        },
        useGlobalFilter,
        useBlockLayout
    );

    // @ts-expect-error setGlobalFilter is not present
    const { globalFilter } = state;
    const [openFilterModal, setOpenFilterModal] = useState(false);

    const toggleFilterModal = () => {
        setOpenFilterModal(!openFilterModal);
    };
    const applyFilter = () => {
        const features = rows.map((row) => row.original.featureId);
        dispatch(
            layers.setFeatureFilter({
                layer: layerState,
                value: { name: globalFilter, features },
            })
        );
        toggleFilterModal();
    };

    const existingFilterWarning = () => {
        if (hasFilter) {
            return (
                <>
                    <h5> Warning! </h5>
                    <div>
                        This layer has an existing filter: `{filter.name}`, which will be removed with this action .{' '}
                    </div>
                </>
            );
        }
        return null;
    };

    return (
        <>
            <div className="search-container">
                <input type="text" value={globalFilter || ''} onChange={(e) => setGlobalFilter(e.target.value)} />
                <Button color="secondary" onClick={toggleFilterModal}>
                    {' '}
                    Set filter{' '}
                </Button>
            </div>

            <Modal className="modal-md" isOpen={openFilterModal} toggle={toggleFilterModal}>
                <ModalHeader>Apply filter</ModalHeader>
                <ModalBody>
                    {existingFilterWarning()}
                    {`You are currently applying the filter ${globalFilter} on the layer ${dataset.name}.
                    Here you can select if the filter should hide other features or have a custom style.
                    These setting can later be changed in the datasets menu on the left.`}
                    <FilterMode dataset={dataset} style={{ marginBottom: '1em' }} />

                    <Button color="primary" onClick={() => applyFilter()}>
                        Apply
                    </Button>
                    <Button color="secondary" onClick={toggleFilterModal}>
                        Cancel
                    </Button>
                </ModalBody>
            </Modal>

            <Table {...getTableProps({ className: 'attribute-table' })}>
                <thead>
                    {headerGroups.map((headerGroup) => (
                        <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <th key={column.id} {...column.getHeaderProps()}>
                                    {column.render('Header')}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps}>
                    {rows.map((row) => {
                        prepareRow(row);
                        return (
                            <tr
                                key={row.id}
                                {...row.getRowProps({
                                    style: {
                                        maxHeight: 50,
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                    },
                                    className: fids.includes(row.id) ? 'active' : '',
                                })}
                            >
                                {row.cells.map((cell) => (
                                    <td
                                        key={`${cell.column.id}-${cell.row.id}`}
                                        {...cell.getCellProps({
                                            style: {
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                            },
                                        })}
                                    >
                                        {' '}
                                        {cell.render('Cell')}
                                    </td>
                                ))}
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
        </>
    );
};

export default AttributeTable;
