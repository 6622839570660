// React
import React, { useState, useEffect } from 'react';
import * as ReactDOM from 'react-dom';
import { useSelector } from 'react-redux';
import { Overlay } from 'ol';
import { Card, CardHeader, CardBody } from 'reactstrap';
// Redux
import { getOverviewMap } from '../../redux/selectors';
import overviewMapService from '../../services/OverviewMapService';

export const popoverSection = (title, text) => (
    <>
        <h6 className="card-subtitle mb-2">{title}</h6>
        <p className="card-text">{text}</p>
        <hr className="hr-separator" />
    </>
);

export const ItemPopupoverBase = ({ overlayId, at, item, popoverBody }) => {
    const map = useSelector(getOverviewMap);
    const [domNode, setDomNode] = useState(undefined);

    const close = () => {
        const olOverlay = map.getOverlayById(overlayId);
        olOverlay.setPosition(undefined);
        overviewMapService.unselectItem(item, at);
    };
    useEffect(() => {
        if (map !== undefined) {
            const datasetPortalNode = document.createElement('div');
            datasetPortalNode.setAttribute('id', `${overlayId}Portal`);
            const datasetPopup = new Overlay({
                id: overlayId,
                position: undefined,
                element: datasetPortalNode,
            });
            map.addOverlay(datasetPopup);

            setDomNode(datasetPopup.getElement());
        }
    }, [map]);

    useEffect(() => {
        if (map !== undefined && item !== undefined) {
            const olOverlay = map.getOverlayById(overlayId);
            olOverlay.setPosition(at);
            olOverlay.setOffset([15, -25]);
        }
    }, [item]);

    const popover = () =>
        item === undefined ? null : (
            <Card className="popup-body">
                <CardHeader>
                    <h5>{item.name}</h5>
                    <button type="button" className="btn-close btn-close-white" aria-label="Close" onClick={close} />
                </CardHeader>
                <CardBody>{popoverBody()}</CardBody>
            </Card>
        );

    return domNode === undefined ? null : ReactDOM.createPortal(popover(), domNode);
};
