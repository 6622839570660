import { useState } from 'react';

export type Format = 'raw' | 'percent' | 'degrees';

export type Props = {
    value: number;
    format?: Format;
    min: number;
    max: number;
    step: number;
    replaceNaN?: number;
    onChange: (value: number) => void;
};

export type TitledProps = Props & { title: string };

function format(value: number, type: Format = 'raw') {
    switch (type) {
        case 'percent':
            return Math.round(value * 100);
        default:
            return value;
    }
}

function unformat(value: number, type: Format = 'raw') {
    switch (type) {
        case 'percent':
            return value / 100;
        default:
            return value;
    }
}

function getSuffix(type: Format = 'raw') {
    switch (type) {
        case 'percent':
            return '%';
        case 'degrees':
            return '°';
        default:
            return null;
    }
}

export function BareSettingSlider(props: Props) {
    function emit(v: number) {
        props.onChange(Number.isNaN(v) ? props.replaceNaN || 0 : unformat(v, props.format));
    }

    const [open, setOpen] = useState(false);

    const id = Math.random();

    return (
        <button
            type="button"
            className="dropdown-settings-slider-button"
            onClick={(e) => {
                if (open) {
                    setOpen(false);
                    e.currentTarget.blur();
                } else setOpen(true);
            }}
        >
            <input
                id={`${id}-text`}
                className="settings-slider-text"
                type="number"
                min={format(props.min, props.format)}
                max={format(props.max, props.format)}
                value={format(props.value, props.format)}
                autoComplete="off"
                onChange={(e) => emit(e.target.valueAsNumber)}
            />
            <label htmlFor={`${id}-text`}>{getSuffix(props.format)}</label>
            <i className="fal fa-angle-down" />
            <div>
                <div>
                    <input
                        id={`${id}`}
                        type="range"
                        min={format(props.min, props.format)}
                        max={format(props.max, props.format)}
                        step={format(props.step, props.format)}
                        value={format(props.value, props.format)}
                        autoComplete="off"
                        className="settings-slider"
                        onChange={(e) => emit(e.target.valueAsNumber)}
                    />
                </div>
            </div>
        </button>
    );
}

export function SettingSlider(props: TitledProps) {
    return (
        <div className="settings-row">
            <span>{props.title}</span>
            <BareSettingSlider {...props} />
        </div>
    );
}
