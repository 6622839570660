import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, UncontrolledTooltip } from 'reactstrap';
import UppyService from '../../services/UppyService';
import Scrollbox from '../Scrollbox';
import { CompletedUpload, InProgressUpload } from './Upload';
import { getAllDatasets, getUppyRev } from '../../redux/selectors';

const UploadsMenu = () => {
    const rev = useSelector(getUppyRev);

    const [uploading, setUploading] = useState(UppyService.getUploading());
    const [complete, setComplete] = useState(UppyService.getComplete());

    useEffect(() => {
        setUploading(UppyService.getUploading());
        setComplete(UppyService.getComplete());
    }, [rev]);

    const datasets = useSelector(getAllDatasets);

    const filesByDataset = {};
    Object.keys(uploading).forEach((datasetId) => {
        filesByDataset[datasetId] = uploading[datasetId].getFiles();
    });

    return (
        <>
            <div className="map-pane-title-bar">
                <div className="map-pane-title">Uploads</div>
                <div id="clear-uploads">
                    <Button
                        className="circle blue"
                        disabled={Object.keys(complete).length === 0}
                        onClick={UppyService.clear}
                        title="Clear completed uploads"
                    >
                        <i className="fal fa-trash-can" />
                    </Button>
                    {Object.keys(complete).length === 0 ? (
                        <UncontrolledTooltip target="clear-uploads">
                            There are no completed uploads to clear.
                        </UncontrolledTooltip>
                    ) : null}
                </div>
            </div>
            <Scrollbox>
                {Object.keys(filesByDataset).map((datasetId) => (
                    <InProgressUpload
                        dataset={datasets.find((d) => d.id === datasetId)}
                        files={filesByDataset[datasetId]}
                        key={`upload-${datasetId}`}
                    />
                ))}
                {Object.keys(complete).map((datasetId) => (
                    <CompletedUpload
                        dataset={datasets.find((d) => d.id === datasetId)}
                        files={complete[datasetId]}
                        key={`upload-complete-${datasetId}`}
                    />
                ))}
            </Scrollbox>
        </>
    );
};

export default UploadsMenu;
