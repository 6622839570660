import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Group, Vector2, Vector3, type Box3, MathUtils, Object3D, Color } from 'three';

import { genericEqualityFn } from 'components/utils';
import * as giro3d from 'redux/giro3d';

import { useAppSelector } from 'store';
import { fromPlane } from 'types/common';
import CrossSection from './CrossSection';

import * as crossSections from '../../../redux/crossSections';

interface Props {
    parent: Object3D;
    notify: (changeSource: unknown) => void;
}

const tmpVec3 = new Vector3();

const DEFAULT_SIZE = new Vector2(1000, 500);

function getSize(volume: Box3, zScale: number): Vector2 {
    if (!volume) {
        return DEFAULT_SIZE;
    }

    const size = volume.getSize(tmpVec3);
    const width = size.x * 1.5 + 10;
    const height = (size.z / zScale) * 1.5 + 10;

    return new Vector2(width, height);
}

/**
 * Renderless component that handles the visual representation of cross-sections
 * and updates Giro3D entities with the resulting clipping planes.
 */
function CrossSections(props: Props) {
    const { parent, notify } = props;

    const dispatch = useDispatch();

    // Component states
    const [rootObject, setRootObject] = useState<Group>(null);
    const [activeCrossSection, setActiveCrossSection] = useState<CrossSection>(null);

    // Redux state
    const center: Vector3 = useAppSelector(crossSections.getCenter, genericEqualityFn<Vector3>);
    const invert: boolean = useAppSelector(crossSections.getInvert);
    const volume: Box3 = useAppSelector(giro3d.getVolume, genericEqualityFn<Box3>);
    const zScale: number = useAppSelector(giro3d.getZScale);
    const visible = useAppSelector(crossSections.getVisibility);
    const opacity = useAppSelector(crossSections.getOpacity);
    const color = useAppSelector(crossSections.getColor, genericEqualityFn<Color>);
    const azimuth = useAppSelector(crossSections.getAzimuth);

    /**
     * Create the Giro3D objects.
     */
    function init() {
        const group = new Group();
        group.name = 'component: CrossSections';

        parent.add(group);

        const crossSection = new CrossSection({
            color,
            opacity,
            lineWidth: 0.0015,
        });
        crossSection.size = new Vector2(1000, 500);

        group.add(crossSection);

        setActiveCrossSection(crossSection);

        setRootObject(group);

        update();
    }

    /**
     * Cleanup any created Giro3D object.
     */
    function cleanup() {
        activeCrossSection?.dispose();
        rootObject?.removeFromParent();

        dispatch(crossSections.setVisibility(false));
        dispatch(crossSections.setPlane(null));
        dispatch(crossSections.setCenter(null));
    }

    /**
     * Updates Giro3D objects in reaction to state changes.
     */
    function update() {
        if (activeCrossSection) {
            if (center) {
                const midZ = volume ? volume.getCenter(tmpVec3).z : center.z;
                const actualCenter = new Vector3(center.x, center.y, midZ / zScale);
                activeCrossSection.position.copy(actualCenter);
            }

            activeCrossSection.rotation.x = Math.PI / 2;
            activeCrossSection.rotation.y = -MathUtils.degToRad(azimuth);

            activeCrossSection.visible = visible;
            activeCrossSection.children.forEach((c) => {
                c.visible = visible;
            });
            activeCrossSection.invert = invert;
            activeCrossSection.size = getSize(volume, zScale);
            activeCrossSection.opacity = opacity;
            activeCrossSection.color = color;

            activeCrossSection.updateMatrixWorld(true);

            notify(activeCrossSection);

            const newPlane = visible ? activeCrossSection.plane : null;

            dispatch(crossSections.setPlane(fromPlane(newPlane)));
        }
    }

    useEffect(update, [
        center,
        invert,
        rootObject,
        activeCrossSection,
        volume,
        zScale,
        visible,
        opacity,
        color,
        azimuth,
    ]);

    useEffect(() => {
        init();
        return cleanup;
    }, [parent]);

    // This is a renderless component. We don't create any DOM element,
    // however we are still "rendering" stuff in the 3D view.
    return null;
}

export default CrossSections;
