import { HostView } from 'giro3d_extensions/layers/Layer';
import BaseGiro3dService from 'services/BaseGiro3dService';
import { Box3, EventDispatcher, EventListener } from 'three';
import HoveredItem from 'types/HoveredItem';
import { LoadingStatus } from 'types/LoadingStatus';
import MouseCoordinates from 'types/MouseCoordinates';
import { DatasetId, SourceFileId, ZoomFactor } from 'types/common';

type NoArgs = Record<string, never>;

export type EventMap = {
    'close-context-menu': NoArgs;
    'save-camera-state': NoArgs;
    'restore-camera-state': NoArgs;
    'zoom-camera': { factor: ZoomFactor };
    'notify-change': { source?: unknown };
    'highlight-layer': { layer: DatasetId };
    'highlight-file': { dataset: DatasetId; file: SourceFileId };
    'layer-initialized': { layer: DatasetId };
    'go-to-layer': { layer: DatasetId };
    'go-to-file': { dataset: DatasetId; file: SourceFileId };
    'go-to-bbox': { bbox: Box3 };
    'mouse-coordinates': { coordinates: MouseCoordinates };
    'hovered-item': { item?: HoveredItem; location?: { x: number; y: number } };
    'dataset-loaded': { id: DatasetId; view: HostView };
    'go-to-follow-progress': { progress: number };
    'start-drawing': NoArgs;
    'end-drawing': NoArgs;
    'rendering-context-lost': { service: BaseGiro3dService };
    'rendering-context-restored': { service: BaseGiro3dService };
    'instance-loading-status-updated': { status: LoadingStatus };
    'dataset-loading-status-updated': { id: DatasetId; loading: boolean };
};

/**
 * Dispatches events.
 *
 * The EventBus can be used instead of normal redux actions to represent
 * transient events that should not trigger a redux state change.
 * This is useful for optimizing "actions" that happen a lot, such as mouse interactions,
 * which do not need to be persisted into the redux store.
 */
export class EventBus {
    // For convenience, we use the already implemented EventDispatcher from THREE
    private readonly _eventDispatcher = new EventDispatcher<EventMap>();

    /**
     * Dispatches an event.
     * @param type The event type.
     * @param arg The event argument.
     */
    dispatch<T extends keyof EventMap>(type: T, arg?: EventMap[T]): void {
        this._eventDispatcher.dispatchEvent({ type, ...arg });
    }

    /**
     * Adds a listener to an event type.
     * @param type The type of event to listen to.
     * @param listener The function that gets called when the event is fired.
     */
    subscribe<T extends Extract<keyof EventMap, string>>(
        type: T,
        listener: EventListener<EventMap[T], T, EventDispatcher>
    ): void {
        this._eventDispatcher.addEventListener(type, listener);
    }

    /**
     * Removes a listener from an event type.
     * @param type The type of the listener that gets removed.
     * @param listener The listener function that gets removed.
     */
    unsubscribe<T extends Extract<keyof EventMap, string>>(
        type: T,
        listener: EventListener<EventMap[T], T, EventDispatcher>
    ): void {
        this._eventDispatcher.removeEventListener(type, listener);
    }
}

const GLOBAL_EVENT_BUS = new EventBus();

export function useEventBus(): EventBus {
    return GLOBAL_EVENT_BUS;
}
