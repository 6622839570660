import { LAYER_TYPES, LAYER_DATA_TYPES, LAYER_STATES } from './Constants';

export const supportedTypesLabels = {
    [LAYER_TYPES.ATTRIBUTE]: 'Attribute',
    [LAYER_TYPES.BACKSCATTER]: 'Backscatter',
    [LAYER_TYPES.BATHYMETRY]: 'Bathymetry',
    [LAYER_TYPES.HORIZON]: 'Horizon',
    [LAYER_TYPES.WATER_COLUMN]: 'Water column',
    [LAYER_TYPES.BOREHOLE]: 'Borehole',
    [LAYER_TYPES.CABLE]: 'Cable',
    [LAYER_TYPES.DOCUMENT]: 'Document',
    [LAYER_TYPES.PIPELINE]: 'Pipeline',
    [LAYER_TYPES.TRACK]: 'Trackline',
    [LAYER_TYPES.VESSEL]: 'Vessel',
    [LAYER_TYPES.SAS]: 'SAS',
    [LAYER_TYPES.SEISMIC]: 'Seismic',
    [LAYER_TYPES.VECTOR]: 'Vector',
    [LAYER_TYPES.EM]: 'EM',
    [LAYER_TYPES.CAMERA]: 'Camera',
};

// TODO: get this from back-end (supportedDatasetType)
export const supportedDataTypes = {
    [LAYER_TYPES.BACKSCATTER]: [LAYER_DATA_TYPES.SINGLEBANDCOG, LAYER_DATA_TYPES.MULTIBANDCOG, LAYER_DATA_TYPES.MOSAIC],
    [LAYER_TYPES.BATHYMETRY]: [LAYER_DATA_TYPES.SINGLEBANDCOG, LAYER_DATA_TYPES.POINTCLOUD, LAYER_DATA_TYPES.MOSAIC],
    [LAYER_TYPES.HORIZON]: [LAYER_DATA_TYPES.SINGLEBANDCOG, LAYER_DATA_TYPES.POINTCLOUD, LAYER_DATA_TYPES.MOSAIC],
    [LAYER_TYPES.BOREHOLE]: [LAYER_DATA_TYPES.LAS],
    [LAYER_TYPES.CABLE]: [LAYER_DATA_TYPES.VECTOR],
    [LAYER_TYPES.DOCUMENT]: [LAYER_DATA_TYPES.OBJECT3D],
    [LAYER_TYPES.PIPELINE]: [LAYER_DATA_TYPES.VECTOR],
    [LAYER_TYPES.TRACK]: [LAYER_DATA_TYPES.VECTOR],
    [LAYER_TYPES.VESSEL]: [LAYER_DATA_TYPES.OBJECT3D],
    [LAYER_TYPES.SAS]: [LAYER_DATA_TYPES.SINGLEBANDCOG, LAYER_DATA_TYPES.MULTIBANDCOG, LAYER_DATA_TYPES.MOSAIC],
    [LAYER_TYPES.SEISMIC]: [LAYER_DATA_TYPES.SEGY],
    [LAYER_TYPES.VECTOR]: [LAYER_DATA_TYPES.VECTOR],
    [LAYER_TYPES.ATTRIBUTE]: [LAYER_DATA_TYPES.SINGLEBANDCOG, LAYER_DATA_TYPES.MULTIBANDCOG, LAYER_DATA_TYPES.MOSAIC],
    [LAYER_TYPES.EM]: [
        LAYER_DATA_TYPES.VECTOR,
        LAYER_DATA_TYPES.SINGLEBANDCOG,
        LAYER_DATA_TYPES.MULTIBANDCOG,
        LAYER_DATA_TYPES.MOSAIC,
    ],
    [LAYER_TYPES.CAMERA]: [LAYER_DATA_TYPES.COG, LAYER_DATA_TYPES.MOSAIC],
};

export const supportedDataTypesLabels = {
    [LAYER_DATA_TYPES.SINGLEBANDCOG]: 'Single-band GeoTIFF',
    [LAYER_DATA_TYPES.MULTIBANDCOG]: 'Multi-band GeoTIFF',
    [LAYER_DATA_TYPES.POINTCLOUD]: 'Point cloud',
    [LAYER_DATA_TYPES.VECTOR]: 'GeoJSON/Shapefile',
    [LAYER_DATA_TYPES.OBJECT3D]: 'Object 3D',
    [LAYER_DATA_TYPES.SEGY]: 'SEG-Y',
    [LAYER_DATA_TYPES.MOSAIC]: 'MosaicJSON',
    [LAYER_DATA_TYPES.LAS]: 'Log ASCII standard (LAS)',
};

export const supportedDataTypesDetails = {
    [LAYER_DATA_TYPES.SINGLEBANDCOG]: "Expects single-band .tiff files - Beware: must be in the dataset's projection",
    [LAYER_DATA_TYPES.MULTIBANDCOG]: "Expects multi-band .tiff files - Beware: must be in the dataset's projection",
    [LAYER_DATA_TYPES.POINTCLOUD]:
        "Expects a .xyz file or a ZIP file containing a 3d-tiled pointcloud, with a main tileset.json file - Beware: must be in the dataset's projection",
    [LAYER_DATA_TYPES.VECTOR]: 'Expects a single .geojson file, or a zipped .shp file (with .shx, .dbf, .prj etc.)',
    [LAYER_DATA_TYPES.OBJECT3D]: 'Expects a ZIP file containg a 3D Model, with a main .fbx file',
    [LAYER_DATA_TYPES.SEGY]:
        'Expects SEG-Y file(s) in the same projection as this dataset. Will be reprocessed to VDS.',
    [LAYER_DATA_TYPES.MOSAIC]:
        'Expects one or more geotiffs - Beware: The geotiffs will be reprojected to the dataset projection.',
    [LAYER_DATA_TYPES.LAS]: "Expects a single .las file in the dataset's projection",
    // Special case
    [LAYER_TYPES.DOCUMENT]: 'Expects one or more files, images and PDFs for instance',
};

export const layerStateLabels = {
    [LAYER_STATES.ACTIVE]: 'Active',
    [LAYER_STATES.VALIDATION_FAILED]: 'Validation Failed',
    [LAYER_STATES.CONVERSION_FAILED]: 'Conversion Failed',
    [LAYER_STATES.CONVERTING]: 'Converting',
    [LAYER_STATES.DELETING]: 'Deleting',
    [LAYER_STATES.DELETION_FAILED]: 'Deletion Failed',
    [LAYER_STATES.EMPTY]: 'Empty',
    [LAYER_STATES.LOADED]: 'Loaded',
    [LAYER_STATES.LOADING]: 'Loading',
    [LAYER_STATES.LOADING_FAILED]: 'Loading Failed',
    [LAYER_STATES.UPLOADING]: 'Uploading',
};
