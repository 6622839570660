import { computeExtent } from 'geojsonUtils';
import { Box3, Vector3 } from 'three';
import { ScopeColorLayer } from 'types/common';
import RasterLayer, { ConstructorParams } from './RasterLayer';

export default class CogLayer extends RasterLayer<ScopeColorLayer> {
    constructor(params: ConstructorParams<ScopeColorLayer>) {
        super(params);
        this.assignInitialValues();
    }

    getBoundingBox(scaleFactor: number) {
        if (this._cachedFootprint) {
            const extent = computeExtent(this._cachedFootprint, this._cachedFootprint.crs.properties.name);
            const mapRange = this._layerManager.getMapsMinMax(scaleFactor);
            return new Box3(
                new Vector3(extent.west(), extent.south(), mapRange.min),
                new Vector3(extent.east(), extent.north(), mapRange.max)
            );
        }

        return super.getBoundingBox(scaleFactor);
    }
}
