import React from 'react';
import ReactSelect, { components } from 'react-select';

const CheckboxOption = (props) => (
    <components.Option {...props}>
        {props.isSelected ? (
            <i className="fal fa-square-check multiselect-checkbox checked" />
        ) : (
            <i className="fal fa-square multiselect-checkbox" />
        )}
        {props.data.label}
    </components.Option>
);

const MultiValueCounter = (props) => {
    if (props.index !== 0) return null;
    const { length } = props.getValue();
    return (
        <div className="option-label">{`${props.selectProps.multiValueCounterMessage}${
            length === 1 ? '' : 's'
        } (${length})`}</div>
    );
};

const DropdownIndicator = (props) => (
    <components.DropdownIndicator {...props}>
        <i className="fal fa-angle-down" />
    </components.DropdownIndicator>
);

const Multiselect = ({ multiValueCounterMessage = 'Selected item', ...rest }) => (
    <div className="form-group">
        <ReactSelect
            className={`multiselect ${rest.error ? 'is-invalid' : ''}`}
            isMulti
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            components={{
                Option: CheckboxOption,
                DropdownIndicator,
                MultiValue: MultiValueCounter,
            }}
            unstyled
            classNames={{
                control: () => 'multiselect-control',
                input: () => 'multiselect-input',
                dropdownIndicator: () => 'multiselect-dropdown-indicator',
                menu: () => 'multiselect-menu',
                menuList: () => 'multiselect-menu-list',
                option: (state) =>
                    `multiselect-option ${state.isSelected ? 'selected' : ''} ${state.data.faded ? 'faded' : ''}`,
            }}
            multiValueCounterMessage={multiValueCounterMessage}
            {...rest}
        />
        <div className="invalid-feedback">{rest.error}</div>
    </div>
);

export default Multiselect;
