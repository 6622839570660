import { CanShowInMinimap, LayerState } from 'types/LayerState';
import { useDispatch } from 'react-redux';
import * as layers from 'redux/layers';
import { useAppSelector } from 'store';
import SettingToggleSwitch from './SettingToggleSwitch';

export type Props = {
    layer: LayerState & CanShowInMinimap;
};

const MinimapVisibilitySettings = (props: Props) => {
    const { layer } = props;
    const id = layer.datasetId;
    const dispatch = useDispatch();

    const isVisibleInMap = useAppSelector(layers.isShownInMinimap(props.layer));

    function showInMinimap(value: boolean) {
        dispatch(layers.showInMinimap({ layer, value }));
    }

    return (
        <SettingToggleSwitch
            id={`toggle-minimap-${id}`}
            checked={isVisibleInMap}
            title="Show on minimap"
            icon="fa-map"
            onChange={(e) => showInMinimap(e.target.checked)}
        />
    );
};

export default MinimapVisibilitySettings;
