import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Modal, ModalBody, ModalFooter, Spinner } from 'reactstrap';

// Formik
import { Formik, Form, Field } from 'formik';

// Components
import { showError } from '../../redux/actions';
import BaseSubmit from './BaseSubmit';
import handleApiError from '../../services/Forms';
import BaseField from './BaseField';
import Scrollbox from '../Scrollbox';

const getOptions = (field) => {
    // Options can be nested in React components, so we need a bit of annoying logic to handle that correctly
    let options = field.props.children.filter((item) => item !== null).flat();
    options = options.map((item) => (item.type === 'option' ? item : getOptions(item))).flat();
    return options;
};

/**
 * Traverses an object to get its display name.
 * @param {Object} o Object to traverse
 * @param {string|Array<string>} fullPath Path to field to display
 * @returns {string} Display name
 */
const getDisplayName = (o, fullPath) => {
    if (!Array.isArray(fullPath)) {
        fullPath = fullPath.split('.');
    }
    const p = fullPath.shift();
    const newO = o[p];
    if (fullPath.length > 0) {
        return getDisplayName(newO, fullPath);
    }

    // Got to the bottom of the path, format the value
    let value = newO;
    if (typeof value === 'boolean') {
        value = value ? 'Yes' : 'No';
    } else if (Array.isArray(value)) {
        value = value.join(', ');
    }

    return value;
};

const getFieldDisplayName = (o, field) => {
    if (field.props.formatter) {
        return field.props.formatter(o);
    }
    return getDisplayName(o, field.props.showFrom || field.props.name);
};

const Crud = (
    {
        fetchService,
        createService,
        updateService,
        deleteService,
        additionalActions,
        refreshable,
        title,
        createTitle,
        editTitle,
        deleteTitle,
        refreshTitle,
        fieldName,
        children,
    },
    ref
) => {
    const dispatch = useDispatch();
    const [items, setItems] = useState(null);

    if (!Array.isArray(children)) {
        children = [children];
    }

    const allFields = children.filter((field) => field.type === Field || field.type === BaseField);
    const editableFields = allFields.filter((field) => !field.props.readOnly);
    const otherChildren = children.filter((field) => field.type !== Field && field.type !== BaseField);

    const initialFormState = {};
    editableFields.forEach((field) => {
        const name = field.props.name;
        const type = field.props.as || field.props.type || 'input';
        let val = '';
        if (type === 'select') {
            getOptions(field).forEach((option, i) => {
                if (option && (i === 0 || option.props.default)) {
                    val = option.props.value || option.props.children;
                }
            });
        } else if (type === 'checkbox') {
            val = field.props.checked ?? false;
        } else {
            val = field.props.value || '';
        }
        initialFormState[name] = val;
    });
    const [currentFormState, setCurrentFormState] = useState(initialFormState);

    const [modalCreate, setModalCreate] = useState(false);
    const toggleCreate = () => setModalCreate(!modalCreate);

    const [currentEditedItem, setCurrentEditedItem] = useState();
    const [modalEdit, setModalEdit] = useState(false);
    const toggleEdit = (item) => {
        if (item) {
            const state = editableFields.reduce(
                (o, field) => ({ ...o, [field.props.name]: item[field.props.name] }),
                {}
            );
            setCurrentFormState(state);
        } else {
            setCurrentFormState(initialFormState);
        }
        setCurrentEditedItem(item);
        setModalEdit(!modalEdit);
    };
    const [currentDeletedItem, setCurrentDeletedItem] = useState();
    const [modalDelete, setModalDelete] = useState(false);
    const toggleDelete = (item) => {
        setCurrentDeletedItem(item);
        setModalDelete(!modalDelete);
    };

    const fetchItems = () => {
        fetchService()
            .then((data) => {
                const o = {};
                for (const item of data) {
                    o[item.id] = item;
                }
                setItems(o);
            })
            .catch((err) => showError(dispatch, err));
    };

    useEffect(() => fetchItems(), []);

    const createItem = (values, helpers) => {
        console.log(values);
        createService(values)
            .then((data) => {
                const o = { ...items };
                o[data.id] = data;
                setItems(o);
                setModalCreate(false);
            })
            .catch((err) => handleApiError(err, helpers));
    };

    const editItem = (values, helpers) =>
        updateService(currentEditedItem.id, values)
            .then((data) => {
                items[data.id] = data;
                setItems(items);
                setModalEdit(false);
                setCurrentEditedItem(undefined);
            })
            .catch((err) => handleApiError(err, helpers));

    const deleteItem = (data) => {
        deleteService(data.id)
            .then(() => {
                delete items[data.id];
                setItems(items);
            })
            .catch((err) => showError(dispatch, err))
            .finally(() => {
                setModalDelete(false);
                setCurrentDeletedItem(undefined);
            });
    };

    useImperativeHandle(ref, () => ({
        refresh: fetchItems,
    }));

    return (
        <>
            <Modal isOpen={modalCreate} toggle={toggleCreate} keyboard={false} centered>
                <ModalBody>
                    <Formik enableReinitialize initialValues={currentFormState} onSubmit={createItem}>
                        {({ isSubmitting }) => (
                            <Form>
                                {editableFields}
                                <BaseSubmit name="submit" disabled={isSubmitting} value={createTitle || 'Add'} />
                                {otherChildren}
                            </Form>
                        )}
                    </Formik>
                </ModalBody>
            </Modal>
            {updateService && currentEditedItem ? (
                <Modal
                    isOpen={modalEdit}
                    toggle={() => toggleEdit(undefined)}
                    keyboard={false}
                    centered
                    className="modal-confirm"
                >
                    <ModalBody>
                        <Formik enableReinitialize initialValues={currentFormState} onSubmit={editItem}>
                            {({ isSubmitting }) => (
                                <Form>
                                    {editableFields}
                                    <BaseSubmit name="submit" disabled={isSubmitting} value={editTitle || 'Edit'} />
                                    {otherChildren}
                                </Form>
                            )}
                        </Formik>
                    </ModalBody>
                </Modal>
            ) : null}
            {deleteService && currentDeletedItem ? (
                <Modal
                    isOpen={modalDelete}
                    toggle={() => toggleDelete(undefined)}
                    keyboard={false}
                    centered
                    className="modal-confirm"
                >
                    <ModalBody>{`Are you sure you want to delete ${getDisplayName(
                        currentDeletedItem,
                        fieldName || 'id'
                    )} ?`}</ModalBody>
                    <ModalFooter>
                        <Button color="danger" onClick={() => deleteItem(currentDeletedItem)}>
                            {deleteTitle || 'Delete'}
                        </Button>
                        <Button color="secondary" onClick={() => toggleDelete(undefined)}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Modal>
            ) : null}
            {items === null ? (
                <Spinner animation="border" />
            ) : (
                <div className="full-page">
                    <div className="full-page-title-bar">
                        <div className="full-page-title">
                            {title}
                            <span className="faded-text">({Object.values(items).length})</span>
                        </div>
                        <div className="map-pane-title-bar-buttons">
                            {/* {user.user_permissions.add_user ? <AddUser /> : null} */}
                            {refreshable ? (
                                <Button onClick={fetchItems} color="link" title={refreshTitle || 'Refresh'}>
                                    <i className="fal fa-refresh" />
                                </Button>
                            ) : null}
                            {createService ? (
                                <Button className="circle green" title={createTitle || 'Add'} onClick={toggleCreate}>
                                    <i className="fal fa-plus" />
                                </Button>
                            ) : null}
                        </div>
                    </div>
                    <Scrollbox>
                        <table>
                            <thead>
                                <tr>
                                    {allFields
                                        .filter((field) => field.props.type !== 'hidden')
                                        .map((field) => (
                                            <th key={`header-${field.props.name}`}>{field.props.label}</th>
                                        ))}
                                    {updateService || deleteService || additionalActions ? <th>Actions</th> : null}
                                </tr>
                            </thead>
                            <tbody>
                                {Object.values(items).map((o) => (
                                    <tr key={o.id}>
                                        {allFields
                                            .filter((field) => field.props.type !== 'hidden')
                                            .map((field) => (
                                                <td key={`${o.id}-${field.props.name}`}>
                                                    {getFieldDisplayName(o, field)}
                                                </td>
                                            ))}
                                        {updateService || deleteService || additionalActions ? (
                                            <td>
                                                {updateService ? (
                                                    <Button
                                                        className="borderless green"
                                                        title={`Edit ${getDisplayName(o, fieldName || 'id')}`}
                                                        onClick={() => toggleEdit(o)}
                                                    >
                                                        <i className="fal fa-pencil-alt" />
                                                    </Button>
                                                ) : null}
                                                {deleteService ? (
                                                    <Button
                                                        className="borderless red"
                                                        title={`Delete  ${getDisplayName(o, fieldName || 'id')}`}
                                                        onClick={() => toggleDelete(o)}
                                                    >
                                                        <i className="fal fa-trash-alt" />
                                                    </Button>
                                                ) : null}
                                                {additionalActions
                                                    ? additionalActions.map((action) => (
                                                          <Button
                                                              key={`action-${action.id}`}
                                                              className="borderless white"
                                                              title={action.title.replace(
                                                                  '{name}',
                                                                  getDisplayName(o, fieldName || 'id')
                                                              )}
                                                              onClick={() => action.callback(o)}
                                                          >
                                                              <i className={action.icon} />
                                                          </Button>
                                                      ))
                                                    : null}
                                            </td>
                                        ) : null}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </Scrollbox>
                </div>
            )}
        </>
    );
};

export default forwardRef(Crud);
