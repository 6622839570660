import React from 'react';
import { Button } from 'reactstrap';

import overviewMapService from '../../../services/OverviewMapService';

const ViewTool = () => {
    const goToAllLayers = () => overviewMapService.goToLayers();

    return (
        <>
            <div className="map-pane-title">View</div>

            <div className="view-options">
                <Button onClick={goToAllLayers}>Reset</Button>
            </div>
        </>
    );
};

export default ViewTool;
