import { useDispatch } from 'react-redux';
import ReactSlider from 'react-slider';
import {
    Attribute,
    ColoringMode,
    HasAttributes,
    HasColoringMode,
    HasOverlayColor,
    LayerState,
    hasAttributes,
    hasOpacityCurve,
} from 'types/LayerState';
import * as layers from 'redux/layers';

// Components
import ToggleSwitch from 'components/ToggleSwitch';
import { Color } from 'three';
import ColorMap, { COLORMAP_BOUNDSMODE, COLORMAP_NAMES, getLUT } from 'types/ColorMap';
import { useEffect, useRef, useState } from 'react';
import { toHex, Unit } from 'types/common';
import { useAppSelector } from 'store';
import { CurveKnot, getDefaultOpacityCurveKnots } from 'types/Curve';
import { Button } from 'reactstrap';
import AttributeSelector from './AttributeSelector';
import ColormapScaleBar from './ColormapScaleBar';
import SettingColorSelector from './SettingColorSelector';
import CurveEditor from './CurveEditor';

type LegendProps = {
    attribute: Attribute;
    colorMap: ColorMap;
    layer: LayerState & HasAttributes;
    pinned: boolean;
    scale: JSX.Element;
    minColor: Color;
    maxColor: Color;
    onMinBoundChanged: (min: number) => void;
    onMaxBoundChanged: (min: number) => void;
    onPinLegend: (pin: boolean) => void;
};

const LegendTool = (props: LegendProps) => {
    const { layer, scale, minColor, maxColor, attribute, colorMap } = props;
    const pinned = useAppSelector(layers.isPinnedLegend(layer));

    const disabled = colorMap.boundsMode === COLORMAP_BOUNDSMODE.DATA;

    const minNumber = useRef<HTMLInputElement>();
    const maxNumber = useRef<HTMLInputElement>();

    const unit = attribute.unit !== Unit.None ? attribute.unit : '';

    function to2DecimalPlaces(num: number): number {
        return Math.round(num * 100) / 100;
    }

    const roundDataBound = (bound: number) => {
        if (bound === 0) return 0;
        const power = Math.log10(Math.abs(bound));
        if (power < 0) {
            return parseFloat(bound.toFixed(Math.abs(power) + 2));
        }
        return to2DecimalPlaces(bound);
    };

    const step = attribute.max - attribute.min !== 0 ? roundDataBound((attribute.max - attribute.min) / 100) : 1;

    function changeMinBound(value: number) {
        props.onMinBoundChanged(value);
    }

    function changeMaxBound(value: number) {
        props.onMaxBoundChanged(value);
    }

    const togglePinned = (value: boolean) => {
        props.onPinLegend(value);
    };

    return (
        <>
            <div className="legend-limits">
                <span>{`${roundDataBound(attribute.min)}${unit}`}</span>
                <span>{`${roundDataBound(attribute.max)}${unit}`}</span>
            </div>
            {disabled ? (
                <div className="horizontal-slider">{scale}</div>
            ) : (
                <>
                    <ReactSlider
                        className="horizontal-slider"
                        thumbClassName="slider-thumb"
                        trackClassName="slider-track"
                        value={[colorMap.customMin, colorMap.customMax]}
                        min={attribute.min}
                        max={attribute.max}
                        ariaLabel={['Lower thumb', 'Upper thumb']}
                        ariaValuetext={(state) => `Thumb value ${state.valueNow}`}
                        renderThumb={(prop) => <div {...prop} />}
                        renderTrack={(prop, state) => {
                            switch (state.index) {
                                case 0:
                                    prop.style.backgroundColor = `#${minColor.getHexString()}`;
                                    return <div {...prop} />;
                                case 1:
                                    return <div {...prop}>{scale}</div>;
                                case 2:
                                    prop.style.backgroundColor = `#${maxColor.getHexString()}`;
                                    return <div {...prop} />;
                                default:
                                    return null;
                            }
                        }}
                        step={step}
                        minDistance={step}
                        pearling
                        onChange={(data: number[]) => {
                            changeMinBound(data[0]);
                            changeMaxBound(data[1]);
                        }}
                        onAfterChange={(data: number[]) => {
                            minNumber.current.valueAsNumber = roundDataBound(data[0]);
                            maxNumber.current.valueAsNumber = roundDataBound(data[1]);
                        }}
                    />
                    <label htmlFor="min">Min:</label>
                    <div className="input-group">
                        <input
                            title="min"
                            ref={minNumber}
                            type="number"
                            className="form-control"
                            defaultValue={roundDataBound(colorMap.customMin)}
                            max={Math.min(colorMap.customMax)}
                            onChange={(event) => {
                                if (event.target.value !== '')
                                    changeMinBound(roundDataBound(parseFloat(event.target.value)));
                                else changeMinBound(attribute.min);
                            }}
                            step={step}
                        />
                        <div className="input-group-text" hidden={attribute.unit === Unit.None}>
                            {unit}
                        </div>
                    </div>
                    <label htmlFor="max">Max:</label>
                    <div className="input-group">
                        <input
                            title="max"
                            ref={maxNumber}
                            type="number"
                            className="form-control"
                            defaultValue={roundDataBound(colorMap.customMax)}
                            min={Math.max(colorMap.customMin)}
                            onChange={(event) => {
                                if (event.target.value !== '')
                                    changeMaxBound(roundDataBound(parseFloat(event.target.value)));
                                else changeMaxBound(attribute.max);
                            }}
                            step={step}
                        />
                        <div className="input-group-text" hidden={attribute.unit === Unit.None}>
                            {unit}
                        </div>
                    </div>
                </>
            )}
            <div className="select-group d-flex">
                <label htmlFor="colormap_map_legend" className="col-sm-9 toggle-switch-label">
                    Pin Legend
                </label>
                <span className="col-sm-3 col-form-label">
                    <ToggleSwitch
                        id="colormap_map_legend"
                        checked={pinned}
                        onChange={(e) => togglePinned(e.target.checked)}
                    />
                </span>
            </div>
        </>
    );
};

export const ColormapSetting = (props: { layer: LayerState & HasAttributes }) => {
    const dispatch = useDispatch();

    const { layer } = props;

    const attribute = useAppSelector(layers.getActiveAttribute(layer));

    const colorMap = useAppSelector(layers.getColorMap(layer, attribute.id));

    const [lut, setLUT] = useState(getLUT(colorMap, { samples: 256 }));

    const [curveRevision, setCurveRevision] = useState<number>(0);

    useEffect(() => {
        setLUT(getLUT(colorMap, { samples: 256 }));
    }, [colorMap]);

    if (!attribute) {
        return null;
    }

    function setColorMapName(value: COLORMAP_NAMES) {
        dispatch(layers.setAttributeColorMapName({ layer, attributeId: attribute.id, value }));
        dispatch(layers.setActiveAttribute({ layer, value: attribute.id }));
    }

    function setBoundsMode(value: COLORMAP_BOUNDSMODE) {
        dispatch(layers.setAttributeColorMapBoundsMode({ layer, attributeId: attribute.id, value }));
        dispatch(layers.setActiveAttribute({ layer, value: attribute.id }));
    }

    function changeLayerColormapDiscrete(value: boolean) {
        dispatch(layers.setAttributeColorMapDiscrete({ layer, attributeId: attribute.id, value }));
        dispatch(layers.setActiveAttribute({ layer, value: attribute.id }));
    }

    function changeLayerColormapInvert(value: boolean) {
        dispatch(layers.setAttributeColorMapInvert({ layer, attributeId: attribute.id, value }));
        dispatch(layers.setActiveAttribute({ layer, value: attribute.id }));
    }

    function changeLayerColormapMaxBound(value: number) {
        dispatch(layers.setAttributeColorMapCustomMaxBound({ layer, attributeId: attribute.id, value }));
        dispatch(layers.setActiveAttribute({ layer, value: attribute.id }));
    }

    function changeLayerColormapMinBound(value: number) {
        dispatch(layers.setAttributeColorMapCustomMinBound({ layer, attributeId: attribute.id, value }));
        dispatch(layers.setActiveAttribute({ layer, value: attribute.id }));
    }

    function updateOpacityCurve(value: Array<CurveKnot>) {
        dispatch(layers.setAttributeColorMapOpacityCurveKnots({ layer, attributeId: attribute.id, value }));
        dispatch(layers.setActiveAttribute({ layer, value: attribute.id }));
    }

    return (
        <div className="row">
            <label htmlFor="colormap" className="col-sm-5 col-form-label">
                <i className="fal fa-palette fa-fw" /> Colormap
            </label>
            <div className="col-sm-7">
                <div className="select-group">
                    <select
                        id="colormap"
                        className="form-select"
                        value={colorMap.name}
                        onChange={(e) => setColorMapName(e.target.value as COLORMAP_NAMES)}
                    >
                        {Object.keys(COLORMAP_NAMES).map((name) => (
                            <option value={COLORMAP_NAMES[name]} key={`colormap-${name}`}>
                                {COLORMAP_NAMES[name]}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="select-group">
                    <select
                        id="colormap_mode"
                        className="form-select"
                        value={colorMap.boundsMode}
                        onChange={(e) => setBoundsMode(e.target.value as COLORMAP_BOUNDSMODE)}
                    >
                        <option value={COLORMAP_BOUNDSMODE.DATA}>Relative to data</option>
                        <option value={COLORMAP_BOUNDSMODE.CUSTOM}>Custom bounds</option>
                    </select>
                </div>
                <div className="select-group d-flex">
                    <label htmlFor="colormap_discrete" className="col-sm-3 toggle-switch-label">
                        Discrete
                    </label>
                    <span className="col-sm-3 col-form-label">
                        <ToggleSwitch
                            id="colormap_discrete"
                            checked={colorMap.discrete}
                            onChange={(e) => changeLayerColormapDiscrete(e.target.checked)}
                        />
                    </span>
                    <label htmlFor="colormap_invert" className="col-sm-3 toggle-switch-label">
                        Invert
                    </label>
                    <span className="col-sm-3 col-form-label">
                        <ToggleSwitch
                            id="colormap_invert"
                            checked={colorMap.invert}
                            onChange={(e) => changeLayerColormapInvert(e.target.checked)}
                        />
                    </span>
                </div>
            </div>
            <label htmlFor="colorbar" className="col-sm-5 col-form-label">
                <i className="far fa-ruler-horizontal fa-fw" /> Legend
            </label>
            <div className="col-sm-7 d-flex legend-tool" id="legend-tool">
                <LegendTool
                    scale={ColormapScaleBar({ lut, id: layer.datasetId, attribute, colorMap })}
                    minColor={lut[0]}
                    maxColor={lut[lut.length - 1]}
                    layer={layer}
                    attribute={attribute}
                    colorMap={colorMap}
                    pinned={layer.pinLegend}
                    onMaxBoundChanged={(value) => changeLayerColormapMaxBound(value)}
                    onMinBoundChanged={(value) => changeLayerColormapMinBound(value)}
                    onPinLegend={(value) => dispatch(layers.setPinnedLegend({ layer, value }))}
                />
            </div>
            {hasOpacityCurve(layer) ? (
                <>
                    <label htmlFor="colorbar" className="col-sm-5 col-form-label">
                        <i className="far fa-ruler-horizontal fa-fw" /> Opacity curve
                    </label>

                    <div className="col-sm-7">
                        <CurveEditor
                            xMin={attribute.min}
                            xMax={attribute.max}
                            yMin={0}
                            yMax={100}
                            knots={colorMap.opacityCurve?.knots}
                            onChange={(knots) => updateOpacityCurve(knots)}
                            revision={curveRevision}
                        />
                        <Button
                            className="borderless"
                            title="Reset curve to default"
                            onClick={() => {
                                updateOpacityCurve(getDefaultOpacityCurveKnots());
                                setCurveRevision(curveRevision + 1);
                            }}
                        >
                            <i className="fal fa-arrow-rotate-left" />
                            Reset curve to defaults
                        </Button>
                    </div>
                </>
            ) : null}
        </div>
    );
};

const OverlayColorSetting = (props: { layer: LayerState; name?: string }) => {
    const layer = props.layer as LayerState & HasOverlayColor;
    const name = props.name ?? 'Color';
    const dispatch = useDispatch();
    const color = useAppSelector(layers.getOverlayColor(layer));

    function changeLayerOverlayColor(value: Color) {
        dispatch(layers.setOverlayColor({ layer, value: toHex(value) }));
    }

    return <SettingColorSelector title={name} color={color} onChange={(c) => changeLayerOverlayColor(c)} />;
};

export type Props = {
    layer: LayerState & HasColoringMode;
};

function formatColoringMode(mode: ColoringMode) {
    switch (mode) {
        case ColoringMode.OverlayColor:
            return 'Solid color';
        case ColoringMode.Colormap:
            return 'Property';
        default:
            throw new Error('invalid state');
    }
}

function ColoringModeSelector(props: {
    id: string;
    current: ColoringMode;
    availableModes: ColoringMode[];
    onChange: (newMode: ColoringMode) => void;
}) {
    return (
        <div className="row" key={`datasetsettings-${props.id}-coloring`}>
            <label htmlFor="cogmode" className="col-sm-5 col-form-label">
                <i className="fal fa-fill-drip fa-fw" /> Coloring
            </label>
            <div className="col-sm-7">
                <div className="select-group">
                    <select
                        id="cogmode"
                        className="form-select"
                        value={props.current}
                        onChange={(e) => props.onChange(e.target.value as ColoringMode)}
                    >
                        {props.availableModes.map((mode) => (
                            <option value={mode} key={mode}>
                                {formatColoringMode(mode)}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
        </div>
    );
}

export const ColorModeSetting = (props: Props) => {
    const { layer } = props;
    const id = layer.datasetId;
    const dispatch = useDispatch();

    let attributeCount = 0;
    if (hasAttributes(layer)) {
        attributeCount = useAppSelector(layers.getAttributes(layer)).length;
    }
    const availableModes = useAppSelector(layers.getColoringModes(layer));
    const coloringMode = useAppSelector(layers.getCurrentColoringMode(layer));

    function setCurrentMode(value: ColoringMode) {
        dispatch(layers.setColoringMode({ layer, value }));
    }

    return (
        <>
            {attributeCount > 0 && availableModes.length > 1 ? (
                <ColoringModeSelector
                    id={id}
                    current={coloringMode}
                    availableModes={availableModes}
                    onChange={(m) => setCurrentMode(m)}
                />
            ) : null}

            {hasAttributes(layer) && attributeCount > 0 && coloringMode === ColoringMode.Colormap ? (
                <>
                    <AttributeSelector layer={layer} />
                    <ColormapSetting key={`datasetsettings-${id}-colormap`} layer={layer} />
                </>
            ) : null}

            {coloringMode === ColoringMode.OverlayColor ? (
                <OverlayColorSetting key={`datasetsettings-${id}-overlaycolor`} layer={layer} />
            ) : null}
        </>
    );
};

const ColoringSettings = {
    ColorModeSetting,
    ColormapSetting,
    OverlayColorSetting,
};

export default ColoringSettings;
