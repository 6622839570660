import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, ModalBody, ModalFooter } from 'reactstrap';
import { Formik, Form } from 'formik';
import ReactSelect, { components } from 'react-select';
import { DragDrop } from '@uppy/react';
import { UPPY_SHOWN } from 'redux/actionTypes';
import { getCollections, getOrganizationsForCreatingDataset, getProjections } from '../../../redux/selectors';
import {
    createDataset,
    fetchCollections,
    fetchOrganizations,
    fetchProjections,
    relateProjectDataset,
} from '../../../redux/actions';
import { LAYER_DATA_TYPES, PROCESSABLE_EXTENSIONS } from '../../../services/Constants';
import handleApiError from '../../../services/Forms';
import { supportedDataTypes, supportedDataTypesLabels, supportedTypesLabels } from '../../../services/Labels';
import parseFiles from '../../../services/ParseFiles';

import UppyService from '../../../services/UppyService';

import '@uppy/core/dist/style.min.css';
import '@uppy/drag-drop/dist/style.min.css';

const AddDataset = ({ project, collectionOpen, onClose }) => {
    const dispatch = useDispatch();
    const organizations = useSelector(getOrganizationsForCreatingDataset);
    const collections = useSelector(getCollections);
    const projections = useSelector(getProjections);

    const formikRef = useRef();

    const initialFormState = {
        type: '',
        datatype: '',
        name: '',
        organization_id: collectionOpen ? collectionOpen.organization_id : '',
        projection: '',
        collection_id: collectionOpen ? collectionOpen.id : '',
    };

    const [currentFormState, setCurrentFormState] = useState(initialFormState);
    const [files, setFiles] = useState([]);
    const [uploading, setUploading] = useState(null);
    const [closer, setCloser] = useState(null);

    useEffect(() => setCurrentFormState(initialFormState), [organizations]);

    useEffect(() => {
        dispatch(fetchProjections());
        dispatch(fetchOrganizations());
        dispatch(fetchCollections());
        dispatch({ type: UPPY_SHOWN, payload: true });

        const uppy = UppyService.getInstance();
        setFiles(uppy.getFiles());
        uppy.on('files-added', () => {
            setFiles(uppy.getFiles());
            parseFiles(uppy.getFiles()).then((defaults) => {
                Object.keys(defaults).forEach((key) => {
                    formikRef.current.setFieldValue(key, defaults[key] || initialFormState[key]);
                });
                formikRef.current.setFieldError('parse', defaults.errors.parse);
                formikRef.current.setFieldError('files', defaults.errors.files);
            });
        });
        uppy.on('file-removed', () => setFiles(uppy.getFiles()));
        return () => {
            uppy.off('files-added');
            uppy.off('file-removed');
            dispatch({ type: UPPY_SHOWN, payload: false });
        };
    }, []);

    const selectStyling = {
        components: {
            DropdownIndicator: (props) => (
                <components.DropdownIndicator {...props}>
                    <i className="fal fa-angle-down" />
                </components.DropdownIndicator>
            ),
            IndicatorSeparator: () => null,
        },
        classNames: {
            control: () => 'full-form-control',
            valueContainer: () => 'full-form-value-container',
            input: () => 'full-form-input',
            dropdownIndicator: () => 'full-form-dropdown-indicator-container',
            menuList: () => 'full-form-menu',
        },
    };

    const validation = (values) => {
        const errors = {};
        if (files.length === 0) errors.files = 'Required';
        if (
            files.length > 1 &&
            ![
                LAYER_DATA_TYPES.DOCUMENT,
                LAYER_DATA_TYPES.MOSAIC,
                LAYER_DATA_TYPES.SINGLEBANDCOG,
                LAYER_DATA_TYPES.MULTIBANDCOG,
                LAYER_DATA_TYPES.SEGY,
                LAYER_DATA_TYPES.LAS,
            ].includes(values.datatype)
        )
            errors.files = 'Multiple files not supported for this datatype';
        const extensions = [...new Set(files.map((file) => file.extension))];
        if (extensions.length !== 1) errors.files = 'Files must have the same extension';
        else if (!PROCESSABLE_EXTENSIONS.includes(extensions[0]))
            errors.files = `File is not processable: ${extensions[0]}`;
        if (!values.type) errors.type = 'Required';
        if (!values.datatype) errors.datatype = 'Required';
        else if (values.type && !supportedDataTypes[values.type].includes(values.datatype))
            errors.datatype = 'Datatype not supported by selected type';
        if (!values.name) errors.name = 'Required';
        if (!values.organization_id) errors.organization_id = 'Required';
        if (!values.projection) errors.projection = 'Required';
        else if (!projections.find((p) => p.srid === values.projection)) errors.projection = 'Unknown CRS';
        return errors;
    };

    const addDataset = (values, helpers) => {
        if (values.collection_id === '') delete values.collection_id;
        // If we're here, validate has passed and we know files is not undefined
        UppyService.getInstance().on('restriction-failed', (file, error) => {
            helpers.setFieldError('files', error.message);
            formikRef.current.setSubmitting(false);
        });
        dispatch(createDataset(values))
            .then((dataset) => {
                if (project) dispatch(relateProjectDataset(project, dataset, true));
                setUploading(dataset);
            })
            .catch((err) => {
                if (err.response && err.response.status === 409)
                    helpers.setFieldError('files', err.response.data.detail);
                else handleApiError(err, helpers);
            });
    };

    const clearFiles = (errors) => {
        delete errors.files;
        delete errors.parse;
        UppyService.clearFiles();
    };

    if (uploading) {
        const uppy = UppyService.getUploading()[uploading.id];
        if (uppy)
            return (
                <>
                    <ModalBody>
                        <div>Uploading files for {uploading.name}</div>
                        <div className="upload-list">
                            {uppy.getFiles().map((file) => (
                                <div key={`file-${file.id}`}>
                                    <label htmlFor={file.id}>{file.name}</label>
                                    <progress id={file.id} value={file.progress.percentage} max={100}>
                                        {file.progress.percentage}%
                                    </progress>
                                </div>
                            ))}
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <span>Ongoing uploads will continue, check the uploads menu for their status</span>
                        <Button type="button" color="primary" onClick={onClose}>
                            Close
                        </Button>
                    </ModalFooter>
                </>
            );

        if (!closer) setCloser(setTimeout(onClose, 2000));

        return (
            <ModalBody className="centred">
                <i className="modal-icon modal-icon-good fal fa-circle-check no-hover" />
                <span className="big-modal-text">Upload completed</span>
            </ModalBody>
        );
    }

    return (
        <Formik
            enableReinitialize
            initialValues={currentFormState}
            onSubmit={addDataset}
            validate={validation}
            validateOnBlur={false}
            validateOnChange={false}
            innerRef={formikRef}
        >
            {({ values, errors, isSubmitting, setFieldValue, handleBlur, handleChange }) => (
                <Form>
                    <ModalBody>
                        <DragDrop
                            className="form-group"
                            uppy={UppyService.getInstance()}
                            allowMultipleFiles
                            note="Drag a file or browse to upload"
                        />
                        <div className="form-group file-readout">
                            <span className="file-upload-list">{files.map((file) => file.name).join(', ')}</span>
                            {files.length !== 0 ? (
                                <Button className="circle red" onClick={() => clearFiles(errors)}>
                                    <i className="fa fa-xmark" />
                                </Button>
                            ) : null}
                        </div>
                        {errors.parse
                            ? errors.parse.map((error) => <div className="error-text minor">{error}</div>)
                            : null}
                        <div className="error-text">{errors.files}</div>
                        <div className="form-group">
                            Type
                            <ReactSelect
                                name="type"
                                {...selectStyling}
                                placeholder="Select... (Required)"
                                options={[
                                    ...Object.keys(supportedDataTypes)
                                        .reduce(
                                            (acc, layerType) =>
                                                supportedDataTypes[layerType].includes(values.datatype) ||
                                                !values.datatype
                                                    ? [...acc, layerType]
                                                    : acc,
                                            []
                                        )
                                        .map((e) => ({
                                            value: e,
                                            label: supportedTypesLabels[e],
                                        })),
                                ]}
                                value={
                                    values.type
                                        ? {
                                              value: values.type,
                                              label: supportedTypesLabels[values.type],
                                          }
                                        : null
                                }
                                onBlur={handleBlur}
                                onChange={(data) => setFieldValue('type', data.value)}
                                isDisabled={files.length === 0}
                            />
                            <span className="error-text">{errors.type}</span>
                        </div>
                        <div className="form-group">
                            Datatype
                            <ReactSelect
                                name="datatype"
                                {...selectStyling}
                                placeholder={`Select... (${values.type === '' ? 'Select type first' : 'Required'})`}
                                isDisabled={files.length === 0 || values.type === ''}
                                options={
                                    values.type !== ''
                                        ? [
                                              ...supportedDataTypes[values.type].map((e) => ({
                                                  value: e,
                                                  label: supportedDataTypesLabels[e],
                                              })),
                                          ]
                                        : []
                                }
                                value={
                                    values.datatype
                                        ? {
                                              value: values.datatype,
                                              label: supportedDataTypesLabels[values.datatype],
                                          }
                                        : null
                                }
                                onBlur={handleBlur}
                                onChange={(data) => setFieldValue('datatype', data.value)}
                            />
                            <span className="error-text">{errors.datatype}</span>
                        </div>
                        <div className="form-group">
                            Name
                            <input
                                name="name"
                                type="text"
                                placeholder="Enter name... (Required)"
                                className="form-basic-input"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.name}
                            />
                            <span className="error-text">{errors.name}</span>
                        </div>
                        {collectionOpen ? null : (
                            <div className="form-group">
                                Organization
                                <ReactSelect
                                    name="organization"
                                    {...selectStyling}
                                    placeholder="Select... (Required)"
                                    options={[
                                        ...organizations.map((e) => ({
                                            value: e.id,
                                            label: e.display_name,
                                        })),
                                    ]}
                                    onBlur={handleBlur}
                                    onChange={(data) => setFieldValue('organization_id', data.value)}
                                />
                                <span className="error-text">{errors.organization_id}</span>
                            </div>
                        )}
                        <div className="form-group">
                            Projection
                            <ReactSelect
                                name="projection"
                                {...selectStyling}
                                placeholder="Select... (Required)"
                                options={
                                    projections
                                        ? projections.map((e) => ({
                                              value: e.srid,
                                              label: e.title,
                                          }))
                                        : []
                                }
                                value={
                                    values.projection && projections
                                        ? {
                                              value: values.projection,
                                              label:
                                                  projections.find((p) => p.srid === values.projection)?.title ||
                                                  'Unknown CRS, file may be incompatible',
                                          }
                                        : null
                                }
                                onBlur={handleBlur}
                                onChange={(data) => setFieldValue('projection', data.value)}
                            />
                            <span className="error-text">{errors.projection}</span>
                        </div>
                        {collectionOpen ? null : (
                            <div className="form-group">
                                Collection
                                <ReactSelect
                                    name="collection"
                                    {...selectStyling}
                                    placeholder={`Select${
                                        values.organization_id === '' ? ' (Select organization first)' : ''
                                    }...`}
                                    isDisabled={values.organization_id === ''}
                                    options={[
                                        ...collections
                                            .filter(
                                                (collection) =>
                                                    collection.user_permissions.update_collection &&
                                                    collection.organization_id === values.organization_id
                                            )
                                            .map((e) => ({
                                                value: e.id,
                                                label: e.name,
                                            })),
                                    ]}
                                    onBlur={handleBlur}
                                    onChange={(data) => setFieldValue('collection_id', data?.value || null)}
                                    isClearable
                                />
                                <span className="error-text">{errors.collection_id}</span>
                            </div>
                        )}
                    </ModalBody>
                    <ModalFooter>
                        <Button type="submit" color="primary" disabled={isSubmitting}>
                            Upload <i className={`fal fa-${isSubmitting ? 'spinner fa-pulse' : 'arrow-right'}`} />
                        </Button>
                    </ModalFooter>
                </Form>
            )}
        </Formik>
    );
};

export default AddDataset;
