import React, { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import ReactSelect from 'react-select';
import { Button } from 'reactstrap';
import Annotation, { AnnotationComment, AnnotationId } from 'types/Annotation';
import { User } from 'types/User';
import { useAppDispatch } from 'store';
import { updateComment, updateReply } from '../../../redux/actions';
import { getProjectUsers } from '../../../redux/selectors';

export type Props = {
    annotation: Annotation;
    comment: AnnotationComment;
    parent_id?: AnnotationId;
    onSubmit?: () => void;
    close?: () => void;
};

const CommentEditForm = (props: Props) => {
    const dispatch = useAppDispatch();
    const [submitting, setSumbitting] = useState(false);
    const [mentioning, setMentioning] = useState(false);

    const users = useSelector(getProjectUsers);

    const ref = useRef<HTMLDivElement>();

    const submit = () => {
        setSumbitting(true);
        if (ref.current.innerText === '') {
            setSumbitting(false);
            if (props.onSubmit) props.onSubmit();
        } else if (props.parent_id) {
            document.getElementById('edit-field').contentEditable = 'false';
            dispatch(
                updateReply(props.annotation, props.parent_id, props.comment.id, {
                    content: document.getElementById('edit-field').innerText,
                })
            ).finally(() => {
                if (props.onSubmit) props.onSubmit();
            });
        } else {
            document.getElementById('edit-field').contentEditable = 'false';
            dispatch(
                updateComment(props.annotation, props.comment.id, {
                    content: document.getElementById('edit-field').innerText,
                })
            ).finally(() => {
                if (props.onSubmit) props.onSubmit();
            });
        }
    };

    return (
        <div className={`comment-form ${props.parent_id ? 'indent' : ''}`}>
            <div className="comment-close-container">
                <Button className="btn-edit-comment-close" onClick={props.close}>
                    <i className="fal fa-xmark-large" />
                </Button>
            </div>
            <div
                placeholder="Edit comment..."
                className={`comment-field ${submitting ? 'faded-text' : ''}`}
                role="textbox"
                id="edit-field"
                aria-label="comment edit field"
                contentEditable={!submitting}
                suppressContentEditableWarning
                ref={ref}
            >
                {props.comment.content}
            </div>
            <div className="comment-control-row">
                <div className="comment-controls">
                    <Button
                        className="borderless"
                        id="mention"
                        title="Mention"
                        onClick={() => setMentioning(!mentioning)}
                    >
                        <i className="comment-control fal fa-at" />
                    </Button>
                    {mentioning ? (
                        <ReactSelect
                            isMulti={false}
                            options={users}
                            className="mentionselect"
                            placeholder="Select a user to mention..."
                            unstyled
                            classNames={{
                                control: () => 'mentionselect-control',
                                input: () => 'mentionselect-input',
                                menu: () => 'mentionselect-menu',
                                menuList: () => 'mentionselect-menu-list',
                                option: (state) => `mentionselect-option ${state.isSelected ? 'selected' : ''}`,
                            }}
                            autoFocus
                            onChange={(val) => {
                                ref.current.textContent += `@(${val.id} | ${val.given_name} ${val.family_name}) `;
                                setMentioning(false);
                            }}
                            getOptionLabel={(option: User) => `${option.given_name} ${option.family_name}`}
                            maxMenuHeight={200}
                            components={{ DropdownIndicator: null }}
                            menuIsOpen
                            isSearchable
                        />
                    ) : null}
                </div>
                <Button className="borderless" id="send" title="Send" onClick={submit}>
                    <i className="comment-send fal fa-paper-plane-top" />
                </Button>
            </div>
        </div>
    );
};

export default CommentEditForm;
