import { RIGHT_TAB } from 'Navigation';
import Annotation, { AnnotationId } from 'types/Annotation';
import { Dispatch, GetState } from 'store';
import DosApi from 'services/DosApi';
import { ANNOTATION_CREATESTATE } from 'services/Constants';
import * as annotationSlice from 'redux/annotations';
import { NAV_RIGHT_TAB } from './actionTypes';

// Note: the code of this file was originally in actions.ts,
// but actions.ts imports the Giro3D service, which makes it untestable
// in a unit test environment.

export function selectAnnotationById(id?: AnnotationId) {
    return async (dispatch: Dispatch, getState: GetState) => {
        const annotations: Annotation[] = getState().annotations.list;
        const annotation = annotations.find((ann) => ann.id === id);

        if (annotation) {
            // Only select if not in edit mode
            dispatch({
                type: NAV_RIGHT_TAB,
                payload: RIGHT_TAB.PROJECT_ANNOTATIONS,
            });
            await dispatch(updateCommentsList(annotation));
        }
        return dispatch(annotationSlice.setActiveAnnotation(annotation));
    };
}

export function updateCommentsList(annotation: Annotation) {
    return async (dispatch: Dispatch) => {
        dispatch(annotationSlice.setCommentList([]));
        if (!annotation) return null;
        return DosApi.fetchComments(annotation.project_id, annotation.id).then((data) =>
            dispatch(annotationSlice.setCommentList(data))
        );
    };
}

export function selectAnnotation(annotation?: Annotation) {
    return async (dispatch: Dispatch) => {
        // Only select if not in edit mode
        dispatch({
            type: NAV_RIGHT_TAB,
            payload: RIGHT_TAB.PROJECT_ANNOTATIONS,
        });
        await dispatch(updateCommentsList(annotation));
        return dispatch(annotationSlice.setActiveAnnotation(annotation));
    };
}

export const startAnnotationCreation = () => (dispatch: Dispatch) => {
    // Unselect any annotation
    dispatch(selectAnnotation());
    dispatch(annotationSlice.createAnnotationState(ANNOTATION_CREATESTATE.SELECT));
};
