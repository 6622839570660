// React
import React from 'react';

// Formik
import { Field, useField } from 'formik';

// Boostrap
import { FormGroup, FormFeedback } from 'reactstrap';

const BaseSubmit = ({ className, id, type, ...props }) => {
    const [field, meta] = useField(props);
    if (!className) {
        className = 'btn btn-primary form-control';
    }
    if (!id) {
        id = props.name;
    }
    if (!type) {
        type = 'submit';
    }

    if (meta.error) {
        className += ' is-invalid';
    }

    return (
        <FormGroup>
            <Field {...field} {...props} id={id} type={type} className={className} />
            {meta.error ? <FormFeedback>{meta.error}</FormFeedback> : null}
        </FormGroup>
    );
};

export default BaseSubmit;
