import { Instance } from '@giro3d/giro3d/core';
import Controls from 'services/Controls';
import { AxesHelper, GridHelper, Group, Mesh, MeshLambertMaterial, SphereGeometry, Vector3 } from 'three';
import { LineMaterial } from 'three/examples/jsm/lines/LineMaterial';
import { CSS2DObject } from 'three/examples/jsm/renderers/CSS2DRenderer';

class Orientation {
    private readonly _instance: Instance;
    private readonly _controls: Controls;
    private readonly _position: Vector3;
    private readonly _group: Group;
    private readonly _grid: GridHelper;
    private readonly _sphere: Mesh<SphereGeometry, MeshLambertMaterial>;
    private readonly _eventHandlers: { change: () => void };

    private _axes: Map<number, AxesHelper> = new Map();
    private _axesHelperSize: number;
    private _zScale: number;

    constructor(
        instance: Instance,
        controls: Controls,
        options: {
            zScale: number;
            showAxesHelper?: boolean;
            axesHelperSize?: number;
            showGrid?: boolean;
            showCenterWireframe?: boolean;
        } = { zScale: 1 }
    ) {
        this._instance = instance;
        this._controls = controls;
        this._position = new Vector3();

        this._controls.getInteractionPoint(this._position);

        this._group = new Group();
        this._group.renderOrder = 100;
        this._group.position.copy(this._position);
        this._group.updateMatrix();
        this._group.updateMatrixWorld(true);
        instance.scene.add(this._group);

        if (options.showAxesHelper ?? true) {
            const axesHelperSize = options.axesHelperSize ?? 200;

            this._axesHelperSize = axesHelperSize;
            this._zScale = options.zScale;

            this.createAxesHelper(axesHelperSize, options.zScale);

            this.updateAxisDimensionLabels(axesHelperSize, options.zScale);
        }

        if (options.showGrid ?? false) {
            this._grid = new GridHelper(200, 10, 0x888888, 0x888888);
            this._grid.position.copy(this._position);
            this._grid.updateMatrix();
            this._grid.updateMatrixWorld(true);
            instance.threeObjects.add(this._grid);
        }

        if (options.showCenterWireframe ?? false) {
            const material = new MeshLambertMaterial({ color: 0xc0c0c0, wireframe: true });
            const geometry = new SphereGeometry(20, 8, 8);
            const sphere = new Mesh(geometry, material);
            this._group.add(sphere);
            this._sphere = sphere;
        }

        this._group.visible = false;

        this._eventHandlers = {
            change: this.update.bind(this),
        };

        this._controls.cameraControls.addEventListener('update', this._eventHandlers.change);

        this.update();
    }

    set visible(visible: boolean) {
        this._group.visible = visible;
        this._group.children.forEach((o) => {
            if (o instanceof CSS2DObject) o.visible = visible;
        });
    }

    get visible() {
        return this._group.visible;
    }

    dispose() {
        this._axes?.forEach((helper) => helper.dispose());
        this._axes?.clear();
        this._grid?.dispose();
        if (this._sphere) {
            this._sphere.material.dispose();
            this._sphere.geometry.dispose();
        }
        this._controls.cameraControls.removeEventListener('update', this._eventHandlers.change);
        this._instance.scene.remove(this._group);
    }

    update() {
        this._controls.getInteractionPoint(this._position);

        if (this._grid) {
            this._grid.visible = this._group.visible;
        }

        if (this._group.visible) {
            this._group.position.copy(this._position);
            this._group.updateMatrix();
            this._group.updateMatrixWorld(true);

            if (this._grid) {
                this._grid.position.copy(this._position).divide(this._instance.threeObjects.scale);
                this._grid.updateMatrix();
                this._grid.updateMatrixWorld(true);
            }
        }

        this._instance.notifyChange(this._group);
        if (this._grid) {
            this._instance.notifyChange(this._grid);
        }
    }

    // eslint-disable-next-line class-methods-use-this
    private createLabel(innerText: string, relativePosition = new Vector3(0, 0, 0)) {
        const label = document.createElement('div');
        label.style.color = '#ffffff';
        label.style.fontSize = '14px';
        label.style.fontWeight = 'bold';
        label.style.pointerEvents = 'none';
        label.style.textAlign = 'center';
        label.innerText = `${innerText}`;
        const axisLabel = new CSS2DObject(label);
        axisLabel.renderOrder = -1; // Display behind any other label
        axisLabel.position.set(relativePosition.x, relativePosition.y, relativePosition.z);
        this._group.add(axisLabel);
    }

    private updateAxisDimensionLabels(axesHelperSize: number, zScale = 1) {
        this._group.remove(...this._group.children.filter((o) => o instanceof CSS2DObject));

        if (zScale !== 1) {
            this.createLabel(`${Number(axesHelperSize / zScale).toFixed(0)}m`, new Vector3(0, 0, axesHelperSize));
            this.createLabel(`${axesHelperSize}m`, new Vector3(0, axesHelperSize, 0));
            this.createLabel(`${axesHelperSize}m`, new Vector3(axesHelperSize, 0, 0));
        } else this.createLabel(`${axesHelperSize}m`);
    }

    private createAxesHelper(size: number, zScale: number): AxesHelper {
        const axesHelper = new AxesHelper(size);
        axesHelper.setColors(0x880000, 0x008800, 0x000088);
        const material = axesHelper.material as LineMaterial;
        material.depthTest = false;
        material.transparent = true;
        axesHelper.renderOrder = 100;
        this.updateAxisDimensionLabels(size, zScale);
        this._group.add(axesHelper);
        this._axes.set(size, axesHelper);
        return axesHelper;
    }

    setAxesHelperSize(size: number, zScale = 1) {
        if (size !== this._axesHelperSize || zScale !== this._zScale) {
            this._axes.forEach((helper) => {
                helper.visible = false;
            });

            if (this._zScale !== zScale) {
                this._axes.forEach((helper) => {
                    helper.dispose();
                    helper.removeFromParent();
                });

                this._axes.clear();
                this._zScale = zScale;
            }

            let helper = this._axes.get(size);
            if (!helper) {
                helper = this.createAxesHelper(size, this._zScale);
            }

            this._axesHelperSize = size;

            this.updateAxisDimensionLabels(size, zScale);

            helper.visible = true;
        }
    }
}

export default Orientation;
