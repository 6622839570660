import React, { useState } from 'react';

import { Button, Modal, UncontrolledTooltip } from 'reactstrap';
import ViewMenu from './ViewMenu';

const ViewButton = () => {
    const [openExportUrlModal, setOpenExportUrlModal] = useState(false);

    return (
        <>
            <Button className="giro3d-save" id="giro3d-save" onClick={() => setOpenExportUrlModal(true)}>
                <i className="fal fa-share-alt" />
                <UncontrolledTooltip placement="top" target="giro3d-save">
                    Share view
                </UncontrolledTooltip>
            </Button>

            <Modal className="modal-md" isOpen={openExportUrlModal} toggle={() => setOpenExportUrlModal(false)}>
                <ViewMenu closeMenu={() => setOpenExportUrlModal(false)} />
            </Modal>
        </>
    );
};

export default ViewButton;
