// React
import React, { useState, useEffect } from 'react';
import * as ReactDOM from 'react-dom';
import { useSelector } from 'react-redux';
import { Button, Card, CardHeader, CardBody } from 'reactstrap';
import { CSS2DObject } from 'three/examples/jsm/renderers/CSS2DRenderer';
import { getService } from 'ServiceContainer';
import { Vector3 } from 'three';
import { useEventBus } from 'EventBus';
import FeatureCard from './FeatureCard';

// Redux
import * as giro3d from '../../../redux/giro3d';

// Required for preventing Giro3D from using any click event on the tooltip
const noop = (e) => {
    e.stopPropagation();
};

const MAX_DISPLAYED_FEATURES = 10;

const ItemCard = (props: { item: giro3d.SelectionItem; at: Vector3 }) => {
    const { item, at } = props;
    const featureManager = getService('FeatureManager');
    const selectItem = () => {
        const { layer, feature } = item;
        featureManager.selectFeature(layer, feature, at);
    };

    const featureIcon = 'fal fa-vector-square fa-fw';
    const properties = item.feature.getProperties();
    const firstColumn = Object.keys(properties)
        .filter((h) => h !== 'geometry')
        .at(0);

    return (
        <li>
            <Button onClick={selectItem} color="link" className="px-0 border-0">
                <i className={featureIcon} /> {firstColumn ? properties[firstColumn] : item.feature.getId()}
            </Button>
        </li>
    );
};

// NOTE: it seems that click events on the ItemsPopup are propagated directly to the giro3d map. Clicking anywhere on the popup except for
// precicely on the buttons causes it to close.
const ItemsPopup = () => {
    const featureManager = getService('FeatureManager');
    const mainView = getService('MainViewManager');
    const eventBus = useEventBus();
    const giro3dInitialized = useSelector(giro3d.isInitialized);
    const { at, items } = useSelector(giro3d.getSelectedItems);
    const [domNode, setDomNode] = useState(undefined);
    const [popup, setPopup] = useState(undefined);

    const close = () => {
        featureManager.unselectFeature();
    };

    useEffect(() => {
        if (giro3dInitialized) {
            const itemsPortalNode = document.createElement('div');
            itemsPortalNode.setAttribute('id', 'itemsPopoverPortal');
            const itemsPopup = new CSS2DObject(itemsPortalNode);
            itemsPopup.renderOrder = 10; // Always show on top of other labels
            setDomNode(itemsPopup.element);
            setPopup(itemsPopup);
            mainView.addThreeObject(itemsPopup);
        }
    }, [giro3dInitialized]);

    useEffect(() => {
        if (giro3dInitialized && items !== undefined) {
            popup?.position.copy(at);
            popup?.updateMatrixWorld();
            eventBus.dispatch('notify-change', { source: popup });
        }
    }, [giro3dInitialized, at, items]);

    const popover = () => {
        if (items.length === 1) return <FeatureCard feature={items[0].feature} closeCardCallback={close} />;
        return (
            <Card
                className="popup-body"
                style={{ pointerEvents: 'auto', transform: 'translate(50%, 50%) translate(0.5rem, -1.5rem)' }}
                onClick={noop}
            >
                <CardHeader>
                    <h5>{items.length} features</h5>
                    <button type="button" className="btn-close btn-close-white" aria-label="Close" onClick={close} />
                </CardHeader>
                <CardBody>
                    <ul className="list-unstyled">
                        {items.slice(0, MAX_DISPLAYED_FEATURES).map((item) => (
                            <ItemCard key={`featureCard-${item.feature.getId()}`} item={item} at={at} />
                        ))}
                        {items.length > MAX_DISPLAYED_FEATURES ? (
                            <li>
                                <i className="fal fa-ellipsis fa-fw" />
                            </li>
                        ) : null}
                    </ul>
                </CardBody>
            </Card>
        );
    };

    return domNode === undefined ? null : ReactDOM.createPortal(popover(), domNode);
};

const ItemsPopupWrapper = () => {
    const { items } = useSelector(giro3d.getSelectedItems, giro3d.equalItems);
    if (items.length === 0) return null;
    return <ItemsPopup />;
};

export default ItemsPopupWrapper;
