// React
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

// Redux
import { getProjects } from '../../../redux/selectors';
import ToggleSwitch from '../../ToggleSwitch';

// Components
import ProjectListItem from './ProjectListItem';

const ProjectsMenu = () => {
    const projects = useSelector(getProjects);
    const [open, setOpen] = useState(false);
    const [visible, setVisible] = useState(true);

    const renderedListItems = projects
        ? projects
              .sort((a, b) => {
                  if (a.name.toUpperCase() < b.name.toUpperCase()) return -1;
                  if (a.name.toUpperCase() > b.name.toUpperCase()) return 1;
                  return 0;
              })
              .map((project) => (
                  <ProjectListItem
                      key={`project-overview-${project.id}`}
                      project={project}
                      groupOpen={open}
                      groupVisible={visible}
                  />
              ))
        : [];

    return (
        <div className="data-group">
            <div className="data-group-header">
                <label htmlFor="projects-dropdown" className="data-group-label dropdown-arrow">
                    <span>Projects</span>
                    <input
                        type="checkbox"
                        id="projects-dropdown"
                        checked={open}
                        onChange={(e) => setOpen(e.target.checked)}
                    />
                    <div className="arrow" />
                </label>
                <ToggleSwitch id="projects-visible" checked={visible} onChange={(e) => setVisible(e.target.checked)} />
            </div>
            {renderedListItems}
        </div>
    );
};

export default ProjectsMenu;
