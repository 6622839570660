import { Formik, Form } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactSelect from 'react-select';
import { Button, Input, Modal, ModalBody, ModalFooter, ModalHeader, UncontrolledTooltip } from 'reactstrap';

import { createMembershipFromEmail, fetchMembershipsToAdmin, fetchUsersToAdmin, showError } from '../../redux/actions';
import { getMembershipsToAdministrate, getUser, getUsersToAdministrate } from '../../redux/selectors';
import { ROLES } from '../../services/Constants';
import DosApi from '../../services/DosApi';
import BaseField from '../forms/BaseField';
import BaseSubmit from '../forms/BaseSubmit';
import HelpPanel from '../forms/HelpPanel';

import UserItem from './UserItem';

const Users = () => {
    const dispatch = useDispatch();

    const [search, setSearch] = useState('');
    const [organizations, setOrganizations] = useState([]);
    const [organization, setOrganization] = useState();
    const [addingUser, setAddingUser] = useState(false);

    const memberships = useSelector(getMembershipsToAdministrate);
    const users = useSelector(getUsersToAdministrate);

    const currentUser = useSelector(getUser);

    const organizationOptions = [
        ...organizations
            .filter(
                (option) =>
                    option.user_permissions.create_memberships ||
                    option.user_permissions.delete_memberships ||
                    currentUser.is_admin
            )
            .map((option) => ({
                value: option,
                label: option.display_name,
            })),
        ...(currentUser.is_admin ? [{ value: null, label: 'All users' }] : []),
    ];

    useEffect(() => {
        DosApi.fetchOwnedOrganizations()
            .then((data) => {
                setOrganizations(data);
                if (data.length !== 0 && currentUser.is_admin === false) setOrganization(data[0]);
                else setOrganization(null);
            })
            .catch((error) => showError(dispatch, error));
    }, []);

    useEffect(() => {
        if (organization) dispatch(fetchMembershipsToAdmin(organization.id));
        else if (currentUser.is_admin) dispatch(fetchUsersToAdmin());
    }, [organization]);

    const addUser = (values) => {
        dispatch(
            createMembershipFromEmail({ email: values.email, organization_id: organization.id, role: ROLES.MEMBER })
        );
        setAddingUser(false);
    };

    const renderedListItems = organization
        ? memberships
              .filter(
                  (membership) =>
                      membership.role !== ROLES.PENDING &&
                      `${membership.user.given_name} ${membership.user.family_name}`
                          .toLowerCase()
                          .includes(search.toLowerCase())
              )
              .sort((a, b) => a.id - b.id)
              .map((membership) => (
                  <UserItem organization={organization} membership={membership} user={membership.user} />
              ))
        : users
              .filter((user) => `${user.given_name} ${user.family_name}`.toLowerCase().includes(search.toLowerCase()))
              .sort((a, b) => {
                  const aName = `${a.given_name} ${a.family_name}`;
                  const bName = `${b.given_name} ${b.family_name}`;
                  if (aName < bName) return -1;
                  if (aName > bName) return 1;
                  return 0;
              })
              .map((user) => <UserItem user={user} />);

    return (
        <>
            <div className="full-page-title">
                Users<span className="faded-text">({renderedListItems.length})</span>
            </div>
            <div className="admin-header">
                <ReactSelect
                    title="Organization"
                    options={organizationOptions}
                    unstyled
                    className="adminselect"
                    classNames={{
                        control: () => 'adminselect-control',
                        input: () => 'adminselect-input',
                        menu: () => 'adminselect-menu',
                        menuList: () => 'adminselect-menu-list',
                        option: (state) => `adminselect-option ${state.isSelected ? 'selected' : ''}`,
                    }}
                    value={organization ? { label: organization.display_name } : { label: 'All users' }}
                    onChange={(value) => setOrganization(value.value)}
                />
                <div className="searchbox">
                    <Input
                        placeholder="Search"
                        type="text"
                        value={search}
                        onChange={(value) => setSearch(value.target.value)}
                    />
                    <i className="fa-solid fa-magnifying-glass search-icon" />
                </div>
                <div id="add-user" className="fit-content">
                    <Button
                        className="circle green"
                        title="Add User"
                        onClick={() => setAddingUser(true)}
                        disabled={
                            !organization || !(currentUser.is_admin || organization.user_permissions.create_memberships)
                        }
                    >
                        <i className="fal fa-plus" />
                    </Button>
                    {!organization || !(currentUser.is_admin || organization.user_permissions.create_memberships) ? (
                        <UncontrolledTooltip target="add-user">
                            You do not have the permissions to add users to this organization.
                        </UncontrolledTooltip>
                    ) : null}
                </div>
            </div>
            <table>
                <tr className="user-admin-header">
                    <th>Name</th>
                    <th>Email</th>
                    {organization ? <th>Role</th> : null}
                    <th>Actions</th>
                </tr>
                {renderedListItems}
            </table>
            <Modal isOpen={addingUser} toggle={() => setAddingUser(false)} centered className="modal-form">
                <ModalHeader
                    toggle={() => {
                        setAddingUser(null);
                    }}
                >
                    Add User to {organization ? organization.display_name : ''}
                </ModalHeader>
                <Formik enableReinitialize onSubmit={addUser} initialValues={{ email: '' }}>
                    {({ isSubmitting }) => (
                        <Form>
                            <ModalBody>
                                <BaseField name="email" label="Email" />
                                <HelpPanel>
                                    You do not need this form to add users who are using the Azure tenant that is
                                    registered to the organization, requests to join will be generated as they register.
                                    <br />
                                    This form can be used to add users who already have access to Argeo SCOPE but are
                                    not in your orgaization such as those from other organizations.
                                </HelpPanel>
                            </ModalBody>
                            <ModalFooter>
                                <BaseSubmit name="submit" color="primary" disabled={isSubmitting} value="Add user" />
                            </ModalFooter>
                        </Form>
                    )}
                </Formik>
            </Modal>
        </>
    );
};

export default Users;
