import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'reactstrap';

import * as graphicsSettings from 'redux/graphicsSettings';
import * as settings from 'redux/settings';

import { toHex } from 'types/common';
import ToggleSwitch from '../../ToggleSwitch';
import SettingsSelect from './SettingsSelect';
import { TICKS_PRESETS } from '../../../services/Constants';
import ColorSelector from './ColorSelector';
import { SettingSlider } from './SettingSlider';

function ContourLineSetting() {
    const dispatch = useDispatch();

    const open = useSelector(settings.getDropDown);

    const enabled = useSelector(graphicsSettings.isContourLinesEnabled);
    const opacity = useSelector(graphicsSettings.getContourLineOpacity);
    const primary = useSelector(graphicsSettings.getContourLinePrimaryInterval);
    const secondary = useSelector(graphicsSettings.getContourLineSecondaryInterval);
    const color = useSelector(graphicsSettings.getContourLineColor);

    const changeOpen = (value: boolean) => {
        dispatch(settings.setDropDown(value ? 'contourlines' : null));
    };

    return (
        <div className="settings-group">
            <div className="settings-main-toggle">
                <div>
                    <i className={`fal fa-line-height ${enabled ? 'active' : null}`} />
                </div>
                <label htmlFor="contourline-dropdown" className="settings-label dropdown-arrow">
                    <span>Contour lines</span>
                    <Button
                        className="borderless"
                        title="Reset Contour lines"
                        onClick={() => {
                            dispatch(graphicsSettings.resetContourLines());
                        }}
                    >
                        <i className="fal fa-arrow-rotate-left" />
                    </Button>
                    <input
                        type="checkbox"
                        id="contourline-dropdown"
                        checked={open === 'contourlines'}
                        onChange={(e) => changeOpen(e.target.checked)}
                        hidden
                    />
                    <div className="arrow" />
                </label>
                <ToggleSwitch
                    id="contourlines"
                    checked={enabled}
                    onChange={(e) => dispatch(graphicsSettings.enableContourLines(e.target.checked))}
                />
            </div>

            {open === 'contourlines' ? (
                <div className="settings-dropdown">
                    <div className="settings-row">
                        <span>Primary interval</span>
                        <SettingsSelect
                            value={{ label: `${primary}m`, value: primary }}
                            onChange={(e) => dispatch(graphicsSettings.setContourLinesPrimaryInterval(e.value))}
                            options={TICKS_PRESETS.sort((a, b) => a - b).map((x) => ({ label: `${x}m`, value: x }))}
                        />
                    </div>
                    <div className="settings-row">
                        <span>Secondary interval</span>
                        <SettingsSelect
                            value={{ label: `${secondary}m`, value: secondary }}
                            onChange={(e) => dispatch(graphicsSettings.setContourLinesSecondaryInterval(e.value))}
                            options={TICKS_PRESETS.sort((a, b) => a - b).map((x) => ({ label: `${x}m`, value: x }))}
                        />
                    </div>
                    <div className="settings-row">
                        <span>Color</span>
                        <ColorSelector
                            color={color}
                            onChange={(c) => dispatch(graphicsSettings.setContourLinesColor(toHex(c)))}
                        />
                    </div>
                    <SettingSlider
                        title="Opacity"
                        format="percent"
                        min={0}
                        max={1}
                        step={0.01}
                        value={opacity}
                        onChange={(v) => dispatch(graphicsSettings.setContourLinesOpacity(v))}
                    />
                </div>
            ) : null}
        </div>
    );
}

export default ContourLineSetting;
