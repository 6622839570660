import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store';

type State = {
    dropdown: string;
    showAnnotationsInViewport: boolean;
    filterAnnotationByVisibility: boolean;
};

const initialState: State = {
    dropdown: null,
    showAnnotationsInViewport: true,
    filterAnnotationByVisibility: false,
};

const self = (state: RootState) => state.settings;

// Selectors
export const getDropDown = createSelector(self, (s) => s.dropdown);
export const getShowAnnotationsInViewport = createSelector(self, (s) => s.showAnnotationsInViewport);
export const getFilterAnnotationByVisibility = createSelector(self, (s) => s.filterAnnotationByVisibility);

export const slice = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        reset: () => initialState,
        dropdown: (state, action: PayloadAction<string>) => {
            state.dropdown = action.payload;
        },
        showAnnotationsInViewport: (state, action: PayloadAction<boolean>) => {
            state.showAnnotationsInViewport = action.payload;
        },
        filterAnnotationByVisibility: (state, action: PayloadAction<boolean>) => {
            state.filterAnnotationByVisibility = action.payload;
        },
    },
});

export const reducer = slice.reducer;

// Actions
export const setDropDown = slice.actions.dropdown;
export const showAnnotationsInViewport = slice.actions.showAnnotationsInViewport;
export const filterAnnotations = slice.actions.filterAnnotationByVisibility;
