import Overlay from 'ol/Overlay';
import { Group } from 'ol/layer';

import { OVERVIEW_MAP_RESOLUTION, OVERVIEW_MAP_FEATURE_INFO } from '../../../redux/actionTypes';

function getFirstWmsFeatureDataAtPixel(map, pixel) {
    let lData;
    let hasWmsFeatureAtPixel;
    for (const layer of map.getLayers().array_) {
        if (layer.getVisible() && layer instanceof Group && layer.values_.title !== 'Basemap') {
            for (const l of layer.getLayers().array_) {
                if (l.getVisible()) {
                    lData = l.getData(pixel);
                    hasWmsFeatureAtPixel = lData && lData[3] > 0; // lData[3] is zero for transparent pixels.
                    if (hasWmsFeatureAtPixel) {
                        return { wmsData: lData, layer: l };
                    }
                }
            }
        }
    }
    return { wmsData: undefined, layer: undefined };
}

const addFeatureInfoInteraction = (map, dispatch) => {
    const featureInfoPortal = document.createElement('div');
    featureInfoPortal.setAttribute('id', 'featureInfoPortal');
    const featureInfoPopup = new Overlay({
        id: 'featureInfoPopover',
        position: undefined,
        element: featureInfoPortal,
    });

    map.addOverlay(featureInfoPopup);

    map.on('pointermove', (evt) => {
        if (!evt.dragging) {
            const featureData = getFirstWmsFeatureDataAtPixel(map, evt.pixel);
            if (featureData.wmsData !== undefined) {
                map.getTargetElement().style.cursor = 'pointer';
            }
        }
    });

    map.on('change:resolution', () => {
        dispatch({ type: OVERVIEW_MAP_RESOLUTION, payload: map.getView().getResolution() });
    });

    map.on('singleclick', (evt) => {
        const featureData = getFirstWmsFeatureDataAtPixel(map, evt.pixel);
        if (featureData.layer !== undefined) {
            const viewResolution = map.getView().getResolution();
            const url = featureData.layer.getSource().getFeatureInfoUrl(evt.coordinate, viewResolution, 'EPSG:3857', {
                'INFO_FORMAT': 'application/json',
            });
            if (url) {
                fetch(`${url}&QUERY_LAYERS=${featureData.layer.get('layerId')}`)
                    .then((response) => response.json())
                    .then((data) => {
                        dispatch({ type: OVERVIEW_MAP_FEATURE_INFO, payload: { data, coordinate: evt.coordinate } });
                    })
                    .catch((error) => console.log(error));
            }
        }
    });
};

export default addFeatureInfoInteraction;
