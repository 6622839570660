import { HasFootprint, LayerState } from 'types/LayerState';
import * as layers from 'redux/layers';
import { useAppDispatch, useAppSelector } from 'store';
import SettingToggleSwitch from './SettingToggleSwitch';

export type Props = {
    layer: LayerState & HasFootprint;
};

const FootprintSettings = (props: Props) => {
    const { layer } = props;
    const id = layer.datasetId;
    const dispatch = useAppDispatch();

    const showLabels = useAppSelector(layers.getFootprintLabelVisibility(props.layer));

    function setLabelVisibility(value: boolean) {
        dispatch(layers.setFootprintLabelVisibility({ layer, value }));
    }

    return (
        <SettingToggleSwitch
            id={`toggle-footprint-labels-${id}`}
            checked={showLabels}
            title="Show filenames"
            icon="fa-input-text"
            onChange={(e) => setLabelVisibility(e.target.checked)}
        />
    );
};

export default FootprintSettings;
