// React
import React from 'react';
import { useSelector } from 'react-redux';
import { supportedTypesLabels, supportedDataTypesLabels } from '../../../services/Labels';
// Redux
import { getOrganizations, getSelectedDataset } from '../../../redux/selectors';
import { ItemPopupoverBase, popoverSection } from '../ItemPopoverBase';

const DatasetPopup = () => {
    const organizations = useSelector(getOrganizations);
    const { at, dataset } = useSelector(getSelectedDataset) ?? {};

    const popoverBody = () =>
        dataset === undefined ? null : (
            <>
                {popoverSection('Type', supportedTypesLabels[dataset.type])}
                {popoverSection('Source', supportedDataTypesLabels[dataset.datatype])}
                {popoverSection(
                    'Owner',
                    organizations
                        ? organizations.find((e) => e.id === dataset.organization_id)?.display_name
                        : 'Loading...'
                )}
            </>
        );

    return <ItemPopupoverBase overlayId="datasetPopup" at={at} item={dataset} popoverBody={popoverBody} />;
};

export default DatasetPopup;
