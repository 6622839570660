import { Color } from 'three';
import ColorSelector from './ColorSelector';

export type Props = {
    color: Color;
    title?: string;
    onChange: (color: Color) => void;
};

function SettingColorSelector(props: Props) {
    return (
        <div className="settings-row">
            <span>{props.title ?? 'Color'}</span>
            <ColorSelector color={props.color} onChange={(c) => props.onChange(c)} />
        </div>
    );
}

export default SettingColorSelector;
