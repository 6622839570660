import React from 'react';
import { useSelector } from 'react-redux';

import { getUser } from '../../redux/selectors';

const MyProfile = () => {
    const user = useSelector(getUser);

    return (
        <dl className="row">
            <dt className="col-sm-3">Given name</dt>
            <dd className="col-sm-9">{user.given_name}</dd>

            <dt className="col-sm-3">Family name</dt>
            <dd className="col-sm-9">{user.family_name}</dd>

            <dt className="col-sm-3">Username</dt>
            <dd className="col-sm-9">{user.username}</dd>

            <dt className="col-sm-3">Email</dt>
            <dd className="col-sm-9">{user.email}</dd>
        </dl>
    );
};

export default MyProfile;
