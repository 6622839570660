import React from 'react';

const ElevationTooltip = (a) => {
    const { payload, active } = a;
    return active && payload ? (
        <div className="elevation-profile-tooltip">
            <p>{payload[0].payload.datasetName}</p>
            <p>
                <span>x: </span>
                {payload[0].payload.x}
            </p>
            <p>
                <span>y: </span>
                {payload[0].payload.y}
            </p>
            <p>
                <span>z: </span>
                {payload[0].payload.z}
            </p>
            <p>
                <span>d: </span>
                {payload[0].payload.d}
            </p>
        </div>
    ) : null;
};

export default ElevationTooltip;
