import Point from 'ol/geom/Point';
import { Circle as CircleStyle, Fill, Stroke, Style, Text } from 'ol/style';
import { Polygon } from 'ol/geom';

const verticeLabelsStyle = (coordinates) => {
    const styles = [];
    coordinates.forEach((coord, index) => {
        styles.push(
            new Style({
                image: new CircleStyle({
                    radius: 14,
                    fill: new Fill({
                        color: '#070607',
                    }),
                    stroke: new Stroke({
                        color: '#ebebec',
                        width: 2,
                    }),
                }),
                text: new Text({
                    font: '14px futura-pt',
                    overflow: true,
                    fill: new Fill({
                        color: '#ebebec',
                    }),
                    stroke: new Stroke({
                        color: '#ebebec',
                        width: 1,
                    }),
                    text: String(index + 1),
                    offsetX: '0.2',
                    offsetY: '0.4',
                }),
                geometry: new Point(coord),
            })
        );
    });
    return styles;
};

const getFeatureVertices = (feature) => {
    const geom = feature.getGeometry();
    let coordinates;
    if (geom instanceof Polygon) {
        coordinates = geom.getCoordinates()[0].slice(0, -1);
    } else {
        coordinates = geom.getCoordinates();
    }
    return coordinates;
};

const drawStyle = new Style({
    fill: new Fill({
        color: 'rgba(255, 255, 255, 0.2)',
    }),
    stroke: new Stroke({
        color: '#000',
        lineDash: [10, 5],
        width: 2,
    }),
});

export const drawStyleFunction = (feature) => {
    const styles = [drawStyle];
    const vertices = getFeatureVertices(feature);
    const labels = verticeLabelsStyle(vertices);
    styles.push(...labels);
    return styles;
};

const finishedStyle = new Style({
    fill: new Fill({
        color: 'rgba(255, 255, 255, 0.2)',
    }),
    stroke: new Stroke({
        color: '#000',
        lineDash: [10, 5],
        width: 2,
    }),
});

export const finishedStyleFunction = (feature) => {
    const styles = [finishedStyle];
    const vertices = getFeatureVertices(feature);
    const labels = verticeLabelsStyle(vertices);
    styles.push(...labels);
    return styles;
};

export const modifyStyleFunction = new Style({
    image: new CircleStyle({
        radius: 10,
        fill: new Fill({
            color: '#000',
        }),
        stroke: new Stroke({
            color: '#1bb34b',
            width: 2,
        }),
    }),
});
