import * as THREE from 'three';
import { Extent } from '@giro3d/giro3d/core/geographic';
import { Dispatch } from 'store';
import { DatasetId, SourceFileId, fromBox3 } from 'types/common';
import Dataset from 'types/Dataset';
import SeismicPlane2dLayer from 'giro3d_extensions/layers/seismic/SeismicPlane2dLayer';
import { HostView } from 'giro3d_extensions/layers/Layer';
import { useServiceContainer } from 'ServiceContainer';
import { hasSeismicPlane } from 'types/LayerState';
import { SourceFile } from 'types/SourceFile';
import SeismicPlane2dBuilder from '../giro3d_extensions/layerBuilders/SeismicPlane2dBuilder';
import BaseGiro3dService, { PickedPoint } from './BaseGiro3dService';
import { LAYER_TYPES } from './Constants';
import SeismicControls from './SeismicControls';
import LayerManager from '../giro3d_extensions/LayerManager';

import * as giro3d from '../redux/giro3d';
import * as layersSlice from '../redux/layers';
import ViewManager from './ViewManager';

class SeismicService extends BaseGiro3dService implements ViewManager {
    private _activeLayer: DatasetId;

    constructor() {
        super({ hostView: HostView.SeismicView, canHover: false });
        this._inspectorTitle = 'Seismic Inspector';

        const container = useServiceContainer();
        container.register('SeismicViewManager', this);
    }

    init(domElem: HTMLDivElement, inspectorDomElem: HTMLDivElement, extent: Extent, _dispatch: Dispatch) {
        super.init(domElem, inspectorDomElem, extent, _dispatch, {
            camera: new THREE.OrthographicCamera(-50, 50, 50, -50, 1, 2),
        });
        this._dispatch(giro3d.setSeismicViewInitialized(true));
    }

    deinit() {
        super.deinit();
        this._dispatch(giro3d.setSeismicViewInitialized(false));
    }

    protected initLayerManager() {
        return new LayerManager({
            instance: this._instance,
            segments: this._segments,
            hillshading: this._enableHillshading,
            hillshadingIntensity: this._hillshadeIntensity,
            azimuth: this._lightDirection.azimuth,
            zenith: this._lightDirection.zenith,
        });
    }

    protected initControls() {
        return new SeismicControls(this._instance, this.getPointAt.bind(this), this.getBoundingBox.bind(this));
    }

    protected onInteractionEnd() {
        if (this._interactionTimer !== null) {
            // There was already an end of interaction pending, cancel it
            clearTimeout(this._interactionTimer);
        }
        this._interactionTimer = setTimeout(this.doOnInteractionEnd.bind(this), 500);
    }

    async loadDataset(dataset: Dataset, sourceFiles: SourceFile[]) {
        if (!this._instance) return Promise.reject(new Error('Giro3d not initialized yet'));

        // Remove existing layer(s)
        this.getAllLayers().forEach((layer) => this.removeLayer(layer.datasetId));

        if (dataset.type !== LAYER_TYPES.SEISMIC)
            return Promise.reject(new Error(`Layer must be of type ${LAYER_TYPES.SEISMIC}, is ${dataset.type}`));

        // if (dataset.state !== LAYER_STATES.ACTIVE)
        //     return Promise.reject(new Error(`State should be ${LAYER_STATES.ACTIVE}, is ${dataset.state}`));

        this._activeLayer = dataset.id;

        const layerState = this._stateObserver.select(layersSlice.get(dataset.id));

        if (!hasSeismicPlane(layerState)) throw new Error('Layer does not have seismic plane');

        const builder = new SeismicPlane2dBuilder(dataset, sourceFiles, {
            dispatch: this._dispatch,
            instance: this._instance,
            layerManager: this._layerManager,
            hostView: this._hostView,
        });

        return builder
            .build()
            .then(async (layers) => {
                const promises: Promise<SeismicPlane2dLayer>[] = [];

                const list: SeismicPlane2dLayer[] = [];
                this._layers.set(dataset.id, list);

                for (const l of layers) {
                    list.push(l);

                    let initPromise: Promise<SeismicPlane2dLayer>;

                    if (!l.initialized) {
                        initPromise = l.init().then(() => this._instance.add(l.get3dElement()).then(() => l));
                    } else {
                        initPromise = this._instance.add(l.get3dElement()).then(() => l);
                    }

                    promises.push(initPromise);
                }

                await Promise.all(promises);

                return layers;
            })
            .then((layers) => {
                this._stateObserver.subscribe(layersSlice.getActiveSourceFile(layerState), (v) => this.updateBbox(v));
                this.updateBbox(this._stateObserver.select(layersSlice.getActiveSourceFile(layerState)));
                return layers;
            })
            .catch((error) => {
                console.error(error);
                return Promise.reject(new Error(`Something went wrong during loading of dataset ${dataset.name}`));
            });
    }

    updateBbox(sourceFile: SourceFileId) {
        const layer = this._layers
            .get(this._activeLayer)
            .find((l) => l.sourceFileId === sourceFile) as SeismicPlane2dLayer;

        const bbox = layer.getBoundingBox();
        const center = new THREE.Vector3();
        bbox.getCenter(center);

        this._controls.lookAt(
            new THREE.Vector3(center.x, -10, center.z),
            new THREE.Vector3(center.x, 0, center.z),
            false
        );

        // Snap the zoom to the top/bottom if the bbox is taller than it is wide
        // Accounts for the 'valid' area being 32px narrower and 128px shorter than the viewport
        const viewportWidth = this._instance.viewport.offsetWidth;
        const viewportHeight = this._instance.viewport.offsetHeight;
        const datasetWidth = bbox.max.x;
        const datasetHeight = -bbox.min.z;

        this._controls.cameraControls.zoomTo(
            100 /
                (datasetHeight * ((viewportWidth - 32) / (viewportHeight - 128)) > datasetWidth
                    ? (datasetHeight * (viewportWidth / viewportHeight)) / ((viewportHeight - 128) / viewportHeight)
                    : datasetWidth / ((viewportWidth - 32) / viewportWidth)),
            false
        );

        this._controls.cameraControls.setBoundary(bbox);
        if (!this._stateObserver.select(giro3d.getSeismicVolume)?.equals(bbox))
            this._dispatch(giro3d.setSeismicVolume(fromBox3(bbox)));
    }

    removeLayer(datasetOrId) {
        let id = datasetOrId;
        if (typeof datasetOrId === 'object') id = datasetOrId.id; // assume id

        if (id === this._activeLayer) this._activeLayer = null;

        super.removeLayer(datasetOrId);
    }

    updateCoordinates(point: PickedPoint = null) {
        if (point?.picked) {
            const layerState = this._stateObserver.select(layersSlice.get(this._activeLayer));
            if (!hasSeismicPlane(layerState)) throw new Error('Layer does not have seismic plane');

            const layer = this.getLayersForDataset<SeismicPlane2dLayer>(this._activeLayer).find(
                (l) => l.sourceFileId === layerState.activeFile
            );

            if (layer) {
                return super.updateCoordinates(layer.lookupCoordinate(point));
            }
        }
        return super.updateCoordinates();
    }
}

export default SeismicService;
