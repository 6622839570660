import { useMountEffect } from 'components/utils';
import { EventMap, useEventBus } from 'EventBus';
import { useState } from 'react';
import HoveredItem from 'types/HoveredItem';

const HoveredItemIndicator = () => {
    const eventBus = useEventBus();

    const [item, setItem] = useState<HoveredItem>(null);

    function onHoveredItemChanged(arg: EventMap['hovered-item']) {
        setItem(arg.item);
    }

    function subscribe() {
        eventBus.subscribe('hovered-item', onHoveredItemChanged);
    }

    function unsubscribe() {
        eventBus.unsubscribe('hovered-item', onHoveredItemChanged);
    }

    useMountEffect(subscribe, unsubscribe);

    if (!item) {
        return null;
    }

    return (
        <div className="map-statusbar-side">
            <i className="fal fa-arrow-pointer map-statusbar-marker" />
            <span>
                <b>{item.name}</b> ({item.itemType}) |
            </span>
        </div>
    );
};

export default HoveredItemIndicator;
