import { PropsWithChildren } from 'react';
import { TabPane } from 'reactstrap';

type LazyProps = {
    tabId: string;
    currentTab: string;
};

export default function LazyTabPane({ tabId, currentTab, children }: PropsWithChildren<LazyProps>) {
    return <TabPane tabId={tabId}>{currentTab === tabId ? children : null}</TabPane>;
}
