// React
import { useDispatch, useSelector } from 'react-redux';

import * as giro3d from 'redux/giro3d';

// Components
import ToggleSwitch from '../../ToggleSwitch';

// NOTE: The select tool for obtaining feature information on vector layers is not completely developed, and may be annoying during navigation
// as it interfereres with moving the map. Add it here as an optional feature so we can easily test it for now.
function ClickableVectorsSetting() {
    const dispatch = useDispatch();

    const isClickable = useSelector(giro3d.isClickableVectorFeatureEnabled);

    return (
        <div className="settings-row">
            <span>Clickable Vector Features</span>
            <ToggleSwitch
                id="clicable-vectors"
                checked={isClickable}
                onChange={(e) => dispatch(giro3d.setClickableVectorFeatures(e.target.checked))}
            />
        </div>
    );
}

export default ClickableVectorsSetting;
