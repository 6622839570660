import React from 'react';
import { useSelector } from 'react-redux';

import { getUser } from '../../redux/selectors';
import { Logout } from '../login/Login';
import ApiKey from './ApiKey';

const ProfileContent = () => {
    const user = useSelector(getUser);

    return user ? (
        <>
            <div className="map-pane-centred-fill centred-text">
                <div className="map-pane-jumbo-icon">
                    <i className="fal fa-user-circle" />
                </div>
                <div className="map-pane-title">
                    {user.given_name} {user.family_name}
                    <div className="map-pane-title-sub">{user.email}</div>
                    <div className="map-pane-title-sub">
                        {user.memberships.map((m) => m.organization.display_name).join(', ')}
                    </div>
                </div>
            </div>
            <div className="map-pane-centred-fill">
                {/* <Nav tabs vertical className="map-menu-open">
                    <NavItem title="Profile">
                        <Link to="/me" className="nav-link">
                            <span>Profile</span>
                        </Link>
                    </NavItem>
                    {user?.is_admin ? (
                        <NavItem title="Admin">
                            <Link to="/admin" className="nav-link">
                                <span>Admin</span>
                            </Link>
                        </NavItem>
                    ) : null}
                    <Logout />
                </Nav> */}
                <ApiKey />
                <Logout />
            </div>
        </>
    ) : (
        <span>Not logged in</span>
    );
};

export default ProfileContent;
