// React
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import { checkForUsernames, fetchNotifications } from '../../redux/actions';

// Redux
import { getNotifications } from '../../redux/selectors';
import Scrollbox from '../Scrollbox';

// Components
import NotificationListItem from './NotificationListItem';

const NotificationsMenu = (props) => {
    const dispatch = useDispatch();

    const notifications = useSelector(getNotifications) || [];

    useEffect(() => dispatch(fetchNotifications()), []);

    if (notifications) {
        dispatch(checkForUsernames([...new Set(notifications.map((notification) => notification.sender_id))]));
    }

    notifications.sort((a, b) => {
        if (a.read === b.read) return (b.reminded_at || b.timestamp) - (a.reminded_at || a.timestamp);
        if (a.read) return 1;
        return -1;
    });
    const renderedListItems = notifications
        ? notifications.map((item) => <NotificationListItem key={`notification-${item.id}`} notification={item} />)
        : [];
    const activeNotifications = notifications.filter((x) => !x.read).length;

    return renderedListItems.length === 0 ? (
        <>
            <div className="map-pane-centred-fill">
                <i className="modal-icon fal fa-bell" />
                <span className="map-pane-large-text">WOOHOO</span>
                <span>You have no notifications right now</span>
            </div>
            <div className="map-pane-centred-fill">
                <Button onClick={props.close}>Hide Notifications</Button>
            </div>
        </>
    ) : (
        <>
            <div className="map-pane-title-bar">
                <div className="map-pane-title">
                    Notifications <span className="faded-text">({activeNotifications})</span>
                </div>
            </div>
            <Scrollbox>{renderedListItems}</Scrollbox>
        </>
    );
};

export default NotificationsMenu;
