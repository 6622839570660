// React
import { useDispatch, useSelector } from 'react-redux';

import * as graphicsSettings from 'redux/graphicsSettings';

// Components
import SettingToggle from './SettingToggle';

function TransparencySetting() {
    const dispatch = useDispatch();

    const enabled = useSelector(graphicsSettings.isTransparencyOnInteractionEnabled);

    return (
        <SettingToggle
            title="Transparency on Interaction"
            checked={enabled}
            onChange={(v) => dispatch(graphicsSettings.enableTransparencyOnInteraction(v))}
        />
    );
}

export default TransparencySetting;
