import { useState } from 'react';

import { ColoringMode, HasAttributes, HasColoringMode, HasOverlayColor, LayerState } from 'types/LayerState';
import * as datasetsSlice from 'redux/datasets';
import * as layers from 'redux/layers';
import { useSelector } from 'react-redux';
import { COLORMAP_BOUNDSMODE, getLUT } from 'types/ColorMap';
import { useAppSelector } from 'store';
import ColormapScaleBar from '../datasetsMenu/datasetSettings/ColormapScaleBar';
import DatasetSettingsPopover from '../datasetsMenu/DatasetSettingsPopover';

export type Props = {
    layer: LayerState & HasAttributes & HasColoringMode & HasOverlayColor;
};

const PinnedLegendItem = (props: Props) => {
    const { layer } = props;
    const dataset = useAppSelector(datasetsSlice.get(layer.datasetId));
    const coloringMode = useSelector(layers.getCurrentColoringMode(layer));
    const overlayColor = useSelector(layers.getOverlayColor(layer));
    const attribute = useSelector(layers.getActiveAttribute(layer));
    const colorMap = useSelector(layers.getColorMap(layer, attribute.id));
    const [settingsOpen, setSettingsOpen] = useState(false);
    const unit = attribute.unit;
    const lut = getLUT(colorMap, { samples: 100 });

    const roundDataBound = (bound: number) => {
        if (bound === 0) return 0;
        const power = Math.log10(Math.abs(bound));
        if (power < 0) {
            return bound.toFixed(Math.abs(power) + 2);
        }
        return bound.toFixed(2);
    };

    return (
        <div className="listed-scale" key={`scale-${dataset.id}`}>
            <div>{dataset.name}</div>
            {coloringMode === ColoringMode.Colormap ? (
                <>
                    <div className="capitalize-first">{attribute.name}</div>
                    <div className="legend-limits">
                        <span>
                            {`${
                                colorMap.boundsMode === COLORMAP_BOUNDSMODE.DATA
                                    ? roundDataBound(attribute.min)
                                    : roundDataBound(colorMap.customMin)
                            }${unit}`}
                        </span>
                        <span>
                            {`${
                                colorMap.boundsMode === COLORMAP_BOUNDSMODE.DATA
                                    ? roundDataBound(attribute.max)
                                    : roundDataBound(colorMap.customMax)
                            }${unit}`}
                        </span>
                    </div>
                </>
            ) : null}
            <button
                type="button"
                title="Colormap settings"
                id={`scale-settings-${dataset.id}`}
                className="horizontal-slider"
                onClick={() => setSettingsOpen(true)}
            >
                {coloringMode === ColoringMode.Colormap ? (
                    <ColormapScaleBar id={dataset.id} attribute={attribute} lut={lut} colorMap={colorMap} />
                ) : (
                    <div className="scale-bar" style={{ backgroundColor: `#${overlayColor.getHexString()}` }} />
                )}
            </button>
            <DatasetSettingsPopover
                dataset={dataset}
                target={`scale-settings-${dataset.id}`}
                isOpen={settingsOpen}
                onToggle={() => setSettingsOpen(false)}
            />
        </div>
    );
};

export default PinnedLegendItem;
