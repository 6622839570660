// React
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button } from 'reactstrap';
import { OVERVIEW_MAP_LOADED } from '../../../redux/actionTypes';

// Redux
import { getSelectedProject } from '../../../redux/selectors';
import DosApi from '../../../services/DosApi';
import { ItemPopupoverBase, popoverSection } from '../ItemPopoverBase';
import { getApiUrl } from '../../../config';

const apiUrl = getApiUrl();

const ProjectPopup = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { at, project } = useSelector(getSelectedProject) ?? {};

    const openFile = (path) => DosApi.openDocument(`${apiUrl}${path}`);

    const toProjectView = () => {
        const slug = project.name
            .toString()
            .normalize('NFD') // break accented characters into components
            .replace(/[\u0300-\u036f]/g, '') // remove diacritics
            .toLowerCase()
            .replace(/\s+/g, '-') // spaces to dashes
            .replace(/&/g, '-and-') // ampersand to and
            .replace(/[^\w-]+/g, '') // remove non-words
            .replace(/--+/g, '-') // collapse multiple dashes
            .replace(/^-+/, '') // trim starting dash
            .replace(/-+$/, ''); // trim ending dash;
        navigate(`/project/${project.id}/${slug}`);
        dispatch({ type: OVERVIEW_MAP_LOADED, payload: undefined });
    };

    const popoverBody = () =>
        project === undefined ? null : (
            <>
                {popoverSection('Quick facts', project.description)}
                {/* {popoverSection('Products', '...')} */}
                {project.links.attachments
                    ? popoverSection(
                          'Media & Documents',
                          project.links.attachments.map((path) => {
                              const filename = path.split('/').at(-1);
                              return <Button onClick={() => openFile(path)} key={path}>{`Open ${filename}`}</Button>;
                          })
                      )
                    : null}
                {project.links.flyer
                    ? popoverSection('Flyer', <Button onClick={() => openFile(project.links.flyer)}>Open flyer</Button>)
                    : null}
                <Button className="full-width" onClick={toProjectView}>
                    Dive in!
                </Button>
            </>
        );

    return <ItemPopupoverBase overlayId="projectPopup" at={at} item={project} popoverBody={popoverBody} />;
};

export default ProjectPopup;
