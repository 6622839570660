import { useDispatch } from 'react-redux';
import { HasAttributes, LayerState } from 'types/LayerState';

import * as layers from 'redux/layers';
import { useAppSelector } from 'store';

export type Props = {
    layer: LayerState & HasAttributes;
};

function AttributeSelector(props: Props) {
    const { layer } = props;

    const dispatch = useDispatch();
    const allAttributes = useAppSelector(layers.getAttributes(layer));

    const activeAttribute = useAppSelector(layers.getActiveAttribute(layer));
    function changeActiveAttribute(value: string) {
        dispatch(layers.setActiveAttribute({ layer, value }));
    }

    if (allAttributes.length === 0 || activeAttribute == null) {
        return null;
    }

    const id = layer.datasetId;

    return (
        <div className="row" key={`dataset-${id}-attributes`}>
            <label htmlFor={`dataset-${id}-attributes-prop`} className="col-sm-5 col-form-label">
                <i className="fal fa-table" /> Property
            </label>
            <div className="col-sm-7">
                <div className="select-group">
                    <select
                        id={`dataset-${id}-attributes-prop`}
                        className="form-select"
                        value={activeAttribute.id}
                        onChange={(e) => changeActiveAttribute(e.target.value)}
                    >
                        {allAttributes.map((att) => (
                            <option key={`dataset-prop-${att.id}`} value={att.id}>
                                {att.name}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
        </div>
    );
}

export default AttributeSelector;
