// React
import React from 'react';
import { useSelector } from 'react-redux';

// Redux
import { getSelectedCollection } from '../../../redux/selectors';
import { ItemPopupoverBase } from '../ItemPopoverBase';

const CollectionPopup = () => {
    const { at, collection } = useSelector(getSelectedCollection) ?? {};

    return <ItemPopupoverBase overlayId="collectionPopup" at={at} item={collection} popoverBody={() => null} />;
};

export default CollectionPopup;
