import { LayerState, HasAmplitude, ColoringMode, HasColoringMode, HasAttributes } from 'types/LayerState';

// Components
import { useDispatch } from 'react-redux';
import * as layers from 'redux/layers';
import { GEOJSON_AMPLITUDE_MODE } from 'services/Constants';
import { useAppSelector } from 'store';
import SettingToggleSwitch from './SettingToggleSwitch';

type LayerType = LayerState & HasAmplitude & HasColoringMode & HasAttributes;

const AmplitudeRange = (props: { layer: LayerType; attributeId: string }) => {
    const { layer, attributeId } = props;
    const id = layer.datasetId;
    const dispatch = useDispatch();

    const spatialAmplitudeRange = useAppSelector(layers.getAmplitudeRange(layer));
    const changeSpatialAmplitudeRange = (value: number) => {
        dispatch(layers.setAmplitudeRange({ layer, value, attributeId }));
    };

    return (
        <div className="row" key={`datasetsettings-${id}-spatialamp`}>
            <label htmlFor="trackline_spatialAmplitudeRange" className="col-sm-6 col-form-label">
                <i className="fa fa-waveform-lines fa-fw" /> Spatial Amplitude
            </label>
            <div className="col-sm-6">
                <div className="input-group">
                    <input
                        id="trackline_spatialAmplitudeRange"
                        type="number"
                        min="0"
                        max="200"
                        step="1"
                        value={spatialAmplitudeRange}
                        autoComplete="off"
                        className="form-control"
                        onChange={(e) => changeSpatialAmplitudeRange(e.target.valueAsNumber)}
                    />
                </div>
            </div>
        </div>
    );
};

const AmplitudeMode = (props: { layer: LayerType; attributeId: string }) => {
    const { layer, attributeId } = props;
    const id = layer.datasetId;
    const dispatch = useDispatch();

    const amplitudeMode = useAppSelector(layers.getAmplitudeMode(layer));
    const changeAmplitudeMode = (value: GEOJSON_AMPLITUDE_MODE) => {
        dispatch(layers.setAmplitudeMode({ layer, value, attributeId }));
    };

    return (
        <div className="row" key={`datasetsettings-${id}-ampmode`}>
            <label htmlFor="trackline_amplitudeMode" className="col-sm-6 col-form-label">
                <i className="fal fa-xmark fa-fw" /> Amplitude mode
            </label>
            <div className="col-sm-6">
                <div className="select-group">
                    <select
                        id="trackline_amplitudeMode"
                        className="form-select"
                        value={amplitudeMode}
                        onChange={(e) => changeAmplitudeMode(e.target.value as GEOJSON_AMPLITUDE_MODE)}
                    >
                        <option key="trackline-ampmode-mean" value={GEOJSON_AMPLITUDE_MODE.FROM_MEAN_DEVIATION}>
                            {GEOJSON_AMPLITUDE_MODE.FROM_MEAN_DEVIATION}
                        </option>
                        <option key="trackline-ampmode-max" value={GEOJSON_AMPLITUDE_MODE.FROM_MAX_DEVIATION}>
                            {GEOJSON_AMPLITUDE_MODE.FROM_MAX_DEVIATION}
                        </option>
                        <option key="trackline-ampmode-min" value={GEOJSON_AMPLITUDE_MODE.FROM_MIN_DEVIATION}>
                            {GEOJSON_AMPLITUDE_MODE.FROM_MIN_DEVIATION}
                        </option>
                    </select>
                </div>
            </div>
        </div>
    );
};

export type Props = {
    layer: LayerType;
};

export const AmplitudeSettings = (props: Props) => {
    const { layer } = props;
    const id = layer.datasetId;
    const mode = useAppSelector(layers.getCurrentColoringMode(layer));
    const activeAttribute = useAppSelector(layers.getActiveAttribute(layer));
    const dispatch = useDispatch();

    if (!activeAttribute) {
        return null;
    }

    const showAmplitude = useAppSelector(layers.getShowAmplitude(layer));
    const changeIsShowingAmplitude = (value: boolean) => {
        dispatch(layers.setShowAmplitude({ layer, value, attributeId: activeAttribute.id }));
    };

    if (mode !== ColoringMode.Colormap) {
        return null;
    }

    return (
        <>
            <SettingToggleSwitch
                id={`toggle-amplitude-${id}`}
                checked={showAmplitude}
                title="Show amplitude"
                icon="fa-waveform-lines"
                onChange={(e) => changeIsShowingAmplitude(e.target.checked)}
            />
            {showAmplitude ? <AmplitudeMode layer={layer} attributeId={activeAttribute.id} /> : null}
            {showAmplitude ? <AmplitudeRange layer={layer} attributeId={activeAttribute.id} /> : null}
        </>
    );
};

export default AmplitudeSettings;
