import { useFormik } from 'formik';
import { useState } from 'react';
import { Button, ModalBody, ModalFooter, ModalHeader, UncontrolledTooltip } from 'reactstrap';

import Annotation, { AnnotationFilter, isFiltered } from 'types/Annotation';
import { SsdmType, UUID } from 'types/common';
import { User } from 'types/User';
import { useAppDispatch, useAppSelector } from 'store';
import * as annotationSlice from 'redux/annotations';
import Dataset from 'types/Dataset';
import { getSSDMTypes, getUsernames } from '../../../redux/selectors';
import { DEFAULT_ANNOTATION_FILTER } from '../../../services/Constants';

import GroupedMultiSelect from '../../forms/GroupedMultiSelect';
import Multiselect from '../../forms/Multiselect';

export type Props = {
    close: () => void;
    authors: string[];
    datasets: Dataset[];
};

const AnnotationFilters = (props: Props) => {
    const dispatch = useAppDispatch();

    const [ssdmKey, setSsdmKey] = useState(Date.now());

    const ssdmTypes = useAppSelector(getSSDMTypes);
    const usernames = useAppSelector<User[]>(getUsernames);
    const filters = useAppSelector<AnnotationFilter>(annotationSlice.filter);
    const annotations = useAppSelector<Annotation[]>(annotationSlice.list);

    const generateOption = (id: UUID) => ({
        label: usernames.find((x) => x.id === id)
            ? `${usernames.find((x) => x.id === id).given_name} ${usernames.find((x) => x.id === id).family_name}`
            : 'Unknown',
        value: id,
    });

    const formik = useFormik<AnnotationFilter>({
        initialValues: filters,
        validateOnChange: false,
        validateOnBlur: false,
        onSubmit: (values) => {
            if (!values.object) values.ssdmTypes = [];
            dispatch(annotationSlice.setAnnotationFilter(values));
            props.close();
        },
    });

    const reset = () => {
        formik.setValues(DEFAULT_ANNOTATION_FILTER);
        setSsdmKey(Date.now());
    };

    const getSSDMGroupName = (ssdmGroup: string) => {
        const words = ssdmGroup
            .substring(ssdmGroup.indexOf('_') + 1)
            .toLowerCase()
            .split('_');

        for (let i = 0; i < words.length; i++) {
            words[i] = words[i][0].toUpperCase() + words[i].substring(1);
        }

        return words.join(' ');
    };

    const liveFilteredAnnotations = annotations.filter((annotation) => !isFiltered(formik.values, annotation));

    const ssdmGrouped = ssdmTypes.reduce((array: SsdmType[], x: SsdmType) => {
        if (!array[x.parent_type]) {
            array[x.parent_type] = [];
        }
        array[x.parent_type].push({ label: `${x.name} ${x.display_name}`, value: x.name });
        return array;
    }, {});
    const ssdmOptions = [];
    Object.keys(ssdmGrouped).forEach((x) => ssdmOptions.push({ label: getSSDMGroupName(x), options: ssdmGrouped[x] }));

    return (
        <form className="filter-form" onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
            <ModalHeader toggle={() => props.close()}>
                Filter options <span className="faded-text green">({liveFilteredAnnotations.length})</span>
            </ModalHeader>
            <ModalBody className="annotation-filters">
                <div>
                    <div className="styled-checkbox">
                        <input
                            type="checkbox"
                            id="observation"
                            value="observation"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            checked={formik.values.observation}
                        />
                        <label htmlFor="observation">Observation</label>
                        <i className="fal fa-circle-question auto-center-v" id="obsHelp" />
                        <UncontrolledTooltip placement="right" target="obsHelp">
                            <span className="submenu-tooltip">
                                An observation is used to annotate parts of the data that are unidentified or erroneous.
                            </span>
                        </UncontrolledTooltip>
                    </div>
                    <div className="styled-checkbox">
                        <input
                            type="checkbox"
                            id="object"
                            value="object"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            checked={formik.values.object}
                        />
                        <label htmlFor="object">Object</label>
                        <i className="fal fa-circle-question auto-center-v" id="objHelp" />
                        <UncontrolledTooltip placement="right" target="objHelp">
                            <span className="submenu-tooltip">
                                An object is something that can be classified by the SSDM standard.
                            </span>
                        </UncontrolledTooltip>
                    </div>
                    <hr />
                    <table className="geometry-select">
                        <tbody>
                            <tr>
                                <td>
                                    <Button
                                        className={formik.values.point ? 'selected' : ''}
                                        onClick={() => formik.setFieldValue('point', !formik.values.point)}
                                    >
                                        <i className="fal fa-period" />
                                        POINT
                                    </Button>
                                </td>
                                <td>
                                    <Button
                                        className={formik.values.line ? 'selected' : ''}
                                        onClick={() => formik.setFieldValue('line', !formik.values.line)}
                                    >
                                        <i className="fal fa-dash" />
                                        LINE
                                    </Button>
                                </td>
                                <td>
                                    <Button
                                        className={formik.values.polygon ? 'selected' : ''}
                                        onClick={() => formik.setFieldValue('polygon', !formik.values.polygon)}
                                    >
                                        <i className="fal fa-draw-polygon" />
                                        POLYGON
                                    </Button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <hr />
                    {formik.values.object ? (
                        <GroupedMultiSelect
                            name="ssdmTypes"
                            id="ssdmTypes"
                            key={ssdmKey}
                            options={ssdmOptions}
                            onBlur={formik.handleBlur}
                            onChange={(choice) => formik.setFieldValue('ssdmTypes', choice)}
                            placeholder="Select SSDM types"
                            multiValueCounterMessage="Selected SSDM type"
                            isClearable={false}
                            value={formik.values.ssdmTypes}
                        />
                    ) : null}
                </div>
                <div>
                    <Multiselect
                        name="datasets"
                        options={props.datasets.map((x) => ({ 'label': x.name, 'value': x.id }))}
                        onBlur={formik.handleBlur}
                        onChange={(choice) => formik.setFieldValue('datasets', choice)}
                        placeholder="Select dataset"
                        multiValueCounterMessage="Selected dataset"
                        isClearable={false}
                        value={formik.values.datasets}
                    />
                    <div className="date-range">
                        Date
                        <input
                            type="date"
                            value={formik.values.startDate}
                            max={formik.values.endDate ? formik.values.endDate : new Date().toISOString().split('T')[0]}
                            onChange={(choice) => formik.setFieldValue('startDate', choice.target.value)}
                        />
                        <input
                            type="date"
                            value={formik.values.endDate}
                            min={formik.values.startDate}
                            max={new Date().toISOString().split('T')[0]}
                            onChange={(choice) => formik.setFieldValue('endDate', choice.target.value)}
                        />
                    </div>
                    <Multiselect
                        name="authors"
                        options={props.authors.map((id) => generateOption(id))}
                        onBlur={formik.handleBlur}
                        onChange={(choice) => formik.setFieldValue('authors', choice)}
                        placeholder="Select author"
                        multiValueCounterMessage="Selected author"
                        isClearable={false}
                        value={formik.values.authors}
                    />
                    <div className="styled-checkbox">
                        <input
                            type="checkbox"
                            id="unresolvedComments"
                            value="unresolvedComments"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            checked={formik.values.unresolvedComments}
                        />
                        <label htmlFor="unresolvedComments">Has unresolved comments</label>
                    </div>
                    <hr />
                    <div className="state-filters">
                        <div className="styled-checkbox">
                            <input
                                type="checkbox"
                                name="status"
                                id="in_progress"
                                value="in_progress"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                checked={formik.values.status.includes('in_progress')}
                            />
                            <label htmlFor="in_progress">In progress</label>
                        </div>
                        <div className="styled-checkbox">
                            <input
                                type="checkbox"
                                name="status"
                                id="complete"
                                value="complete"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                checked={formik.values.status.includes('complete')}
                            />
                            <label htmlFor="complete">Complete</label>
                        </div>
                        <div className="styled-checkbox">
                            <input
                                type="checkbox"
                                name="status"
                                id="archived"
                                value="archived"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                checked={formik.values.status.includes('archived')}
                            />
                            <label htmlFor="archived">Archived</label>
                        </div>
                    </div>
                    <hr />
                </div>
            </ModalBody>
            <ModalFooter>
                <Button type="button" color="warning" onClick={reset}>
                    RESET
                </Button>
                <Button type="submit" color="primary">
                    APPLY
                </Button>
            </ModalFooter>
        </form>
    );
};

export default AnnotationFilters;
