import { createSelector, PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { SerializableVector2, SourceFileId } from 'types/common';

type Profile = {
    points: SerializableVector2[];
    elevationsMap: Map<SourceFileId, number[]>;
};

export type State = {
    points: SerializableVector2[];
    elevationMap: Map<SourceFileId, number[]>;
};

const initialState: State = {
    points: null,
    elevationMap: null,
};

const self = (store: RootState) => store.elevationProfile;

// Selectors
export const getProfile = createSelector(self, (s) => {
    return {
        'points': s.points,
        'elevationMap': s.elevationMap,
    };
});

const slice = createSlice({
    name: 'elevationProfile',
    initialState,
    reducers: {
        clear: (state) => {
            state.points = initialState.points;
        },
        setProfile: (state, action: PayloadAction<Profile>) => {
            state.points = action.payload.points;
            state.elevationMap = action.payload.elevationsMap;
        },
    },
});

export const reducer = slice.reducer;

// Actions
export const clear = slice.actions.clear;
export const setProfile = slice.actions.setProfile;
