import * as THREE from 'three';
import * as layersSlice from 'redux/layers';
import { PickedPoint } from 'services/BaseGiro3dService';
import SeismicPlaneLayer, { ConstructorParams, TLayerState } from './SeismicPlaneLayer';
import Vector3Array from '../../Vector3Array';
import LayerStateObserver from '../LayerStateObserver';

class SeismicPlane2dLayer extends SeismicPlaneLayer {
    private coordinateMap: { cumulativeDistance: number; coordinates: THREE.Vector3 }[];

    constructor(params: ConstructorParams) {
        super({ orthographic: true, ...params, buildLine: false });
    }

    protected override subscribeToStateChanges(observer: LayerStateObserver<TLayerState>): void {
        super.subscribeToStateChanges(observer);

        observer.subscribe(layersSlice.getActiveSourceFile(this._layerState), (v) =>
            this.set2DVisibility(v === this.sourceFileId)
        );
    }

    async initOnce() {
        await super.initOnce();

        this.object3d.position.copy(new THREE.Vector3(0, 0, 0));

        this._isLoadingSeismicPlane = true;
        await this._loadSeismicPlane();
        this._seismicPlaneVisible = true;
        this._isLoadingSeismicPlane = false;

        this.initialized = true;
    }

    /**
     * @param rawCoordinates The raw coordinates.
     */
    protected processCoordinates(rawCoordinates: THREE.Vector3[]) {
        const result = new Vector3Array({ length: rawCoordinates.length });
        let cumulativeDistance = 0;
        this.origin = new THREE.Vector3(0, 0, 0);
        /** @type {THREE.Vector3} */
        let previousV: THREE.Vector3;

        const coordMap: Array<{ cumulativeDistance: number; coordinates: THREE.Vector3 }> = [];

        for (let i = 0; i < rawCoordinates.length; i++) {
            const v = rawCoordinates[i];

            // Compute the total length of the line
            if (previousV) {
                cumulativeDistance += previousV.distanceTo(v);
            }
            coordMap.push({ cumulativeDistance, coordinates: v });
            result.set(i, cumulativeDistance, 0, 0);
            previousV = v;
        }

        this._totalLength = cumulativeDistance;
        this._adjustedCoordinates = result;
        this.coordinateMap = coordMap;
    }

    // eslint-disable-next-line class-methods-use-this
    clickHandler() {
        return Promise.resolve();
    }

    setOffset(offset: number) {
        this.settings.offset = offset;
    }

    lookupCoordinate(point: PickedPoint): PickedPoint {
        let leftIndex = 0;
        let rightIndex = this.coordinateMap.length - 1;

        while (leftIndex <= rightIndex) {
            const mid = Math.floor((leftIndex + rightIndex) / 2);
            const midValue = this.coordinateMap[mid].cumulativeDistance;

            if (midValue === point.point.x) {
                // If the target value is found, return it as both closestBelow and closestAbove
                leftIndex = mid;
                rightIndex = mid;
                break;
            } else if (midValue < point.point.x) {
                // If the current element is less than the target value, update closestBelow and move the left pointer to the right
                leftIndex = mid + 1;
            } else {
                // If the current element is greater than the target value, update closestAbove and move the right pointer to the left
                rightIndex = mid - 1;
            }
        }

        const point1 = this.coordinateMap[leftIndex].coordinates;
        const point2 = this.coordinateMap[rightIndex].coordinates;
        const point1Value = this.coordinateMap[leftIndex].cumulativeDistance;
        const point2Value = this.coordinateMap[rightIndex].cumulativeDistance;

        const proportionFactor = (point.point.x - point1Value) / (point1Value - point2Value);

        const interpolatedX = point1.x + proportionFactor * (point1.x - point2.x);
        const interpolatedY = point1.y + proportionFactor * (point1.y - point2.y);
        const interpolatedZ = point1.z + proportionFactor * (point1.z - point2.z);

        point.point = new THREE.Vector3(interpolatedX, interpolatedY, interpolatedZ).add(
            new THREE.Vector3(0, 0, point.point.z + this.settings.offset)
        );
        return point;
    }

    getBoundingBox() {
        if (!this.initialized) return null;

        const bbox = new THREE.Box3().setFromPoints(this._adjustedCoordinates.toArray());
        bbox.max.z = 0;
        bbox.min.z = -this.computeDepth(this.settings.speedmoduleMs);
        return bbox;
    }

    setSpeedModuleMs(speedmoduleMs: number) {
        super.setSpeedModuleMs(speedmoduleMs);
        if (this.get3dElement()) this.dispatchEvent({ type: 'bbox_change', payload: this.getBoundingBox() });
    }

    // Always be visible
    async setThisVisibility() {
        super.setThisVisibility(true);
    }

    // Ignore normal visibility toggle
    // eslint-disable-next-line class-methods-use-this, @typescript-eslint/no-empty-function
    async setSourceFileVisibility() {}

    async set2DVisibility(visible: boolean) {
        if (this.shouldInitialize()) {
            await this.init();
        }
        this.showSeismicPlane(visible);
    }
}

export default SeismicPlane2dLayer;
