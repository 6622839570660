import { Button, Popover, PopoverBody, PopoverHeader } from 'reactstrap';
import * as datasetsSlice from 'redux/datasets';
import { LAYER_STATES } from 'services/Constants';
import { useAppSelector } from 'store';
import Dataset from 'types/Dataset';
import { useState } from 'react';
import { useMountEffect } from 'components/utils';
import { useEventBus } from 'EventBus';
import { DatasetId } from 'types/common';
import DatasetListItemStatus from './DatasetListItemStatus';

export type Props = {
    id: string;
    dataset: Dataset;
};

const DatasetIndicator = (props: Props) => {
    const { id, dataset } = props;
    const eventBus = useEventBus();

    const [statusPopover, setStatusPopover] = useState(false);
    const [loading, setLoading] = useState(false);

    const state = useAppSelector(datasetsSlice.getDatasetState(dataset.id));

    const onStatusUpdated = (arg: { id: DatasetId; loading: boolean }) => {
        if (dataset.id === arg.id) {
            setLoading(arg.loading);
        }
    };

    const mount = () => {
        eventBus.subscribe('dataset-loading-status-updated', onStatusUpdated);
    };

    const unmount = () => {
        eventBus.unsubscribe('dataset-loading-status-updated', onStatusUpdated);
    };

    useMountEffect(mount, unmount);

    let icon: string;
    switch (state) {
        case LAYER_STATES.EMPTY:
            icon = 'far fa-file';
            break;
        case LAYER_STATES.UPLOADING:
            icon = 'fal fa-file-upload';
            break;
        case LAYER_STATES.CONVERTING:
            icon = 'fal fa-cog fa-spin';
            break;
        case LAYER_STATES.CONVERSION_FAILED:
            icon = 'fal fa-exclamation-triangle';
            break;
        case LAYER_STATES.DELETING:
            icon = 'fal fa-trash';
            break;
        case LAYER_STATES.DELETION_FAILED:
            icon = 'fal fa-exclamation-triangle';
            break;
        case LAYER_STATES.LOADED:
        case LAYER_STATES.ACTIVE:
            icon = loading ? 'fal fa-spinner fa-pulse' : '';
            break;
        case LAYER_STATES.LOADING:
            icon = 'fal fa-spinner fa-pulse';
            break;
        case LAYER_STATES.LOADING_FAILED:
            icon = 'fal fa-exclamation-triangle';
            break;
        default:
            icon = 'fal fa-exclamation-triangle';
            break;
    }

    if (!icon) {
        return null;
    }

    return (
        <>
            <Button
                className="borderless indicator"
                onClick={() => setStatusPopover(true)}
                disabled={state === LAYER_STATES.LOADED}
                title={`${dataset.name}-state`}
            >
                <i className={icon} id={`dataset-indicator-${id}`} />
            </Button>
            <Popover
                placement="left"
                isOpen={statusPopover}
                target={`dataset-indicator-${id}`}
                toggle={() => setStatusPopover(false)}
                trigger="legacy"
                fade={false}
            >
                <PopoverHeader>{`Status of dataset ${dataset.name}`}</PopoverHeader>
                <PopoverBody>
                    <DatasetListItemStatus dataset={dataset} />
                </PopoverBody>
            </Popover>
        </>
    );
};

export default DatasetIndicator;
