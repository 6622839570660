import React, { useState, useEffect } from 'react';
import { Button, Popover, PopoverBody, PopoverHeader } from 'reactstrap';

import ProjectListItemStatus from './ProjectListItemStatus';
import overviewMapService from '../../../services/OverviewMapService';
import { PROJECT_STATES } from '../../../services/Constants';
import ToggleSwitch from '../../ToggleSwitch';

const ProjectListItem = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);

    const { project, groupVisible, groupOpen } = props;

    const [visible, setVisible] = useState(true);

    useEffect(() => {
        if (project.olstate === PROJECT_STATES.LOADED && project.olFeature)
            overviewMapService.setItemVisible(project, groupVisible && visible);
    }, [groupVisible, visible, project.olFeature]);

    let icon;
    switch (project.olstate) {
        case PROJECT_STATES.LOADED:
            icon = '';
            break;
        case PROJECT_STATES.LOADING:
            icon = 'fal fa-spinner fa-pulse';
            break;
        case PROJECT_STATES.LOADING_FAILED:
            icon = 'fal fa-exclamation-triangle';
            break;
        default:
            icon = 'fal fa-exclamation-triangle';
            break;
    }
    if (!project.olFeature) icon = 'fal fa-location-pin-slash';

    const goToProject = () => {
        if (project.olFeature !== null) overviewMapService.goToItem(project);
        else overviewMapService.unselectItem(null, null);
    };

    return (
        <div
            className="data-item"
            key={`project-overview-row-${project.id}`}
            hidden={!groupOpen || (!project.olFeature && project.olstate === PROJECT_STATES.LOADED)}
        >
            <Button
                type="button"
                className="borderless indicator"
                onClick={toggle}
                disabled={project.olstate === PROJECT_STATES.LOADED}
                title={`${project.name}-state`}
            >
                <i className={icon} id={`project-indicator-${project.id}`} />
            </Button>
            <Button
                className="borderless white full-width left-text"
                disabled={!visible}
                title={
                    project.olstate === PROJECT_STATES.LOADED
                        ? `Go to ${project.name}`
                        : `Error loading ${project.name}`
                }
                id={`projectbtn-${project.id}`}
                onClick={project.olstate === PROJECT_STATES.LOADED ? goToProject : toggle}
            >
                {project.name}
            </Button>
            <ToggleSwitch
                id={`${project.id}-visible`}
                checked={visible}
                onChange={(e) => setVisible(e.target.checked)}
                disabled={!groupVisible || project.olstate !== PROJECT_STATES.LOADED || !project.olFeature}
            />

            {project.olstate !== PROJECT_STATES.LOADED || !project.olFeature ? (
                <Popover
                    placement="left"
                    isOpen={isOpen}
                    target={`project-indicator-${project.id}`}
                    toggle={toggle}
                    trigger="legacy"
                >
                    <PopoverHeader>{`Status of project ${project.name}`}</PopoverHeader>
                    <PopoverBody>
                        <ProjectListItemStatus project={project} />
                    </PopoverBody>
                </Popover>
            ) : null}
        </div>
    );
};

export default ProjectListItem;
