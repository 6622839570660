import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, UncontrolledTooltip } from 'reactstrap';
import { fetchOrganizations } from '../../redux/actions';
import { getOrganizations } from '../../redux/selectors';
import InlineDropMenu from '../InlineDropMenu';

export const PROJECT_ACTION = {
    CREATE: 'create',
    EDIT: 'edit',
    DELETE: 'delete',
    DATASETS: 'datasets',
    OPEN: 'open',
};

const ProjectListItem = (props) => {
    const { project, setAction, setCurrentProject } = props;

    const dispatch = useDispatch();

    const organizations = useSelector(getOrganizations);
    useEffect(() => dispatch(fetchOrganizations()), []);

    return (
        <li className="pane-list-item" key={`project-menu-row-${project.id}`}>
            {!project.geometry ? (
                <>
                    <i className="fal fa-circle-info text-yellow" id="info" />
                    <UncontrolledTooltip placement="right" target="info">
                        <span className="submenu-tooltip">
                            This project has no location information so cannot be dispalyed in the overview map.
                            <br />
                            Add a dataset to fix this.
                        </span>
                    </UncontrolledTooltip>
                </>
            ) : null}
            <Button
                className="borderless title-button"
                onClick={() => {
                    setCurrentProject(project);
                    setAction(PROJECT_ACTION.OPEN);
                }}
            >
                <div className="pane-list-item-title">{project.name}</div>
                <div className="pane-list-item-subtitle">
                    {organizations
                        ? organizations.find((e) => e.id === project.organization_id)?.display_name
                        : 'Loading...'}
                </div>
            </Button>
            <InlineDropMenu>
                <Button
                    className="borderless green"
                    title="Edit Project"
                    onClick={() => {
                        setCurrentProject(project);
                        setAction(PROJECT_ACTION.EDIT);
                    }}
                >
                    <i className="fal fa-pen" />
                </Button>
                <Button
                    className="borderless red"
                    title="Delete Project"
                    onClick={() => {
                        setCurrentProject(project);
                        setAction(PROJECT_ACTION.DELETE);
                    }}
                >
                    <i className="fal fa-trash-can" />
                </Button>
            </InlineDropMenu>
        </li>
    );
};

export default ProjectListItem;
