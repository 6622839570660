import { ColoringMode, HasBorehole, HasColoringMode, LayerState } from 'types/LayerState';

import * as layers from 'redux/layers';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'store';
import SettingToggleSwitch from './SettingToggleSwitch';

export type Props = {
    layer: LayerState & HasBorehole & HasColoringMode;
};

export const BoreholeSettings = (props: Props) => {
    const { layer } = props;
    const id = layer.datasetId;
    const dispatch = useDispatch();

    const variableRadii = useAppSelector(layers.getHasVariableRadii(layer));

    const coloringMode = useAppSelector(layers.getCurrentColoringMode(layer));

    function setVariableRadii(value: boolean) {
        dispatch(layers.setVariableRadii({ layer, value }));
    }

    if (coloringMode !== ColoringMode.Colormap) {
        return null;
    }

    return (
        <SettingToggleSwitch
            id={`toggle-variable-radii-${id}`}
            checked={variableRadii}
            title="Variable radii"
            icon="fa-waveform"
            onChange={(e) => setVariableRadii(e.target.checked)}
        />
    );
};

export default BoreholeSettings;
