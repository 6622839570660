import { CartesianGrid, Dot, ResponsiveContainer, Scatter, ScatterChart, Tooltip, XAxis, YAxis, ZAxis } from 'recharts';
import { useAppSelector } from 'store';
import { SourceFileId } from 'types/common';
import { getDatasetColorHexString } from 'services/DatasetColors';
import ElevationTooltip from './ElevationTooltip';
import giro3dService from '../../../services/Giro3dService';
import * as elevationProfileSlice from '../../../redux/elevationProfile';
import * as datasetsSlice from '../../../redux/datasets';

const ElevationProfile = () => {
    const datasets = useAppSelector(datasetsSlice.getProjectDatasets);
    const sourceFiles = useAppSelector(datasetsSlice.getProjectSourceFiles);

    const { points, elevationMap: elevationMaps } = useAppSelector(elevationProfileSlice.getProfile);

    if (!points) return null;

    const findDatasetIdName = (sourceFileId: SourceFileId) => {
        for (const [datasetId, files] of Object.entries(sourceFiles)) {
            if (files.some((file) => file.id === sourceFileId)) {
                return { datasetId, name: datasets.find((d) => d.id === datasetId).name };
            }
        }
        return { datasetId: '', name: '' };
    };

    const generateChartValues = () => {
        const sampleCount = points.length;

        const xStep = points[1][0] - points[0][0];
        const yStep = points[1][1] - points[0][1];
        const dStep = Math.sqrt(xStep ** 2 + yStep ** 2);

        const data = [];
        const ids = Array.from(elevationMaps.keys());

        ids.forEach((id) => {
            const { datasetId, name } = findDatasetIdName(id);
            const line = [];
            for (let i = 0; i < sampleCount; i++) {
                line.push({
                    x: points[i][0],
                    y: points[i][1],
                    z: elevationMaps.get(id)[i],
                    d: dStep * i,
                    datasetName: name,
                });
            }
            data.push({ name, id, visible: true, data: line, color: `#${getDatasetColorHexString(datasetId)}` });
        });

        return data;
    };

    const min = undefined;
    const max = undefined;
    const data = generateChartValues();

    return (
        <div className="elevation-profile">
            <ResponsiveContainer width="100%" height="100%">
                <ScatterChart
                    margin={{
                        top: 8,
                        right: 8,
                        bottom: 0,
                        left: -24,
                    }}
                >
                    <CartesianGrid stroke="var(--light-gray-4)" />
                    <XAxis
                        type="number"
                        dataKey="d"
                        name="distance"
                        label={{ value: 'Distance', fontSize: '0.9rem', position: 'insideBottom' }}
                        unit="m"
                        stroke="var(--light-gray-4)"
                        domain={[(dataMin) => Math.floor(dataMin), (dataMax) => Math.ceil(dataMax)]}
                        tick={{ fontSize: '0.5rem' }}
                        tickCount={30}
                        interval={2}
                    />
                    <YAxis
                        type="number"
                        dataKey="z"
                        name="elevation"
                        label={{ value: 'Elevation', angle: -90, fontSize: '0.9rem' }}
                        stroke="var(--light-gray-4)"
                        domain={[
                            min === undefined ? (dataMin) => Math.floor(dataMin) : min,
                            max === undefined ? (dataMax) => Math.ceil(dataMax) : max,
                        ]}
                        tick={{ fontSize: '0.5rem' }}
                        tickCount={20}
                        interval={2}
                        allowDataOverflow
                    />
                    <ZAxis range={[10]} />
                    <Tooltip
                        cursor={{ strokeDasharray: '3 3' }}
                        isAnimationActive={false}
                        content={<ElevationTooltip />}
                    />
                    {data.map((series) => {
                        return series.visible ? (
                            <Scatter
                                name={series.name}
                                key={series.id}
                                data={series.data}
                                fill={series.color}
                                shape={<Dot r={2} />}
                                line
                                isAnimationActive={false}
                                onMouseEnter={(event) =>
                                    giro3dService.updateCoordinates(
                                        {
                                            point: event.payload,
                                            picked: true,
                                        },
                                        true
                                    )
                                }
                                onMouseLeave={(event) =>
                                    giro3dService.updateCoordinates(
                                        {
                                            point: event.payload,
                                            picked: false,
                                        },
                                        true
                                    )
                                }
                            />
                        ) : null;
                    })}
                </ScatterChart>
            </ResponsiveContainer>
        </div>
    );
};
export default ElevationProfile;
