import React, { useState, useEffect } from 'react';
import { Button, Popover, PopoverBody, PopoverHeader } from 'reactstrap';

import CollectionListItemStatus from './CollectionListItemStatus';
import overviewMapService from '../../../services/OverviewMapService';
import { COLLECTION_STATES } from '../../../services/Constants';
import ToggleSwitch from '../../ToggleSwitch';

const CollectionListItem = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);

    const { collection, groupVisible, groupOpen } = props;

    const [visible, setVisible] = useState(false);

    useEffect(() => {
        if (collection.olstate === COLLECTION_STATES.LOADED && collection.olFeature)
            overviewMapService.setItemVisible(collection, groupVisible && visible);
    }, [groupVisible, visible, collection.olFeature]);

    let icon;
    switch (collection.olstate) {
        case COLLECTION_STATES.LOADED:
            icon = '';
            break;
        case COLLECTION_STATES.LOADING:
            icon = 'fal fa-spinner fa-pulse';
            break;
        case COLLECTION_STATES.LOADING_FAILED:
            icon = 'fal fa-exclamation-triangle';
            break;
        default:
            icon = 'fal fa-exclamation-triangle';
            break;
    }

    const goToCollection = () => {
        if (collection.olFeature !== null) overviewMapService.goToItem(collection);
        else overviewMapService.unselectItem(null, null);
    };

    return (
        <div
            className="data-item"
            key={`collection-overview-row-${collection.id}`}
            hidden={!groupOpen || (!collection.olFeature && collection.olstate === COLLECTION_STATES.LOADED)}
        >
            <Button
                className="borderless indicator"
                onClick={toggle}
                disabled={collection.olstate === COLLECTION_STATES.LOADED}
                title={`${collection.name}-state`}
            >
                <i className={icon} id={`collection-indicator-${collection.id}`} />
            </Button>
            <Button
                className="borderless white full-width left-text"
                disabled={!visible}
                title={
                    collection.olstate === COLLECTION_STATES.LOADED
                        ? `Go to ${collection.name}`
                        : `Error loading ${collection.name}`
                }
                id={`collectionbtn-${collection.id}`}
                onClick={collection.olstate === COLLECTION_STATES.LOADED ? goToCollection : toggle}
            >
                {collection.name}
            </Button>
            <ToggleSwitch
                id={`${collection.id}-visible`}
                checked={visible}
                onChange={(e) => setVisible(e.target.checked)}
                disabled={!groupVisible || collection.olstate !== COLLECTION_STATES.LOADED || !collection.olFeature}
            />

            {collection.olstate !== COLLECTION_STATES.LOADED || !collection.olFeature ? (
                <Popover
                    placement="left"
                    isOpen={isOpen}
                    target={`collection-indicator-${collection.id}`}
                    toggle={toggle}
                    trigger="legacy"
                >
                    <PopoverHeader>{`Status of collection ${collection.name}`}</PopoverHeader>
                    <PopoverBody>
                        <CollectionListItemStatus collection={collection} />
                    </PopoverBody>
                </Popover>
            ) : null}
        </div>
    );
};

export default CollectionListItem;
