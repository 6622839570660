import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { shallowEqual } from 'react-redux';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, UncontrolledTooltip } from 'reactstrap';
import Annotation from 'types/Annotation';
import store, { useAppDispatch, useAppSelector } from 'store';
import { DatasetId, SsdmType } from 'types/common';
import Dataset from 'types/Dataset';
import {
    editAnnotation,
    applyAnnotationEdit,
    stopAnnotationEdit,
    relateAnnotationDatasets,
    unrelateAnnotationDatasets,
    setAnnotationDatasets,
} from 'redux/actions';
import { getSSDMTypes } from 'redux/selectors';

import * as giro3d from 'redux/giro3d';
import * as layers from 'redux/layers';
import * as datasetsSlice from 'redux/datasets';
import * as annotationsSlice from 'redux/annotations';

import { EDITSTATE, SSDM_CLASSIFICATION_TYPES } from 'services/Constants';

import Multiselect from '../../forms/Multiselect';
import GeometryInfo from './GeometryInfo';
import TypeaheadField from '../../forms/TypeaheadField';
import BaseField from '../../forms/BaseField';

export function getDataset(id: DatasetId): Dataset {
    return datasetsSlice.get(id)(store.getState());
}

export function isVisible(id: DatasetId): boolean {
    const state = store.getState();
    const layerState = layers.get(id)(state);
    if (!layerState) {
        return false;
    }

    return layerState.visible;
}

const EditAnnotation = () => {
    const dispatch = useAppDispatch();

    const editState = useAppSelector(annotationsSlice.editState);
    const SSDMTypes = useAppSelector<SsdmType[]>(getSSDMTypes);
    const activeAnnotation: Annotation = useAppSelector(annotationsSlice.active);
    const datasets = useAppSelector<Dataset[]>(datasetsSlice.getRenderableDatasets, shallowEqual);
    const rev = useAppSelector(giro3d.getRevision);

    const [datasetOptions, setDatasetOptions] = useState([]);

    const classificationTypeOptions = [];
    const classificationOptions = [];

    Object.keys(SSDM_CLASSIFICATION_TYPES).forEach((type) => {
        if (
            (type.includes('SYMBOL_POINT') && activeAnnotation.geometry.type === 'Point') ||
            (type.includes('SYMBOL_LINE') && activeAnnotation.geometry.type === 'LineString') ||
            (type.includes('SYMBOL_POLYGON') && activeAnnotation.geometry.type === 'Polygon')
        )
            classificationTypeOptions.push({ title: SSDM_CLASSIFICATION_TYPES[type], value: type });
        classificationOptions.push([]);
    });

    SSDMTypes.forEach((SSDM: SsdmType) => {
        classificationOptions[Object.keys(SSDM_CLASSIFICATION_TYPES).indexOf(SSDM.parent_type)].push({
            title: `${SSDM.name} ${SSDM.display_name}`,
            value: SSDM.name,
        });
    });

    const abortEdit = () => {
        dispatch(stopAnnotationEdit());
    };
    const applyEdit = () => {
        dispatch(applyAnnotationEdit());
    };

    const updateOptions = (choice: { value: DatasetId }[]) => {
        setDatasetOptions(
            datasets
                .filter((dataset) => choice.some((option) => option.value === dataset.id) || isVisible(dataset.id))
                .map((dataset) => ({
                    label: dataset.name,
                    value: dataset.id,
                    faded: !isVisible(dataset.id),
                }))
        );
    };

    const SSDMType = SSDMTypes.find((type) => type.name === activeAnnotation.ssdm_type)?.parent_type;

    switch (editState) {
        case EDITSTATE.DRAWING:
            return (
                <>
                    <p>
                        Drag and drop points to move them. Hold space to enable splicing mode. Right click to end
                        editing.
                    </p>
                    <GeometryInfo />
                    <hr />
                    <div className="sideform-buttons">
                        <Button color="warning" id="cancel-drawing" onClick={abortEdit}>
                            ABORT
                        </Button>
                        <Button color="primary" id="apply-drawing" onClick={applyEdit}>
                            APPLY
                        </Button>
                    </div>
                </>
            );
        case EDITSTATE.DETAILS:
        case EDITSTATE.SUMBIT:
        case EDITSTATE.COMPLETE:
            return (
                <Formik
                    initialValues={{
                        name: activeAnnotation.name,
                        description: activeAnnotation.description,
                        type: activeAnnotation.observation_type !== null ? 'observation' : 'object',
                        observationType: activeAnnotation.observation_type,
                        classificationCategory: SSDMType
                            ? [
                                  {
                                      title: SSDM_CLASSIFICATION_TYPES[SSDMType],
                                      value: SSDMType,
                                  },
                              ]
                            : [],
                        classificationType: SSDMType
                            ? [
                                  classificationOptions[Object.keys(SSDM_CLASSIFICATION_TYPES).indexOf(SSDMType)]?.find(
                                      (o) => o.value === activeAnnotation.ssdm_type
                                  ),
                              ]
                            : [],

                        datasets: activeAnnotation.datasets.map((id) => ({
                            label: getDataset(id).name,
                            value: id,
                            faded: !isVisible(id),
                        })),
                    }}
                    validateOnChange={false}
                    validateOnBlur={false}
                    validate={(values) => {
                        const errors: {
                            name?: string;
                            type?: string;
                            observationType?: string;
                            classificationType?: string;
                            classificationCategory?: string;
                        } = {};
                        if (!values.name) errors.name = 'Required';
                        if (!values.type) errors.type = 'Required';
                        if (values.type === 'observation' && !values.observationType)
                            errors.observationType = 'Required';
                        if (values.type === 'object' && values.classificationCategory.length === 0)
                            errors.classificationCategory = 'Required';
                        if (
                            values.type === 'object' &&
                            values.classificationCategory.length !== 0 &&
                            values.classificationType.length === 0
                        )
                            errors.classificationType = 'Required';
                        return errors;
                    }}
                    onSubmit={(values) => {
                        const annotation: Partial<Annotation> = {
                            name: values.name,
                            description: values.description,
                            project_id: activeAnnotation.project_id,
                            id: activeAnnotation.id,
                            observation_type: values.type === 'observation' ? values.observationType : null,
                            ssdm_type: values.type !== 'observation' ? values.classificationType[0].value : null,
                        };

                        const datasetIds = values.datasets.map((dataset) => dataset.value);
                        const idsToRemove = activeAnnotation.datasets.filter((id) => !datasetIds.includes(id));
                        const idsToAdd = datasetIds.filter((id) => !activeAnnotation.datasets.includes(id));

                        dispatch(editAnnotation(annotation))
                            .then(() => {
                                unrelateAnnotationDatasets(activeAnnotation, idsToRemove);
                                relateAnnotationDatasets(activeAnnotation, idsToAdd);
                                dispatch(setAnnotationDatasets(activeAnnotation.id, datasetIds));
                            })
                            .then(abortEdit);
                    }}
                >
                    {({ isSubmitting, values, setFieldValue, handleChange, handleBlur, errors }) => (
                        <Form className="sideform">
                            {useEffect(() => updateOptions(values.datasets), [rev])}
                            <BaseField name="name" placeholder="Title of annotation" type="text" required="required" />
                            <hr className="sideform-bar" />
                            <div className="custom-radio">
                                <label className="text-yellow flex-row" htmlFor="observation">
                                    <input
                                        type="radio"
                                        id="observation"
                                        value="observation"
                                        name="type"
                                        className="radio"
                                        checked={values.type === 'observation'}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    <span className="flex-row grow">Observation</span>
                                    <i className="fal fa-circle-question auto-center-v" id="obsHelp" />
                                    <UncontrolledTooltip placement="right" target="obsHelp">
                                        <span className="submenu-tooltip">
                                            An observation is used to annotate parts of the data that are unidentified
                                            or erroneous.
                                        </span>
                                    </UncontrolledTooltip>
                                </label>
                                <label className="text-green flex-row" htmlFor="object">
                                    <input
                                        type="radio"
                                        id="object"
                                        value="object"
                                        name="type"
                                        className="radio"
                                        checked={values.type === 'object'}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    <span className="grow">Object</span>
                                    <i className="fal fa-circle-question auto-center-v" id="objHelp" />
                                    <UncontrolledTooltip placement="right" target="objHelp">
                                        <span className="submenu-tooltip">
                                            An object is something that can be classified by the SSDM standard.
                                        </span>
                                    </UncontrolledTooltip>
                                </label>
                            </div>
                            {errors.type ? <span className="invalid-feedback">{errors.type}</span> : null}
                            {values.type === 'observation' ? (
                                <>
                                    <hr className="sideform-bar" />
                                    <div className="custom-radio">
                                        <label htmlFor="uso" className="text-purple flex-row">
                                            <input
                                                type="radio"
                                                id="uso"
                                                value="uso"
                                                name="observationType"
                                                className="radio"
                                                checked={values.observationType === 'uso'}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            <span className="grow">USO</span>
                                            <i className="fal fa-ufo" />
                                        </label>
                                        <label htmlFor="flaw">
                                            <input
                                                type="radio"
                                                id="flaw"
                                                value="flaw"
                                                name="observationType"
                                                className="radio"
                                                checked={values.observationType === 'flaw'}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            <span className="text-purple grow">Flaw in Dataset</span>
                                        </label>
                                    </div>
                                    {errors.observationType ? (
                                        <span className="invalid-feedback">{errors.observationType}</span>
                                    ) : null}
                                </>
                            ) : null}
                            {values.type === 'object' ? (
                                <>
                                    <hr className="sideform-bar" />
                                    <TypeaheadField
                                        id="classificationCategory"
                                        name="classificationCategory"
                                        options={classificationTypeOptions || []}
                                        placeholder="Select a classification category"
                                        labelKey="title"
                                        setFieldValue={setFieldValue}
                                        selected={values.classificationCategory}
                                        clearButton
                                        onChange={() => setFieldValue('classificationType', [])}
                                    />
                                </>
                            ) : null}
                            {values.type === 'object' && values.classificationCategory.length !== 0 ? (
                                <>
                                    <hr className="sideform-bar" />
                                    <TypeaheadField
                                        id="classificationType"
                                        name="classificationType"
                                        options={
                                            classificationOptions[
                                                Object.keys(SSDM_CLASSIFICATION_TYPES).indexOf(
                                                    values.classificationCategory[0].value
                                                )
                                            ] || []
                                        }
                                        placeholder="Select a classification"
                                        labelKey="title"
                                        setFieldValue={setFieldValue}
                                        selected={values.classificationType}
                                        clearButton
                                    />
                                </>
                            ) : null}
                            <hr className="sideform-bar" />
                            <Multiselect
                                name="datasets"
                                options={datasetOptions}
                                onBlur={handleBlur}
                                onChange={(choice) => {
                                    setFieldValue('datasets', choice);
                                    updateOptions(choice);
                                }}
                                placeholder="Connect datasets"
                                multiValueCounterMessage="Connected dataset"
                                isClearable={false}
                                defaultValue={activeAnnotation.datasets.map((id) => ({
                                    label: getDataset(id).name,
                                    value: id,
                                    faded: !isVisible(id),
                                }))}
                            />
                            <hr className="sideform-bar" />
                            <BaseField
                                name="description"
                                label="Description"
                                type="text"
                                as="textarea"
                                value={values.description}
                            />
                            <hr className="sideform-bar" />
                            <div className="sideform-buttons">
                                <Button type="button" color="warning" id="cancel-creation" onClick={abortEdit}>
                                    ABORT
                                </Button>
                                <Button
                                    type="submit"
                                    color="primary"
                                    id="complete-creation"
                                    disabled={
                                        isSubmitting ||
                                        editState === EDITSTATE.SUMBIT ||
                                        editState === EDITSTATE.COMPLETE
                                    }
                                >
                                    SAVE
                                </Button>
                            </div>
                            <Modal centered className="modal-confirm" isOpen={editState === EDITSTATE.COMPLETE}>
                                <ModalHeader />
                                <ModalBody>
                                    <i className="modal-icon modal-icon-good fal fa-circle-check no-hover" />
                                    <span className="big-modal-text">Annotation created</span>
                                </ModalBody>
                                <ModalFooter />
                            </Modal>
                        </Form>
                    )}
                </Formik>
            );
        default:
            return <span>The state is {editState}</span>;
    }
};

export default EditAnnotation;
