import { useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Button, UncontrolledTooltip } from 'reactstrap';
import * as layers from 'redux/layers';
import {
    HasAttributes,
    HasColoringMode,
    HasOverlayColor,
    LayerState,
    hasAttributes,
    hasColoringMode,
} from 'types/LayerState';
import PinnedLegendItem from './PinnedLegendItem';

type PinnableLayer = LayerState & HasAttributes & HasOverlayColor & HasColoringMode;

const PinnedLegendList = () => {
    const [open, setOpen] = useState(true);

    function shouldDisplay(layer: LayerState): layer is PinnableLayer {
        if (!layer.visible) {
            return false;
        }

        if (!hasColoringMode(layer)) {
            return false;
        }

        if (hasAttributes(layer)) {
            return layer.pinLegend;
        }

        return false;
    }

    const list = useSelector(
        layers.filter<PinnableLayer>((l) => shouldDisplay(l)),
        shallowEqual
    );

    return (
        <div className="map-controls">
            <Button type="button" id="legend-button" onClick={() => setOpen(true)} hidden={open && list.length !== 0}>
                <i className="fal fa-ruler" />
                <UncontrolledTooltip placement="top" target="legend-button">
                    {list.length !== 0 ? 'Legend' : 'No legend data to show'}
                </UncontrolledTooltip>
            </Button>
            <div className="legend-box" hidden={!open || list.length === 0}>
                <button type="button" onClick={() => setOpen(false)} className="legend-bar">
                    <i className="fal fa-minus" />
                </button>
                {open ? list.map((layer) => <PinnedLegendItem key={layer.datasetId} layer={layer} />) : null}
            </div>
        </div>
    );
};

export default PinnedLegendList;
