import ToggleSwitch from 'components/ToggleSwitch';

export type Props = {
    id: string;
    checked: boolean;
    title: string;
    icon: string;
    disabled?: boolean;
    onChange: React.ChangeEventHandler<HTMLInputElement>;
};

function SettingToggleSwitch(props: Props) {
    return (
        <div className="row">
            <label htmlFor={props.id} className="col-sm-5 col-form-label">
                <i className={`fal ${props.icon} fa-fw`} /> {props.title}
            </label>
            <div className="col-sm-7 col-form-label">
                <ToggleSwitch {...props} />
            </div>
        </div>
    );
}

export default SettingToggleSwitch;
