import { useDispatch } from 'react-redux';
import { Button } from 'reactstrap';
import { stopAnnotationView } from '../../../redux/actions';
import GeometryInfo from './GeometryInfo';

const ViewAnnotation = () => {
    const dispatch = useDispatch();

    const endViewing = () => {
        dispatch(stopAnnotationView());
    };

    return (
        <>
            <p>
                You can move the points of the annotation but they will not be saved as you cannot edit this annotation.
            </p>
            <GeometryInfo />
            <hr />
            <div className="sideform-buttons">
                <Button color="primary" id="end-viewing" onClick={endViewing}>
                    CLOSE
                </Button>
            </div>
        </>
    );
};

export default ViewAnnotation;
