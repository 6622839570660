import { useAppSelector } from 'store';
import * as giro3d from 'redux/giro3d';
import giro3dService from 'services/Giro3dService';
import LineLayer from 'giro3d_extensions/layers/lines/LineLayer';
import * as THREE from 'three';
import { useEffect, useState } from 'react';
import gsap from 'gsap';
import { DatasetId, PlayState } from 'types/common';
import { useDispatch } from 'react-redux';
import { useEventBus } from 'EventBus';

const Z_AXIS = new THREE.Vector3(0, 0, 1);
const SMOOTH_OFFSET = 50;

const LineFollower = () => {
    const dispatch = useDispatch();
    const eventBus = useEventBus();

    const followCamera = useAppSelector(giro3d.getFollowCamera);

    const [datasetId, setDatasetId] = useState<DatasetId>();
    const [animation, setAnimation] = useState<gsap.core.Tween>();

    const cameraControls = giro3dService.getControls();

    function progressToValue(progress) {
        return progress * (animation.vars.duration.valueOf() as number);
    }

    const _linePos = new THREE.Vector3();
    const _targetPos = new THREE.Vector3();
    const _camPos = new THREE.Vector3();

    const _prevDirection = new THREE.Vector3();
    const _currentDirection = new THREE.Vector3();

    function setDirection(value, curve, length, vector) {
        curve
            .getPoint(Math.max(0, Math.min(value - SMOOTH_OFFSET / length, 1)), vector)
            .sub(curve.getPoint(Math.max(0, Math.min(value + SMOOTH_OFFSET / length, 1))));
    }

    function moveTo(
        props: { value: number; previousValue: number },
        curve: THREE.CatmullRomCurve3,
        pathOrigin: THREE.Vector3,
        fixedDepth: boolean,
        rotateCamera: boolean,
        length: number
    ) {
        curve.getPoint(props.value, _linePos);
        _linePos.add(pathOrigin);
        if (fixedDepth) _linePos.setZ(pathOrigin.z);

        cameraControls.getPosition(_camPos);
        cameraControls.getTarget(_targetPos);
        if (rotateCamera) {
            // Calculate the change in azimuth (smoothed) to set the camera's direction
            // and move the camera 1 unit from the line target
            setDirection(props.previousValue, curve, length, _prevDirection);
            setDirection(props.value, curve, length, _currentDirection);

            _camPos
                .sub(_targetPos)
                .setLength(1)
                .applyAxisAngle(
                    Z_AXIS,
                    Math.atan2(_prevDirection.x, _prevDirection.y) -
                        Math.atan2(_currentDirection.x, _currentDirection.y)
                )
                .add(_linePos);
        } else {
            // Move the camera 1 unit from the line target in the same direction it was before
            _camPos.sub(_targetPos).setLength(1).add(_linePos);
        }
        cameraControls.lookAt(_camPos, _linePos, false);
        dispatch(giro3d.setFollowCameraProgress(props.value));

        props.previousValue = props.value;
    }

    function jumpProgress(arg: { progress: number }) {
        if (animation) {
            switch (followCamera.state) {
                case PlayState.Release:
                case PlayState.Pause:
                    animation.pause(progressToValue(arg.progress), false);
                    break;
                case PlayState.Rewind:
                case PlayState.FastRewind:
                    animation.reverse(progressToValue(arg.progress), false);
                    break;
                default:
                    animation.play(progressToValue(arg.progress), false);
                    break;
            }
        }
    }

    function clearAnimation() {
        if (animation) {
            animation.kill();
            setAnimation(undefined);
        }
    }

    eventBus.subscribe('go-to-follow-progress', jumpProgress);

    useEffect(() => {
        if (animation) {
            switch (followCamera.state) {
                case PlayState.Play:
                    animation.timeScale(1);
                    animation.play(progressToValue(followCamera.progress));
                    break;
                case PlayState.Pause:
                case PlayState.Release:
                    animation.pause(progressToValue(followCamera.progress));
                    break;
                case PlayState.Rewind:
                    animation.timeScale(5);
                    animation.reverse(progressToValue(followCamera.progress));
                    break;
                case PlayState.FastForward:
                    animation.timeScale(5);
                    animation.play(progressToValue(followCamera.progress));
                    break;
                case PlayState.FastRewind:
                    animation.timeScale(25);
                    animation.reverse(progressToValue(followCamera.progress));
                    break;
                case PlayState.FastFastForward:
                    animation.timeScale(25);
                    animation.play(progressToValue(followCamera.progress));
                    break;
                default:
                    break;
            }
        }
    }, [followCamera.state]);

    useEffect(() => {
        clearAnimation();

        const layer = giro3dService.getLayersForDataset(followCamera.datasetId)[0];
        if (!(layer instanceof LineLayer)) return;
        layer.getGeometry().then((geometry) => {
            const vec3Array = geometry[0].points.toArray();
            const lengths = vec3Array.map((value: THREE.Vector3, index, array: THREE.Vector3[]) => {
                if (index === 0) return 0;
                return value.distanceTo(array[index - 1]);
            });
            const length = lengths.reduce((acc, value) => acc + value);
            const duration = length / followCamera.speed;

            const curve = new THREE.CatmullRomCurve3(vec3Array);

            const pathOrigin = new THREE.Vector3(
                geometry[0].origin.x,
                geometry[0].origin.y,
                followCamera.fixedDepth ? followCamera.depth : geometry[0].origin.z + followCamera.altitude
            );

            const animationProgress = {
                value: datasetId === followCamera.datasetId ? followCamera.progress : 0,
                previousValue: datasetId === followCamera.datasetId ? followCamera.progress : 0,
            };
            setAnimation(
                gsap.fromTo(
                    animationProgress,
                    {
                        value: 0,
                    },
                    {
                        value: 1,
                        duration,
                        overwrite: true,
                        paused: true,
                        onUpdateParams: [animationProgress],
                        onUpdate(props) {
                            moveTo(
                                props,
                                curve,
                                pathOrigin,
                                followCamera.fixedDepth,
                                followCamera.rotateCamera,
                                length
                            );
                        },
                        ease: 'none',
                    }
                )
            );

            moveTo(animationProgress, curve, pathOrigin, followCamera.fixedDepth, followCamera.rotateCamera, length);

            if (datasetId !== followCamera.datasetId) {
                cameraControls.getTarget(_targetPos);
                setDirection(0, curve, length, _currentDirection);
                _camPos.copy(_targetPos).add(_currentDirection);
                cameraControls.lookAt(_camPos, _targetPos, false);
                setDatasetId(followCamera.datasetId);
            }
        });
    }, [
        followCamera.datasetId,
        followCamera.altitude,
        followCamera.depth,
        followCamera.fixedDepth,
        followCamera.rotateCamera,
        followCamera.speed,
    ]);

    return null;
};

export default LineFollower;
