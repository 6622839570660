import { Form, Formik } from 'formik';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from 'reactstrap';
import * as serializer from 'services/serializer';
import { createView, updateView, updateProject, fetchProjections, fetchOrganizations } from '../../redux/actions';
import { getOrganizations, getProjections } from '../../redux/selectors';
import * as datasetsSlice from '../../redux/datasets';
import ApiErrors from '../../services/ApiErrors';
import DosApi from '../../services/DosApi';
import handleApiError from '../../services/Forms';
import BaseField from '../forms/BaseField';
import TypeaheadField from '../forms/TypeaheadField';

const EditProject = (props) => {
    const dispatch = useDispatch();

    const { project, endEdit } = props;

    const organizations = useSelector(getOrganizations);
    const projections = useSelector(getProjections);
    const openProject = useSelector(datasetsSlice.currentProject);
    const state = useSelector((s) => s);

    const STATE = {
        PROCESSING: 'processing',
        COMPLETE: 'complete',
        ERROR: 'error',
    };
    const [modalState, setModal] = useState(null);
    const [errorText, setErrorText] = useState();

    useEffect(() => {
        dispatch(fetchProjections());
        dispatch(fetchOrganizations());
    }, []);

    if (!organizations || !projections) return <Spinner animation="border" />;

    const modalContent = () => {
        switch (modalState) {
            case STATE.PROCESSING:
                return (
                    <>
                        <ModalHeader />
                        <ModalBody>
                            <i className="modal-icon modal-icon-warn fal fa-timer no-hover" />
                            <span className="big-modal-text">Editing project</span>
                        </ModalBody>
                        <ModalFooter />
                    </>
                );
            case STATE.COMPLETE:
                return (
                    <>
                        <ModalHeader />
                        <ModalBody>
                            <i className="modal-icon modal-icon-good fal fa-circle-check no-hover" />
                            <span className="big-modal-text">Project edited</span>
                        </ModalBody>
                        <ModalFooter />
                    </>
                );
            case STATE.ERROR:
                return (
                    <>
                        <ModalHeader />
                        <ModalBody>
                            <i className="modal-icon modal-icon-bad fal fa-circle-exclamation no-hover" />
                            <span className="big-modal-text">An error occured</span>
                            <span className="small-modal-text">{errorText}</span>
                        </ModalBody>
                        <ModalFooter>
                            <Button onClick={() => setModal(null)}>Okay</Button>
                        </ModalFooter>
                    </>
                );
            default:
                return null;
        }
    };

    const validate = (values) => {
        const errors = {};
        if (!values.title) errors.title = 'Required';
        if (!values.organization) errors.organization = 'Required';
        if (!values.projection) errors.projection = 'Required';
        return errors;
    };

    const onSubmit = async (values, helpers) => {
        setModal(STATE.PROCESSING);
        let newViewId;
        if (values.save_default_view) {
            const serialized = serializer.serialize(state);
            if (project.default_view_id) {
                await dispatch(updateView({ id: project.default_view_id, project_id: project.id, view: serialized }));
            } else {
                newViewId = (await dispatch(createView({ name: 'Default view', view: serialized }))).id;
            }
        }
        DosApi.updateProject(project.id, {
            name: values.title,
            description: values.description,
            organization_id: values.organization.id,
            projection: values.projection.srid,
            default_view_id: newViewId || project.default_view_id,
        })
            .catch((err) => {
                handleApiError(err, helpers);
                setErrorText(ApiErrors.getErrorMessage(err));
                setModal(STATE.ERROR);
            })
            .then((data) => {
                updateProject(dispatch, data);
                setModal(STATE.COMPLETE);
                setTimeout(endEdit, 2000);
            });
    };

    return (
        <>
            <ModalHeader>Edit project</ModalHeader>
            <Formik
                enableReinitialize
                initialValues={{
                    title: project.name,
                    description: project.description,
                    organization: { id: project.organization_id },
                    projection: project.projection,
                    save_default_view: false,
                }}
                onSubmit={onSubmit}
                validate={validate}
            >
                {({ isSubmitting, setFieldValue }) => (
                    <Form>
                        <ModalBody>
                            <BaseField name="title" label="Project name" type="text" required="required" />
                            <BaseField name="description" label="Description" type="textarea" />
                            <TypeaheadField
                                name="organization"
                                label="Organization"
                                required="required"
                                options={
                                    organizations !== null
                                        ? organizations.map((e) => ({ title: e.display_name, id: e.id }))
                                        : null
                                }
                                labelKey="title"
                                setFieldValue={setFieldValue}
                                highlightOnlyResult
                                emptyLabel="No organizations found"
                                defaultInputValue={
                                    organizations !== null
                                        ? organizations.find((o) => o.id === project.organization_id).display_name
                                        : ''
                                }
                                disabled={organizations?.length === 1}
                            />
                            <TypeaheadField
                                name="projection"
                                label="Projection"
                                required="required"
                                helperLabel="Default SRID to be used for datasets"
                                options={projections || []}
                                labelKey="title"
                                setFieldValue={setFieldValue}
                                highlightOnlyResult
                                emptyLabel="No compatible CRSs found"
                                defaultInputValue={
                                    projections !== null
                                        ? projections.find((p) => p.srid === String(project.projection)).title
                                        : ''
                                }
                                dropup
                                onBlur={undefined}
                                disabled
                            />
                            {project === openProject ? (
                                <BaseField
                                    name="save_default_view"
                                    label="Save current view as default view"
                                    type="checkbox"
                                />
                            ) : null}
                        </ModalBody>
                        <ModalFooter>
                            <Button type="button" color="warning" id="cancel-update" onClick={endEdit}>
                                Abort
                            </Button>
                            <Button type="submit" color="primary" id="complete-update" disabled={isSubmitting}>
                                Save
                            </Button>
                        </ModalFooter>
                    </Form>
                )}
            </Formik>
            <Modal isOpen={modalState !== null} className="modal-confirm">
                {modalContent()}
            </Modal>
        </>
    );
};

export default EditProject;
