import { Mesh, Group, BufferGeometry, Material, Box3, Object3D } from 'three';
import { HasOpacity, LayerState } from 'types/LayerState';
import * as layers from 'redux/layers';
import Layer, { Settings as BaseSettings, ConstructorParams as BaseConstructorParams, LayerEventMap } from './Layer';
import LayerStateObserver from './LayerStateObserver';

export interface Settings extends BaseSettings {
    opacity: number;
}

type ThisLayerState = LayerState & HasOpacity;

// interface impl common for all layers where get3delt() returns a THREEJS group.
export default abstract class ThreejsGroupLayer<
    TSettings extends Settings = Settings,
    TEvents extends LayerEventMap = LayerEventMap,
    TLayerState extends ThisLayerState = ThisLayerState,
> extends Layer<TSettings, TEvents, TLayerState> {
    object3d: Group;

    constructor(params: BaseConstructorParams) {
        super(params);
        this.settings.opacity = 1;
    }

    protected subscribeToStateChanges(observer: LayerStateObserver<TLayerState>): void {
        super.subscribeToStateChanges(observer);

        observer.subscribe(layers.getOpacity(this._layerState), (v) => this.setOpacity(v));
    }

    protected override async initOnce() {
        if (this.initialized) {
            console.warn(`Layer ${this.datasetId} is already initialized`);
            return;
        }

        // Beware: this object *can* be overriden in subclasses (e.g. DocumentLayer)
        this.object3d = new Group();
        this.object3d.visible = this.getVisibility();

        await this._giro3dInstance.add(this.get3dElement());
    }

    protected override onDispose(): void {
        const element = this.get3dElement();
        if (element) {
            this._giro3dInstance.remove(this.get3dElement());
        }
    }

    get3dElement() {
        return this.object3d;
    }

    protected getObjectOpacity(obj: Object3D | Mesh<BufferGeometry, Material>) {
        if (obj.children.length !== 0) {
            return this.getObjectOpacity(obj.children[0]);
        }

        const asMesh = obj as Mesh<BufferGeometry, Material>;
        if (asMesh.material) {
            return asMesh.material.opacity;
        }
        return this.settings.opacity;
    }

    getOpacity() {
        // return opacity of first object. all should be equal.
        return this.initialized ? this.getObjectOpacity(this.get3dElement()) : this.settings.opacity;
    }

    setOpacity(value: number) {
        this.settings.opacity = value;
        if (this.initialized) {
            const shouldBeTransparent = value < 1;
            this.get3dElement().traverse((obj: Mesh<BufferGeometry, Material>) => {
                if (obj.material) {
                    const currentTransparent = obj.material.transparent;
                    obj.material.transparent = shouldBeTransparent;
                    obj.material.opacity = value;
                    obj.material.needsUpdate = currentTransparent !== shouldBeTransparent;
                }
            });
            this.notifyLayerChange();
        }
    }

    protected async doSetVisibility(value: boolean) {
        if (this.initialized && value !== undefined) {
            this.get3dElement().visible = value;
            this.notifyLayerChange();
        }
    }

    getBoundingBox() {
        if (!this.initialized) return null;
        const getBoundingBoxTmpBox3 = new Box3();
        return getBoundingBoxTmpBox3.setFromObject(this.get3dElement());
    }
}
