// React
import React from 'react';

// Formik
import { Field, useField } from 'formik';

// Boostrap
import { Label, FormFeedback, UncontrolledTooltip } from 'reactstrap';

const BaseField = ({ label = undefined, helperLabel = undefined, className = undefined, id = undefined, ...props }) => {
    const [field, meta] = useField(props);
    if (!className) {
        className = props.as === 'select' ? 'form-select' : 'form-control';
        if (props.type === 'checkbox' || props.type === 'radio') {
            className = 'form-check';
        }
    }
    if (!id) {
        id = props.name;
    }

    if (meta.error && meta.touched) {
        className += ' is-invalid';
    }
    if (props.type === 'checkbox') {
        return (
            <div className="form-group">
                <div className="styled-checkbox">
                    <Field {...field} {...props} id={id} className={className} />
                    <Label for={id} check>
                        {label}
                    </Label>
                    {helperLabel ? (
                        <>
                            <i className="fal fa-circle-question tooltip-icon" id={`${id}-help`} />
                            <UncontrolledTooltip placement="right" target={`${id}-help`}>
                                <span className="submenu-tooltip">{helperLabel}</span>
                            </UncontrolledTooltip>
                        </>
                    ) : null}
                </div>
                {meta.touched && meta.error ? <FormFeedback>{meta.error}</FormFeedback> : null}
            </div>
        );
    }
    return (
        <div className="form-group">
            {label ? <Label for={id}>{label}</Label> : null}
            <Field {...field} {...props} id={id} className={className} />
            {meta.touched && meta.error ? <FormFeedback>{meta.error}</FormFeedback> : null}
            {helperLabel ? (
                <>
                    <i className="fal fa-circle-question tooltip-icon" id={`${id}-help`} />
                    <UncontrolledTooltip placement="right" target={`${id}-help`}>
                        <span className="submenu-tooltip">{helperLabel}</span>
                    </UncontrolledTooltip>
                </>
            ) : null}
        </div>
    );
};

export default BaseField;
