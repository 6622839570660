import React, { useEffect } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';

import { getReleaseInfoShow, getReleaseInfoShownAt, getUser } from '../../redux/selectors';
import { RELEASE_HIDE, RELEASE_SHOW } from '../../redux/actionTypes';

import Releases, { latestRelease } from './Releases';

const ReleaseModal = () => {
    const show = useSelector(getReleaseInfoShow);
    const shownAt = useSelector(getReleaseInfoShownAt);
    const user = useSelector(getUser);
    const dispatch = useDispatch();

    const toggleModal = () => {
        dispatch({
            type: RELEASE_HIDE,
        });
    };

    useEffect(() => {
        if (!!user && (!shownAt || new Date(shownAt) < new Date(latestRelease))) {
            dispatch({
                type: RELEASE_SHOW,
            });
        }
    }, [shownAt, user]);

    return (
        <Modal isOpen={show} toggle={toggleModal} keyboard={false} centered>
            <ModalHeader>New features & improvements</ModalHeader>
            <ModalBody>
                <Releases showSince={new Date(shownAt)} />
            </ModalBody>
        </Modal>
    );
};

export default ReleaseModal;
