import React from 'react';

export const InProgressUpload = ({ dataset, files }) => (
    <div className="upload-group">
        <div className="upload-group-title">{dataset.name}</div>
        <div className="upload-group-list">
            {files.map((file) => (
                <div key={`file-${file.id}`}>
                    <label htmlFor={file.id}>{file.name}</label>
                    <progress id={file.id} value={file.progress.percentage} max={100}>
                        {file.progress.percentage}%
                    </progress>
                </div>
            ))}
        </div>
    </div>
);

export const CompletedUpload = ({ dataset, files }) => (
    <div className="upload-group complete">
        <div className="upload-group-title">{dataset.name}</div>
        <div className="upload-group-list">
            {files.successful.map((file) => (
                <div key={`file-${file.id}`}>
                    <i className="fal fa-check icon-green" />
                    <label>{file.name}</label>
                </div>
            ))}
            {files.failed.map((file) => (
                <div key={`file-${file.id}`}>
                    <i className="fal fa-x icon-red" />
                    <label>{file.name}</label>
                </div>
            ))}
        </div>
    </div>
);
