import { nanoid } from '@reduxjs/toolkit';
import ToggleSwitch from 'components/ToggleSwitch';

export type Props = {
    checked: boolean;
    title: string;
    onChange: (checked: boolean) => void;
};

function SettingToggle(props: Props) {
    const id = nanoid();
    return (
        <div className="settings-row">
            <span>{props.title}</span>
            <ToggleSwitch id={id} checked={props.checked} onChange={(e) => props.onChange(e.target.checked)} />
        </div>
    );
}

export default SettingToggle;
