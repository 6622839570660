import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { removeMembership, updateMembership, updateMembershipUser, updateUser } from '../../redux/actions';
import { getUser } from '../../redux/selectors';
import { ROLES } from '../../services/Constants';

const UserItem = (props) => {
    const dispatch = useDispatch();

    const currentUser = useSelector(getUser);

    const [removePrompt, setRemovePrompt] = useState(false);

    const { organization, membership, user } = props;

    const disabled = currentUser.id === user.id;

    const roleColor = (role) => {
        switch (role) {
            case ROLES.OWNER:
                return 'green';
            default:
                return 'yellow';
        }
    };

    const updateActive = (active) => {
        user.is_active = active;
        dispatch(membership ? updateMembershipUser(membership, user) : updateUser(user));
    };

    const updateAdmin = (admin) => {
        user.is_admin = admin;
        dispatch(membership ? updateMembershipUser(membership, user) : updateUser(user));
    };

    return (
        <tr className="user-item" id={user.id}>
            <td className="user-name">
                {user.given_name} {user.family_name}
            </td>
            <td className="user-email">{user.email}</td>

            {membership ? (
                <td>
                    <div className="centre-drop-menu">
                        <Button
                            className={`borderless ${roleColor(membership.role)} capitalize-first full-color`}
                            id="membership"
                            title="membership"
                            disabled={
                                (disabled && !currentUser.is_admin) || !organization.user_permissions.create_memberships
                            }
                        >
                            {membership.role}
                        </Button>
                        <div className="centre-drop-content">
                            <Button
                                className="borderless green full-color"
                                onClick={() => dispatch(updateMembership(membership, ROLES.OWNER))}
                                disabled={
                                    (disabled && !currentUser.is_admin) ||
                                    !organization.user_permissions.create_memberships
                                }
                            >
                                Owner
                            </Button>
                            <hr className="centre-drop-menu-divider" />
                            <Button
                                className="borderless yellow full-color"
                                onClick={() => dispatch(updateMembership(membership, ROLES.MEMBER))}
                                disabled={
                                    (disabled && !currentUser.is_admin) ||
                                    !organization.user_permissions.create_memberships
                                }
                            >
                                Member
                            </Button>
                        </div>
                    </div>
                </td>
            ) : null}

            <td>
                <div className="admin-actions">
                    {membership ? (
                        <>
                            <Button
                                className="borderless red"
                                id="remove-user"
                                title="Remove user"
                                onClick={() => setRemovePrompt(true)}
                                disabled={
                                    (disabled && !currentUser.is_admin) ||
                                    !organization.user_permissions.delete_memberships
                                }
                            >
                                <i className="fal fa-link-horizontal-slash" />
                            </Button>
                            <Modal
                                isOpen={removePrompt}
                                onClick={() => setRemovePrompt(false)}
                                centered
                                className="modal-confirm"
                            >
                                <ModalBody>
                                    <i className="modal-icon modal-icon-bad fal fa-circle-xmark no-hover" />
                                    <span className="big-modal-text">Are you sure?</span>
                                    <span className="small-modal-text">
                                        {user.given_name} {user.family_name} will be removed from{' '}
                                        {organization.display_name} and lose all access permissions that the
                                        organization provides.
                                        <br />
                                        They can be added again later with their email.
                                    </span>
                                    <Button color="warning" onClick={() => dispatch(removeMembership(membership))}>
                                        Yes, remove this user
                                    </Button>
                                </ModalBody>
                                <ModalFooter>
                                    <Button
                                        className="borderless green underline"
                                        onClick={() => setRemovePrompt(false)}
                                    >
                                        No, I regret this action
                                    </Button>
                                </ModalFooter>
                            </Modal>
                        </>
                    ) : null}
                    {currentUser.is_admin ? (
                        <>
                            <div className="styled-checkbox">
                                <input
                                    type="checkbox"
                                    id={`${user.id}-active`}
                                    value="active"
                                    checked={user.is_active}
                                    onChange={() => updateActive(!user.is_active)}
                                    disabled={disabled}
                                />
                                <label htmlFor={`${user.id}-active`}>Active</label>
                            </div>
                            <div className="styled-checkbox">
                                <input
                                    type="checkbox"
                                    id={`${user.id}-admin`}
                                    value="admin"
                                    checked={user.is_admin}
                                    onChange={() => updateAdmin(!user.is_admin)}
                                    disabled={disabled}
                                />
                                <label htmlFor={`${user.id}-admin`}>Admin</label>
                            </div>
                        </>
                    ) : null}
                </div>
            </td>
        </tr>
    );
};
export default UserItem;
