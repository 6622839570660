import React from 'react';
import { Field } from 'formik';
import { FormText } from 'reactstrap';

function CheckboxGroup(props) {
    const { label, helperLabel, name, options, ...rest } = props;

    return (
        <div className="form-control">
            <label>{label}</label>
            <br />
            <Field name={name} {...rest}>
                {({ field }) =>
                    options.map((option) => (
                        <React.Fragment key={option.key}>
                            <input
                                type="checkbox"
                                id={option.value}
                                {...field}
                                value={option.value}
                                checked={field.value === undefined ? null : field.value.includes(option.value)}
                            />
                            <label htmlFor={option.value}>{option.key}</label>
                            <br />
                        </React.Fragment>
                    ))
                }
            </Field>
            {helperLabel ? <FormText>{helperLabel}</FormText> : null}
        </div>
    );
}

export default CheckboxGroup;
