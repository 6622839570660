import React from 'react';
import { PROJECT_STATES } from '../../../services/Constants';

const ProjectListItemStatus = ({ project }) => {
    switch (project.olstate) {
        case PROJECT_STATES.LOADING:
            return <p>Project is currently being loaded into the view.</p>;
        case PROJECT_STATES.LOADING_FAILED:
            return <p>{`Loading data has failed: ${project.olstate_msg}`}</p>;
        default:
            return <p>Unknown state</p>;
    }
};

export default ProjectListItemStatus;
