// React
import React, { useState, useEffect } from 'react';
import * as ReactDOM from 'react-dom';
import { useSelector } from 'react-redux';
import { Button, Card, CardHeader, CardBody } from 'reactstrap';
import { Overlay } from 'ol';

// Redux
import { getOverviewMap, getSelectedItems } from '../../../redux/selectors';
import overviewMapService from '../../../services/OverviewMapService';

const ItemCard = ({ type, item, at }) => {
    const selectItem = () => overviewMapService.selectItem(item, at);
    const mouseOver = () => overviewMapService.highlightItem(item);
    const mouseOut = () => overviewMapService.unhighlightItem(item);

    let featureIcon;
    switch (type) {
        case 'collection':
            featureIcon = 'fal fa-arrow-up-from-water-pump fa-fw';
            break;
        case 'project':
            featureIcon = 'fal fa-book-open fa-fw';
            break;
        case 'dataset':
            featureIcon = 'fal fa-layer-group fa-fw';
            break;
        default:
            featureIcon = 'fal fa-question fa-fw';
    }

    return (
        <li>
            <Button onClick={selectItem} onMouseOver={mouseOver} onMouseOut={mouseOut} color="link">
                <i className={featureIcon} /> {item.name} <small>({type})</small>
            </Button>
        </li>
    );
};

const ItemsPopup = () => {
    const map = useSelector(getOverviewMap);
    const { at, items } = useSelector(getSelectedItems) ?? {};
    const [domNode, setDomNode] = useState(undefined);
    const overlayId = 'itemsPopover';

    const close = () => {
        const olOverlay = map.getOverlayById(overlayId);
        olOverlay.setPosition(undefined);
        overviewMapService.unselectItem(items, at);
    };
    useEffect(() => {
        if (map !== undefined) {
            const itemsPortalNode = document.createElement('div');
            itemsPortalNode.setAttribute('id', 'itemsPopoverPortal');
            const itemsPopup = new Overlay({
                id: overlayId,
                position: undefined,
                element: itemsPortalNode,
            });
            map.addOverlay(itemsPopup);
            setDomNode(itemsPopup.getElement());
        }
    }, [map]);

    useEffect(() => {
        if (map !== undefined && items !== undefined) {
            const olOverlay = map.getOverlayById(overlayId);
            olOverlay.setPosition(at);
            olOverlay.setOffset([15, -25]);
        }
    }, [at, items]);

    const popover = () =>
        items === undefined ? null : (
            <Card className="popup-body">
                <CardHeader>
                    <h5>{items.length} features</h5>
                    <button type="button" className="btn-close btn-close-white" aria-label="Close" onClick={close} />
                </CardHeader>
                <CardBody>
                    <ul className="list-unstyled">
                        {items.map(({ type, item }) => (
                            <ItemCard key={`featureCard-${item.id}`} type={type} item={item} at={at} />
                        ))}
                    </ul>
                </CardBody>
            </Card>
        );

    return domNode === undefined ? null : ReactDOM.createPortal(popover(), domNode);
};

export default ItemsPopup;
