// React
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, UncontrolledTooltip } from 'reactstrap';
import { useNavigate } from 'react-router-dom';

// Redux
import { getProjects, getUser } from '../../redux/selectors';
import { fetchProjects, showError } from '../../redux/actions';
import DosApi from '../../services/DosApi';
import { PROJECT_REMOVED, PROJECT_DELETED } from '../../redux/actionTypes';

// Components
import ProjectListItem, { PROJECT_ACTION } from './ProjectListItem';
import CreateProject from './CreateProject';
import EditProject from './EditProject';
import ProjectDatasets from './ProjectDatasets';
import Scrollbox from '../Scrollbox';

const ProjectsMenu = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const projects = useSelector(getProjects);
    const user = useSelector(getUser);

    const { endSelection } = props;

    const STATE = {
        INPUT: 'input',
        PROCESSING: 'processing',
        COMPLETE: 'complete',
    };

    const [action, setAction] = useState(null);
    const [modalState, setModalState] = useState(STATE.INPUT);
    const [currentProject, setCurrentProject] = useState(null);

    useEffect(() => {
        dispatch(fetchProjects());
    }, []);

    useEffect(() => {
        if (action === PROJECT_ACTION.OPEN) {
            try {
                setAction(null);
                const slug = currentProject.name
                    .toString()
                    .normalize('NFD') // break accented characters into components
                    .replace(/[\u0300-\u036f]/g, '') // remove diacritics
                    .toLowerCase()
                    .replace(/\s+/g, '-') // spaces to dashes
                    .replace(/&/g, '-and-') // ampersand to and
                    .replace(/[^\w-]+/g, '') // remove non-words
                    .replace(/--+/g, '-') // collapse multiple dashes
                    .replace(/^-+/, '') // trim starting dash
                    .replace(/-+$/, ''); // trim ending dash;
                navigate(`/project/${currentProject.id}/${slug}`);
                if (endSelection) {
                    endSelection();
                }
            } catch (error) {
                console.error(error);
            }
        }
    }, [action]);

    const deleteProject = () => {
        DosApi.deleteProject(currentProject.id)
            .then(() => {
                setModalState(STATE.PROCESSING);
            })
            .catch((err) => {
                showError(dispatch, err);
                setModalState(STATE.INPUT);
                setAction(null);
            })
            .finally(() => {
                setModalState(STATE.COMPLETE);
                dispatch({
                    type: PROJECT_REMOVED,
                    payload: currentProject,
                });
                dispatch({
                    type: PROJECT_DELETED,
                    payload: currentProject,
                });
                setTimeout(() => {
                    setModalState(STATE.INPUT);
                    setAction(null);
                }, 2000);
            });
    };

    const modalContent = () => {
        switch (action) {
            case PROJECT_ACTION.DELETE:
                switch (modalState) {
                    case STATE.INPUT:
                        return (
                            <>
                                <ModalHeader />
                                <ModalBody>
                                    <i className="modal-icon modal-icon-bad fal fa-circle-xmark no-hover" />
                                    <span className="big-modal-text">Are you sure?</span>
                                    <span className="small-modal-text">
                                        This will also delete all annotations created in {currentProject.name}.
                                    </span>
                                    <Button color="warning" onClick={deleteProject}>
                                        Yes, delete this project
                                    </Button>
                                </ModalBody>
                                <ModalFooter>
                                    <Button className="borderless green underline" onClick={() => setAction(null)}>
                                        No, I regret this action
                                    </Button>
                                </ModalFooter>
                            </>
                        );
                    case STATE.PROCESSING:
                        return (
                            <>
                                <ModalHeader />
                                <ModalBody>
                                    <i className="modal-icon modal-icon-warn fal fa-timer no-hover" />
                                    <span className="big-modal-text">Deleting project</span>
                                </ModalBody>
                                <ModalFooter />
                            </>
                        );
                    case STATE.COMPLETE:
                        return (
                            <>
                                <ModalHeader />
                                <ModalBody>
                                    <i className="modal-icon modal-icon-good fal fa-circle-check no-hover" />
                                    <span className="big-modal-text">Project deleted</span>
                                </ModalBody>
                                <ModalFooter />
                            </>
                        );
                    default:
                        return null;
                }
            case PROJECT_ACTION.DATASETS:
                return (
                    <>
                        <ModalHeader
                            toggle={() => {
                                setAction(null);
                            }}
                        >
                            Select Datasets for {currentProject.name}
                        </ModalHeader>
                        <ModalBody>
                            <ProjectDatasets project={currentProject} />
                        </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" onClick={() => setAction(null)}>
                                Close
                            </Button>
                        </ModalFooter>
                    </>
                );
            case PROJECT_ACTION.CREATE:
                return <CreateProject endCreate={() => setAction(null)} />;
            case PROJECT_ACTION.EDIT:
                return <EditProject project={currentProject} endEdit={() => setAction(null)} />;
            default:
                return null;
        }
    };

    const renderedListItems = projects
        ? projects
              .sort((a, b) => {
                  if (a.name.toUpperCase() < b.name.toUpperCase()) return -1;
                  if (a.name.toUpperCase() > b.name.toUpperCase()) return 1;
                  return 0;
              })
              .map((project) => (
                  <ProjectListItem
                      key={`project-select-${project.id}`}
                      project={project}
                      setAction={setAction}
                      setCurrentProject={setCurrentProject}
                  />
              ))
        : [];

    return (
        <div className="map-pane-body">
            <div className="map-pane-title-bar">
                <div className="map-pane-title">
                    Projects <span className="faded-text">({projects.length})</span>
                </div>
                <div className="map-pane-title-bar-buttons">
                    <div id="create-project">
                        <Button
                            className="circle green"
                            onClick={() => setAction(PROJECT_ACTION.CREATE)}
                            title="Create project"
                            disabled={
                                user.memberships.filter(
                                    (membership) => membership.organization.user_permissions.create_projects
                                ).length === 0 && !user.is_admin
                            }
                        >
                            <i className="fal fa-plus" />
                        </Button>
                        {user.memberships.filter(
                            (membership) => membership.organization.user_permissions.create_projects
                        ).length === 0 && !user.is_admin ? (
                            <UncontrolledTooltip target="create-project">
                                You do not have the permissions to create projects.
                            </UncontrolledTooltip>
                        ) : null}
                    </div>
                </div>
            </div>
            <Scrollbox className="project-list">
                <ul>{renderedListItems}</ul>
            </Scrollbox>
            {modalContent() && (
                <Modal
                    id="projects-pane"
                    isOpen={!!action}
                    className={action === PROJECT_ACTION.DELETE ? 'modal-confirm' : undefined}
                >
                    {modalContent()}
                </Modal>
            )}
        </div>
    );
};

export default ProjectsMenu;
