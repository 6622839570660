import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames';

import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { useNavigate } from 'react-router-dom';

import { getUser } from '../../redux/selectors';

import MyProfile from './MyProfile';
import MyOrganizations from './MyOrganizations';
import MyMemberships from './MyMemberships';
import Memberships from './Memberships';

const TABS = {
    PROFILE: 'profile',
    ORGANIZATIONS: 'organizations',
    MEMBERSHIPS: 'memberships',
};

const Profile = () => {
    useEffect(() => {
        document.title = 'Profile | SCOPE';
    }, []);

    const user = useSelector(getUser);
    const orgs = user.memberships
        .filter((m) => m.organization.user_permissions.read_memberships)
        .map((m) => m.organization);

    const [currentActiveTab, setCurrentActiveTab] = useState(TABS.PROFILE);
    const goToTab = (tab) => {
        if (currentActiveTab !== tab) setCurrentActiveTab(tab);
    };

    const navigate = useNavigate();

    return (
        <div className="admin">
            <div className="menu-right">
                <div className="tab-menu">
                    <div className="tab-menu-header">
                        <button
                            type="button"
                            className="fal fa-arrow-left-long back-button"
                            onClick={() => navigate(-1)}
                            aria-label="Back"
                        />
                        <h2>Profile</h2>
                    </div>
                    <Nav>
                        <NavItem>
                            <NavLink
                                className={classnames({
                                    active: currentActiveTab === TABS.PROFILE,
                                })}
                                onClick={() => goToTab(TABS.PROFILE)}
                            >
                                <i className="fal fa-id-card" />
                                <span>My profile</span>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({
                                    active: currentActiveTab === TABS.ORGANIZATIONS,
                                })}
                                onClick={() => goToTab(TABS.ORGANIZATIONS)}
                            >
                                <i className="fal fa-building" />
                                <span>My organizations</span>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({
                                    active: currentActiveTab === TABS.MEMBERSHIPS,
                                })}
                                onClick={() => goToTab(TABS.MEMBERSHIPS)}
                            >
                                <i className="fal fa-building-user" />
                                <span>My memberships</span>
                            </NavLink>
                        </NavItem>
                        {orgs.map((organization) => (
                            <NavItem key={`navitem-${organization.id}`}>
                                <NavLink
                                    className={classnames({
                                        active: currentActiveTab === `org-${organization.id}`,
                                    })}
                                    onClick={() => goToTab(`org-${organization.id}`)}
                                >
                                    <i className="fal fa-building" />
                                    <span>{organization.display_name}</span>
                                </NavLink>
                            </NavItem>
                        ))}
                    </Nav>
                </div>
            </div>
            <TabContent activeTab={currentActiveTab}>
                <TabPane tabId={TABS.PROFILE}>
                    <MyProfile />
                </TabPane>
                <TabPane tabId={TABS.ORGANIZATIONS}>
                    <MyOrganizations />
                </TabPane>
                <TabPane tabId={TABS.MEMBERSHIPS}>
                    <MyMemberships />
                </TabPane>
                {orgs.map((organization) => (
                    <TabPane tabId={`org-${organization.id}`} key={`tabitem-${organization.id}`}>
                        <Memberships organization={organization} />
                    </TabPane>
                ))}
            </TabContent>
        </div>
    );
};

export default Profile;
